import React, { useState } from "react";
import checkMarkGreen from "../../assets/images/svg/location-green-icon.svg";
import ReactPortal from "../../components/Portal/ReactPortal";
import SubscribePlanModal from "../../components/Modals/SubscribePlanModal";
import { capitalizeString, convertNumberToCommaNotation, lowerCaseString } from "../../utils/utils";
import { useSelector } from "react-redux";
import { getUserPlanData } from "../../store/slicers/authSlice";
import { autoBatchEnhancer } from "@reduxjs/toolkit";

import { useElements, useStripe, CardCvcElement, Elements } from "@stripe/react-stripe-js";
import { errorMessage, successMessage } from "../../utils/messages";

const BillingCard = (props) => {
    const {
        handleSubscribePlan,
        userPlan,
        billingState,
        setbillingState,
        data,
        index,
        selectedPlanIndex,
        stripeTestPromise,
    } = props;
    // const [openSubscribeModal, setOpenSubscribeModal] = useState(false);
    const [hoverText, setHoverText] = useState("Subscribe");
    const userPlanData = useSelector(getUserPlanData);
    // console.log("userPlan", userPlanData);
    // console.log("userPlan", userPlan);
    const [hoverButton, setHoverButton] = useState(true);
    const handleMouseOver = () => {
        setHoverButton(false);
        setHoverText("Subscribe");
    };

    const handleMouseOut = () => {
        setHoverButton(true);
        setHoverText("Subscribe");
    };

    return (
        <>
            <div className={`card-wrapper ${lowerCaseString(data?.planName)}`}>
                <div className="header">
                    <h4>{capitalizeString(data?.planName)}</h4>
                    {data?.planName === "BUSINESS" && <h6>Popular</h6>}
                </div>
                <div className="content">
                    <div className="pricing">
                        <h5>
                            <span>$</span>
                            {data?.price}
                        </h5>
                        <p>
                            Per month <br /> <span>(Billed in USD)</span>
                        </p>
                    </div>

                    <div className="button-wrapper">
                        <button
                            className={`${
                                !userPlanData.endDate && userPlan === data?.planName ? "active" : ""
                            }`}
                            style={{
                                pointerEvents: `${
                                    (!userPlanData?.endDate && userPlan === data?.planName) ||
                                    // userPlanData.endDate
                                    (!userPlanData?.endDate &&
                                        data.position < selectedPlanIndex.position)
                                        ? "none"
                                        : "auto"

                                    // !userPlanData.endDate ||
                                    // data.position < selectedPlanIndex.position
                                    //     ? "none"
                                    //     : "auto"
                                }`,
                                // if userPlan === Free hua toh curser auto
                            }}
                            onClick={() =>
                                setbillingState((prevState) => {
                                    return {
                                        ...prevState,
                                        openSubscribeModal: true,
                                        planModalOpen: data.planName,
                                    };
                                })
                            }
                            onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                        >
                            {userPlan === data?.planName && !userPlanData.endDate ? (
                                <>Current Plan</>
                            ) : (
                                <>{hoverText}</>
                            )}
                        </button>
                    </div>
                    <div className="specification-checklist">
                        <div className="checklist">
                            <img src={checkMarkGreen} alt="checkbox" />
                            <p>
                                <strong>{data?.usersCount}</strong> Users
                            </p>
                        </div>
                        <div className="checklist">
                            <img src={checkMarkGreen} alt="checkbox" />
                            <p>
                                <strong>{convertNumberToCommaNotation(data?.markersPerMap)}</strong>{" "}
                                Markers per map
                            </p>
                        </div>
                        <div className="checklist">
                            <img src={checkMarkGreen} alt="checkbox" />
                            <p>
                                <strong>{convertNumberToCommaNotation(data?.sharedMaps)}</strong>{" "}
                                Shared Maps
                            </p>
                        </div>
                        <div className="checklist">
                            <img src={checkMarkGreen} alt="checkbox" />
                            <p>
                                <strong>
                                    {convertNumberToCommaNotation(data?.pointRouteOptimization)}
                                </strong>{" "}
                                Point route optimization
                            </p>
                        </div>
                        <div className="checklist">
                            <img src={checkMarkGreen} alt="checkbox" />
                            <p>
                                <strong>{convertNumberToCommaNotation(data?.viewsPerMonth)}</strong>{" "}
                                Views per month
                            </p>
                        </div>
                        <div className="checklist">
                            <img src={checkMarkGreen} alt="checkbox" />
                            <p>
                                <strong>
                                    {convertNumberToCommaNotation(data?.markersPerMonth)}
                                </strong>{" "}
                                Markers per month
                            </p>
                        </div>
                        <div className="checklist">
                            <img src={checkMarkGreen} alt="checkbox" />
                            <p>While labeling (FREE in Beta)</p>
                        </div>
                        <div className="checklist">
                            <img src={checkMarkGreen} alt="checkbox" />
                            <p>
                                <strong>
                                    {convertNumberToCommaNotation(data?.optimizedRoutes)}
                                </strong>{" "}
                                Optimized routes
                            </p>
                        </div>
                        <div className="checklist">
                            <img src={checkMarkGreen} alt="checkbox" />
                            <p>
                                <strong>{convertNumberToCommaNotation(data?.groups)}</strong> Groups
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {!!billingState.openSubscribeModal && billingState.planModalOpen === data?.planName && (
                <Elements stripe={stripeTestPromise}>
                    <ReactPortal wrapperId="external_modal_container">
                        <SubscribePlanModal
                            title={data?.planName}
                            handleSubscribePlan={handleSubscribePlan}
                            price={data?.price}
                            billingState={billingState}
                            setbillingState={setbillingState}
                        />
                    </ReactPortal>
                </Elements>
            )}
        </>
    );
};

export default BillingCard;
