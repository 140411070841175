import React, { useEffect, useState } from "react";
import noImage from "../../../assets/images/noImage.png";
import { useDispatch, useSelector } from "react-redux";
import { setMapDashboardId } from "../../../store/slicers/mapDashboardSlice";
import starGrey from "../../../assets/images/star-grey.png";
import starGolden from "../../../assets/images/star-golden.png";
import userIcon from "../../../assets/images/svg/userIcon.svg";
import dummyUser from "../../../assets/images/avatar-grey.png";
import phoneIcon from "../../../assets/images/svg/phoneIcon.svg";
import emailIcon from "../../../assets/images/svg/emailIcon.svg";
import chatIcon from "../../../assets/images/svg/chatIcon.svg";
import ImageListView from "../../../components/ImageListView/ImageListView";
import TrashIcon from "../../../components/icons/TrashIcon";
import ShareIcon from "../../../components/icons/ShareIcon";
import closeIconGrey from "../../../assets/images/svg/closeIconGrey.svg";
import EditIcon from "../../../components/icons/EditIcon";
import ReactPortal from "../../../components/Portal/ReactPortal";
import ChevronRightIcon from "../../../components/icons/ChevronRightIcon";
import { setGroupType } from "../../../store/slicers/userMapSlice";

const SharedMapListItem = (props) => {
    const mapDashboardId = useSelector((state) => state.mapDashboardState.id);
    const dispatch = useDispatch();

    const {
        data,
        myMapFavHandle,
        handleShare,
        handleToggleMapDelete,
        handleShowShared,
        sharedState,
        setSharedState,
        handleEdit,
        pinsLoading,
        setOwnerModelState,
        modelClose,
        GetSharedMapGroups,
    } = props;

    const [mapListState, setMapListState] = useState({
        showOptions: false,
        userModal: false,
        mouseCordinates: {
            x: null,
            y: null,
        },
    });

    const toggleUserModal = (e) => {
        e.stopPropagation();
        setMapListState((prevState) => {
            return {
                ...prevState,
                userModal: !prevState.userModal,
                mouseCordinates: {
                    x: e.clientX,
                    y: e.clientY + 10,
                },
            };
        });
    };

    const handleCheckboxChange = (id, title) => {
        if (sharedState.checkListArr.some((item) => item.id === id)) {
            const updatedIds = sharedState.checkListArr.filter((item) => item.id !== id);
            setSharedState((prevState) => {
                return {
                    ...prevState,
                    checkListArr: updatedIds,
                };
            });
        } else {
            setSharedState((prevState) => {
                return {
                    ...prevState,
                    checkListArr: [...sharedState.checkListArr, { id: id, title: title }],
                };
            });
        }
    };

    const handleSharedGroupOpen = () => {
        setSharedState((prev) => {
            return {
                ...prev,
                showShared: true,
            };
        });
    };

    const handleSharedMapGroups = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setSharedState((prevState) => {
            return {
                ...prevState,
                activeMapGroupID: data._id,
                activeGroupGroupID: null,
            };
        });
        GetSharedMapGroups(data?.map?._id);
        handleSharedGroupOpen();
    };

    const handleSharedMapSelect = () => {
        setSharedState((prevState) => {
            return {
                ...prevState,
                activeMapGroupID: null,
                parentId: data?.map?._id,
                isGroupSelected: false,
                firstGroups: false,
                secondGroups: false,
            };
        });
        modelClose();

        dispatch(setMapDashboardId(data?.map?._id));
        dispatch(setGroupType(null));
    };

    return (
        <li
            className={`myMapList-data-item ${mapDashboardId === data?.map?._id ? "active" : ""} ${
                pinsLoading ? "clickable-none" : ""
            }`}
            onClick={(e) => handleSharedMapSelect(e)}
        >
            <div
                className={`myMapList-data-item-inner ${
                    sharedState.activeMapGroupID === data._id || mapDashboardId === data?.map?._id
                        ? "active"
                        : ""
                }`}
            >
                <div className="flex-gap-y5">
                    <div className="myMap-img">
                        <div className="list-input-wrapper">
                            {sharedState.showCheckList && (
                                <>
                                    <label
                                        className="custom-label"
                                        htmlFor={`${data._id}`}
                                        style={{ paddingLeft: 15 }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        <input
                                            type="checkbox"
                                            name={`${data._id}`}
                                            id={`${data._id}`}
                                            value={data._id}
                                            onChange={() => {
                                                handleCheckboxChange(data._id, data.map.title);
                                            }}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </>
                            )}
                            <img
                                src={data?.map?.favorite ? starGolden : starGrey}
                                className="fav-star"
                                alt="Favourite Map"
                                title="Mark as favorite"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    myMapFavHandle(data?._id, !data?.map?.favorite, "share-map");
                                }}
                            />
                        </div>
                        <img
                            src={
                                data.type === "map"
                                    ? data?.map_id?.mapImage || noImage
                                    : data?.group_id?.mapImage || noImage
                            }
                            className="myMap-list-img"
                            alt="map-list"
                        />
                    </div>
                    <div className="mapList-data">
                        <h5 className="mapList-title">
                            {data.type === "map" ? data?.map_id?.title : data?.group_id?.title}
                        </h5>

                        {data?.sender_id && (
                            <div className="myMapList-people-shared">
                                {data?.sender_id && (
                                    <div onClick={toggleUserModal}>
                                        <img
                                            src={data?.sender_id?.avatar || dummyUser}
                                            className={`myMap-sharedlist-img owner`}
                                            alt="shared-user"
                                        />
                                    </div>
                                )}
                                {data.map?.shared?.length ? (
                                    <div
                                        onClick={(e) => {
                                            handleShowShared(true);
                                        }}
                                    >
                                        <ImageListView data={data?.map?.shared} />
                                    </div>
                                ) : (
                                    <div className="no-shared-user-wrapper">
                                        <img src={dummyUser} alt="user" />
                                    </div>
                                )}
                            </div>
                        )}
                        <button
                            className="shared_mapList_btn"
                            onClick={(e) => handleSharedMapGroups(e)}
                        >
                            Container
                            <ChevronRightIcon />
                        </button>
                    </div>
                </div>

                <div className="myMap-config options">
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            handleShare(data._id, data?.map?.shared, "shared-map");
                            props.setModalMapTitle(data?.map?.title);
                        }}
                    >
                        <ShareIcon />
                    </button>

                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            props?.handleToggleMapDelete(
                                data?._id,
                                data?.map?._id,
                                data?.parent_group,
                                "delete-share"
                            );
                            props.setModalMapTitle(data?.map?.title);
                        }}
                    >
                        <TrashIcon />
                    </button>
                </div>
            </div>

            {mapListState.userModal && (
                <div className="transparent-block-modal">
                    <div
                        className="sharedMap-user--modal"
                        style={{
                            top: mapListState?.mouseCordinates?.y,
                            left: mapListState?.mouseCordinates?.x,
                        }}
                    >
                        <img
                            src={closeIconGrey}
                            className="close-modal"
                            alt="close icon"
                            onClick={toggleUserModal}
                        />
                        <div className="user-img">
                            <img
                                src={data?.sender_id?.avatar || dummyUser}
                                className="sharedMap-user-img"
                                alt="map-list"
                            />
                        </div>
                        <h6 className="user-name">{`${data?.sender_id?.first_name} ${data?.sender_id?.last_name}`}</h6>
                        <div className="sharedMap-user--details">
                            <div className="info-icons">
                                <a title="Phone" href={`tel:${data.sender_id}`}>
                                    <img src={chatIcon} alt="chat icon" />
                                    <span>Message</span>
                                </a>
                                <a
                                    title="Phone"
                                    href={`tel:${data?.sender_id?.countryCode}${data?.sender_id?.stateCode}${data?.sender_id?.mobile}`}
                                >
                                    <img src={phoneIcon} alt="phone icon" />
                                    <span>Call</span>
                                </a>
                                <a href={`mailto:${data?.sender_id?.email}`} title="Email">
                                    <img src={emailIcon} alt="email icon" />
                                    <span>Email</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </li>
    );
};

export default SharedMapListItem;
