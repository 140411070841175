import React, { useState } from "react";
import closeIconGrey from "../../assets/images/svg/closeIconGrey.svg";
import DotLoader from "../../components/DotLoaderBtn/DotLoader";

const FilterGroups = (props) => {
    const { handleSaveFilterGroup, filterGroupState, kmlGroupsRef } = props;
    // console.log("data of states 1", handleSaveFilterGroup);
    // console.log("data of states 2", filterGroupState);
    // console.log("data of states 3", kmlGroupsRef);

    const [filterState, setFilterState] = useState(
        Object.keys(kmlGroupsRef.current).map((key) => ({ ...kmlGroupsRef.current[key], key }))
    );
    console.log("kmlGroupsRef", kmlGroupsRef);
    console.log("data of Groups Filter", filterState);

    const handleGroupCheck = (key, checked) => {
        setFilterState((prevState) => {
            const group = prevState.find((g) => g.key === key);
            group.checked = checked;
            return [...prevState];
        });
    };

    return (
        <>
            <h5>Save to My Groups</h5>
            <div className="content-wrapper">
                {filterState.map((item) => {
                    return (
                        <div className="formgroup-flex" key={item.key}>
                            <label className="custom-label" htmlFor={item.groupName}>
                                {item.groupName} <span>({item.pins.length} Markers)</span>
                                <input
                                    type="checkbox"
                                    name="clustering"
                                    id={item.groupName}
                                    defaultChecked={item.checked}
                                    onChange={(e) => handleGroupCheck(item.key, e.target.checked)}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    );
                })}
            </div>
            <div className="content-wrapper-line"></div>
            <div className="form-actions">
                <button
                    type="button"
                    onClick={props.resetFilters}
                    aria-label="Close"
                    className="cancel"
                    disabled={filterGroupState.loading}
                >
                    Cancel
                </button>
                {filterGroupState.loading ? (
                    <button
                        className="btn animate-dots-blue cta-border-blue-auto"
                        type="button"
                        disabled
                    >
                        <DotLoader />
                    </button>
                ) : (
                    <button
                        type="button"
                        className="save"
                        onClick={() => handleSaveFilterGroup(filterState)}
                        disabled={filterState.every((item) => !item.checked)}
                    >
                        Save
                    </button>
                )}
            </div>
            <div className="create-group-note flex">
                <p>
                    NOTE: Markers selected in previous groups will not appear in new groups within
                    the same map.<a href="#">Read More</a>
                </p>
            </div>
        </>
    );
};

export default FilterGroups;
