import React from "react";
import { useState } from "react";
import closeIcon from "../../assets/images/svg/closeIconGrey.svg";
import eyeIcon from "../../assets/images/svg/eyeIcon.svg";
import eyeIconOff from "../../assets/images/svg/eyeIconOff.svg";
import checkMark from "../../assets/images/svg/checkmark.svg";
import checkMarkGreen from "../../assets/images/svg/checkMark-green.svg";
import DotLoader from "../../components/DotLoaderBtn/DotLoader";

const ResetPasswordUI = (props) => {
    const { passwordState, setPasswordState, handleSubmit, loading } = props;

    const [regexState, setRegexState] = useState({
        stringLength: false,
        upperCase: false,
        lowerCase: false,
        specialChar: false,
        numberVal: false,
    });
    const validatePassword = (password) => {
        const checkPassword = {
            stringLength: password.length >= 8,
            upperCase: /[A-Z]/g.test(password),
            lowerCase: /[a-z]/g.test(password),
            specialChar: /(?=.*?[@!#$%+/=-])/g.test(password),
            numberVal: /\d/g.test(password),
        };
        setRegexState(checkPassword);
    };

    const handleCPassword = (e) => {
        let checkPassword = e.target.value;
        setPasswordState((prevState) => {
            return {
                ...prevState,
                confirmPassword: checkPassword,
                passwordMatch: passwordState.password.startsWith(checkPassword),
            };
        });
    };
    return (
        <section className="change-password--container">
            <div className="change-password--wrapper">
                <div className="content-wrapper">
                    <button
                        className="close-button"
                        type="button"
                        onClick={() => {
                            setPasswordState((prevState) => {
                                return {
                                    ...prevState,
                                    showPasswordModal: false,
                                    password: "",
                                    confirmPassword: "",
                                };
                            });
                        }}
                    >
                        <img src={closeIcon} alt="close icon" />
                    </button>
                    <form onSubmit={handleSubmit}>
                        <h2>Change password</h2>
                        {passwordState.showOldFeild && (
                            <div className="change-password-input-container">
                                <label>Old password</label>
                                <div className="pos-rel">
                                    <input
                                        type={passwordState.showOldPassword ? "text" : "password"}
                                        aria-label="Old password"
                                        onChange={(e) => {
                                            setPasswordState((prevState) => {
                                                return {
                                                    ...prevState,
                                                    oldPassword: e.target.value,
                                                };
                                            });
                                        }}
                                        required
                                    />
                                    <img
                                        src={passwordState.showOldPassword ? eyeIconOff : eyeIcon}
                                        className="eye-password-toggle"
                                        alt="eye-password-toggle"
                                        style={{ top: 8, right: 8, width: 20 }}
                                        onClick={() => {
                                            setPasswordState((prevState) => {
                                                return {
                                                    ...prevState,
                                                    showOldPassword: !prevState.showOldPassword,
                                                };
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        )}

                        <div className="change-password-input-container">
                            <label>New password</label>
                            <div className="pos-rel">
                                <input
                                    type={passwordState.showPassword ? "text" : "password"}
                                    aria-label="Password"
                                    onChange={(e) => {
                                        let passValue = e.target.value.trim();
                                        validatePassword(passValue);
                                        setPasswordState((prevState) => {
                                            return {
                                                ...prevState,
                                                password: passValue,
                                            };
                                        });
                                    }}
                                    required
                                />
                                <img
                                    src={passwordState.showPassword ? eyeIconOff : eyeIcon}
                                    className="eye-password-toggle"
                                    alt="eye-password-toggle"
                                    style={{ top: 8, right: 8, width: 20 }}
                                    onClick={() => {
                                        setPasswordState((prevState) => {
                                            return {
                                                ...prevState,
                                                showPassword: !prevState.showPassword,
                                            };
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="change-password-specs">
                            <p>Your password must contain:</p>

                            <div className="checklist">
                                <img
                                    src={regexState.stringLength ? checkMarkGreen : checkMark}
                                    alt="checkbox"
                                />
                                <span>Eight character minimum</span>
                            </div>
                            <div className="checklist">
                                <img
                                    src={regexState.upperCase ? checkMarkGreen : checkMark}
                                    alt="checkbox"
                                />
                                <span>One uppercase character</span>
                            </div>
                            <div className="checklist">
                                <img
                                    src={regexState.lowerCase ? checkMarkGreen : checkMark}
                                    alt="checkbox"
                                />
                                <span>One lowercase character</span>
                            </div>
                            <div className="checklist">
                                <img
                                    src={regexState.numberVal ? checkMarkGreen : checkMark}
                                    alt="checkbox"
                                />
                                <span>One number</span>
                            </div>
                            <div className="checklist">
                                <img
                                    src={regexState.specialChar ? checkMarkGreen : checkMark}
                                    alt="checkbox"
                                />
                                <span>One special character: @ ! # $ % + / = -</span>
                            </div>
                        </div>

                        <div className="change-password-input-container">
                            <label>Confirm password</label>
                            <input
                                type="password"
                                aria-label="Password"
                                onChange={handleCPassword}
                                required
                            />
                            {!passwordState.passwordMatch && (
                                <div className="passwordError"> Error: Passwords do not match </div>
                            )}
                        </div>

                        {loading ? (
                            <button
                                className="btn animate-dots-blue login-load-outline-btn"
                                type="button"
                                disabled
                                style={{ width: "100%" }}
                            >
                                <DotLoader />
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className="forget-recover-cta"
                                disabled={
                                    passwordState.password === passwordState.confirmPassword &&
                                    passwordState.password.trim() &&
                                    regexState.stringLength &&
                                    regexState.upperCase &&
                                    regexState.lowerCase &&
                                    regexState.specialChar &&
                                    regexState.numberVal
                                        ? false
                                        : true
                                }
                            >
                                Save
                            </button>
                        )}
                    </form>
                </div>
            </div>
        </section>
    );
};

export default ResetPasswordUI;
