import React from "react";
import { Link } from "react-router-dom";
import warningIcon from "../../assets/images/warning.png";
import { debounce } from "../../utils/utils";

const CreateMapMaxMarkersLimitModal = (props) => {
    const { dropState, setDropState } = props;

    return (
        <div className="createMapMapMarkers--modal">
            <div className="text-center">
                <img src={warningIcon} alt="xoom maps warning" />
            </div>
            <div className="content">
                <p>
                    {`Your current plan does not support mapping more than ${dropState?.pinsCount} records. Would you
                    like to proceed with the first ${dropState?.pinsCount} records or`}{" "}
                    <Link to="/billing">upgrade</Link> your subscription?
                </p>
            </div>
            <div className="cta-wrapper">
                <button
                    type="button"
                    onClick={() =>
                        setDropState((prevState) => {
                            return {
                                ...prevState,
                                showMaxMarkersModal: false,
                            };
                        })
                    }
                    aria-label="Close"
                    className="cancel-border-btn"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    onClick={debounce(() => {
                        props.updatingRef.current = false;
                        props.handleMapNow();
                    }, 300)}
                    className="btn cta-filled-blue-auto"
                >
                    Continue
                </button>
            </div>
        </div>
    );
};

export default CreateMapMaxMarkersLimitModal;
