import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import sendIcon from "../../assets/images/svg/send-icon.svg";
import closeIconGrey from "../../assets/images/svg/closeIconGrey.svg";
import searchGreyIcon from "../../assets/images/svg/searchGreyIcon.svg";
import { checkEmailValidation, debounce, getType } from "../../utils/utils";
import { useGetConnectedUsersQuery } from "../../services/baseApi";
import { getAllConnectedUsers, setConnectedUsers } from "../../store/slicers/usersListSlice";
import SharedTypeList from "../Map/SharedList/SharedTypeList";

const ShareModal = (props) => {
    const { user } = useSelector((state) => state.auth);
    const { connectedUsers } = useSelector(getAllConnectedUsers);
    const dispatch = useDispatch();

    const [connectedUsersFilter, setConnectedUsersFilter] = useState({
        email: user.email,
        pageNum: 1,
        filter: 1,
        search: "",
    });

    const {
        shareMapEmail,
        shareEmail,
        setShareEmail,
        setConfirmShareOpen,
        mapDataTypeState,
        setBulkData,
        bulkData,
        setBulkEmail,
        bulkEmail,
        title,
    } = props;

    const [connectedUsersPageLoading, setConnectedUsersPageLoading] = useState(false);

    const [searchState, setSearchState] = useState("");
    let isValidEmail = checkEmailValidation([{ email: searchState }]);

    let type = getType(mapDataTypeState);

    let paramConnectedUsers = {
        email: user?.email,
        mapId: mapDataTypeState.id,
        mapType: type,
        pageNum: connectedUsersFilter?.pageNum,
        filter: connectedUsersFilter?.filter,
        search: connectedUsersFilter?.search,
    };

    // GETTING CONNECTED USERS
    const { data: dataOfConnectedUsers, isFetching } =
        useGetConnectedUsersQuery(paramConnectedUsers);

    useEffect(() => {
        if (isFetching) {
            setConnectedUsersPageLoading(true);
        }
        if (dataOfConnectedUsers) {
            dispatch(setConnectedUsers(dataOfConnectedUsers));
        }
        if (!isFetching) {
            setConnectedUsersPageLoading(false);
        }
    }, [dispatch, dataOfConnectedUsers, isFetching]);

    const handleShareClose = () => {
        setConfirmShareOpen(false);
        setSearchState("");
    };
    const handleShareEmailSearch = (e, emailId) => {
        shareMapEmail(e, emailId);
        setSearchState("");
    };

    return (
        <>
            <section className="shareMap-modal--container">
                <div className="shareMap-modal-wrapper">
                    {/* <form onSubmit={shareMapEmail}> */}
                    <div className="shareMap-header">
                        {/* closeIcon */}
                        <div className="share-close-icon">
                            <button
                                type="button"
                                className="share-close-icon"
                                aria-label="Close"
                                onClick={handleShareClose}
                            >
                                <img src={closeIconGrey} alt="search move pin" />
                            </button>
                        </div>
                        {/* closeIcon */}
                        <h6>Share</h6>
                        <h5 className="title">{title}</h5>
                        <div className="movePin-search-box">
                            <input
                                type="email"
                                name="myMapListSearch"
                                defaultValue={setSearchState || ""}
                                placeholder="Search..."
                                onChange={debounce((e) => {
                                    let value = e.target.value.trim().toLowerCase();
                                    setSearchState(value);
                                    setShareEmail((prevState) => {
                                        return {
                                            ...prevState,
                                            value: value,
                                        };
                                    });
                                }, 1000)}
                                autoComplete="off"
                            />
                            {isValidEmail ? (
                                <img
                                    src={sendIcon}
                                    alt="search move pin"
                                    onClick={(e) =>
                                        handleShareEmailSearch(e, [{ email: searchState }])
                                    }
                                    style={{ width: 20, top: 8, cursor: "pointer" }}
                                />
                            ) : (
                                <img src={searchGreyIcon} alt="search move pin" />
                            )}
                        </div>
                    </div>
                    {/* </form> */}
                    <SharedTypeList
                        bulkEmail={bulkEmail}
                        setBulkEmail={setBulkEmail}
                        setBulkData={setBulkData}
                        bulkData={bulkData}
                        setShareEmail={setShareEmail}
                        searchState={searchState}
                        shareEmail={shareEmail}
                        mapDataTypeState={mapDataTypeState}
                        connectedUsersPageLoading={connectedUsersPageLoading}
                        connectedUsers={connectedUsers}
                        shareMapEmail={shareMapEmail}
                        setConfirmShareOpen={setConfirmShareOpen}
                        setConnectedUsersFilter={setConnectedUsersFilter}
                        connectedUsersFilter={connectedUsersFilter}
                        setSearchState={setSearchState}
                    />
                </div>
            </section>
        </>
    );
};

export default ShareModal;
