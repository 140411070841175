import React, { useEffect } from "react";
import { useState } from "react";
import searchWhiteIcon from "../../../assets/images/svg/searchGreyIcon.svg";
import dummyUser from "../../../assets/images/avatar-grey.png";
import closeIcon from "../../../assets/images//svg/closeIconGrey.svg";
import { useSelector } from "react-redux";
import ThreeDotIcon from "../../../components/icons/ThreeDotIcon";
import TrashIcon from "../../../components/icons/TrashIcon";
import PhoneIcon from "../../../components/icons/PhoneIcon";
import EmailIcon from "../../../components/icons/EmailIcon";
import ChatIcon from "../../../components/icons/ChatIcon";

const MyMapSharedList = (props) => {
    const mapDashboardId = useSelector((state) => state.mapDashboardState.id);

    const [sharedState, setSharedState] = useState({
        searchItem: "",
        data: props.data,
    });

    useEffect(() => {
        setSharedState((prevState) => {
            return {
                ...prevState,
                data: props.data?.filter((item) => {
                    return (
                        item.first_name
                            ?.toLowerCase()
                            .includes(sharedState.searchItem.toLowerCase()) ||
                        item.last_name
                            ?.toLowerCase()
                            .includes(sharedState.searchItem.toLowerCase()) ||
                        item.email?.toLowerCase().includes(sharedState.searchItem.toLowerCase())
                    );
                }),
            };
        });
    }, [sharedState.searchItem]);

    useEffect(() => {
        setSharedState((prevState) => {
            return {
                ...prevState,
                data: props.data,
            };
        });
    }, [mapDashboardId, props.data]);

    return (
        <>
            <section className="myMapSharedUserList-container">
                <div className="myMapShared-title">
                    <div className="mapList-search-sec">
                        <input
                            type="text"
                            name="myMapListSearch"
                            id=""
                            placeholder="Search name, email…"
                            autoComplete="off"
                            defaultValue={sharedState.searchItem}
                            onChange={(e) =>
                                setSharedState((prevState) => {
                                    return {
                                        ...prevState,
                                        searchItem: e.target.value,
                                    };
                                })
                            }
                        />
                        <img src={searchWhiteIcon} className="searchIcon" alt="search icon" />
                        <div className="horizontal-bar"></div>
                        <img
                            src={closeIcon}
                            alt="close icon"
                            onClick={() => props.handleClosedSharedList()}
                        />
                    </div>
                </div>

                {!sharedState.data?.length ? (
                    <div className="myMap-sharedList--items">
                        <p className="text-center">No users</p>
                    </div>
                ) : (
                    <div
                        className={`${
                            sharedState.data?.length > 3
                                ? "myMap-sharedList--items"
                                : "myMap-sharedList--items overflowYunset"
                        }`}
                    >
                        {sharedState.data.map((item, index) => {
                            return (
                                <div className="myMapShared-list" key={index}>
                                    <div className="user-email">
                                        {item?.avatar && item?.avatar !== "null" ? (
                                            <>
                                                <img
                                                    src={item?.avatar || dummyUser}
                                                    className="myMap-sharedlist-img"
                                                    alt="My-map-shared-user"
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <span
                                                    className="user-initials-circle"
                                                    key={index}
                                                    style={{ marginRight: 5 }}
                                                >
                                                    {item.first_name?.[0]}
                                                    {item.last_name?.[0]}
                                                </span>
                                            </>
                                        )}

                                        <div>
                                            <h5>
                                                {item.first_name ? (
                                                    <>
                                                        {item?.first_name} {item?.last_name}{" "}
                                                        {item?.name}
                                                    </>
                                                ) : (
                                                    <>New User</>
                                                )}
                                            </h5>
                                            <p>{item?.email}</p>
                                            {!item?.isRegistered && (
                                                <p style={{ fontSize: "11px" }}>
                                                    User not registered
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="three-dot-wrapper">
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                props.setShowState((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        sharedOptionID: item?._id,
                                                        sharedShowOptions:
                                                            item._id === prevState.sharedOptionID
                                                                ? !prevState.sharedShowOptions
                                                                : true,
                                                    };
                                                });
                                            }}
                                        >
                                            <ThreeDotIcon fill="#999b9e" color="#999b9e" />
                                        </button>
                                        <div className="myMap-config options">
                                            <button>
                                                <a href="/" title="Email">
                                                    <ChatIcon />
                                                    <span>Message</span>
                                                </a>
                                            </button>
                                            <button>
                                                <a href={`mailto:${item?.email}`} title="Email">
                                                    <EmailIcon />
                                                    <span>Email</span>
                                                </a>
                                            </button>
                                            <button>
                                                <a title="Phone" href={`tel:${item?.mobile}`}>
                                                    <PhoneIcon />
                                                    <span>Phone</span>
                                                </a>
                                            </button>
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    props.handleToggleSharedUserDelete(
                                                        item?.email,
                                                        props.type,
                                                        (item.first_name ?? "New") +
                                                            " " +
                                                            (item.last_name ?? "User")
                                                    );
                                                }}
                                            >
                                                <TrashIcon /> <span>Remove</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </section>
        </>
    );
};

export default MyMapSharedList;
