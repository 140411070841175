import React from "react";
import closeIconGrey from "../../assets/images/svg/closeIconGrey.svg";
import PrintIcon from "../icons/PrintIcon";
import FileIcon from "../icons/FileIcon";
import { useNavigate } from "react-router-dom";
import { printMap } from "../../utils/utils";
import { useSelector } from "react-redux";

const DashboardSettingOptionsModel = (props) => {
    const {
        handleMapSetting,
        handleShowPDF,
        setContextOptions,
        handleContextClick,
        showState,
        showGroupState,
    } = props;
    const mapDashboardId = useSelector((state) => state.mapDashboardState.id);

    const navigate = useNavigate();

    return (
        <>
            <div className="mapView-settings-list-box">
                {handleMapSetting && (
                    <div className="mapSettings-title-box">
                        <div className="close-wrap">
                            <img
                                src={closeIconGrey}
                                alt="close icon"
                                onClick={() => {
                                    if (handleMapSetting) {
                                        handleMapSetting();
                                    }
                                    if (setContextOptions) {
                                        handleContextClick();
                                    }
                                }}
                            />
                        </div>

                        <h6>Options</h6>
                    </div>
                )}
                <ul>
                    <li
                        onClick={() => {
                            if (setContextOptions) {
                                handleContextClick();
                            }
                            if (showState?.isGroupSelected || showGroupState?.isGroupSelected) {
                                return;
                            } else {
                                navigate(`/edit-map/${mapDashboardId}`);
                            }
                        }}
                    >
                        <svg
                            id="Edit"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <rect
                                id="Boundary"
                                width="24"
                                height="24"
                                rx="5"
                                fill="#eee"
                                opacity="0"
                            />
                            <path
                                id="Icon_material-edit"
                                data-name="Icon material-edit"
                                d="M4.5,17.163V20.5H7.833l9.83-9.83L14.33,7.334ZM20.24,8.089a.885.885,0,0,0,0-1.253l-2.08-2.08a.885.885,0,0,0-1.253,0L15.281,6.383l3.333,3.333Z"
                                transform="translate(-0.5 -0.496)"
                                fill="#999b9e"
                            />
                        </svg>

                        <span>Edit Map</span>
                    </li>

                    <li
                        onClick={() => {
                            if (setContextOptions && handleContextClick) {
                                handleContextClick();
                            }
                            props?.hideBars();

                            printMap();
                            setTimeout(() => {
                                props?.showBars();
                            }, 2000);
                        }}
                    >
                        <PrintIcon />

                        <span>Print</span>
                    </li>
                    <li
                        onClick={() => {
                            if (setContextOptions && handleContextClick) {
                                handleContextClick();
                            }
                            handleShowPDF();
                        }}
                    >
                        <FileIcon />

                        <span>Export into PDF / Image</span>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default DashboardSettingOptionsModel;
