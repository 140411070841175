import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { calculatePercentage, capitalizeString, getPercentageColor } from "../../utils/utils";
import { errorMessage } from "../../utils/messages";
import { useGetCoworkersQuery } from "../../services/baseApi";
import { useNavigate } from "react-router";
import { getUserPlanData } from "../../store/slicers/authSlice";

const ProfilePlanDetails = (props) => {
    const navigate = useNavigate();
    const userPlanData = useSelector(getUserPlanData);
    const { setManageUsersState, userDataState, setUserDataState, activeTab, mapPercentagesState } =
        props;

    const { user, planData } = useSelector((state) => state.auth);

    const navigateToBilling = () => {
        navigate("/billing");
    };

    // Calculations for the progress bar to calculate formula
    const sharedMapListPercentage =
        calculatePercentage(mapPercentagesState?.mySharedMaps?.length, planData?.sharedMaps) || 0;
    const groupsListPercentage =
        calculatePercentage(mapPercentagesState?.myGroups?.length, planData?.groups) || 0;
    const routesListPercentage =
        calculatePercentage(mapPercentagesState?.myRoutes?.length, planData?.optimizedRoutes) || 0;

    const myMapsListPercentage = parseFloat(
        ((sharedMapListPercentage + groupsListPercentage + routesListPercentage) / 3).toFixed(0) ||
            0
    );

    // Calculations for the user invitation data
    const { data: allCoworkersData, error: allCoworkersError } = useGetCoworkersQuery(user.email);
    useEffect(() => {
        if (allCoworkersData) {
            setUserDataState((prevState) => {
                return {
                    ...prevState,
                    coworkersData: allCoworkersData,
                };
            });
        }

        if (allCoworkersError) {
            errorMessage(allCoworkersError.data.message);
        }
    }, [allCoworkersData, allCoworkersError]);

    const limitImg = 10;

    return (
        <div className="userPlan-container">
            <header>
                <div className="userPlan-wrap">
                    <h6>Current plan</h6>
                    <h3>{capitalizeString(planData?.planName)}</h3>
                </div>
                <div className="subscription-wrap">
                    {planData?.planName !== "ENTERPRISE" && (
                        <button className="cta-filled-blue-auto" onClick={navigateToBilling}>
                            Upgrade
                        </button>
                    )}
                    {planData?.planName === "ENTERPRISE" && (
                        <button
                            className="cta-filled-blue-auto"
                            onClick={(e) =>
                                setManageUsersState((prevState) => ({
                                    ...prevState,
                                    openCallSalesModal: true,
                                }))
                            }
                        >
                            Call sales
                        </button>
                    )}
                    {activeTab === "support" &&
                        planData?.planName !== "FREE" &&
                        !userPlanData.endDate && (
                            <button
                                type="button"
                                className="cancel-subscription"
                                onClick={() => {
                                    setUserDataState((prevState) => {
                                        return {
                                            ...prevState,
                                            showCancelSubscriptionModal: true,
                                        };
                                    });
                                }}
                            >
                                Cancel subscription
                            </button>
                        )}
                </div>
            </header>

            <section className="user-invites">
                <h6>
                    Users invited {userDataState?.coworkersData?.length} / {planData?.usersCount}
                </h6>
                <ul className="myMapList-people-shared">
                    {userDataState?.coworkersData?.length < limitImg ? (
                        userDataState?.coworkersData?.map((data, index) => (
                            <span
                                className="user-initials-circle"
                                style={{ zIndex: data?.length - index || 1 }}
                                key={index}
                            >
                                {data?.avatar ? (
                                    <img src={data.avatar} alt="coworkder avatar" />
                                ) : (
                                    <>
                                        {" "}
                                        {data.name?.[0]}
                                        {data.name?.[1]}
                                    </>
                                )}
                            </span>
                        ))
                    ) : (
                        <>
                            {userDataState?.coworkersData?.slice(0, limitImg).map((data, index) => (
                                <span
                                    className="user-initials-circle"
                                    style={{ zIndex: data?.length - index || 1 }}
                                    key={index}
                                >
                                    {data?.avatar ? (
                                        <img src={data.avatar} alt="coworkder avatar" />
                                    ) : (
                                        <>
                                            {" "}
                                            {data.name?.[0]}
                                            {data.name?.[1]}
                                        </>
                                    )}
                                </span>
                            ))}

                            {userDataState?.coworkersData?.slice(limitImg).length === 0 ? (
                                <></>
                            ) : (
                                <div className="myMapList-people-maxcount">
                                    <span>
                                        +{userDataState?.coworkersData?.slice(limitImg).length}
                                    </span>
                                </div>
                            )}
                        </>
                    )}
                </ul>
            </section>

            <section className="user-storage">
                <h5>Storage</h5>

                <div className="user-storage-content">
                    <div className="storage-block">
                        <div className="left">
                            <p>My Maps</p>
                            <span>
                                {mapPercentagesState?.myMaps?.length} / {planData.maps} maps
                            </span>
                        </div>
                        <div className="right">
                            <div>{myMapsListPercentage}%</div>
                            <div className="progress-bar">
                                <span
                                    className="green"
                                    style={{
                                        width: `${myMapsListPercentage}%`,
                                        backgroundColor: `${getPercentageColor(
                                            myMapsListPercentage
                                        )}`,
                                    }}
                                ></span>
                            </div>
                        </div>
                    </div>

                    <div className="storage-block">
                        <div className="left">
                            <p>Shared Maps</p>
                            <span>
                                {mapPercentagesState?.mySharedMaps?.length} / {planData.sharedMaps}{" "}
                                maps
                            </span>
                        </div>
                        <div className="right">
                            <div>{sharedMapListPercentage}%</div>
                            <div className="progress-bar">
                                <span
                                    className="green"
                                    style={{
                                        width: `${sharedMapListPercentage}%`,
                                        backgroundColor: `${getPercentageColor(
                                            sharedMapListPercentage
                                        )}`,
                                    }}
                                ></span>
                            </div>
                        </div>
                    </div>

                    <div className="storage-block">
                        <div className="left">
                            <p>My Routes</p>
                            <span>
                                {mapPercentagesState?.myRoutes?.length} / {planData.optimizedRoutes}{" "}
                                maps
                            </span>
                        </div>
                        <div className="right">
                            <div>{routesListPercentage}%</div>
                            <div className="progress-bar">
                                <span
                                    className="green"
                                    style={{
                                        width: `${routesListPercentage}%`,
                                        backgroundColor: `${getPercentageColor(
                                            routesListPercentage
                                        )}`,
                                    }}
                                ></span>
                            </div>
                        </div>
                    </div>

                    <div className="storage-block">
                        <div className="left">
                            <p>My Groups</p>
                            <span>
                                {mapPercentagesState?.myGroups?.length} / {planData.groups} maps
                            </span>
                        </div>
                        <div className="right">
                            <div>{groupsListPercentage}%</div>
                            <div className="progress-bar">
                                <span
                                    className="green"
                                    style={{
                                        width: `${groupsListPercentage}%`,
                                        backgroundColor: `${getPercentageColor(
                                            groupsListPercentage
                                        )}`,
                                    }}
                                ></span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ProfilePlanDetails;
