import React from "react";

const HowToVideoBox = (props) => {
    const { url, name, showBorderVideo } = props;
    return (
        <div className={`video-container ${showBorderVideo === name ? "active" : ""}`}>
            <div className="video-wrapper">
                <div className="how-to-video">
                    <iframe
                        style={{
                            borderRadius: `${showBorderVideo === name ? "" : "5px 5px 0px 0px"}`,
                        }}
                        title="how-to-video-player"
                        // width="210"
                        // height="130"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        src={url}
                        frameborder="0"
                    ></iframe>
                    <div className="title">
                        <a href={url} target="_blank" rel="noreferrer">
                            {name}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowToVideoBox;
