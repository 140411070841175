import React from "react";

const CustomToastMsg = (props) => {
    return (
        <div className="custom-toast--wrapper">
            <h5>{props.title}</h5>
            <p>{props.msg}</p>
        </div>
    );
};

export default CustomToastMsg;
