import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    myMapsList: [],
    myGroupslist: [],
};

export const myMapDataSlice = createSlice({
    name: "myMapData",
    initialState,
    reducers: {
        setMyMaps: (state, action) => {
            state.myMapsList = action.payload;
        },
        setMyGroupsList: (state, action) => {
            state.myGroupslist = action.payload;
        },
    },
});

export const { setMyMaps, setMyGroupsList } = myMapDataSlice.actions;

export default myMapDataSlice.reducer;
