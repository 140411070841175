import React from "react";
import preferenceImage from "../../assets/images/preference.png";
import chevronRight from "../../assets/images/svg/chevron-right-poiner.svg";
import infoTrans from "../../assets/images/info-transparent-icon.png";
import { useState } from "react";
import ResetPasswordUI from "../auth/ResetPasswordUI";
import WhiteLabelPopup from "./WhiteLabelPopup";
import { useDispatch, useSelector } from "react-redux";
import ReactPortal from "../../components/Portal/ReactPortal";
import WhiteLabelModal from "../../components/Modals/WhiteLabelModal";
import {
    useToggleTwoStepVerificationMutation,
    useUpdateUserDataMutation,
} from "../../services/baseApi";
import { errorMessage, successMessage } from "../../utils/messages";
import { debounce } from "../../utils/utils";
import { setUserMeasurement } from "../../store/slicers/authSlice";

const whiteLabelPrice = 49.99;
const ProfilePreference = (props) => {
    const [toggleTwoStepVerification] = useToggleTwoStepVerificationMutation();
    const [updateUserData] = useUpdateUserDataMutation();
    const dispatch = useDispatch();

    const {
        userData,
        passwordState,
        setPasswordState,
        preferenceState,
        setPreferenceState,
        handleUpdatePassword,
    } = props;
    const [userMeasureDistance, setUserMeasureDistance] = useState(userData?.measureDistance);

    const onDistanceMeterChange = async (e) => {
        let value = Number(e.target.value);
        setUserMeasureDistance(value);
        const profileData = {
            ...userData,
            measureDistance: value,
        };
        const { data, error } = await updateUserData(profileData);
        if (data) {
            successMessage("Successfully changed");
            dispatch(setUserMeasurement(value));
        }
        if (error) {
            errorMessage(error?.data?.message);
        }
    };

    const { user } = useSelector((state) => state.auth);

    const handleSubscribeWhiteLabel = (e) => {
        e.preventDefault();
        alert("white label func on the way");
    };

    const handleToggleTwoStepVerification = async (e) => {
        e.preventDefault();
        const { data, error } = await toggleTwoStepVerification({
            email: user?.email,
            enableTwoStep: !user?.twoStepVerification,
        });
        if (data) {
            successMessage(data?.message);
        }
        if (error) {
            errorMessage(error?.data?.message);
        }
    };

    return (
        <div id="preference">
            <div className="left">
                <div className="form-group">
                    <button
                        className="profile-changePassword-btn"
                        onClick={() => {
                            setPasswordState((prevState) => {
                                return {
                                    ...prevState,
                                    showPasswordModal: true,
                                };
                            });
                        }}
                    >
                        Change password <img src={chevronRight} alt="right arrow icon" />
                    </button>
                </div>
                <div className="form-group">
                    <div className="form-group-checkbox">
                        <label htmlFor="twoStep">
                            <span>Two-step verification</span>
                        </label>

                        <div>
                            <input
                                type="checkbox"
                                name="twoStep"
                                id="twoStep"
                                hidden
                                defaultChecked={user.twoStepVerification}
                                onChange={debounce(handleToggleTwoStepVerification, 500)}
                            />
                            <label htmlFor="twoStep"></label>
                        </div>
                    </div>
                    <span className="user-profile-info">
                        XoomMaps will send a verification code to your email each time you login.
                    </span>
                </div>

                <div className="form-group">
                    <label htmlFor="distance">Distance measurement</label>
                    <select
                        onChange={(e) => onDistanceMeterChange(e)}
                        name="distance"
                        id="distance"
                        value={userMeasureDistance}
                    >
                        <option value="1">Miles</option>
                        <option value="2">Kilo meters</option>
                    </select>
                </div>

                <div className="profile-preference-tip">
                    <p>
                        TIP: For best performance when using XoomMaps, we recommend Chrome. The
                        fastest browser available, Chrome does especially well with large datasets
                        in XoomMap.
                    </p>
                    <div className="text-right">
                        <img src={preferenceImage} alt="illustration" />
                    </div>
                </div>
            </div>
            <div className="right">
                <div className="form-group">
                    <div className="form-group-checkbox">
                        <label htmlFor="label">
                            <span>
                                White label <strong>${whiteLabelPrice}</strong> / annual
                            </span>{" "}
                            <span>(renews: 1/1/2024)</span>
                        </label>

                        <div>
                            <input
                                type="checkbox"
                                name="label"
                                id="label"
                                hidden
                                defaultChecked={true}
                                onChange={() => {
                                    setPreferenceState((prevState) => {
                                        return {
                                            ...prevState,
                                            showWhiteLabelModal: true,
                                        };
                                    });
                                }}
                            />
                            <label htmlFor="label"></label>
                        </div>
                    </div>
                    <span
                        className="user-profile-info cursor-pointer"
                        onClick={() => {
                            setPreferenceState((prevState) => {
                                return {
                                    ...prevState,
                                    showWhiteLabelPopup: true,
                                };
                            });
                        }}
                    >
                        Customize maps with your logo. <img src={infoTrans} alt="info" width={10} />
                    </span>
                    {preferenceState.showWhiteLabelPopup && (
                        <WhiteLabelPopup setState={setPreferenceState} />
                    )}
                </div>

                <div className="form-group-checkbox">
                    <label htmlFor="darkmode">
                        <span>Dark mode</span>
                    </label>
                    <div>
                        <input type="checkbox" name="darkmode" id="darkmode" hidden />
                        <label htmlFor="darkmode"></label>
                    </div>
                </div>
            </div>
            {passwordState.showPasswordModal && (
                <ResetPasswordUI
                    handleSubmit={handleUpdatePassword}
                    passwordState={passwordState}
                    setPasswordState={setPasswordState}
                    loading={preferenceState.loading}
                />
            )}
            {preferenceState.showWhiteLabelModal && (
                <ReactPortal wrapperId="external_modal_container">
                    <WhiteLabelModal
                        parentState={preferenceState}
                        setParentState={setPreferenceState}
                        handleSubmit={handleSubscribeWhiteLabel}
                        price={whiteLabelPrice}
                    />
                </ReactPortal>
            )}
        </div>
    );
};

export default ProfilePreference;
