export default function ChevronDown({ color = "inherit", fill = "inherit", width = "20px" }) {
    return (
        <svg
            id="Sort-down"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <rect id="Boundary" width="24" height="24" fill="#eee" opacity="0" />
            <path
                id="Icon_awesome-sort-down"
                data-name="Icon awesome-sort-down"
                d="M2.2,20.25h9.981a1.008,1.008,0,0,1,.713,1.719L7.9,26.96a1,1,0,0,1-1.422,0L1.487,21.969A1.008,1.008,0,0,1,2.2,20.25Z"
                transform="translate(4.809 -12.249)"
                fill="#999b9e"
            />
        </svg>
    );
}
