import React, { useEffect, useState } from "react";
import MyMapListItem from "./MyMapListItem";
import MyMapSharedList from "./MyMapSharedList";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { calculatePercentage, convertNumberToCommaNotation } from "../../../utils/utils";
import socket from "../../../socket/socket";
import MyMapGroups from "./MyMapGroups";
import MyGroupGroups from "./MyGroupGroups";
import ReactPortal from "../../../components/Portal/ReactPortal";
import DeleteMultipleMaps from "../../../components/Modals/DeleteMultipleMaps";
import { useDeleteMultipleMapsMutation } from "../../../services/baseApi";
import { errorMessage, successMessage } from "../../../utils/messages";
import ProgressBarMap from "../../../components/ColorPicker/ProgressBarMap";
import { CANCEL_MAP_SOCKET } from "../../../socket/socketsList";
import MapCropperModal from "../../../components/Modals/ImageCropper/MapCropperModal";
import MyMapNocList from "./MyMapNocList";

const MyMapList = (props) => {
    const mapDashboardId = useSelector((state) => state.mapDashboardState.id);
    const navigate = useNavigate();
    const [isHoveredMap, setIsHoveredMap] = useState({
        isHoverd: false,
        id: null,
    });

    const { showState, setShowState, handleHideAllGroups } = props;

    const [groupSharedData, setGroupSharedData] = useState(props.groupData);
    const [secondGroupSharedData, setSecondGroupSharedData] = useState(props.secondGroupData || []);
    const [toggle, setToggle] = useState(false);

    const handleGroupSharedData = (id) => {
        setGroupSharedData(props.groupData?.find?.((group) => group._id === id) || []);
    };
    const handleSecondGroupSharedData = (id) => {
        setSecondGroupSharedData(props.secondGroupData?.find?.((group) => group._id === id) || []);
    };

    const [loadingDeleteMap, setLoadingDeleteMap] = useState(false);
    const [confirmMultipleDeleteMapOpen, setConfirmMultipleDeleteMapOpen] = useState(false);

    const handleHideMyGroups = () => {
        setShowState((prevState) => {
            return {
                ...prevState,
                firstGroups: false,
                secondGroups: false,
                activeMapGroupID: null,
            };
        });
    };

    const handleShowMyGroups = () => {
        setShowState((prevState) => {
            return {
                ...prevState,
                firstGroups: true,
            };
        });
    };

    const handleHideMyGroupGroups = () => {
        setShowState((prevState) => {
            return {
                ...prevState,
                secondGroups: false,
            };
        });
    };

    const handleShowMyGroupGroups = () => {
        setShowState((prevState) => {
            return {
                ...prevState,
                secondGroups: true,
            };
        });
    };

    const handleClosedSharedList = () => {
        setShowState((prevState) => {
            return {
                ...prevState,
                shared: false,
                groupShared: false,
                secondGroupShared: false,
            };
        });
    };

    const handleShowSharedList = () => {
        setShowState((prevState) => {
            return {
                ...prevState,
                shared: true,
                groupShared: false,
            };
        });
    };

    const handleShowMyGroupsShared = () => {
        setShowState((prevState) => {
            return {
                ...prevState,
                shared: false,
                groupShared: true,
                secondGroupShared: false,
            };
        });
    };
    const handleShowMySecondGroupsShared = () => {
        setShowState((prevState) => {
            return {
                ...prevState,
                shared: false,
                groupShared: false,
                secondGroupShared: true,
            };
        });
    };

    const [deleteMultipleMaps] = useDeleteMultipleMapsMutation();

    const handleDeleteMaps = async () => {
        setLoadingDeleteMap(false);
        const { data, error } = await deleteMultipleMaps(showState.checkListArr);

        if (data) {
            successMessage("Successfully removed");
        }

        if (error) {
            errorMessage(error?.data?.message);
        }
        setLoadingDeleteMap(false);
        setConfirmMultipleDeleteMapOpen(false);
        setShowState((prevState) => {
            return {
                ...prevState,
                checkListArr: [],
            };
        });
    };

    const handleCancelMap = (mapID) => () => {
        socket.emit(CANCEL_MAP_SOCKET, { mapID, socketID: socket.id });
    };

    useEffect(() => {
        socket.on(CANCEL_MAP_SOCKET, (res) => {
            if (socket.id === res.socketID) {
                if (res.status) {
                    let cancelledIndex = socket.maps?.findIndex((map) => map.id === res.mapID);
                    if (cancelledIndex === -1) return;

                    socket.maps?.splice(cancelledIndex, 1);
                    successMessage(res.message);
                    setToggle((prevState) => !prevState);
                }
            }
        });

        return () => {
            socket.off(CANCEL_MAP_SOCKET);
        };
    }, []);
    const [activeAccordion, setActiveAccordion] = useState(null);

    const handleAccordionClick = (index) => {
        setActiveAccordion(activeAccordion === index ? null : index);
    };
    const handleMouseEnter = (id) => {
        setIsHoveredMap((prevState) => {
            return {
                ...prevState,
                isHoverd: true,
                id: id,
            };
        });
    };

    const handleMouseLeave = (id) => {
        setIsHoveredMap((prevState) => {
            return {
                ...prevState,
                isHoverd: false,
                id: null,
            };
        });
    };
    const ContextClickingCheck = (e) => {
        if (!e.currentTarget.contains(e.target)) {
            props?.handleContextClick();

            if (e.target.tagName === "BUTTON") {
                console.log("Button clicked:", e.target.textContent);
            }
        }
    };

    return (
        <section onClick={(e) => ContextClickingCheck(e)} className="d-flex">
            {props.isNocUrl ? (
                <MyMapNocList
                    data={props.data}
                    collapseBar={props.collapseBar}
                    handleAccordionClick={handleAccordionClick}
                    handleMouseEnter={handleMouseEnter}
                    handleMouseLeave={handleMouseLeave}
                    isHoveredMap={isHoveredMap}
                    activeAccordion={activeAccordion}
                    getMyMapsGroups={props.getMyMapsGroups}
                    groupsListLoading={props.groupsListLoading}
                    groupData={props.groupData}
                    toggleNocMapMarkers={props.toggleNocMapMarkers}
                />
            ) : (
                <>
                    <section
                        className={`myMapList-container ${
                            props.collapseBar.guageBar ? "collapsebar-active" : ""
                        }`}
                    >
                        <div className="mapList-btns-header">
                            <div className="listCurve-wrapper">
                                <ProgressBarMap percentageValue={props.percentageValue} />
                            </div>
                        </div>
                        {/* SEARCH BAR END */}

                        <section className="custom-maps-container">
                            {socket.maps?.map((map) => {
                                let earned = map.successRows?.length + map.failedRows?.length || 0;
                                let total = map.rawData?.length || 0;
                                let percentage = calculatePercentage(earned, total);

                                if (!map?.[0]?.rowData) {
                                    return (
                                        <div key={map.id}>
                                            {map.status !== "COMPLETED" ? (
                                                <>
                                                    <div className="progress-wrapper progress-container">
                                                        <div className="flex-between">
                                                            <h5 className="title">
                                                                Building your visualization map
                                                            </h5>
                                                            <div className="records-error">
                                                                <button
                                                                    type="button"
                                                                    onClick={handleCancelMap(
                                                                        map.id
                                                                    )}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="incomplete-progress-bar">
                                                            <div
                                                                className="incomplete-progress-bar bg-light-blue"
                                                                role="progressbar"
                                                                aria-valuenow={earned}
                                                                aria-valuemin="0"
                                                                aria-valuemax={total}
                                                                style={{
                                                                    width: `${percentage}%`,
                                                                    animation:
                                                                        " 3s ease 0s 1 normal none running  animate-positive",
                                                                    opacity: 1,
                                                                }}
                                                            ></div>
                                                        </div>
                                                        <div className="incomplete-progress-info">
                                                            <p>Remaining...</p>
                                                            <p>
                                                                {convertNumberToCommaNotation(
                                                                    earned
                                                                )}{" "}
                                                                /{" "}
                                                                {convertNumberToCommaNotation(
                                                                    total
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="progress-wrapper progress-container cursor-pointer">
                                                        <button
                                                            className="navigate-btn"
                                                            onClick={() => {
                                                                if (map.status === "COMPLETED") {
                                                                    navigate(
                                                                        `/create-map/${map.id}`
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            Completed
                                                        </button>
                                                        <div className="incomplete-progress-bar">
                                                            <div
                                                                className="incomplete-progress-bar bg-light-blue"
                                                                role="progressbar"
                                                                aria-valuenow={earned}
                                                                aria-valuemin="0"
                                                                aria-valuemax={total}
                                                                style={{
                                                                    width: `${percentage}%`,
                                                                    animation:
                                                                        " 1s ease 0s 1 normal none running  animate-positive",
                                                                    opacity: 1,
                                                                }}
                                                            ></div>
                                                        </div>
                                                        <div className="incomplete-progress-info ">
                                                            <p>Click to navigate</p>
                                                            <p>
                                                                {convertNumberToCommaNotation(
                                                                    earned
                                                                )}{" "}
                                                                /{" "}
                                                                {convertNumberToCommaNotation(
                                                                    total
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    );
                                }
                            })}
                        </section>

                        <ul className="myMapList-data-container">
                            {props.data?.length ? (
                                props.data
                                    .filter((item) => {
                                        return item.title.toLowerCase();
                                    })
                                    .map((item) => {
                                        return (
                                            <MyMapListItem
                                                data={item}
                                                handleEdit={props.handleEdit}
                                                handleShare={props.handleShare}
                                                key={item._id}
                                                myMapFavHandle={props.myMapFavHandle}
                                                setShowState={setShowState}
                                                showState={showState}
                                                handleHideMyGroups={handleHideMyGroups}
                                                handleHideMyGroupGroups={handleHideMyGroupGroups}
                                                setModalMapTitle={props.setModalMapTitle}
                                                handleToggleMapDelete={props.handleToggleMapDelete}
                                                handleShowSharedList={handleShowSharedList}
                                                handleShowMyGroups={handleShowMyGroups}
                                                handleClosedSharedList={handleClosedSharedList}
                                                getMyMapsGroups={props.getMyMapsGroups}
                                                activeDrawing={props.activeDrawing}
                                                pinsLoading={props.pinsLoading}
                                                handleHideAllGroups={handleHideAllGroups}
                                                hideShare={props?.hideShare}
                                            />
                                        );
                                    })
                            ) : (
                                <p className="null-data-response">No data to show</p>
                            )}
                        </ul>
                    </section>
                </>
            )}

            <section className="myMap-delete-modal--container">
                {!!confirmMultipleDeleteMapOpen && (
                    <ReactPortal wrapperId="external_modal_container">
                        <DeleteMultipleMaps
                            loadingDeleteMap={loadingDeleteMap}
                            handleDeleteMaps={handleDeleteMaps}
                            mapsList={showState.checkListArr}
                            setConfirmMultipleDeleteMapOpen={setConfirmMultipleDeleteMapOpen}
                        />
                    </ReactPortal>
                )}
            </section>

            {showState.firstGroups && (
                <MyMapGroups
                    // props.getMyMapsGroups(data._id);
                    groupsListLoading={props.groupsListLoading}
                    groupData={props.groupData}
                    mapData={props.data.find((item) => item._id === mapDashboardId)}
                    myMapFavHandle={props.myMapFavHandle}
                    handleShare={props.handleShare}
                    handleToggleMapDelete={props.handleToggleMapDelete}
                    handleEditGroup={props.handleEditGroup}
                    setModalMapTitle={props.setModalMapTitle}
                    showState={showState}
                    setShowState={setShowState}
                    getMyMapsGroups={props.getMyMapsGroups}
                    getMyGroupGroups={props.getMyGroupGroups}
                    handleHideMyGroups={handleHideMyGroups}
                    handleShowMyGroupsShared={handleShowMyGroupsShared}
                    handleGroupSharedData={handleGroupSharedData}
                    handleShowMyGroupGroups={handleShowMyGroupGroups}
                    handleShowSharedList={handleShowSharedList}
                    activeDrawing={props.activeDrawing}
                    handleHideAllGroups={handleHideAllGroups}
                    hideShare={props?.hideShare}
                    handleEdit={props.handleEdit}
                />
            )}
            {showState.secondGroups && (
                <MyGroupGroups
                    groupsListLoading={props.groupsListLoading}
                    groupData={props.secondGroupData}
                    mapData={props.data.find((item) => item._id === mapDashboardId)}
                    myMapFavHandle={props.myMapFavHandle}
                    handleShare={props.handleShare}
                    handleToggleMapDelete={props.handleToggleMapDelete}
                    handleEditGroup={props.handleEditGroup}
                    setModalMapTitle={props.setModalMapTitle}
                    showState={showState}
                    setShowState={setShowState}
                    getMyGroupGroups={props.getMyGroupGroups}
                    handleHideMyGroups={handleHideMyGroups}
                    handleShowMySecondGroupsShared={handleShowMySecondGroupsShared}
                    handleSecondGroupSharedData={handleSecondGroupSharedData}
                    handleHideAllGroups={handleHideAllGroups}
                    handleClosedSharedList={handleClosedSharedList}
                    secondPaneStyle={true}
                    handleHideMyGroupGroups={handleHideMyGroupGroups}
                    hideShare={props?.hideShare}
                    handleEdit={props.handleEdit}
                />
            )}

            {showState.shared && (
                <MyMapSharedList
                    data={props.data.find((item) => item._id === mapDashboardId)?.shared || []}
                    title={props.data.find((item) => item._id === mapDashboardId)?.title}
                    handleToggleSharedUserDelete={props.handleToggleSharedUserDelete}
                    handleClosedSharedList={handleClosedSharedList}
                    handleHideMyGroups={handleHideMyGroups}
                    showState={showState}
                    setShowState={setShowState}
                    type="map"
                />
            )}

            {showState.groupShared && (
                <MyMapSharedList
                    data={groupSharedData?.shared || []}
                    title={groupSharedData?.title}
                    handleToggleSharedUserDelete={props.handleToggleSharedUserDelete}
                    handleClosedSharedList={handleClosedSharedList}
                    showState={showState}
                    setShowState={setShowState}
                    type="group"
                />
            )}
            {showState.secondGroupShared && (
                <MyMapSharedList
                    data={secondGroupSharedData?.shared || []}
                    title={secondGroupSharedData?.title}
                    handleToggleSharedUserDelete={props.handleToggleSharedUserDelete}
                    handleClosedSharedList={handleClosedSharedList}
                    showState={showState}
                    setShowState={setShowState}
                    type="group"
                />
            )}

            {showState.openMapCropperModal && (
                <ReactPortal wrapperId="external_modal_container">
                    <MapCropperModal
                        setParentState={setShowState}
                        parentState={showState}
                        genre="map"
                    />
                </ReactPortal>
            )}
        </section>
    );
};

export default MyMapList;
