import { configureStore } from "@reduxjs/toolkit";

// SLICERS
import userMapReducer from "./slicers/userMapSlice";
import mapLayerReducer from "./slicers/mapLayerSlice";
import authReducer from "./slicers/authSlice";
import planDetailsReducer from "./slicers/planDetailsSlice";
import pinDetailPayloadReducer from "./slicers/pinDetailPayloadSlice";
import mapDashboardReducer from "./slicers/mapDashboardSlice";
import usersListReducer, { usersListSlice } from "./slicers/usersListSlice";
import myMapsDataReducer from "./slicers/myMapsDataSlice";
import refreshReducer from "./slicers/refreshSlice";
import baseApi from "../services/baseApi";
import drawingHistoryReducer, { drawingHistorySlice } from "./slicers/drawingHistory";
import systemNotificationsSlice from "./slicers/systemNotificationsSlice";

const store = configureStore({
    reducer: {
        auth: authReducer,
        planDetails: planDetailsReducer,
        mapTypeState: userMapReducer,
        mapLayerType: mapLayerReducer,
        pinDetailsPayload: pinDetailPayloadReducer,
        mapDashboardState: mapDashboardReducer,
        userCompleteMapStats: myMapsDataReducer,
        toggleRefresh: refreshReducer,
        systemNotifications: systemNotificationsSlice,

        [usersListSlice.name]: usersListReducer,
        [drawingHistorySlice.name]: drawingHistoryReducer,

        // API REDUCER
        [baseApi.reducerPath]: baseApi.reducer,
    },

    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseApi.middleware),
});

export default store;
