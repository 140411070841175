import DotLoader from "../DotLoaderBtn/DotLoader";
import closeIconGrey from "../../assets/images/svg/closeIconGrey.svg";

const EditMapConfirmModel = (props) => {
    const { handleEditMapModel, mapOutPins } = props;
    console.log("MapoutPin", mapOutPins);
    return (
        <div className="edit-modal-wrapper">
            <div className="share-close-icon">
                <button
                    type="button"
                    className="share-close-icon"
                    aria-label="Close"
                    onClick={handleEditMapModel}
                >
                    <img src={closeIconGrey} alt="search move pin" />
                </button>
            </div>
            <h5 className="edit-modal-wrapper-title ">Locations updated</h5>
            <div>
                {mapOutPins?.length > 0 && (
                    <>
                        {" "}
                        <p>Note: These pins are out of locations so we remove them from groups</p>
                        <div className="edit-modal-scroll-container">
                            {mapOutPins?.map((item, index) => (
                                <h4 key={index} className="modal-scroll-container-item">
                                    {index + 1} : <strong>{item?.name}</strong>
                                </h4>
                            ))}
                        </div>
                    </>
                )}

                <div className="content">
                    Clicking <strong>confirm</strong> to continue the process.
                </div>
                <div className="edit-modal-buttons">
                    <button
                        type="button"
                        onClick={handleEditMapModel}
                        aria-label="Close"
                        className="btn border-cancel-btn"
                    >
                        Cancel
                    </button>
                    {false ? (
                        <button
                            className="btn animate-dots-red del-outline-btn"
                            type="button"
                            disabled
                        >
                            <DotLoader />
                        </button>
                    ) : (
                        <button
                            type="submit"
                            className="btn del-bulk-outline-btn"
                            onClick={handleEditMapModel}
                        >
                            Confirm
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EditMapConfirmModel;
