export default function ThreeDotIcon({ color = "inherit", fill = "inherit", width = "20px" }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6"
            style={{
                color,
                fill,
                width,
            }}
        >
            <rect id="Boundary" width="24" height="24" rx="12" opacity="0" />
            <path
                id="Path_dots-vertical"
                data-name="Path / dots-vertical"
                d="M12,16a2,2,0,1,1-2,2,2,2,0,0,1,2-2m0-6a2,2,0,1,1-2,2,2,2,0,0,1,2-2m0-6a2,2,0,1,1-2,2A2,2,0,0,1,12,4Z"
            />
        </svg>
    );
}
