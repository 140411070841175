import React, { useState, useEffect, useCallback, useRef } from "react";
import moment from "moment/moment";
import closeIcon from "../../assets/images/svg/closeIconGrey.svg";
import calanderIcon from "../../assets/images/svg/calander-icon.svg";
import checkinIcon from "../../assets/images/checkin-icon.png";
import checkoutIcon from "../../assets/images/checkout-icon.png";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeString } from "../../utils/utils";
import { useUpdatePinMutation } from "../../services/baseApi";
import { errorMessage, successMessage } from "../../utils/messages";
import { refresh } from "../../store/slicers/refreshSlice";
import { convertNumberToCommaNotation } from "../../utils/utils";
import DotLoader from "../DotLoaderBtn/DotLoader";
import { setPinDetailBox } from "../../store/slicers/pinDetailPayloadSlice";

const EditInfoMarker = (props) => {
    const { info, mapState } = props;
    const dispatch = useDispatch();
    const toggleRefresh = useSelector((state) => state.toggleRefresh);
    const [markerState, setMarkerState] = useState({
        count: null,
        loading: false,
    });

    const openDateRef = useRef();
    const closeDateRef = useRef();

    const { mapType } = useSelector((state) => state.mapTypeState);
    const mapDashboardId = useSelector((state) => state.mapDashboardState.id);
    const [updatePin] = useUpdatePinMutation();

    const [editMarkerState, setEditMarkerState] = useState({
        id: "",
        title: "",
        name: "",
        address: "",
        city: "",
        zip: "",
        phone: "",
        url: "",
        stage: "",
        state: "",
        leadBySource: "",
        opendate: "",
        closedate: "",
        opportunities: "",
        note: "",
        check_in_date: "",
        check_out_date: "",
        check_in_pin: "",
        check_out_pin: "",
    });

    useEffect(() => {
        if (info) {
            setEditMarkerState((prevState) => {
                return {
                    ...prevState,
                    id: info.id,
                    title: info?.title || mapState?.title,
                    name: info?.name,
                    address: info?.address,
                    city: info?.city,
                    zip: info?.zip,
                    state: info?.state,
                    phone: info?.phone,
                    leadBySource: info?.leadBySource,
                    stage: info?.stage,
                    url: info?.url,
                    opendate: info?.opendate,
                    closedate: info?.closedate,
                    opportunities: info?.opportunities,
                    note: info?.note,
                    check_in_date: info?.check_in_date,
                    check_out_date: info?.check_out_date,
                    check_in_pin: info?.check_in_pin,
                    check_out_pin: info?.check_out_pin,
                };
            });
            setMarkerState((prevState) => {
                return {
                    ...prevState,
                    count: info?.note?.length || 0,
                };
            });
        }
    }, [info]);

    const handleDateChange = useCallback((e) => {
        let { name, value } = e.target;

        switch (name) {
            case "opendate":
                openDateRef.current.textContent = moment(value).format("ddd, Do MMM");
                break;

            case "closedate":
                closeDateRef.current.textContent = moment(value).format("ddd, Do MMM");
                break;

            default:
                break;
        }
    }, []);

    const handleBlur = useCallback(
        (e) => {
            let { name, value } = e.target;

            let body = {};

            setEditMarkerState((prevState) => {
                body = {
                    ...prevState,
                    [name]: value,
                    type: mapType === 4 ? "group" : "map",
                };
                return body;
            });

            if (editMarkerState[name] !== value) {
                setTimeout(() => {
                    handleUpdatePin(body);
                }, 1000);
            }
        },
        [editMarkerState]
    );

    const handleUpdatePin = async (body) => {
        setMarkerState((prevState) => {
            return {
                ...prevState,
                loading: true,
            };
        });
        const { data, error } = await updatePin({ id: mapDashboardId, body: { ...info, ...body } });
        if (data) {
            dispatch(refresh(!toggleRefresh));
            successMessage("Successfully saved");
        }
        if (error) {
            errorMessage(error?.data?.message);
        }
        setMarkerState((prevState) => {
            return {
                ...prevState,
                loading: false,
            };
        });
    };

    const countCharHandle = (value) => {
        setMarkerState((prevState) => {
            return {
                ...prevState,
                count: value.length,
            };
        });
    };

    return (
        <section className="infoMarker-modal--contanier">
            <button
                className="close-icon-custom"
                style={{ top: "15px", right: "8px", width: 30 }}
                onClick={() => dispatch(setPinDetailBox(false))}
            >
                <img src={closeIcon} alt="close" />
            </button>
            <div className="title flex-between">
                <h3>{capitalizeString(editMarkerState?.name)}</h3>

                {markerState.loading ? (
                    <span
                        className="animate-dots-blue cta-border-blue-auto"
                        style={{ border: "none", minHeight: "unset" }}
                    >
                        <DotLoader />
                    </span>
                ) : (
                    <></>
                )}
            </div>
            <h3 className="header">Basic information </h3>
            <div className="marker-details-container">
                <div className="details-info">
                    <div className="mapName-wrapper" style={{ minHeight: 70 }}>
                        <p>Map</p>
                        <div>
                            <h6>{capitalizeString(editMarkerState?.title)}</h6>
                        </div>
                    </div>
                    <div className="basic-info mb-15">
                        <div className="markerInfo-formgroup">
                            <label>Preferred title</label>
                            <input
                                type="text"
                                name="name"
                                className="input-field"
                                onBlur={handleBlur}
                                defaultValue={capitalizeString(editMarkerState?.name)}
                            />
                        </div>
                        <div className="markerInfo-formgroup">
                            <label>Address</label>
                            <input
                                type="text"
                                className="input-field"
                                name="address"
                                onBlur={handleBlur}
                                defaultValue={capitalizeString(editMarkerState?.address)}
                            />
                            <div className="address-sub-field">
                                <input
                                    type="text"
                                    className="input-field"
                                    name="city"
                                    onBlur={handleBlur}
                                    defaultValue={capitalizeString(editMarkerState?.city)}
                                />
                                <input
                                    type="text"
                                    className="input-field"
                                    disabled
                                    value={capitalizeString(editMarkerState?.state)}
                                    style={{ textTransform: "uppercase" }}
                                />
                                <input
                                    type="text"
                                    className="input-field"
                                    name="zip"
                                    onBlur={handleBlur}
                                    defaultValue={capitalizeString(editMarkerState?.zip)}
                                />
                            </div>
                        </div>
                        <div className="markerInfo-formgroup">
                            <label>Phone</label>
                            <input
                                type="text"
                                className="input-field"
                                name="phone"
                                onBlur={handleBlur}
                                defaultValue={capitalizeString(editMarkerState?.phone)}
                            />
                        </div>
                        <div className="markerInfo-formgroup">
                            <label>Website</label>
                            <input
                                type="text"
                                className="input-field"
                                name="url"
                                onBlur={handleBlur}
                                defaultValue={capitalizeString(editMarkerState?.url)}
                                style={{ textTransform: "lowercase" }}
                            />
                        </div>
                    </div>
                </div>
                <div className="detail-selectors">
                    <div className="checkinout-wrapper" style={{ minHeight: 75 }}>
                        <div className="checkinout-item">
                            <div className="label-info">
                                <img src={checkinIcon} alt="checkin" />
                                <p style={{ color: "#999B9E" }}>Check in</p>
                            </div>
                            <div>
                                <p className="input-date-wrapper">
                                    {" "}
                                    {editMarkerState.check_in_date
                                        ? editMarkerState.check_in_date
                                        : "MM/DD/YY--"}
                                </p>

                                <div className="pin-number-wrapper">
                                    <p style={{ color: "#999B9E" }}>PIN #</p>
                                    <div className="pin-number-box">
                                        {editMarkerState.check_in_pin
                                            ? editMarkerState.check_in_pin
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="checkinout-item">
                            <div className="label-info">
                                <img src={checkoutIcon} alt="checkin" />
                                <p style={{ color: "#999B9E" }}>Check out</p>
                            </div>
                            <div>
                                <p className="input-date-wrapper">
                                    {editMarkerState.check_out_date
                                        ? editMarkerState.check_out_date
                                        : "MM/DD/YY--"}
                                </p>

                                <div className="pin-number-wrapper">
                                    <p style={{ color: "#999B9E" }}>PIN #</p>
                                    <div className="pin-number-box">
                                        {editMarkerState.check_out_pin
                                            ? editMarkerState.check_out_pin
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="markerInfo-formgroup">
                        <label htmlFor="leadBySource">Lead by source</label>
                        <select
                            id="leadBySource"
                            value={editMarkerState?.leadBySource}
                            name="leadBySource"
                            onBlur={handleBlur}
                            className={`form-control ${
                                editMarkerState?.leadBySource === undefined ? "dull" : ""
                            }`}
                        >
                            <option value="">- - Select one - -</option>
                            <option value="Self generated">Self generated</option>
                            <option value="Trade show">Trade show</option>
                            <option value="Direct mail">Direct mail</option>
                            <option value="Word of mouth">Word of mouth</option>
                            <option value="Web site">Web site</option>
                            <option value="Partner">Partner</option>
                            <option value="Conference">Conference</option>
                            <option value="Cold call">Cold call</option>
                            <option value="Public relations">Public relations</option>
                            {mapState?.rawData
                                ?.reduce((initial, item) => {
                                    if (item.leadBySource) initial.push(item.leadBySource);
                                    return initial;
                                }, [])
                                .map((option, index) => (
                                    <option key={index} value={option}>
                                        {capitalizeString(option)}
                                    </option>
                                ))}
                        </select>
                    </div>
                    <div className="markerInfo-formgroup">
                        <label htmlFor="stage">Stage</label>
                        <select
                            id="stage"
                            value={editMarkerState?.stage}
                            name="stage"
                            onBlur={handleBlur}
                            className={`form-control ${
                                editMarkerState?.stage === undefined ? "dull" : ""
                            }`}
                        >
                            <option value="">- - Select one - -</option>
                            <option value="Lead generation">Lead generation</option>
                            <option value="Lead qualification">Lead qualification</option>
                            <option value="Initial contact">Initial contact</option>
                            <option value="Schedule meeting">Schedule meeting</option>
                            <option value="Negotiation">Negotiation</option>
                            <option value="Closing the deal">Closing the deal</option>
                        </select>
                    </div>

                    <div className="calender-container markerInfo-formgroup">
                        <label>Close date</label>

                        <div className="calender-wrapper">
                            <label>
                                <span className="custom-calander-icon-wrapper">
                                    Start
                                    <img src={calanderIcon} alt="calander" />
                                </span>

                                <input
                                    placeholder="Select date"
                                    type="date"
                                    name="opendate"
                                    aria-label="Date"
                                    onBlur={handleBlur}
                                    onChange={handleDateChange}
                                    defaultValue={editMarkerState?.opendate}
                                />

                                <span id="opendate" ref={openDateRef}>
                                    {moment(editMarkerState?.opendate).format("ddd, Do MMM")}
                                </span>
                            </label>

                            <label>
                                <span className="custom-calander-icon-wrapper">
                                    End
                                    <img
                                        src={calanderIcon}
                                        alt="calander"
                                        className="custom-calander-icon"
                                    />
                                </span>

                                <input
                                    placeholder="Select date"
                                    name="closedate"
                                    type="date"
                                    defaultValue={editMarkerState?.closedate}
                                    onBlur={handleBlur}
                                    onChange={handleDateChange}
                                    aria-label="Date"
                                />

                                <span id="closedate" ref={closeDateRef}>
                                    {moment(editMarkerState?.closedate).format("ddd, Do MMM")}
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="markerInfo-formgroup">
                        <div className="opputunity-label">
                            <label>Opportunity</label>
                        </div>
                        <input
                            className="input-field opportunity"
                            type="number"
                            name="opportunities"
                            onBlur={handleBlur}
                            aria-label="Opportunity"
                            placeholder="0.00"
                            defaultValue={editMarkerState?.opportunities}
                        />
                    </div>
                </div>
            </div>
            <div className="markerInfo-formgroup detail-selectors " style={{ marginBottom: 0 }}>
                <div className="flex-between">
                    <label className="header">Notes</label>
                    <span className="limit-wrapper">
                        {convertNumberToCommaNotation(markerState.count)}/
                        {convertNumberToCommaNotation(3000)}
                    </span>
                </div>
                <textarea
                    disabled={mapType === 2}
                    className="input-field textarea"
                    placeholder="Leave a comment..."
                    aria-label="note"
                    name="note"
                    onChange={(e) => countCharHandle(e.target.value)}
                    onBlur={handleBlur}
                    defaultValue={capitalizeString(editMarkerState?.note)}
                    rows={6}
                    maxLength="3000"
                />
            </div>
        </section>
    );
};

export default EditInfoMarker;
