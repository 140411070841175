export default function LinkIcon({ color = "inherit", fill = "inherit", width = "20px" }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 16 8">
            <path
                id="link-active"
                d="M4.52,14.5A2.482,2.482,0,0,1,7,12.02h3.2V10.5H7a4,4,0,1,0,0,8h3.2V16.98H7A2.482,2.482,0,0,1,4.52,14.5Zm3.28.8h6.4V13.7H7.8ZM15,10.5H11.8v1.52H15a2.48,2.48,0,0,1,0,4.96H11.8V18.5H15a4,4,0,0,0,0-8Z"
                transform="translate(-3 -10.5)"
                fill="#999b9e"
            />
        </svg>
    );
}
