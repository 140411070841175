import React, { useCallback, useState } from "react";
import splashImage from "../../assets/images/notification-illustration.png";
import { useUpdateUserNotificationsMutation } from "../../services/baseApi";
import { useEffect } from "react";
import { errorMessage, successMessage } from "../../utils/messages";
import { debounce } from "../../utils/utils";

const ProfileNotification = (props) => {
    const { userData, notificationsData } = props;

    const [updateUserNotifications] = useUpdateUserNotificationsMutation();

    const [notificationsState, setNotificationsState] = useState({
        active_shared_map: false,
        delete_large_number_maps: false,
        running_out_space: false,
        new_browser_signin: false,
        new_device_linked: false,
        new_feature_updates: false,
        tips_using_app: false,
        feedback_survey: false,
        checkin_checkout_status: true,
    });

    useEffect(() => {
        if (userData) {
            setNotificationsState((prevState) => {
                return {
                    ...prevState,
                    active_shared_map: notificationsData?.active_shared_map || false,
                    delete_large_number_maps: notificationsData?.delete_large_number_maps || false,
                    running_out_space: notificationsData?.running_out_space || false,
                    new_browser_signin: notificationsData?.new_browser_signin || false,
                    new_device_linked: notificationsData?.new_device_linked || false,
                    new_feature_updates: notificationsData?.new_feature_updates || false,
                    tips_using_app: notificationsData?.tips_using_app || false,
                    feedback_survey: notificationsData?.feedback_survey || false,
                    checkin_checkout_status: !notificationsData?.checkin_checkout_status
                        ? false
                        : true,
                };
            });
        }
    }, [userData]);

    const handleChange = useCallback((e, val) => {
        let { name } = e.target;

        let body = {};

        setNotificationsState((prevState) => {
            body = {
                ...prevState,
                [name]: val,
                userID: userData?._id,
            };
            return body;
        });
        setTimeout(() => {
            handleUpdate(body);
        }, 1000);
    }, []);

    const handleUpdate = async (body) => {
        const { data, error } = await updateUserNotifications({
            id: userData?._id,
            body,
        });
        if (data) {
            successMessage(data?.message);
        }
        if (error) {
            errorMessage(error?.data?.message);
        }
    };

    return (
        <div className="profileNotification--wrapper">
            <div className="content-wrapper">
                <div className="map-wrap">
                    <h4>Maps</h4>
                    <p>Email me when:</p>
                    <div className="formgroup-flex">
                        <label className="custom-label clr-greyc" htmlFor="active_shared_map">
                            <input
                                type="checkbox"
                                name="active_shared_map"
                                id="active_shared_map"
                                checked={notificationsState?.active_shared_map}
                                onChange={debounce(
                                    (e) => handleChange(e, !notificationsState?.active_shared_map),
                                    500
                                )}
                            />
                            <span>Activity in Shared Maps</span>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="formgroup-flex">
                        <label
                            className="custom-label clr-greyc"
                            htmlFor="delete_large_number_maps"
                        >
                            <input
                                type="checkbox"
                                name="delete_large_number_maps"
                                id="delete_large_number_maps"
                                checked={notificationsState?.delete_large_number_maps}
                                onChange={debounce(
                                    (e) =>
                                        handleChange(
                                            e,
                                            !notificationsState?.delete_large_number_maps
                                        ),
                                    500
                                )}
                            />
                            <span>I delete a large number of maps</span>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div className="alert-wrap">
                    <h4>Alerts</h4>
                    <p>Email me when:</p>
                    <div className="formgroup-flex">
                        <label className="custom-label clr-greyc" htmlFor="running_out_space">
                            <input
                                type="checkbox"
                                name="running_out_space"
                                id="running_out_space"
                                checked={notificationsState?.running_out_space}
                                onChange={debounce(
                                    (e) => handleChange(e, !notificationsState?.running_out_space),
                                    500
                                )}
                            />
                            <span>I’m running out of space</span>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="formgroup-flex">
                        <label className="custom-label clr-greyc" htmlFor="new_browser_signin">
                            <input
                                type="checkbox"
                                name="new_browser_signin"
                                id="new_browser_signin"
                                checked={notificationsState?.new_browser_signin}
                                onChange={debounce(
                                    (e) => handleChange(e, !notificationsState?.new_browser_signin),
                                    500
                                )}
                            />
                            <span>A new browser is used to sign in</span>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="formgroup-flex">
                        <label className="custom-label clr-greyc" htmlFor="new_device_linked">
                            <input
                                type="checkbox"
                                name="new_device_linked"
                                id="new_device_linked"
                                checked={notificationsState?.new_device_linked}
                                onChange={debounce(
                                    (e) => handleChange(e, !notificationsState?.new_device_linked),
                                    500
                                )}
                            />
                            <span>A new device is linked</span>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="formgroup-flex">
                        <label className="custom-label clr-greyc" htmlFor="checkin_checkout_status">
                            <input
                                type="checkbox"
                                name="checkin_checkout_status"
                                id="checkin_checkout_status"
                                checked={notificationsState?.checkin_checkout_status}
                                onChange={debounce(
                                    (e) =>
                                        handleChange(
                                            e,
                                            !notificationsState?.checkin_checkout_status
                                        ),
                                    500
                                )}
                            />
                            <span>Check-in and check-out notifications</span>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div className="alert-wrap">
                    <h4>News</h4>
                    <p>Email me about:</p>
                    <div className="formgroup-flex">
                        <label className="custom-label clr-greyc" htmlFor="new_feature_updates">
                            <input
                                type="checkbox"
                                name="new_feature_updates"
                                id="new_feature_updates"
                                checked={notificationsState?.new_feature_updates}
                                onChange={debounce(
                                    (e) =>
                                        handleChange(e, !notificationsState?.new_feature_updates),
                                    500
                                )}
                            />
                            <span>New features and updates</span>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="formgroup-flex">
                        <label className="custom-label clr-greyc" htmlFor="tips_using_app">
                            <input
                                type="checkbox"
                                name="tips_using_app"
                                id="tips_using_app"
                                checked={notificationsState?.tips_using_app}
                                onChange={debounce(
                                    (e) => handleChange(e, !notificationsState?.tips_using_app),
                                    500
                                )}
                            />
                            <span>Tips on using XoomMaps</span>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="formgroup-flex">
                        <label className="custom-label clr-greyc" htmlFor="feedback_survey">
                            <input
                                type="checkbox"
                                name="feedback_survey"
                                id="feedback_survey"
                                checked={notificationsState?.feedback_survey}
                                onChange={debounce(
                                    (e) => handleChange(e, !notificationsState?.feedback_survey),
                                    500
                                )}
                            />
                            <span>XoomMaps feedback surveys</span>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>
            {/* <div className="image-wrapper">
                <img src={splashImage} alt="notification modal" />
            </div> */}
        </div>
    );
};

export default ProfileNotification;
