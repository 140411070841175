export default function ChevronRightIcon({ color = "inherit", fill = "inherit", width = "20px" }) {
    return (
        <svg
            id="Arrow-right"
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 10 10"
        >
            <g id="Arrow-Right-2" data-name="Arrow-Right">
                <g id="Boundary" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
                    <rect width="10" height="10" stroke="none" />
                    <rect x="0.5" y="0.5" width="9" height="9" fill="none" />
                </g>
                <path
                    id="Path_chevron-left"
                    data-name="Path / chevron-left"
                    d="M3.7,5.29,1.415,3,3.7.7,3,0,0,3,3,6Z"
                    transform="translate(6.852 8) rotate(180)"
                    fill="#999b9e"
                />
            </g>
        </svg>
    );
}
