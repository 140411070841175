import React, { useEffect, useState } from "react";
import searchGreyIcon from "../../assets/images/svg/searchGreyIcon.svg";
import pdfIcon from "../../assets/images/svg/pdf-icon.svg";
import { useGetPaymentInvoicesQuery } from "../../services/baseApi";
import moment from "moment";
import { debounce } from "../../utils/utils";

const TransactionTable = (props) => {
    const { email } = props;

    const { data: allTransactionData, error: allTransactionError } =
        useGetPaymentInvoicesQuery(email);

    const [transactionHistory, setTransactionHistory] = useState({
        data: "",
        error: "",
        loading: false,
        invoiceLink: null,
    });

    const [searchItem, setSearchItem] = useState("");
    const [rowSize, setRowSize] = useState(transactionHistory.data?.length || 15);

    useEffect(() => {
        if (allTransactionData) {
            setTransactionHistory((prevState) => {
                return {
                    ...prevState,
                    data: allTransactionData.invoices.data,
                };
            });
        }
        if (allTransactionError) {
            console.log("getting invoice list error");
        }
    }, [allTransactionData, allTransactionError]);

    return (
        <>
            <div className="trasnsaction-history-table-wrapper">
                <h2 style={{ marginBottom: 20 }}>Transaction history</h2>
                <div className="trasnsaction-history-functionality-bar">
                    <div className="searchbar">
                        <input
                            type="text"
                            placeholder="Name, transaction ID"
                            defaultValue={searchItem}
                            onChange={debounce((e) => {
                                setSearchItem(e.target.value);
                            }, 500)}
                        />
                        <img src={searchGreyIcon} alt="search icon" />
                    </div>
                    <div className="transaction-paginationBar">
                        <label htmlFor="transactionRows">Row Per Page:</label>
                        <select
                            value={rowSize}
                            name="transactionRows"
                            id="transactionRows"
                            onChange={(e) => {
                                setRowSize(Number(e.target.value));
                            }}
                        >
                            <option value={transactionHistory.data?.length}>All</option>
                            <option value="2">2</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="75">75</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                </div>
                <div className="transaction-table">
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Status</th>
                                <th>Transaction ID</th>
                                <th>Created at</th>
                                <th>Amount</th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactionHistory.data?.length ? (
                                transactionHistory.data
                                    .filter((item) => {
                                        return (
                                            item.customer_name
                                                .toLowerCase()
                                                .includes(searchItem?.trim()?.toLowerCase()) ||
                                            item.id
                                                .toLowerCase()
                                                .includes(searchItem?.trim()?.toLowerCase())
                                        );
                                    })
                                    .slice(0, rowSize)
                                    .map((data, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{data.customer_name}</td>
                                                <td>{data.status}</td>
                                                <td>{data.id}</td>
                                                <td>
                                                    {moment(new Date(1000 * data?.created)).format(
                                                        "LL"
                                                    )}
                                                </td>
                                                <td>${(data.amount_paid / 100).toFixed(2)}</td>
                                                <td></td>
                                                <td style={{ textAlign: "right" }}>
                                                    <img
                                                        src={pdfIcon}
                                                        alt="generate pdf"
                                                        onClick={() => {
                                                            window.open(data.invoice_pdf, "_blank");
                                                        }}
                                                    />
                                                </td>

                                                <td></td>
                                            </tr>
                                        );
                                    })
                            ) : (
                                <></>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default TransactionTable;
