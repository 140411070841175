import React, { useState } from "react";
import SharedMapListItem from "./SharedMapListItem";
import { useDispatch, useSelector } from "react-redux";
import DeleteMultipleMaps from "../../../components/Modals/DeleteMultipleMaps";
import ReactPortal from "../../../components/Portal/ReactPortal";
import { errorMessage, successMessage } from "../../../utils/messages";
import { useDeleteMultipleMySharedMutation } from "../../../services/baseApi";
import ProgressBarMap from "../../../components/ColorPicker/ProgressBarMap";
import noImage from "../../../assets/images/noImage.png";
import { setMapDashboardId } from "../../../store/slicers/mapDashboardSlice";
import chevronDown from "../../../assets/images/svg/chevronDown.svg";
import chevronUp from "../../../assets/images/chevronUp.png";
import DotLoader from "../../../components/DotLoaderBtn/DotLoader";

import SharedMapGroups from "./SharedMapGroups";
import SharedMapsGroupGroups from "./SharedMapsGroupGroups";

const SharedMapList = (props) => {
    const {
        sharedState,
        setSharedState,
        handleShare,
        setModalMapTitle,
        handleEdit,
        handleToggleMapDelete,
        GetSharedMapGroupGroups,
        GetSharedMapGroups,
    } = props;

    const [isHoveredMap, setIsHoveredMap] = useState({
        isHoverd: false,
        id: null,
    });

    const dispatch = useDispatch();
    const [loadingDeleteMap, setLoadingDeleteMap] = useState(false);
    const [confirmMultipleDeleteMapOpen, setConfirmMultipleDeleteMapOpen] = useState(false);

    const mapDashboardId = useSelector((state) => state.mapDashboardState.id);
    const [checkedItems, setCheckedItems] = useState({});

    const handleItemClick = (index) => {
        setCheckedItems((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const handleShowShared = (val) => {
        setSharedState((prevState) => {
            return {
                ...prevState,
                showShared: val,
            };
        });
    };

    const [deleteMultipleMyShared] = useDeleteMultipleMySharedMutation();

    const handleDeleteMaps = async () => {
        setLoadingDeleteMap(false);

        const myshared_del_arr = sharedState?.checkListArr?.map((obj) => obj.id);

        const { data, error } = await deleteMultipleMyShared(myshared_del_arr);

        if (data) {
            successMessage("Successfully removed");
        }

        if (error) {
            errorMessage(error?.data?.message);
        }

        setLoadingDeleteMap(false);
        setConfirmMultipleDeleteMapOpen(false);
        setSharedState((prevState) => {
            return {
                ...prevState,
                checkListArr: [],
            };
        });
    };

    const [activeAccordion, setActiveAccordion] = useState(null);

    const handleAccordionClick = (index) => {
        setActiveAccordion(activeAccordion === index ? null : index);
    };
    const handleMouseEnter = (id) => {
        setIsHoveredMap((prevState) => {
            return {
                ...prevState,
                isHoverd: true,
                id: id,
            };
        });
    };

    const handleMouseLeave = (id) => {
        setIsHoveredMap((prevState) => {
            return {
                ...prevState,
                isHoverd: false,
                id: null,
            };
        });
    };

    const handleSharedGroupsOpen = () => {
        setSharedState((prev) => {
            return {
                ...prev,
                showShared: true,
            };
        });
    };
    const handleSharedGroupsClose = () => {
        setSharedState((prev) => {
            return {
                ...prev,
                showShared: false,
                showSubGroupShared: false,
            };
        });
    };
    const handleSharedSubGroupsOpen = () => {
        setSharedState((prev) => {
            return {
                ...prev,
                showSubGroupShared: true,
            };
        });
    };
    const handleSharedSubGroupsClose = () => {
        setSharedState((prev) => {
            return {
                ...prev,
                showSubGroupShared: false,
            };
        });
    };

    return (
        <section className="d-flex">
            {!props.isNocUrl ? (
                <section
                    className={`myMapList-container ${
                        props.collapseBar.guageBar ? "collapsebar-active" : ""
                    }`}
                >
                    {!props.isNocUrl ? (
                        <>
                            <div className="mapList-btns-header">
                                <div className="listCurve-wrapper">
                                    <ProgressBarMap percentageValue={props.percentageValue} />
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                    {/* SEARCH BAR END */}
                    <ul className="myMapList-data-container">
                        {props.sharedMapState.sharedMaps.length ? (
                            props.sharedMapState.sharedMaps
                                ?.filter((item) => {
                                    return item.map?.title
                                        ?.toLowerCase()
                                        .includes(sharedState?.searchItem?.trim().toLowerCase());
                                })
                                ?.map((data) => {
                                    return (
                                        <SharedMapListItem
                                            data={data}
                                            setSharedMapState={props.setSharedMapState}
                                            key={data._id}
                                            myMapFavHandle={props.myMapFavHandle}
                                            handleShare={handleShare}
                                            handleEdit={handleEdit}
                                            handleToggleMapDelete={handleToggleMapDelete}
                                            handleToggleMapShareDelete={
                                                props.handleToggleMapShareDelete
                                            }
                                            setModalMapTitle={setModalMapTitle}
                                            sharedState={sharedState}
                                            setSharedState={setSharedState}
                                            handleShowShared={handleShowShared}
                                            pinsLoading={props.pinsLoading}
                                            handleToggleSharedUserDelete={
                                                props?.handleToggleSharedUserDelete
                                            }
                                            setOwnerModelState={props?.setOwnerModelState}
                                            GetSharedMapGroups={GetSharedMapGroups}
                                            modelClose={handleSharedGroupsClose}
                                        />
                                    );
                                })
                        ) : (
                            <p className="null-data-response">No data to show</p>
                        )}
                    </ul>
                </section>
            ) : (
                <section
                    className={`nocList-container ${
                        props.collapseBar.guageBar ? "collapsebar-active" : ""
                    }`}
                >
                    <ul className="nocList-wrapper">
                        {props?.sharedMapState?.sharedMaps?.map((item, index) => {
                            return (
                                <>
                                    <li
                                        className={`nocitem-wrapper ${
                                            mapDashboardId === item.map?._id ? "active" : ""
                                        }`}
                                        key={index}
                                    >
                                        <div
                                            className="noc-singlemap-item"
                                            onClick={() => {
                                                handleAccordionClick(index);
                                                dispatch(setMapDashboardId(item?.map?._id));
                                                props.getMyMapsGroups(item?.map?._id);
                                            }}
                                            onMouseEnter={() => handleMouseEnter(index)}
                                            onMouseLeave={() => handleMouseLeave()}
                                        >
                                            <div className="content">
                                                <img
                                                    src={item?.map?.mapImage || noImage}
                                                    alt="profile"
                                                    className="mapimage"
                                                />
                                                <div>
                                                    <h5 className="mapList-title">
                                                        {item?.map?.title}
                                                    </h5>
                                                    {isHoveredMap.isHoverd &&
                                                    index === isHoveredMap.id ? (
                                                        <p className="count-style-one">
                                                            {item?.map?.count || 0} markers
                                                        </p>
                                                    ) : (
                                                        <p className="count-style-one">
                                                            {item?.map?.directGroups?.length || 0}{" "}
                                                            groups
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="accordion-icon">
                                                {" "}
                                                {activeAccordion === index ? (
                                                    <img src={chevronUp} alt="chevron up"></img>
                                                ) : (
                                                    <img src={chevronDown} alt="chevron up"></img>
                                                )}
                                            </div>
                                        </div>
                                        {activeAccordion === index && (
                                            <>
                                                {props.groupsListLoading.groupsByMapIdLoading ? (
                                                    <div className="null-data-response animate-dots-blue bg-trans">
                                                        <DotLoader />
                                                    </div>
                                                ) : (
                                                    <>
                                                        {props.groupData?.length ? (
                                                            <>
                                                                {props?.groupData?.map(
                                                                    (data, index) => {
                                                                        return (
                                                                            <div
                                                                                key={index}
                                                                                className="noc-group-lists-item"
                                                                                onClick={() => {
                                                                                    handleItemClick(
                                                                                        index
                                                                                    );
                                                                                    props.toggleNocMapMarkers(
                                                                                        data._id
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <div className="content">
                                                                                    <label className="custom-label">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={
                                                                                                checkedItems[
                                                                                                    index
                                                                                                ]
                                                                                            }
                                                                                            onClick={(
                                                                                                e
                                                                                            ) =>
                                                                                                e.stopPropagation()
                                                                                            }
                                                                                            name="mapListcheck"
                                                                                        />
                                                                                        <span className="checkmark"></span>
                                                                                    </label>
                                                                                    <img
                                                                                        src={
                                                                                            data?.mapImage ||
                                                                                            noImage
                                                                                        }
                                                                                        className="mapimage"
                                                                                        alt="map avatar"
                                                                                    />
                                                                                    <div>
                                                                                        <p className="title">
                                                                                            {
                                                                                                data?.title
                                                                                            }
                                                                                        </p>
                                                                                        <p className="count">
                                                                                            {data?.count ||
                                                                                                0}{" "}
                                                                                            markers
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <img
                                                                                        src={
                                                                                            data?.userAvatar ||
                                                                                            noImage
                                                                                        }
                                                                                        alt="groupmap"
                                                                                        className="mapimage"
                                                                                        style={{
                                                                                            borderRadius:
                                                                                                "50%",
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <p className="null-data-response bg-trans">
                                                                    No results found
                                                                </p>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </li>
                                </>
                            );
                        })}
                    </ul>
                </section>
            )}

            <section className="myMap-delete-modal--container">
                {!!confirmMultipleDeleteMapOpen && (
                    <ReactPortal wrapperId="external_modal_container">
                        <DeleteMultipleMaps
                            loadingDeleteMap={loadingDeleteMap}
                            handleDeleteMaps={handleDeleteMaps}
                            mapsList={sharedState.checkListArr}
                            setConfirmMultipleDeleteMapOpen={setConfirmMultipleDeleteMapOpen}
                        />
                    </ReactPortal>
                )}
            </section>

            {mapDashboardId && sharedState.showShared && (
                <SharedMapGroups
                    modelOpen={handleSharedSubGroupsOpen}
                    modelClose={handleSharedGroupsClose}
                    sharedState={sharedState}
                    setSharedState={setSharedState}
                    GetSharedMapGroupGroups={GetSharedMapGroupGroups}
                    handleShare={handleShare}
                    setModalMapTitle={setModalMapTitle}
                    handleEdit={handleEdit}
                    handleToggleMapDelete={handleToggleMapDelete}
                />
            )}
            {mapDashboardId && sharedState.showSubGroupShared && (
                <SharedMapsGroupGroups
                    subGroup={true}
                    modelClose={handleSharedSubGroupsClose}
                    allModelClose={handleSharedGroupsClose}
                    sharedState={sharedState}
                    setSharedState={setSharedState}
                    handleShare={handleShare}
                    setModalMapTitle={setModalMapTitle}
                    handleEdit={handleEdit}
                    handleToggleMapDelete={handleToggleMapDelete}
                />
            )}
        </section>
    );
};

export default SharedMapList;
