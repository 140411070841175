import React from "react";

export const FacebookIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="icon" transform="translate(0 -0.021)">
                <g
                    id="Rectangle_785"
                    data-name="Rectangle 785"
                    transform="translate(0 0.021)"
                    fill="#fff"
                    stroke="#707070"
                    stroke-width="1"
                    opacity="0"
                >
                    <rect width="24" height="24" stroke="none" />
                    <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
                </g>
                <path
                    id="Icon_awesome-facebook"
                    data-name="Icon awesome-facebook"
                    d="M16.563,8.611a8,8,0,1,0-9.25,7.951V10.938H5.28V8.611H7.313V6.838a2.832,2.832,0,0,1,3.022-3.131,12.24,12.24,0,0,1,1.791.157v1.98H11.116A1.16,1.16,0,0,0,9.813,7.1V8.611h2.219l-.355,2.327H9.813v5.625A8.037,8.037,0,0,0,16.563,8.611Z"
                    transform="translate(3.739 3.429)"
                    fill="#32373c"
                />
            </g>
        </svg>
    );
};
