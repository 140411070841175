import React from "react";
import Dashboard from "../dashboard/Dashboard";
import "./NocStyle.css";

const NOC = (props) => {
    return (
        <Dashboard mapDataheaders={props?.mapDataheaders} setDataHeaders={props?.setDataHeaders} />
    );
};

export default NOC;
