export default function ShareIcon({ color = "inherit", fill = "inherit", width = "20px" }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14.4" height="16" viewBox="0 0 14.4 16">
            <path
                id="Share-off"
                d="M12,11.309a2.325,2.325,0,0,0-1.568.618l-5.7-3.333A2.639,2.639,0,0,0,4.8,8.032a2.639,2.639,0,0,0-.072-.562l5.64-3.3A2.392,2.392,0,0,0,14.4,2.41a2.4,2.4,0,1,0-4.8,0,2.639,2.639,0,0,0,.072.562l-5.64,3.3a2.41,2.41,0,1,0,0,3.518l5.7,3.341a2.275,2.275,0,0,0-.064.522A2.336,2.336,0,1,0,12,11.309Z"
                fill="#999b9e"
            />
        </svg>
    );
}
