import React from "react";
import closeIconGrey from "../../../assets/images/svg/closeIconGrey.svg";
import { Cropper, RectangleStencil } from "react-advanced-cropper";
import { getAbsoluteZoom, getZoomFactor } from "advanced-cropper/extensions/absolute-zoom";
import { useState } from "react";
import { useRef } from "react";
import cropIcon from "../../../assets/images/svg/crop-icon.svg";
import { AdjustableCropperBackground } from "./AdjustableCropperBackground.jsx";
import "react-advanced-cropper/dist/style.css";
import {
    useDeleteGroupImageMutation,
    useDeleteMapImageMutation,
    useDeleteRouteImageMutation,
    useUpdateGroupImageMutation,
    useUpdateMapImageMutation,
    useUpdateRouteImageMutation,
} from "../../../services/baseApi";
import { errorMessage, successMessage } from "../../../utils/messages";
import DotLoader from "../../DotLoaderBtn/DotLoader";

const MapCropperModal = (props) => {
    const { setParentState, parentState, genre } = props;
    const [image, setImage] = useState(parentState?.clickedItemAvatar || null);
    const [imageDropped, setImageDropped] = useState(false);

    const [loading, setLoading] = useState(false);

    const [updateMapImage] = useUpdateMapImageMutation();
    const [deleteMapImage] = useDeleteMapImageMutation();
    const [updateRouteImage] = useUpdateRouteImageMutation();
    const [deleteRouteImage] = useDeleteRouteImageMutation();
    const [updateGroupImage] = useUpdateGroupImageMutation();
    const [deleteGroupImage] = useDeleteGroupImageMutation();

    const cropperRef = useRef(null);
    const imageRef = useRef(null);

    const [imageState, setImageState] = useState({
        zoom: 0,
        rotate: 0,
    });
    const [adjustments, setAdjustments] = useState({
        brightness: 0,
        hue: 0,
        saturation: 0,
        contrast: 0,
    });

    // THIS FUNCTION WILL CALL WHEN EVER THERE IS CHANGE IN EDITOR
    const onChange = (cropper) => {
        const state = cropper.getState();
        setImageState({
            rotate: state.transforms.rotate,
            zoom: getAbsoluteZoom(state, cropper.getSettings()),
        });
    };

    const onZoom = (e) => {
        const cropper = cropperRef.current;
        if (cropper) {
            cropper.zoomImage(
                getZoomFactor(cropper.getState(), cropper.getSettings(), e.target.value),
                {
                    transitions: false,
                }
            );
        }
    };

    const onRotate = (e) => {
        const { value } = e.target;
        const difference = value - imageState.rotate;
        cropperRef.current?.rotateImage(difference, {
            transitions: false,
        });
    };

    const handleImageChange = (e) => {
        setImageDropped(true);

        const { files } = e.target;
        if (files && files[0]) {
            const blob = URL.createObjectURL(files[0]);
            setImage(blob);
        } else {
            setImage("");
        }
    };

    // code for custom drag of a image

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setImageDropped(true);

        const file = e.dataTransfer.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setImage(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };
    const handleSubmitMapImage = async () => {
        const canvas = cropperRef.current?.getCanvas();
        if (canvas) {
            const form = new FormData();
            canvas.toBlob(async (blob) => {
                if (blob) {
                    setLoading(true);
                    form.append("avatar", blob);
                    form.append("id", parentState?.clickedItemID);
                    const { data, error } = await updateMapImage(form);
                    if (data) {
                        successMessage("Image updated successfully");
                        setParentState((prevState) => ({
                            ...prevState,
                            openMapCropperModal: false,
                        }));
                    }
                    if (error) {
                        errorMessage(error?.data?.message);
                    }
                    setLoading(false);
                }
            }, "image/jpeg");
        }
    };

    const handleDeleteMapImage = async () => {
        const { data, error } = await deleteMapImage({ id: parentState?.clickedItemID });
        if (data) {
            setParentState((prevState) => ({
                ...prevState,
                openMapCropperModal: false,
            }));
            successMessage(data?.message);
        }
        if (error) {
            errorMessage(error?.data?.message);
        }
    };

    const handleSubmitGroupImage = async () => {
        const canvas = cropperRef.current?.getCanvas();
        if (canvas) {
            const form = new FormData();
            canvas.toBlob(async (blob) => {
                if (blob) {
                    setLoading(true);
                    form.append("avatar", blob);
                    form.append("id", parentState?.clickedItemID);
                    const { data, error } = await updateGroupImage(form);
                    if (data) {
                        successMessage("Image updated successfully");
                        setParentState((prevState) => ({
                            ...prevState,
                            openMapCropperModal: false,
                        }));
                    }
                    if (error) {
                        errorMessage(error?.data?.message);
                    }
                    setLoading(false);
                }
            }, "image/jpeg");
        }
    };

    const handleDeleteGroupImage = async () => {
        setLoading(true);
        const { data, error } = await deleteGroupImage({ id: parentState?.clickedItemID });
        if (data) {
            setLoading(false);
            setParentState((prevState) => ({
                ...prevState,
                openMapCropperModal: false,
            }));
            successMessage(data?.message);
        }
        if (error) {
            setLoading(false);
            errorMessage(error?.data?.message);
        }
    };
    const handleSubmitRouteImage = async () => {
        const canvas = cropperRef.current?.getCanvas();
        if (canvas) {
            const form = new FormData();
            canvas.toBlob(async (blob) => {
                if (blob) {
                    setLoading(true);
                    form.append("avatar", blob);
                    form.append("id", parentState?.clickedItemID);
                    const { data, error } = await updateRouteImage(form);
                    if (data) {
                        successMessage("Image updated successfully");
                        setParentState((prevState) => ({
                            ...prevState,
                            openMapCropperModal: false,
                        }));
                    }
                    if (error) {
                        errorMessage(error?.data?.message);
                    }
                    setLoading(false);
                }
            }, "image/jpeg");
        }
    };

    const handleDeleteRouteImage = async () => {
        setLoading(true);
        const { data, error } = await deleteRouteImage({ id: parentState?.clickedItemID });
        if (data) {
            setLoading(false);
            setParentState((prevState) => ({
                ...prevState,
                openMapCropperModal: false,
            }));
            successMessage(data?.message);
        }
        if (error) {
            setLoading(false);
            errorMessage(error?.data?.message);
        }
    };

    return (
        <div className="image-cropper--container">
            <div className="header">
                <div className="close-wrapper">
                    <img
                        src={closeIconGrey}
                        alt="close"
                        width={22}
                        onClick={(e) =>
                            setParentState((prevState) => ({
                                ...prevState,
                                openMapCropperModal: false,
                            }))
                        }
                    />
                </div>
                <h5 style={{ marginBottom: 0 }}>Map image</h5>
            </div>
            <div className="image-cropper-wrapper">
                {!image && !imageDropped && (
                    <div className="image-cropper-dropzone">
                        <div className="drop-box" onDragOver={handleDragOver} onDrop={handleDrop}>
                            <span> Drag and drop or </span>
                            <div>
                                <label htmlFor="uploadImage">upload</label>
                                <input
                                    ref={imageRef}
                                    type="file"
                                    accept="image/*"
                                    id="uploadImage"
                                    onChange={handleImageChange}
                                    hidden
                                />
                            </div>{" "}
                            <span>an image</span>
                        </div>
                    </div>
                )}

                {image && !imageDropped && (
                    <div className="image-cropper-dropzone">
                        <div
                            className="drop-box"
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                            style={{ backgroundImage: `url(${image})` }}
                        ></div>
                        <div className="upload-highlighter">
                            <span> Drag and drop or </span>
                            <div>
                                <label htmlFor="uploadImage">upload</label>
                                <input
                                    ref={imageRef}
                                    type="file"
                                    accept="image/*"
                                    id="uploadImage"
                                    onChange={handleImageChange}
                                    hidden
                                />
                            </div>{" "}
                            <span>an image</span>
                        </div>
                    </div>
                )}

                {imageDropped && image && (
                    <Cropper
                        className="imageEditor__cropper "
                        src={image}
                        ref={cropperRef}
                        onChange={onChange}
                        stencilComponent={RectangleStencil}
                        backgroundComponent={AdjustableCropperBackground}
                        backgroundProps={adjustments}
                    />
                )}
                {image && imageDropped && (
                    <div className="info-highlighter">
                        <p>Drag to reposition</p>
                    </div>
                )}
            </div>
            <div className="functionality-wrapper">
                <div className="action-tabs">
                    <button type="button" className="active">
                        <img src={cropIcon} alt="crop" />
                        Crop
                    </button>
                </div>
                <div className="settings-wrapper">
                    <div className="item-wrapper">
                        <label htmlFor="zoom">
                            <span>Zoom</span>{" "}
                            <span>{parseFloat(imageState.zoom * 10).toFixed(1)}</span>{" "}
                        </label>
                        <input
                            type="range"
                            name="zoom"
                            id="zoom"
                            value={imageState.zoom}
                            min={0}
                            max={1}
                            step={0.01}
                            onChange={onZoom}
                            className="range-slider"
                        />
                    </div>

                    <div className="item-wrapper">
                        <label htmlFor="rotation">
                            {" "}
                            <span>Rotation</span>{" "}
                            <span>{parseFloat(imageState.rotate).toFixed(1)}</span>
                        </label>
                        <input
                            type="range"
                            name="rotation"
                            id="rotation"
                            value={imageState.rotate}
                            min={0}
                            max={360}
                            step={0.1}
                            onChange={onRotate}
                            className="range-slider"
                        />
                    </div>
                </div>
                <div className="actions-listener-wrapper">
                    <div className="remove-cta-wrapper">
                        {image && !imageDropped && (
                            <button
                                type="button"
                                className="danger-underline-cta"
                                onClick={
                                    genre === "map"
                                        ? handleDeleteMapImage
                                        : genre === "route"
                                        ? handleDeleteRouteImage
                                        : handleDeleteGroupImage
                                }
                            >
                                Delete
                            </button>
                        )}
                    </div>
                    <div className="action-items">
                        <button
                            className="border-cancel-btn"
                            onClick={(e) =>
                                setParentState((prevState) => ({
                                    ...prevState,
                                    openMapCropperModal: false,
                                }))
                            }
                        >
                            Cancel
                        </button>
                        {loading ? (
                            <button
                                className="btn animate-dots-blue cta-border-blue-auto"
                                type="button"
                                disabled
                            >
                                <DotLoader />
                            </button>
                        ) : (
                            <button
                                type="button"
                                className="btn cta-border-blue-auto"
                                onClick={
                                    genre === "map"
                                        ? handleSubmitMapImage
                                        : genre === "route"
                                        ? handleSubmitRouteImage
                                        : handleSubmitGroupImage
                                }
                            >
                                Apply
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MapCropperModal;
