export const countryList = [
    {
        code: "AD",
        country: "Andorra",
        countryCode: "+376",
    },
    {
        code: "AF",
        country: "Afghanistan",
        countryCode: "+93",
    },
    {
        code: "AG",
        country: "Antigua and Barbuda",
        countryCode: "+1268",
    },
    {
        code: "AI",
        country: "Anguilla",
        countryCode: "+1264",
    },
    {
        code: "AL",
        country: "Albania",
        countryCode: "+355",
    },
    {
        code: "AM",
        country: "Armenia",
        countryCode: "+374",
    },
    {
        code: "AO",
        country: "Angola",
        countryCode: "+244",
    },
    {
        code: "AQ",
        country: "Antarctica",
        countryCode: "+672",
    },
    {
        code: "AR",
        country: "Argentina",
        countryCode: "+54",
    },
    {
        code: "AS",
        country: "American Samoa",
        countryCode: "+1684",
    },
    {
        code: "AT",
        country: "Austria",
        countryCode: "+43",
    },
    {
        code: "AU",
        country: "Australia",
        countryCode: "+61",
    },
    {
        code: "AW",
        country: "Aruba",
        countryCode: "+297",
    },
    {
        code: "AX",
        country: "Åland Islands",
        countryCode: "+358",
    },
    {
        code: "AZ",
        country: "Azerbaijan",
        countryCode: "+994",
    },
    {
        code: "BA",
        country: "Bosnia and Herzegovina",
        countryCode: "+387",
    },
    {
        code: "BB",
        country: "Barbados",
        countryCode: "+1246",
    },
    {
        code: "BD",
        country: "Bangladesh",
        countryCode: "+880",
    },
    {
        code: "BE",
        country: "Belgium",
        countryCode: "+32",
    },
    {
        code: "BF",
        country: "Burkina Faso",
        countryCode: "+226",
    },
    {
        code: "BG",
        country: "Bulgaria",
        countryCode: "+359",
    },
    {
        code: "BH",
        country: "Bahrain",
        countryCode: "+973",
    },
    {
        code: "BI",
        country: "Burundi",
        countryCode: "+257",
    },
    {
        code: "BJ",
        country: "Benin",
        countryCode: "+229",
    },
    {
        code: "BL",
        country: "Saint Barthélemy",
        countryCode: "+590",
    },
    {
        code: "BM",
        country: "Bermuda",
        countryCode: "+1441",
    },
    {
        code: "BN",
        country: "Brunei Darussalam",
        countryCode: "+673",
    },
    {
        code: "BO",
        country: "Bolivia (Plurinational State of)",
        countryCode: "+591",
    },
    {
        code: "BQ",
        country: "Bonaire, Sint Eustatius and Saba",
        countryCode: "+5997",
    },
    {
        code: "BR",
        country: "Brazil",
        countryCode: "+55",
    },
    {
        code: "BT",
        country: "Bhutan",
        countryCode: "+975",
    },
    {
        code: "BV",
        country: "Bouvet Island",
        countryCode: "+47",
    },
    {
        code: "BW",
        country: "Botswana",
        countryCode: "+267",
    },
    {
        code: "BY",
        country: "Belarus",
        countryCode: "+375",
    },
    {
        code: "BZ",
        country: "Belize",
        countryCode: "+501",
    },
    {
        code: "CA",
        country: "Canada",
        countryCode: "+1",
    },
    {
        code: "CH",
        country: "Switzerland",
        countryCode: "+41",
    },
    {
        code: "CI",
        country: "Côte d'Ivoire",
        countryCode: "+225",
    },
    {
        code: "CL",
        country: "Chile",
        countryCode: "+56",
    },
    {
        code: "CM",
        country: "Cameroon",
        countryCode: "+237",
    },
    {
        code: "CN",
        country: "China",
        countryCode: "+86",
    },
    {
        code: "CO",
        country: "Colombia",
        countryCode: "+57",
    },
    {
        code: "CR",
        country: "Costa Rica",
        countryCode: "+506",
    },
    {
        code: "CU",
        country: "Cuba",
        countryCode: "+53",
    },
    {
        code: "CV",
        country: "Cabo Verde",
        countryCode: "+238",
    },
    {
        code: "CW",
        country: "Curaçao",
        countryCode: "+599",
    },
    {
        code: "CX",
        country: "Christmas Island",
        countryCode: "+61",
    },
    {
        code: "CY",
        country: "Cyprus",
        countryCode: "+357",
    },
    {
        code: "DE",
        country: "Germany",
        countryCode: "+49",
    },
    {
        code: "DJ",
        country: "Djibouti",
        countryCode: "+253",
    },
    {
        code: "DK",
        country: "Denmark",
        countryCode: "+45",
    },
    {
        code: "DM",
        country: "Dominica",
        countryCode: "+767",
    },
    {
        code: "DZ",
        country: "Algeria",
        countryCode: "+213",
    },
    {
        code: "EC",
        country: "Ecuador",
        countryCode: "+593",
    },
    {
        code: "EE",
        country: "Estonia",
        countryCode: "+372",
    },
    {
        code: "EG",
        country: "Egypt",
        countryCode: "+20",
    },
    {
        code: "EH",
        country: "Western Sahara",
        countryCode: "+212",
    },
    {
        code: "ER",
        country: "Eritrea",
        countryCode: "+291",
    },
    {
        code: "ES",
        country: "Spain",
        countryCode: "+34",
    },
    {
        code: "ET",
        country: "Ethiopia",
        countryCode: "+251",
    },
    {
        code: "FI",
        country: "Finland",
        countryCode: "+358",
    },
    {
        code: "FJ",
        country: "Fiji",
        countryCode: "+679",
    },
    {
        code: "FM",
        country: "Micronesia (Federated States of)",
        countryCode: "+691",
    },
    {
        code: "FR",
        country: "France",
        countryCode: "+33",
    },
    {
        code: "GA",
        country: "Gabon",
        countryCode: "+241",
    },
    {
        code: "GD",
        country: "Grenada",
        countryCode: "+1473",
    },
    {
        code: "GE",
        country: "Georgia",
        countryCode: "+995",
    },
    {
        code: "GF",
        country: "French Guiana",
        countryCode: "+594",
    },
    {
        code: "GG",
        country: "Guernsey",
        countryCode: "+44",
    },
    {
        code: "GH",
        country: "Ghana",
        countryCode: "+233",
    },
    {
        code: "GI",
        country: "Gibraltar",
        countryCode: "+350",
    },
    {
        code: "GL",
        country: "Greenland",
        countryCode: "+299",
    },
    {
        code: "GN",
        country: "Guinea",
        countryCode: "+224",
    },
    {
        code: "GP",
        country: "Guadeloupe",
        countryCode: "+590",
    },
    {
        code: "GQ",
        country: "Equatorial Guinea",
        countryCode: "+240",
    },
    {
        code: "GR",
        country: "Greece",
        countryCode: "+30",
    },
    {
        code: "GS",
        country: "South Georgia and the South Sandwich Islands",
        countryCode: "+500",
    },
    {
        code: "GT",
        country: "Guatemala",
        countryCode: "+502",
    },
    {
        code: "GU",
        country: "Guam",
        countryCode: "+1",
    },
    {
        code: "GW",
        country: "Guinea-Bissau",
        countryCode: "+245",
    },
    {
        code: "GY",
        country: "Guyana",
        countryCode: "+592",
    },
    {
        code: "HK",
        country: "Hong Kong",
        countryCode: "+852",
    },
    {
        code: "HN",
        country: "Honduras",
        countryCode: "+504",
    },
    {
        code: "HR",
        country: "Croatia",
        countryCode: "+385",
    },
    {
        code: "HT",
        country: "Haiti",
        countryCode: "+509",
    },
    {
        code: "HU",
        country: "Hungary",
        countryCode: "+36",
    },
    {
        code: "ID",
        country: "Indonesia",
        countryCode: "+62",
    },
    {
        code: "IE",
        country: "Ireland",
        countryCode: "+353",
    },
    {
        code: "IL",
        country: "Israel",
        countryCode: "+972",
    },
    {
        code: "IM",
        country: "Isle of Man",
        countryCode: "+44",
    },
    {
        code: "IN",
        country: "India",
        countryCode: "+91",
    },
    {
        code: "IQ",
        country: "Iraq",
        countryCode: "+964",
    },
    {
        code: "IR",
        country: "Iran (Islamic Republic of)",
        countryCode: "+98",
    },
    {
        code: "IS",
        country: "Iceland",
        countryCode: "+354",
    },
    {
        code: "IT",
        country: "Italy",
        countryCode: "+39",
    },
    {
        code: "JE",
        country: "Jersey",
        countryCode: "+44",
    },
    {
        code: "JM",
        country: "Jamaica",
        countryCode: "+876",
    },
    {
        code: "JO",
        country: "Jordan",
        countryCode: "+962",
    },
    {
        code: "JP",
        country: "Japan",
        countryCode: "+81",
    },
    {
        code: "KE",
        country: "Kenya",
        countryCode: "+254",
    },
    {
        code: "KG",
        country: "Kyrgyzstan",
        countryCode: "+996",
    },
    {
        code: "KH",
        country: "Cambodia",
        countryCode: "+855",
    },
    {
        code: "KP",
        country: "North Korea",
        countryCode: "+850",
    },
    {
        code: "KR",
        country: "South Korea",
        countryCode: "+82",
    },
    {
        code: "KI",
        country: "Kiribati",
        countryCode: "+686",
    },
    {
        code: "KN",
        country: "Saint Kitts and Nevis",
        countryCode: "+1869",
    },
    {
        code: "KW",
        country: "Kuwait",
        countryCode: "+965",
    },
    {
        code: "KZ",
        country: "Kazakhstan",
        countryCode: "+7",
    },
    {
        code: "LB",
        country: "Lebanon",
        countryCode: "+961",
    },
    {
        code: "LC",
        country: "Saint Lucia",
        countryCode: "+1758",
    },
    {
        code: "LI",
        country: "Liechtenstein",
        countryCode: "+423",
    },
    {
        code: "LK",
        country: "Sri Lanka",
        countryCode: "+94",
    },
    {
        code: "LR",
        country: "Liberia",
        countryCode: "+231",
    },
    {
        code: "LS",
        country: "Lesotho",
        countryCode: "+266",
    },
    {
        code: "LT",
        country: "Lithuania",
        countryCode: "+370",
    },
    {
        code: "LU",
        country: "Luxembourg",
        countryCode: "+352",
    },
    {
        code: "LV",
        country: "Latvia",
        countryCode: "+371",
    },
    {
        code: "LY",
        country: "Libya",
        countryCode: "+218",
    },
    {
        code: "MA",
        country: "Morocco",
        countryCode: "+212",
    },
    {
        code: "MC",
        country: "Monaco",
        countryCode: "+377",
    },
    {
        code: "ME",
        country: "Montenegro",
        countryCode: "+382",
    },
    {
        code: "MF",
        country: "Saint Martin (French part)",
        countryCode: "+590",
    },
    {
        code: "MG",
        country: "Madagascar",
        countryCode: "+261",
    },
    {
        code: "ML",
        country: "Mali",
        countryCode: "+223",
    },
    {
        code: "MM",
        country: "Myanmar",
        countryCode: "+95",
    },
    {
        code: "MN",
        country: "Mongolia",
        countryCode: "+976",
    },
    {
        code: "MO",
        country: "Macao",
        countryCode: "+853",
    },
    {
        code: "MQ",
        country: "Martinique",
        countryCode: "+596",
    },
    {
        code: "MR",
        country: "Mauritania",
        countryCode: "+222",
    },
    {
        code: "MS",
        country: "Montserrat",
        countryCode: "+1664",
    },
    {
        code: "MT",
        country: "Malta",
        countryCode: "+356",
    },
    {
        code: "MU",
        country: "Mauritius",
        countryCode: "+230",
    },
    {
        code: "MV",
        country: "Maldives",
        countryCode: "+960",
    },
    {
        code: "MW",
        country: "Malawi",
        countryCode: "+265",
    },
    {
        code: "MX",
        country: "Mexico",
        countryCode: "+52",
    },
    {
        code: "MY",
        country: "Malaysia",
        countryCode: "+60",
    },
    {
        code: "MZ",
        country: "Mozambique",
        countryCode: "+258",
    },
    {
        code: "NA",
        country: "Namibia",
        countryCode: "+264",
    },
    {
        code: "NC",
        country: "New Caledonia",
        countryCode: "+687",
    },
    {
        code: "NF",
        country: "Norfolk Island",
        countryCode: "+672",
    },
    {
        code: "NG",
        country: "Nigeria",
        countryCode: "+234",
    },
    {
        code: "NI",
        country: "Nicaragua",
        countryCode: "+505",
    },
    {
        code: "NO",
        country: "Norway",
        countryCode: "+47",
    },
    {
        code: "NP",
        country: "Nepal",
        countryCode: "+977",
    },
    {
        code: "NR",
        country: "Nauru",
        countryCode: "+674",
    },
    {
        code: "NU",
        country: "Niue",
        countryCode: "+683",
    },
    {
        code: "NZ",
        country: "New Zealand",
        countryCode: "+64",
    },
    {
        code: "OM",
        country: "Oman",
        countryCode: "+968",
    },
    {
        code: "PA",
        country: "Panama",
        countryCode: "+507",
    },
    {
        code: "PE",
        country: "Peru",
        countryCode: "+51",
    },
    {
        code: "PF",
        country: "French Polynesia",
        countryCode: "+689",
    },
    {
        code: "PG",
        country: "Papua New Guinea",
        countryCode: "+675",
    },
    {
        code: "PK",
        country: "Pakistan",
        countryCode: "+92",
    },
    {
        code: "PL",
        country: "Poland",
        countryCode: "+48",
    },
    {
        code: "PM",
        country: "Saint Pierre and Miquelon",
        countryCode: "+508",
    },
    {
        code: "PN",
        country: "Pitcairn",
        countryCode: "+64",
    },
    {
        code: "PR",
        country: "Puerto Rico",
        countryCode: "+1",
    },
    {
        code: "PS",
        country: "Palestine, State of",
        countryCode: "+970",
    },
    {
        code: "PT",
        country: "Portugal",
        countryCode: "+351",
    },
    {
        code: "PW",
        country: "Palau",
        countryCode: "+680",
    },
    {
        code: "PY",
        country: "Paraguay",
        countryCode: "+595",
    },
    {
        code: "QA",
        country: "Qatar",
        countryCode: "+974",
    },
    {
        code: "RE",
        country: "Réunion",
        countryCode: "+262",
    },
    {
        code: "RO",
        country: "Romania",
        countryCode: "+40",
    },
    {
        code: "RS",
        country: "Serbia",
        countryCode: "+381",
    },
    {
        code: "RU",
        country: "Russia",
        countryCode: "+7",
    },
    {
        code: "RW",
        country: "Rwanda",
        countryCode: "+250",
    },
    {
        code: "SA",
        country: "Saudi Arabia",
        countryCode: "+966",
    },
    {
        code: "SB",
        country: "Solomon Islands",
        countryCode: "+677",
    },
    {
        code: "SC",
        country: "Seychelles",
        countryCode: "+248",
    },
    {
        code: "SE",
        country: "Sweden",
        countryCode: "+46",
    },
    {
        code: "SG",
        country: "Singapore",
        countryCode: "+65",
    },
    {
        code: "SH",
        country: "Saint Helena, Ascension and Tristan da Cunha",
        countryCode: "+290",
    },
    {
        code: "SI",
        country: "Slovenia",
        countryCode: "+386",
    },
    {
        code: "SJ",
        country: "Svalbard and Jan Mayen",
        countryCode: "+4779",
    },
    {
        code: "SK",
        country: "Slovakia",
        countryCode: "+421",
    },
    {
        code: "SL",
        country: "Sierra Leone",
        countryCode: "+232",
    },
    {
        code: "SM",
        country: "Republic of San Marino",
        countryCode: "+378",
    },
    {
        code: "SN",
        country: "Senegal",
        countryCode: "+221",
    },
    {
        code: "SO",
        country: "Somalia",
        countryCode: "+252",
    },
    {
        code: "SR",
        country: "Suriname",
        countryCode: "+597",
    },
    {
        code: "SS",
        country: "South Sudan",
        countryCode: "+211",
    },
    {
        code: "ST",
        country: "Sao Tome and Principe",
        countryCode: "+239",
    },
    {
        code: "SV",
        country: "El Salvador",
        countryCode: "+503",
    },
    {
        code: "SX",
        country: "Sint Maarten (Dutch part)",
        countryCode: "+1721",
    },
    {
        code: "SY",
        country: "Syrian Arab Republic",
        countryCode: "+963",
    },
    {
        code: "TD",
        country: "Chad",
        countryCode: "+235",
    },
    {
        code: "TG",
        country: "Togo",
        countryCode: "+228",
    },
    {
        code: "TH",
        country: "Thailand",
        countryCode: "+66",
    },
    {
        code: "TJ",
        country: "Tajikistan",
        countryCode: "+992",
    },
    {
        code: "TK",
        country: "Tokelau",
        countryCode: "+690",
    },
    {
        code: "TL",
        country: "Timor-Leste",
        countryCode: "+670",
    },
    {
        code: "TM",
        country: "Turkmenistan",
        countryCode: "+993",
    },
    {
        code: "TN",
        country: "Tunisia",
        countryCode: "+216",
    },
    {
        code: "TO",
        country: "Tonga",
        countryCode: "+676",
    },
    {
        code: "TR",
        country: "Turkey",
        countryCode: "+90",
    },
    {
        code: "TT",
        country: "Trinidad and Tobago",
        countryCode: "+868",
    },
    {
        code: "TV",
        country: "Tuvalu",
        countryCode: "+688",
    },
    {
        code: "TZ",
        country: "United Republic of Tanzania",
        countryCode: "+255",
    },
    {
        code: "UA",
        country: "Ukraine",
        countryCode: "+380",
    },
    {
        code: "UG",
        country: "Uganda",
        countryCode: "+256",
    },
    {
        code: "US",
        country: "United States of America",
        countryCode: "+1",
    },
    {
        code: "UY",
        country: "Uruguay",
        countryCode: "+598",
    },
    {
        code: "UZ",
        country: "Uzbekistan",
        countryCode: "+998",
    },
    {
        code: "VC",
        country: "Saint Vincent and the Grenadines",
        countryCode: "+1784",
    },
    {
        code: "VE",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "+58",
    },
    {
        code: "VG",
        country: "Virgin Islands (British)",
        countryCode: "+1284",
    },
    {
        code: "VI",
        country: "Virgin Islands (U.S.)",
        countryCode: "+1340",
    },
    {
        code: "VN",
        country: "Vietnam",
        countryCode: "+84",
    },
    {
        code: "VU",
        country: "Vanuatu",
        countryCode: "+678",
    },
    {
        code: "WF",
        country: "Wallis and Futuna",
        countryCode: "+681",
    },
    {
        code: "WS",
        country: "Samoa",
        countryCode: "+685",
    },
    {
        code: "YE",
        country: "Yemen",
        countryCode: "+967",
    },
    {
        code: "YT",
        country: "Mayotte",
        countryCode: "+262",
    },
    {
        code: "ZA",
        country: "South Africa",
        countryCode: "+27",
    },
    {
        code: "ZM",
        country: "Zambia",
        countryCode: "+260",
    },
    {
        code: "ZW",
        country: "Zimbabwe",
        countryCode: "+263",
    },
    {
        code: "SZ",
        country: "Eswatini",
        countryCode: "+268",
    },
    {
        code: "MK",
        country: "North Macedonia",
        countryCode: "+389",
    },
    {
        code: "PH",
        country: "Philippines",
        countryCode: "+63",
    },
    {
        code: "NL",
        country: "Netherlands",
        countryCode: "+31",
    },
    {
        code: "AE",
        country: "United Arab Emirates",
        countryCode: "+971",
    },
    {
        code: "MD",
        country: "Republic of Moldova",
        countryCode: "+373",
    },
    {
        code: "GM",
        country: "Gambia",
        countryCode: "+220",
    },
    {
        code: "DO",
        country: "Dominican Republic",
        countryCode: "+1",
    },
    {
        code: "SD",
        country: "Sudan",
        countryCode: "+249",
    },
    {
        code: "LA",
        country: "Lao People's Democratic Republic",
        countryCode: "+856",
    },
    {
        code: "TW",
        country: "Taiwan, Province of China",
        countryCode: "+886",
    },
    {
        code: "CG",
        country: "Republic of the Congo",
        countryCode: "+242",
    },
    {
        code: "CZ",
        country: "Czechia",
        countryCode: "+420",
    },
    {
        code: "GB",
        country: "United Kingdom",
        countryCode: "+44",
    },
    {
        code: "NE",
        country: "Niger",
        countryCode: "+227",
    },
    {
        code: "CD",
        country: "Democratic Republic of the Congo",
        countryCode: "+243",
    },
    {
        code: "BS",
        country: "Commonwealth of The Bahamas",
        countryCode: "+1 242",
    },
    {
        code: "CC",
        country: "Cocos (Keeling) Islands",
        countryCode: "+61 891",
    },
    {
        code: "CF",
        country: "Central African Republic",
        countryCode: "+236",
    },
    {
        code: "CK",
        country: "Cook Islands",
        countryCode: "+682",
    },
    {
        code: "FK",
        country: "Falkland Islands",
        countryCode: "+500",
    },
    {
        code: "FO",
        country: "Faroe Islands",
        countryCode: "+298",
    },
    {
        code: "HM",
        country: "Territory of Heard Island and McDonald Islands",
        countryCode: "+672",
    },
    {
        code: "IO",
        country: "British Indian Ocean Territory",
        countryCode: "+246",
    },
    {
        code: "KM",
        country: "Comoros",
        countryCode: "+269",
    },
    {
        code: "KY",
        country: "Cayman Islands",
        countryCode: "+1 345",
    },
    {
        code: "MH",
        country: "Republic of the Marshall Islands",
        countryCode: "+692",
    },
    {
        code: "MP",
        country: "Commonwealth of the Northern Mariana Islands",
        countryCode: "+1 670",
    },
    {
        code: "TC",
        country: "Turks and Caicos Islands",
        countryCode: "+1 649",
    },
    {
        code: "TF",
        country: "French Southern and Antarctic Lands",
        countryCode: "+672",
    },
    {
        code: "UM",
        country: "United States Minor Outlying Islands",
        countryCode: "+246",
    },
    {
        code: "VA",
        country: "Holy See",
        countryCode: "+39",
    },
    {
        code: "XK",
        country: "Republic of Kosovo",
        countryCode: "+383",
    },
    {
        code: "AN",
        country: "Netherlands Antilles",
        countryCode: "+599",
    },
];
