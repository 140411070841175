import React, { useCallback } from "react";
import trashIconWhite from "../../assets/images/svg/trashIconWhite.svg";
import checkmarkWhite from "../../assets/images/svg/checkmark-white-payment.svg";
import visaIcon from "../../assets/images/Billing/simple-visa-icon.png";
import americanIcon from "../../assets/images/svg/paymentIcons/american-express-paymnet-icon.svg";
import dinersIcon from "../../assets/images/svg/paymentIcons/diners-paymnet-icon.svg";
import interacIcon from "../../assets/images/svg/paymentIcons/interac-paymnet-icon.svg";
import jcbIcon from "../../assets/images/svg/paymentIcons/jcb-paymnet-icon.svg";
import mastercardIcon from "../../assets/images/svg/paymentIcons/mastercard-paymnet-icon.svg";
import unionpayIcon from "../../assets/images/svg/paymentIcons/unionpay-paymnet-icon.svg";
import {
    useDeletePaymentCardMutation,
    useSetPrimaryPaymentCardMutation,
} from "../../services/baseApi";
import { errorMessage, successMessage } from "../../utils/messages";
import { debounce } from "../../utils/utils";

const PaymentCard = (props) => {
    const { cardId, name, expMonth, expYear, cardnumber, primary, brand, customerID } = props;

    const getBrandName = useCallback((name) => {
        switch (name) {
            case "visa":
                return <img src={visaIcon} alt="payment method icon" />;
            case "mastercard":
                return <img src={mastercardIcon} alt="payment method icon" />;
            case "diners":
                return <img src={dinersIcon} alt="payment method icon" />;
            case "unionpay":
                return <img src={unionpayIcon} alt="payment method icon" />;
            case "amex":
                return <img src={americanIcon} alt="payment method icon" />;
            case "jcb":
                return <img src={jcbIcon} alt="payment method icon" />;
            case "discover":
                return <img src={interacIcon} alt="payment method icon" />;
            default:
                return null;
        }
    }, []);

    const [deletePaymentCard] = useDeletePaymentCardMutation();
    const [setPrimaryPaymentCard] = useSetPrimaryPaymentCardMutation();

    const updatedPaymentCard = async (customerId, cardId) => {
        if (primary) return;
        else {
            const { data, error } = await setPrimaryPaymentCard({ customerId, cardId });

            if (data) {
                successMessage("Payment method updated");
                return;
            }

            if (error) {
                errorMessage(error?.data?.message);
                console.log("charge error: ", error);
                return;
            }
        }
    };

    const deletePaymentCardHandle = async (cardId) => {
        const { data, error } = await deletePaymentCard(cardId);

        if (data) {
            successMessage("Successfully removed");
            return;
        }

        if (error) {
            errorMessage(error?.data?.message);
            console.log("charge error: ", error);
            return;
        }
    };

    return (
        <div
            className="payment-card-item"
            onClick={debounce((e) => {
                updatedPaymentCard(customerID, cardId);
            }, 500)}
        >
            <div
                className={
                    primary ? " payment-card-item-wrapper border" : "payment-card-item-wrapper"
                }
            >
                <div>
                    <div className="upSide">
                        {getBrandName(brand)}

                        <p>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span> <span></span>
                            <span></span>
                            <span></span>
                            <span></span> <span></span>
                            <span></span>
                            <span></span>
                            <span></span> {cardnumber}
                        </p>
                    </div>
                    <div className="downSide">
                        <h6>{name}</h6>
                        <div>
                            <p>
                                <span>Valid thru</span>
                                <br />
                                {expMonth}/{expYear.toString().slice(2)}
                            </p>
                        </div>
                    </div>
                </div>
                {primary && (
                    <div className="billing-card-label-wrapper">
                        <img src={checkmarkWhite} alt="checkmark" /> <span>Primary</span>
                    </div>
                )}
            </div>
            {!primary && (
                <div className="billing-card-del-wrapper">
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            deletePaymentCardHandle(cardId);
                        }}
                    >
                        <img src={trashIconWhite} alt="trash" />
                    </button>
                </div>
            )}
        </div>
    );
};

export default PaymentCard;
