export default function ChatIcon({ color = "inherit", fill = "inherit", width = "20px" }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 15.996">
            <path
                id="message-off"
                d="M18622,24491h0l1.688-5.066a7.551,7.551,0,0,1,6.732-10.93h.467a7.536,7.536,0,0,1,7.111,7.111v.443a7.56,7.56,0,0,1-7.557,7.553l-.027,0a7.454,7.454,0,0,1-3.35-.8L18622,24491Zm11.467-9.273a.856.856,0,1,0,.859.855A.854.854,0,0,0,18633.465,24481.723Zm-2.855,0a.856.856,0,1,0,.857.855A.857.857,0,0,0,18630.609,24481.723Zm-2.855,0a.856.856,0,1,0,.855.855A.857.857,0,0,0,18627.754,24481.723Z"
                transform="translate(-18621.998 -24475)"
                fill="#999b9e"
            />
        </svg>
    );
}
