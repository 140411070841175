import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import rightArrow from "../../assets/images/svg/arrowRight.svg";
import userIcon from "../../assets/images/avatar-grey.png";
import { errorMessage, successMessage } from "../../utils/messages";
import ManageUsersTable from "./ManageUsersTable";
import { capitalizeString, checkEmailValid } from "../../utils/utils";
import {
    useCreateCoworkerMutation,
    useGetCoworkersQuery,
    useRequestResetPasswordMutation,
} from "../../services/baseApi";
import { ManageUserCallSalesModal } from "../../components/Modals/ManageUserCallSalesModal";
import ReactPortal from "../../components/Portal/ReactPortal";
import CustomToastMsg from "../../components/Modals/CustomToastMsg";
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation";
import InviteConfirmation from "./InviteConfirmation";
import ForgetPasswordModel from "./ForgetPasswordModel";

const ManageUsers = () => {
    const navigate = useNavigate();
    const authPlan = useSelector((state) => state.auth.plan);
    const { user, planData } = useSelector((state) => state.auth);

    const [manageUsersState, setManageUsersState] = useState({
        searchItem: "",
        inviteEmail: "",
        inviteDescription: "",
        invitePassword: "",
        inviteModel: false,
        loading: false,
        coworkersData: null,
        openCallSalesModal: false,
        showCustomToast: false,
        isForgetLoading: false,
        openForgetPassword: false,
        forgetEmail: "",
    });

    const navigateToBilling = () => {
        navigate("/billing");
    };

    const [createCoworker] = useCreateCoworkerMutation();
    const [requestResetPassword, { isLoading: resetPasswordLoading }] =
        useRequestResetPasswordMutation();

    // Get All CoWorkers
    const { data: allCoworkersData, error: allCoworkersError } = useGetCoworkersQuery(user.email);
    useEffect(() => {
        if (allCoworkersData) {
            setManageUsersState((prevState) => {
                return {
                    ...prevState,
                    coworkersData: allCoworkersData,
                };
            });
        }

        if (allCoworkersError) {
            errorMessage(allCoworkersError.data.message);
        }
    }, [allCoworkersData, allCoworkersError]);

    const handleInviteUser = async (e) => {
        e.preventDefault();
        if (checkEmailValid(manageUsersState.inviteEmail)) {
            errorMessage("Invalid Email Format");
            return;
        }
        if (authPlan === "FREE" && manageUsersState.coworkersData.length >= planData?.usersCount) {
            navigate("/billing", {
                state: {
                    openSubscribeModal: true,
                    planModalOpen: "individual",
                },
            });
            return;
        } else if (
            planData?.planName === "BUSINESS" &&
            manageUsersState.coworkersData.length >= planData?.usersCount
        ) {
            navigate("/billing", {
                state: {
                    openSubscribeModal: true,
                    planModalOpen: "enterprise",
                },
            });
            return;
        } else if (
            planData?.planName === "ENTERPRISE" &&
            manageUsersState.coworkersData.length >= planData?.usersCount
        ) {
            errorMessage("Invitation limit reached");
            return;
        } else if (
            planData?.planName === "INDIVIDUAL" &&
            manageUsersState.coworkersData.length >= planData?.usersCount
        ) {
            navigate("/billing", {
                state: {
                    openSubscribeModal: true,
                    planModalOpen: "business",
                },
            });
            return;
        } else {
            setManageUsersState((prevState) => {
                return {
                    ...prevState,
                    loading: true,
                };
            });

            const { data, error } = await createCoworker({
                email: user.email,
                receiverEmail: manageUsersState.inviteEmail,
                message: manageUsersState.inviteDescription,
                password: manageUsersState.invitePassword,
            });

            if (data) {
                successMessage("Successfully sent");
                setManageUsersState((prevState) => {
                    return {
                        ...prevState,
                        inviteEmail: "",
                        inviteDescription: "",
                    };
                });
            }
            if (error) {
                errorMessage(error.data.message);
            }
            setManageUsersState((prevState) => {
                return {
                    ...prevState,
                    loading: false,
                    inviteModel: false,
                };
            });
        }
    };

    const handleOpenInviteModel = (e) => {
        e.preventDefault();
        setManageUsersState((prevState) => {
            return {
                ...prevState,
                inviteModel: true,
            };
        });
    };
    const handleInviteUserAgain = async (e, receieverEmail) => {
        e.preventDefault();
        const { data, error } = await createCoworker({
            email: user.email,
            receiverEmail: receieverEmail,
            message: "Sending invitation again",
        });

        if (data) {
            successMessage("Successfully sent");
        }
        if (error) {
            errorMessage(error.data.message);
        }
    };

    const filteredCoworkerData = manageUsersState?.coworkersData?.filter((item) => {
        return item.status === "PENDING";
    });

    const count_coworker_invited = filteredCoworkerData?.length;
    const count_coworker_remaining = planData?.usersCount - manageUsersState?.coworkersData?.length;

    const handleSubmitForget = async (e, showToast) => {
        e.preventDefault();

        const { data, error } = await requestResetPassword({
            email: manageUsersState.forgetEmail,
        });

        if (data?.status === "SUCCESS") {
            setManageUsersState((prevState) => {
                return {
                    ...prevState,
                    openForgetPassword: false,
                };
            });

            successMessage("Successfully sent message");
        }

        if (error) {
            setManageUsersState((prevState) => {
                return {
                    ...prevState,
                    openForgetPassword: false,
                };
            });
            errorMessage(error?.data?.message);
        }

        setTimeout(() => {
            setManageUsersState((prevState) => {
                return {
                    ...prevState,
                    openForgetPassword: false,
                };
            });
        }, 90000);
    };

    return (
        <>
            {!allCoworkersData ? (
                <LoadingAnimation />
            ) : (
                <section className="manage-users--container">
                    <h2 className="header-title">Manage users</h2>
                    <div className="manage-users-info-wrapper">
                        <div className="boxes-wrapper">
                            <div>
                                <p>
                                    {count_coworker_remaining || 0}
                                    <span>Available users</span>
                                </p>
                            </div>
                            <div>
                                <p>
                                    {count_coworker_invited || 0} <span>Pending users</span>
                                </p>
                            </div>
                            <div>
                                <p>
                                    {manageUsersState?.coworkersData?.length}/{planData?.usersCount}{" "}
                                    <span>Users</span>
                                </p>
                            </div>
                        </div>
                        <div className="plan-wrapper">
                            <div className="plan">
                                <p>Your Plan</p>
                                <h4>{capitalizeString(authPlan)}</h4>
                            </div>
                            <div className="make-call">
                                {authPlan === "INDIVIDUAL" ||
                                authPlan === "BUSINESS" ||
                                authPlan === "FREE" ? (
                                    <div>
                                        <button onClick={navigateToBilling}>
                                            Upgrade{" "}
                                            <img
                                                src={rightArrow}
                                                alt="move arroww"
                                                style={{ width: 18 }}
                                            />
                                        </button>
                                    </div>
                                ) : (
                                    <>
                                        <button
                                            onClick={(e) =>
                                                setManageUsersState((prevState) => ({
                                                    ...prevState,
                                                    openCallSalesModal: true,
                                                }))
                                            }
                                        >
                                            Call sales{" "}
                                            <img
                                                src={rightArrow}
                                                alt="move arroww"
                                                style={{ width: 18 }}
                                            />
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="invitation-search-header">
                        <form
                            className="input-fields-wrapper"
                            onSubmit={(e) => handleOpenInviteModel(e)}
                        >
                            <div className="form-wrapper">
                                <div className="img-wrapper">
                                    <img src={userIcon} alt="move arroww" />
                                </div>
                                <div className="feild-box">
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        name="User Email"
                                        placeholder="Type email address"
                                        autoComplete="on"
                                        value={manageUsersState.inviteEmail}
                                        onChange={(e) =>
                                            setManageUsersState((prevState) => ({
                                                ...prevState,
                                                inviteEmail: e.target.value,
                                            }))
                                        }
                                        required
                                    />
                                </div>
                                <div className="feild-box">
                                    <label>Message (optional)</label>
                                    <input
                                        type="text"
                                        name="User Message"
                                        placeholder="Let’s work together!"
                                        autoComplete="off"
                                        value={manageUsersState.inviteDescription}
                                        onChange={(e) =>
                                            setManageUsersState((prevState) => ({
                                                ...prevState,
                                                inviteDescription: e.target.value,
                                            }))
                                        }
                                        style={{ minWidth: 400 }}
                                    />
                                </div>
                            </div>
                            <div className="action-wrapper">
                                <button
                                    type="submit"
                                    className="btn cta-filled-blue-auto"
                                    disabled={!manageUsersState.inviteEmail}
                                >
                                    Invite
                                </button>
                            </div>
                        </form>
                    </div>

                    <ManageUsersTable
                        manageUsersState={manageUsersState}
                        setManageUsersState={setManageUsersState}
                        handleInviteUserAgain={handleInviteUserAgain}
                        user={user}
                    />

                    {manageUsersState.openCallSalesModal && (
                        <ReactPortal wrapperId="external_modal_container">
                            <ManageUserCallSalesModal setManageUsersState={setManageUsersState} />
                        </ReactPortal>
                    )}
                    {manageUsersState.inviteModel && (
                        <ReactPortal wrapperId="external_modal_container">
                            <InviteConfirmation
                                handleInviteUser={handleInviteUser}
                                setManageUsersState={setManageUsersState}
                                manageUsersState={manageUsersState}
                            />
                        </ReactPortal>
                    )}
                    {manageUsersState?.openForgetPassword && (
                        <ReactPortal wrapperId="external_modal_container">
                            <ForgetPasswordModel
                                loading={resetPasswordLoading}
                                setState={setManageUsersState}
                                state={manageUsersState}
                                handleSubmitForget={handleSubmitForget}
                            />
                        </ReactPortal>
                    )}

                    {manageUsersState.showCustomToast && (
                        <CustomToastMsg
                            title={"Thank you!"}
                            msg={
                                "A XoomMaps team member will be contacting you soon to assist your request."
                            }
                        />
                    )}
                </section>
            )}
        </>
    );
};

export default ManageUsers;
