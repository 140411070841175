import React, { useState } from "react";
import { useSelector } from "react-redux";
import GroupMapListItem from "./GroupMapLIstItem";
import GroupSharedUserList from "./GroupSharedUserList";
import MySecondGroupGroup from "./MySecondGroupGroup";
import GroupGroupList from "./GroupGroupList";
import ReactPortal from "../../../components/Portal/ReactPortal";
import DeleteMultipleMaps from "../../../components/Modals/DeleteMultipleMaps";
import { useDeleteMultipleGroupsMutation } from "../../../services/baseApi";
import { errorMessage, successMessage } from "../../../utils/messages";
import ProgressBarMap from "../../../components/ColorPicker/ProgressBarMap";
import MapCropperModal from "../../../components/Modals/ImageCropper/MapCropperModal";

const GroupMapList = (props) => {
    const { showGroupState, setShowGroupState, handleEdit } = props;
    const [groupSharedData, setGroupSharedData] = useState(props.groupData || []);
    const [groupSecondSharedData, setGroupSecondSharedData] = useState(props.secondGroupData || []);
    const { setConfirmDeleteMapOpen, setMapTypeState } = props;

    const mapDashboardId = useSelector((state) => state.mapDashboardState.id);

    const handleGroupSharedData = (id) => {
        setGroupSharedData(props.groupData?.find?.((group) => group._id === id) || []);
    };
    const handleGroupSecondSharedData = (id) => {
        setGroupSecondSharedData(props.secondGroupData?.find?.((group) => group._id === id) || []);
    };

    const [loadingDeleteMap, setLoadingDeleteMap] = useState(false);
    const [confirmMultipleDeleteMapOpen, setConfirmMultipleDeleteMapOpen] = useState(false);

    const handleHideMyGroupGroups = () => {
        setShowGroupState((prevState) => {
            return {
                ...prevState,
                firstGroups: false,
            };
        });
    };
    const handleHideAllGroups = () => {
        setShowGroupState((prevState) => {
            return {
                ...prevState,
                firstGroups: false,
                secondGroups: false,
            };
        });
    };
    const handleHideSecondGroupList = () => {
        setShowGroupState((prevState) => {
            return {
                ...prevState,
                secondGroups: false,
            };
        });
    };

    const handleShowMyGroupGroups = () => {
        setShowGroupState((prevState) => {
            return {
                ...prevState,
                secondGroups: true,
            };
        });
    };
    const handleShowMyGroupsShared = () => {
        setShowGroupState((prevState) => {
            return {
                ...prevState,
                showShared: true,
                showGroupShared: false,
                showSecondGroupData: false,
            };
        });
    };
    const handleShowMySecondGroupsShared = () => {
        setShowGroupState((prevState) => {
            return {
                ...prevState,
                showShared: false,
                showGroupShared: true,
                showSecondGroupData: true,
            };
        });
    };

    const [deleteMultipleGroups] = useDeleteMultipleGroupsMutation();

    const handleDeleteMaps = async () => {
        setLoadingDeleteMap(false);
        const groups_del_arr = showGroupState.checkListArr.map((obj) => obj.id);
        const { data, error } = await deleteMultipleGroups(groups_del_arr);

        if (data) {
            successMessage("Successfully removed");
        }

        if (error) {
            errorMessage(error?.data?.message);
        }
        setLoadingDeleteMap(false);
        setConfirmMultipleDeleteMapOpen(false);
        setShowGroupState((prevState) => {
            return {
                ...prevState,
                checkListArr: [],
            };
        });
    };
    //////////
    const handleToggleMainMapDelete = (_id, mapId, parentGroup, deleteType, isGroupMap = true) => {
        setMapTypeState((prevState) => {
            return {
                ...prevState,
                id: _id,
                parentId: mapId,
                parentGroup: parentGroup,
                currentMap: mapDashboardId,
                deleteType: deleteType,
                mapGroup: isGroupMap,
            };
        });
        setConfirmDeleteMapOpen(true);
    };

    return (
        <section className="d-flex">
            <section
                className={`myMapList-container ${
                    props.collapseBar.guageBar ? "collapsebar-active" : ""
                }`}
            >
                <div className="mapList-btns-header">
                    <div className="listCurve-wrapper">
                        <ProgressBarMap percentageValue={props.percentageValue} />
                    </div>
                </div>

                {/* SEARCH BAR END */}
                <ul className="myMapList-data-container">
                    {props.groupsMapState.length ? (
                        props.groupsMapState
                            .filter((item) => {
                                return item.title
                                    ?.toLowerCase()
                                    .includes(showGroupState?.searchItem.trim().toLowerCase());
                            })
                            .map((data, index) => {
                                return (
                                    <GroupMapListItem
                                        totalLength={props.groupsMapState.length}
                                        keyLength={index}
                                        data={data}
                                        handleEditGroup={props.handleEditGroup}
                                        setGroupState={setShowGroupState}
                                        handleToggleMapDelete={handleToggleMainMapDelete}
                                        handleShare={props.handleShare}
                                        key={data._id}
                                        myMapFavHandle={props.myMapFavHandle}
                                        setModalMapTitle={props.setModalMapTitle}
                                        getMyGroupGroups={props.getMyGroupGroups}
                                        showGroupState={showGroupState}
                                        setShowGroupState={setShowGroupState}
                                        pinsLoading={props.pinsLoading}
                                        hideShare={props?.hideShare}
                                        handleEdit={handleEdit}
                                    />
                                );
                            })
                    ) : (
                        <p className="null-data-response">No data to show</p>
                    )}
                </ul>
            </section>

            <section className="myMap-delete-modal--container">
                {!!confirmMultipleDeleteMapOpen && (
                    <ReactPortal wrapperId="external_modal_container">
                        <DeleteMultipleMaps
                            loadingDeleteMap={loadingDeleteMap}
                            handleDeleteMaps={handleDeleteMaps}
                            mapsList={showGroupState.checkListArr}
                            setConfirmMultipleDeleteMapOpen={setConfirmMultipleDeleteMapOpen}
                        />
                    </ReactPortal>
                )}
            </section>

            {showGroupState.firstGroups && (
                <GroupGroupList
                    groupsListLoading={props.groupsListLoading}
                    groupData={props.groupData}
                    myMapFavHandle={props.myMapFavHandle}
                    handleShare={props.handleShare}
                    handleEditGroup={props.handleEditGroup}
                    setModalMapTitle={props.setModalMapTitle}
                    handleToggleMapDelete={props?.handleToggleMapDelete}
                    handleHideMyGroupGroups={handleHideMyGroupGroups}
                    handleShowMyGroupGroups={handleShowMyGroupGroups}
                    handleHideAllGroups={handleHideAllGroups}
                    getMyGroupofOtherGroups={props.getMyGroupofOtherGroups}
                    getMyGroupGroups={props.getMyGroupGroups}
                    showGroupState={showGroupState}
                    setShowGroupState={setShowGroupState}
                    handleGroupSharedData={handleGroupSharedData}
                    handleShowMyGroupsShared={handleShowMyGroupsShared}
                    hideShare={props?.hideShare}
                    handleEdit={handleEdit}
                />
            )}
            {showGroupState.secondGroups && (
                <MySecondGroupGroup
                    groupsListLoading={props.groupsListLoading}
                    groupData={props.secondGroupData}
                    myMapFavHandle={props.myMapFavHandle}
                    handleShare={props.handleShare}
                    handleEditGroup={props.handleEditGroup}
                    handleToggleMapDelete={props?.handleToggleMapDelete}
                    setModalMapTitle={props.setModalMapTitle}
                    handleHideAllGroups={handleHideAllGroups}
                    secondPaneStyle={true}
                    showGroupState={showGroupState}
                    setShowGroupState={setShowGroupState}
                    getMyGroupofOtherGroups={props.getMyGroupofOtherGroups}
                    handleGroupSharedData={handleGroupSharedData}
                    handleGroupSecondSharedData={handleGroupSecondSharedData}
                    handleShowMySecondGroupsShared={handleShowMySecondGroupsShared}
                    handleHideSecondGroupList={handleHideSecondGroupList}
                    secondSharedUserList={true}
                    handleEdit={handleEdit}
                />
            )}

            {mapDashboardId && showGroupState.showShared && (
                <GroupSharedUserList
                    data={props.groupsMapState.find((item) => item._id === mapDashboardId)}
                    handleToggleSharedUserDelete={props.handleToggleSharedUserDelete}
                    showGroupState={showGroupState}
                    setShowGroupState={setShowGroupState}
                />
            )}

            {showGroupState.showGroupShared && showGroupState.showShared && (
                <GroupSharedUserList
                    data={groupSharedData}
                    handleToggleSharedUserDelete={props.handleToggleSharedUserDelete}
                    showGroupState={showGroupState}
                    setShowGroupState={setShowGroupState}
                />
            )}

            {showGroupState.showGroupShared && showGroupState.showSecondGroupData && (
                <GroupSharedUserList
                    data={groupSecondSharedData}
                    handleToggleSharedUserDelete={props.handleToggleSharedUserDelete}
                    showGroupState={showGroupState}
                    setShowGroupState={setShowGroupState}
                />
            )}
            {showGroupState.openMapCropperModal && (
                <ReactPortal wrapperId="external_modal_container">
                    <MapCropperModal
                        setParentState={setShowGroupState}
                        parentState={showGroupState}
                        genre="group"
                    />
                </ReactPortal>
            )}
        </section>
    );
};

export default GroupMapList;
