import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import EditInfoMarker from "./EditInfoMarker";
import MovePin from "./MovePin";
import closeIconGrey from "../../assets/images/svg/closeIconGrey.svg";
import dummyUser from "../../assets/images/avatar-grey.png";
import phoneIcon from "../../assets/images/svg/phoneIcon.svg";
import emailIcon from "../../assets/images/svg/emailIcon.svg";
import chatIcon from "../../assets/images/svg/chatIcon.svg";
import ReactPortal from "../Portal/ReactPortal";
import { capitalizeString } from "../../utils/utils";
import DeletePin from "../Modals/DeletePin";
import {
    setPinDetailBox,
    setPinMapStateInfo,
    setMapMarkerInfo,
} from "../../store/slicers/pinDetailPayloadSlice";
import TrashIcon from "../icons/TrashIcon";
import LinkIcon from "../icons/LinkIcon";
import { InfoIcon } from "../icons/InfoIcon";
import { MoveMrakerIcon } from "../icons/MoveMrakerIcon";
import ShareETA from "../icons/ShareETA";
import ShareETAModal from "../Modals/ShareETAModal";
import { useLocation } from "react-router-dom";

function MarkerInfo({
    info,
    publicViewMoveMarker,
    extraClass,
    setMapState,
    hidePinBar,
    mapState,
    getSingleMapData,
    sharedMapState,
    handleMarkerClick,
    showUserBox,
    totalUserGroups,
    UserPlanData,
    handleCloseMarkerModel,
    editMapId,
}) {
    const { mapType } = useSelector((state) => state.mapTypeState);
    const { id: mapDashboardId } = useSelector((state) => state.mapDashboardState);
    const location = useLocation();
    const [isCreateMap, setIsCreateMap] = useState(false);
    const dispatch = useDispatch();
    const [markerInfoState, setMarkerInfoState] = useState({
        showMoveModal: false,
        deletePinModal: false,
        pinMoved: false,
        showEditMarkerInfoModal: false,
        showShareETAModal: false,
        shareETAEmail: "",
        isShareETALoading: false,
        toolTip: false,
    });

    useEffect(() => {
        info?.history?.at(-1)?.status === 1
            ? setMarkerInfoState((prevState) => {
                  return {
                      ...prevState,
                      pinMoved: true,
                  };
              })
            : setMarkerInfoState((prevState) => {
                  return {
                      ...prevState,
                      pinMoved: false,
                  };
              });
    }, []);

    const showToolTip = () => {
        setMarkerInfoState((prevState) => {
            return {
                ...prevState,
                toolTip: true,
            };
        });
    };
    const hideToolTip = () => {
        setMarkerInfoState((prevState) => {
            return {
                ...prevState,
                toolTip: false,
            };
        });
    };

    let [pageIndex, setPageIndex] = useState(1);
    const totalItems = mapState.duplicateMarkers?.length;
    const handleNextPage = () => {
        setMapState((prev) => {
            return {
                ...prev,
                markerInfo: prev.duplicateMarkers?.[pageIndex],
            };
        });
        setPageIndex((prev) => prev + 1);
    };
    const handlePreviousPage = () => {
        setPageIndex((prev) => prev - 1);
        setMapState((prev) => {
            return {
                ...prev,
                markerInfo: prev.duplicateMarkers?.[pageIndex - 2],
            };
        });
    };

    const handleTrash = () => {
        setMarkerInfoState((prevState) => {
            return {
                ...prevState,
                deletePinModal: !prevState.deletePinModal,
            };
        });
    };

    const handleMarkerInfo = () => {
        dispatch(setPinDetailBox(true));
        dispatch(setMapMarkerInfo(info));
        dispatch(
            setPinMapStateInfo({
                ...mapState.mapData,
                ...mapState.data,
            })
        );
    };

    const handleMarkerMove = () => {
        setMarkerInfoState((prevState) => {
            return {
                ...prevState,
                showMoveModal: !prevState.showMoveModal,
            };
        });
    };
    const handleMarkerShareETA = () => {
        setMarkerInfoState((prevState) => {
            return {
                ...prevState,
                showShareETAModal: !prevState.showShareETAModal,
            };
        });
    };

    useEffect(() => {
        if (location.pathname.includes("create-map")) {
            setIsCreateMap(true);
        } else {
            setIsCreateMap(false);
        }
    }, [location]);

    return (
        <>
            {" "}
            {!showUserBox ? (
                <section className={`mapPinDetail--container ${extraClass || ""}`}>
                    <div className="header">
                        <h4> {info?.name}</h4>
                        <img
                            src={closeIconGrey}
                            alt="close icon"
                            className="markerInfo-modal-close"
                            onClick={handleCloseMarkerModel}
                        />
                    </div>
                    <div className="mapPinInfo">
                        {Object.keys(info).map((item) => {
                            return (
                                item === item.toUpperCase() && (
                                    <p key={item}>
                                        {capitalizeString(item)}:{" "}
                                        <span>{capitalizeString(info[item])}</span>
                                    </p>
                                )
                            );
                        })}
                    </div>

                    {!hidePinBar && (
                        <div className="mapPinClicks">
                            {(mapType === 1 || mapType === 4) && (
                                <button
                                    disabled={isCreateMap && true}
                                    className="trash-hide-share"
                                    onClick={handleTrash}
                                >
                                    <TrashIcon />
                                </button>
                            )}

                            <button>
                                <a
                                    href={info?.url ? info?.url : "java:void(0)"}
                                    rel="noreferrer"
                                    className={info?.url ? "" : "movePin-disabled dim"}
                                    target="_blank"
                                >
                                    <LinkIcon />
                                </a>
                            </button>

                            <button disabled={isCreateMap && true} onClick={handleMarkerInfo}>
                                <InfoIcon />
                            </button>

                            {(mapType === 1 || mapType === 4) &&
                                (publicViewMoveMarker === "hide" || !markerInfoState.pinMoved) && (
                                    <button
                                        disabled={isCreateMap && true}
                                        onClick={handleMarkerMove}
                                    >
                                        <MoveMrakerIcon />
                                    </button>
                                )}
                            {(publicViewMoveMarker === "show" || markerInfoState.pinMoved) &&
                                (mapType === 1 || mapType === 4) && (
                                    <button
                                        className={
                                            markerInfoState.pinMoved ? "movePin-disabled" : ""
                                        }
                                    >
                                        <MoveMrakerIcon />
                                    </button>
                                )}
                            {(mapType === 2 || mapType === 3) && (
                                <>
                                    <button
                                        disabled={isCreateMap && true}
                                        onMouseEnter={showToolTip}
                                        onMouseLeave={hideToolTip}
                                        className={markerInfoState.pinMoved ? " dim" : ""}
                                        style={{ opacity: `${markerInfoState.pinMoved ? 0.5 : 1}` }}
                                    >
                                        <MoveMrakerIcon />
                                    </button>
                                </>
                            )}
                            {/* {mapType === 3 && (
                                <button
                                    className="share-eta-btn"
                                    disabled={isCreateMap && true}
                                    onClick={handleMarkerShareETA}
                                >
                                    <ShareETA />
                                </button>
                            )} */}

                            {mapState?.duplicateMarkers?.length > 1 && (
                                <>
                                    <div className="markerInfo-pagination">
                                        <button
                                            className="btn"
                                            onClick={handlePreviousPage}
                                            disabled={pageIndex - 1 < 1}
                                        >
                                            <i className="fa fa-chevron-left"></i>
                                        </button>

                                        <div className="pagination-indexes">
                                            <p>
                                                {pageIndex} - {totalItems}
                                            </p>
                                        </div>

                                        <button
                                            className="btn"
                                            onClick={handleNextPage}
                                            disabled={pageIndex + 1 > totalItems}
                                        >
                                            <i className="fa fa-chevron-right"></i>
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                    {markerInfoState.toolTip && (
                        <p className="markerInfo-noUse">
                            Move tool only works on <b>My Maps</b> and <b>My Groups</b>.
                        </p>
                    )}
                    {markerInfoState.showMoveModal && (mapType === 1 || mapType === 4) && (
                        <MovePin
                            setMarkerInfoState={setMarkerInfoState}
                            pinInfo={info}
                            setMapState={setMapState}
                            myMapState={mapState}
                            getSingleMapData={getSingleMapData}
                            totalUserGroups={totalUserGroups}
                            UserPlanData={UserPlanData}
                        />
                    )}
                </section>
            ) : (
                <div className="sharedMap-user--modal">
                    <img
                        src={closeIconGrey}
                        className="close-modal"
                        alt="close icon"
                        onClick={() => {
                            setMapState((prevState) => ({
                                ...prevState,
                                openMarkerInfo: false,
                            }));
                        }}
                    />
                    <div className="user-img">
                        <img
                            src={info?.user?.avatar || dummyUser}
                            className="sharedMap-user-img"
                            alt="map-list"
                        />
                    </div>
                    <h6 className="user-name">
                        {info?.user?.first_name} {info?.user?.last_name}
                    </h6>
                    <div className="sharedMap-user--details">
                        <div className="info-icons">
                            <a
                                title="Message"
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                                href={`tel:${info?.user?.sender_id}`}
                            >
                                <img src={chatIcon} alt="chat icon" />
                                <span>Message</span>
                            </a>
                            <a
                                title="Phone"
                                href={`tel:${info?.user?.countryCode}${info?.user?.stateCode}${info?.user?.mobile}`}
                            >
                                <img src={phoneIcon} alt="phone icon" />
                                <span>Call</span>
                            </a>
                            <a href={`mailto:${info?.user?.email}`} title="Email">
                                <img src={emailIcon} alt="email icon" />
                                <span>Email</span>
                            </a>
                        </div>
                    </div>
                </div>
            )}
            {markerInfoState.deletePinModal && (
                <ReactPortal wrapperId="external_modal_container">
                    <DeletePin
                        setMarkerInfoState={setMarkerInfoState}
                        pinInfo={info}
                        mapData={mapState.mapData}
                        getSingleMapData={getSingleMapData}
                    />
                </ReactPortal>
            )}
            {markerInfoState.showShareETAModal && (
                <ReactPortal wrapperId="external_modal_container">
                    <ShareETAModal
                        setMarkerInfoState={setMarkerInfoState}
                        pinInfo={info}
                        mapData={mapState.mapData}
                        getSingleMapData={getSingleMapData}
                        routeId={mapDashboardId}
                        markerInfoState={markerInfoState}
                    />
                </ReactPortal>
            )}
            {markerInfoState.showEditMarkerInfoModal && (
                <EditInfoMarker
                    markerInfoState={markerInfoState}
                    setMarkerInfoState={setMarkerInfoState}
                    info={info}
                    mapState={mapState}
                    setMapState={setMapState}
                    sharedMapState={sharedMapState}
                />
            )}
        </>
    );
}

export default MarkerInfo;
