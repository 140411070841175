export default function PrintIcon({ color = "inherit", fill = "inherit", width = "20px" }) {
    return (
        <svg
            id="Print"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <g id="Boundary" stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0">
                <rect width="24" height="24" stroke="none" />
                <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
            </g>
            <path
                id="Path_printer"
                data-name="Path / printer"
                d="M14.8,3H5.2V6.2h9.6m.8,4a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8m-2.4,5.6H6.8v-4h6.4M15.6,7H4.4A2.4,2.4,0,0,0,2,9.4v4.8H5.2v3.2h9.6V14.2H18V9.4A2.4,2.4,0,0,0,15.6,7Z"
                transform="translate(2 2)"
                fill="#999b9e"
            />
        </svg>
    );
}
