import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLazyGetRouteByIdQuery } from "../../services/baseApi";
import Map from "../../components/Map/Map";
import { setMapBounds } from "../../utils/mapUtils";
import MapMarkers from "../../components/Map/MapMarkers";
import { rawData } from "../../utils/constants";
import { Circle, OverlayView } from "@react-google-maps/api";
import { SinglePinHistory } from "../../components/Map/DrawingManagerBtns";
import { GetDataFromLocalStorage, makeDeepCopy, SetDataToLocalStorage } from "../../utils/utils";
import { Marker } from "react-google-maps";
import userIcon from "../../assets/images/avatar-grey.png";
import { useSelector } from "react-redux";
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation";

const ShareLocationView = () => {
    const authUser = useSelector((state) => state.auth.user);
    let { id, pinId, email } = useParams();
    const [locParams, setLocParams] = useState({});
    const navigate = useNavigate();
    console.log("authUser", authUser);
    const location = useLocation();
    // const { mapId, mapPinId } = location?.state;
    console.log("Locationss state Details", locParams);
    const zoomRef = useRef({
        maxZoomOut: 2,
        defaultZoom: 3,
        maxZoomIn: 24,
    });

    const [mapState, setMapState] = useState(() => ({
        center: { lat: 40.756795, lng: -73.954298 },
        authURl: false,
        data: {},
        mapData: {},
        errorMessage: "",
        markerInfo: "",
        map: null,
        maps: null,
        openMarkerInfo: false,
        selectedPins: [],
        alreadySelectedPins: [],
        triangulation: false,
        rowsToShow: null,
        searchText: "",
        sortBy: "",
        shapeArea: null,
        shapeType: "",
        moveMarker: "show",
        showBox: "block",
        publicViewPassword: "",
        passwordVerified: false,
        userLocation: {
            lat: 40.73061,
            lng: -73.935242,
        },
        fencingOn: false,
    }));
    function SetGeoLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                console.log("User live Location", { latitude, longitude });
                setMapState((prevState) => {
                    return {
                        ...prevState,
                        userLocation: {
                            lat: latitude,
                            lng: longitude,
                        },
                    };
                });
            });
            return true;
        } else {
            return false;
            console.log("Geolocation is not supported by this browser.");
        }
    }
    useEffect(() => {
        SetGeoLocation();
    }, []);

    // useEffect(() => {
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition((position) => {
    //             const { latitude, longitude } = position.coords;
    //             console.log("User live Location", { latitude, longitude });
    //             setMapState((prevState) => {
    //                 return {
    //                     ...prevState,
    //                     userLocation: {
    //                         lat: latitude,
    //                         lng: longitude,
    //                     },
    //                 };
    //             });
    //         });
    //     } else {
    //         console.log("Geolocation is not supported by this browser.");
    //     }
    // }, []);

    useEffect(() => {
        if (id || pinId || email) {
            SetDataToLocalStorage(id, pinId, email);
            setLocParams({ id, pinId, email });

            navigate("/sharelocation-view");
        } else {
            let ETAData = GetDataFromLocalStorage();
            setLocParams(ETAData);
        }
    }, [id, pinId, email, navigate]);

    const [getRouteById] = useLazyGetRouteByIdQuery();
    async function getSingleRoute() {
        if (locParams?.id) {
            let { data } = await getRouteById(locParams?.id);
            if (data) {
                let isItemExist = data?.data?.rawData?.find(
                    (item) => item?.id === locParams?.pinId
                );
                if (isItemExist) {
                    setMapState((prevState) => {
                        return {
                            ...prevState,
                            authURl: true,
                            data: { ...data.data, rawData: [isItemExist] },
                            markerInfo: "",
                            openMarkerInfo: false,
                            mapData: data.mapData,
                        };
                    });
                }
            }
        }
    }

    useEffect(() => {
        if (locParams?.id) {
            getSingleRoute();
        }
    }, [locParams?.id]);

    const markers = useMemo(() => {
        return (
            mapState.data?.rawData?.filter(
                (data) =>
                    data?.name?.toLowerCase().includes(mapState.searchText) ||
                    data?.phone?.toLowerCase().includes(mapState.searchText) ||
                    data?.group?.toLowerCase().includes(mapState.searchText) ||
                    data?.address?.toLowerCase().includes(mapState.searchText) ||
                    data?.zip?.toLowerCase().includes(mapState.searchText) ||
                    data?.state?.toLowerCase().includes(mapState.searchText) ||
                    data?.city?.toLowerCase().includes(mapState.searchText)
            ) || []
        );
    }, [mapState.searchText, mapState.data?.rawData?.length]);

    function setMapZoom(map, ref) {
        ref.current.defaultZoom = map.zoom;
    }

    const onMapLoad = ({ map, maps }) => {
        map.addListener("zoom_changed", () => setMapZoom(map, zoomRef));
        setMapState((prevState) => {
            return {
                ...prevState,
                map,
                maps,
            };
        });

        let updateRawData = makeDeepCopy(mapState.data.rawData);

        updateRawData = [
            ...updateRawData,
            {
                location: {
                    lat: Number(mapState?.userLocation?.lat),
                    lng: Number(mapState?.userLocation?.lng),
                },
                // location: centerCoordinates,
            },
        ];

        setTimeout(() => {
            setMapBounds(map, updateRawData, false, window.google?.maps.LatLngBounds);
        }, 0);

        return () => {
            map.removeListener("zoom_changed", () => setMapZoom(map, zoomRef));
        };
    };
    const handleMarkerClick = (e, place) => {
        console.log("marker Click");
    };

    const checkLocationAndToggleCircle = (lat, lng) => {
        if (!window.google.maps) {
            console.error("Google Maps API is not loaded.");
            return;
        }

        const point = new window.google.maps.LatLng(lat, lng);
        const circleCenter = new window.google.maps.LatLng(
            mapState?.userLocation?.lat,
            mapState?.userLocation?.lng
        );

        const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
            point,
            circleCenter
        );

        if (distance <= 305) {
            setMapState((prev) => {
                return {
                    ...prev,

                    fencingOn: false,
                };
            });
        } else {
            setMapState((prev) => {
                return {
                    ...prev,
                    fencingOn: true,
                };
            });
        }
    };

    useEffect(() => {
        if (window.google.maps && mapState?.data?.rawData?.[0]) {
            checkLocationAndToggleCircle(
                mapState?.data?.rawData?.[0]?.location?.lat,
                mapState?.data?.rawData?.[0]?.location?.lng
            );
        }
    }, [mapState?.data?.rawData, window.google.maps]);
    const reloadPage = () => {
        window.location.reload();
    };

    if (authUser?.email !== locParams?.email) {
        return (
            <div className="unlisted-publicview-wrapper">
                <h6>Map preview does not exist</h6>
            </div>
        );
    }

    return !mapState?.authURl ? (
        <LoadingAnimation />
    ) : (
        <section className="shareLocationView-bg-map">
            <div className="page_refresh_btn">
                <button onClick={reloadPage}>Refresh Page</button>
            </div>
            <Map
                componentProps={{
                    mapStyle: mapState.data.mapStyle,
                    center: mapState.center,
                    containerStyle: {
                        width: "100%",
                        maxHeight: "100%",
                        height: "100%",
                    },
                    maxZoomOut: zoomRef.current.maxZoomOut,
                    defaultZoom: zoomRef.current.defaultZoom,
                    maxZoomIn: zoomRef.current.maxZoomIn,
                    onMapLoad,
                }}
            >
                {/* MARKERS */}
                {!!mapState.data?.rawData?.length && (
                    <MapMarkers
                        // showNumbers={type === "route"}
                        data={markers}
                        // hiddenMarkers={hiddenMarkers}
                        handleMarkerClick={handleMarkerClick}
                        map={mapState.map}
                        setMapState={setMapState}
                        markerStyle={mapState.data?.markerStyle}
                        // pinsFilterState={pinsFilterState}
                    />
                )}
                {mapState?.fencingOn && (
                    <Circle
                        center={mapState?.userLocation}
                        radius={305}
                        options={{
                            fillColor: "#98102980",
                            fillOpacity: 1,
                            strokeColor: "#98102980",
                            strokeOpacity: 1,
                            strokeWeight: 2,
                        }}
                    />
                )}
                {!mapState?.fencingOn && (
                    <OverlayView
                        position={mapState?.userLocation}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                        <div
                            className="userProfile"
                            style={{
                                width: "4.5rem",
                                height: "4.5rem",
                                borderRadius: "50%",
                                border: "10px solid rgba(134, 171, 227, 0.6)",
                                overflow: "hidden",
                            }}
                        >
                            <img
                                src={authUser?.avatar || userIcon}
                                alt="share-eta-user-image"
                                width={"100%"}
                                height={"100%"}
                                style={{ objectFit: "cover" }}
                            />
                        </div>
                    </OverlayView>
                )}
            </Map>
        </section>
    );
};

export default ShareLocationView;
