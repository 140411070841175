import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    openPinDetailBox: false,
    // pinID: null,
    // pinMapType: "",
    pinMapStateInfo: {},
    mapMarkerInfo: {},
};

export const pinDetailPayloadSlice = createSlice({
    name: "pinDetailsPayload",
    initialState,
    reducers: {
        setPinDetailBox: (state, action) => {
            state.openPinDetailBox = action.payload;
        },
        // setPinID: (state, action) => {
        //     state.pinID = action.payload;
        // },
        // setPinMapType: (state, action) => {
        //     state.pinMapType = action.payload;
        // },
        setPinMapStateInfo: (state, action) => {
            state.pinMapStateInfo = action.payload;
        },
        setMapMarkerInfo: (state, action) => {
            state.mapMarkerInfo = action.payload;
        },
    },
});

export const { setPinDetailBox, setMapMarkerInfo, setPinMapStateInfo } =
    pinDetailPayloadSlice.actions;

export default pinDetailPayloadSlice.reducer;
