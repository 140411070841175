import React from "react";
import imageBG from "../../assets/images/Auth/login-hover.png";
import closeIcon from "../../assets/images/closeIcon.png";
import "react-toastify/dist/ReactToastify.css";
import DotLoader from "../../components/DotLoaderBtn/DotLoader";

const ForgetPasswordModel = (props) => {
    const { setState, state, handleSubmitForget, loading } = props;

    return (
        <>
            <section className="login-forget--container">
                <div className="login-forget--wrapper">
                    <div className="content-wrapper">
                        <form onSubmit={handleSubmitForget}>
                            <h2>Recover password</h2>
                            <p>Don't worry, happens to the best of us!</p>
                            <div className="forget-input-container">
                                <label>Email</label>
                                <input
                                    type="email"
                                    aria-label="email adress"
                                    placeholder="Type your email"
                                    readOnly
                                    defaultValue={state?.inviteEmail}
                                    // onChange={(e) =>
                                    //     setState((prevState) => {
                                    //         return {
                                    //             ...prevState,
                                    //             forgetEmail: e.target.value,
                                    //         };
                                    //     })
                                    // }
                                    required
                                />
                            </div>

                            {loading ? (
                                <button
                                    className="btn animate-dots-blue login-load-outline-btn mt-20"
                                    type="button"
                                    disabled
                                >
                                    <DotLoader />
                                </button>
                            ) : (
                                <button
                                    type="submit"
                                    className="forget-recover-cta mt-20"
                                    // disabled={!state.forgetEmail.trim()}
                                >
                                    Email recovery link
                                </button>
                            )}
                        </form>
                    </div>
                    <div className="img-wrapper">
                        <button
                            className="close-button"
                            onClick={(e) => {
                                e.preventDefault();
                                setState((prevState) => {
                                    return {
                                        ...prevState,
                                        openForgetPassword: false,
                                    };
                                });
                            }}
                        >
                            <img src={closeIcon} alt="close icon" />
                        </button>
                        <img src={imageBG} className="bg-image" alt="thankyou registered" />
                    </div>
                </div>
            </section>
        </>
    );
};

export default ForgetPasswordModel;
