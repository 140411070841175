import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Login from "./Login";

import { errorMessage, successMessage } from "../../utils/messages";
import { useResetPasswordMutation } from "../../services/baseApi";
import ResetPasswordUI from "./ResetPasswordUI";

const ResetpasswordModal = (props) => {
    const [changePasswordState, setChangePasswordState] = useState({
        password: "",
        confirmPassword: "",
        showPassword: false,
        showPasswordModal: true,
        passwordMatch: true,
    });

    const [resetPassword, { isLoading: resetPasswordLoading }] = useResetPasswordMutation();

    const location = useLocation();
    const livePath = location?.search;
    const my_token = livePath?.split("=")[1]?.slice(0, -3);
    const my_id = livePath?.split("=")[2];

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { data, error } = await resetPassword({
            userId: my_id,
            token: my_token,
            password: changePasswordState?.password,
        });

        if (data) {
            successMessage(data.message);
            setChangePasswordState((prevState) => {
                return {
                    ...prevState,
                    showPasswordModal: false,
                };
            });
        }
        if (error) {
            errorMessage(error.data?.message);
        }
    };

    return (
        <>
            <Login />
            {changePasswordState.showPasswordModal && (
                <ResetPasswordUI
                    handleSubmit={handleSubmit}
                    passwordState={changePasswordState}
                    setPasswordState={setChangePasswordState}
                    loading={resetPasswordLoading}
                />
            )}
        </>
    );
};

export default ResetpasswordModal;
