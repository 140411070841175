import React from "react";

const GroupHistoryDetailsModal = (props) => {
    const { singleGroupData } = props;

    return (
        <div className="group-history-deatil-container">
            <div className="group-history-deatil-wrapper">
                <div className="history-modal-title">
                    <h5>{singleGroupData?.title}</h5>
                </div>
                <p>{singleGroupData?.markers} markers</p>
            </div>
        </div>
    );
};

export default GroupHistoryDetailsModal;
