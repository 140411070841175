import React, { useState } from "react";
import { useSelector } from "react-redux";
import RouteMapListItem from "./RouteMapListItem";
import MyRouteSharedList from "./MyRouteSharedList";
import ReactPortal from "../../../components/Portal/ReactPortal";
import DeleteMultipleMaps from "../../../components/Modals/DeleteMultipleMaps";
import { errorMessage, successMessage } from "../../../utils/messages";
import { useDeleteMultipleRoutesMutation } from "../../../services/baseApi";
import ProgressBarMap from "../../../components/ColorPicker/ProgressBarMap";
import MapCropperModal from "../../../components/Modals/ImageCropper/MapCropperModal";

const RouteMapList = (props) => {
    const {
        routeMapState,
        setRouteMapState,
        handleToggleMapDelete,
        setModalMapTitle,
        handleShare,
        myMapFavHandle,
        handleToggleSharedUserDelete,
    } = props;

    const mapDashboardId = useSelector((state) => state.mapDashboardState.id);

    const [routeState, setRouteState] = useState({
        searchItem: "",
        showShared: false,
        showOptions: false,
        optionID: null,
        sharedShowOptions: false,
        sharedOptionID: null,
        showCheckList: false,
        checkListArr: [],
        openMapCropperModal: false,
        clickedItemID: null,
        clickedItemAvatar: null,
    });
    const [loadingDeleteMap, setLoadingDeleteMap] = useState(false);
    const [confirmMultipleDeleteMapOpen, setConfirmMultipleDeleteMapOpen] = useState(false);

    const handleShowSharedList = () => {
        setRouteState((prevState) => {
            return {
                ...prevState,
                showShared: true,
            };
        });
    };
    const handleCloseSharedList = () => {
        setRouteState((prevState) => {
            return {
                ...prevState,
                showShared: false,
            };
        });
    };

    const [deleteMultipleRoutes] = useDeleteMultipleRoutesMutation();
    const handleDeleteMaps = async () => {
        setLoadingDeleteMap(false);
        const routes_del_arr = routeState.checkListArr.map((obj) => obj.id);

        const { data, error } = await deleteMultipleRoutes(routes_del_arr);

        if (data) {
            successMessage("Successfully removed");
        }

        if (error) {
            errorMessage(error?.data?.message);
        }
        setLoadingDeleteMap(false);
        setConfirmMultipleDeleteMapOpen(false);
        setRouteState((prevState) => {
            return {
                ...prevState,
                checkListArr: [],
            };
        });
    };

    return (
        <section className="d-flex">
            <section
                className={`myMapList-container ${
                    props.collapseBar.guageBar ? "collapsebar-active" : ""
                }`}
            >
                <div className="mapList-btns-header">
                    <div className="listCurve-wrapper">
                        <ProgressBarMap percentageValue={props.percentageValue} />
                    </div>
                </div>
                {/* SEARCH BAR END */}
                <ul className="myMapList-data-container">
                    {routeMapState?.data.length ? (
                        routeMapState?.data
                            .filter((item) => {
                                return item.title
                                    ?.toLowerCase()
                                    ?.includes(routeState?.searchItem.trim().toLowerCase());
                            })
                            .map((data, index) => {
                                return (
                                    <RouteMapListItem
                                        data={data}
                                        id={routeMapState?.id}
                                        setRouteMapState={setRouteMapState}
                                        key={data._id}
                                        handleToggleMapDelete={handleToggleMapDelete}
                                        setModalMapTitle={setModalMapTitle}
                                        handleShare={handleShare}
                                        routeState={routeState}
                                        myMapFavHandle={myMapFavHandle}
                                        setRouteState={setRouteState}
                                        handleShowSharedList={handleShowSharedList}
                                        pinsLoading={props.pinsLoading}
                                    />
                                );
                            })
                    ) : (
                        <p className="null-data-response">No data to show</p>
                    )}
                </ul>
                t
            </section>

            <section className="myMap-delete-modal--container">
                {!!confirmMultipleDeleteMapOpen && (
                    <ReactPortal wrapperId="external_modal_container">
                        <DeleteMultipleMaps
                            loadingDeleteMap={loadingDeleteMap}
                            handleDeleteMaps={handleDeleteMaps}
                            mapsList={routeState.checkListArr}
                            setConfirmMultipleDeleteMapOpen={setConfirmMultipleDeleteMapOpen}
                        />
                    </ReactPortal>
                )}
            </section>

            {routeState.showShared && (
                <MyRouteSharedList
                    data={routeMapState?.data?.find((item) => item._id === mapDashboardId).shared}
                    handleCloseSharedList={handleCloseSharedList}
                    routeState={routeState}
                    setRouteState={setRouteState}
                    handleToggleSharedUserDelete={handleToggleSharedUserDelete}
                    type="route"
                />
            )}

            {routeState.openMapCropperModal && (
                <ReactPortal wrapperId="external_modal_container">
                    <MapCropperModal
                        setParentState={setRouteState}
                        parentState={routeState}
                        genre={"route"}
                    />
                </ReactPortal>
            )}
        </section>
    );
};

export default RouteMapList;
