import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: null,
    plan: "FREE",
    planData: {},
    userImage: null,
    inactiveLogout: false,
    defaultDistanceMeasurement: 1,
    TempPasswordAuth: false,
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setUserMeasurement: (state, action) => {
            state.defaultDistanceMeasurement = action.payload;
        },
        deleteUser: (state) => {
            state.user = null;
        },
        setUserPlan: (state, action) => {
            state.plan = action.payload;
        },
        setUserPlanData: (state, action) => {
            state.planData = action.payload;
        },
        setUserImage: (state, action) => {
            state.userImage = action.payload;
        },
        autoLogout: (state, action) => {
            state.inactiveLogout = action.payload;
        },
        setTempPasswordAuth: (state, action) => {
            state.TempPasswordAuth = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setUser,
    deleteUser,
    setUserPlan,
    setUserPlanData,
    setUserImage,
    autoLogout,
    setUserMeasurement,
    setTempPasswordAuth,
} = authSlice.actions;
export const getDistanceMeasurement = (state) => state?.auth?.defaultDistanceMeasurement;
export const getUserPlanData = (state) => state?.auth?.planData;

export default authSlice.reducer;
