import React, { useRef } from "react";
import arrowLeftSlider from "../../assets/images/arrowLeftSlider.png";
import listStyleDot from "../../assets/images/listStyleDot.png";
import { capitalizeString, getFilteredData } from "../../utils/utils";

const MapsGridTabularData = (props) => {
    const { hiddenMarkers } = props;
    const { data, loading, searchText } = props.mapState;

    const listContainerRef = useRef(null);
    const handleScrollLeft = () => {
        if (listContainerRef.current) {
            const scrollAmount = listContainerRef.current.offsetWidth;
            listContainerRef.current.scrollTo({
                left: listContainerRef.current.scrollLeft - scrollAmount,
                behavior: "smooth",
            });
        }
    };

    const handleScrollRight = () => {
        if (listContainerRef.current) {
            const scrollAmount = listContainerRef.current.offsetWidth;
            listContainerRef.current.scrollTo({
                left: listContainerRef.current.scrollLeft + scrollAmount,
                behavior: "smooth",
            });
        }
    };

    let filteredData = getFilteredData(data?.rawData, hiddenMarkers, searchText);

    return (
        <div className="mapTabular-gridView--container">
            <button className="icon left" onClick={handleScrollLeft}>
                <img src={arrowLeftSlider} alt="Left arrow slider" className="leftArrow" />
            </button>

            <button className="icon right" onClick={handleScrollRight}>
                <img src={arrowLeftSlider} alt="Right arrow slider" className="rightArrow" />
            </button>

            <div className="mapTabular-gridView-details" ref={listContainerRef}>
                {!loading &&
                    filteredData?.map((data, i) => {
                        return (
                            <div className="mapTabularGridView--item" id={data.id} key={data.id}>
                                <div
                                    className="color-block"
                                    style={{
                                        backgroundColor: data.dummyColor || data.color || "red",
                                    }}
                                ></div>
                                <div className="gridViewItem-details">
                                    <h5> {capitalizeString(data?.name)}</h5>
                                    <p>
                                        <img src={listStyleDot} alt="List Style Disc" />
                                        {capitalizeString(data?.address)},
                                        {capitalizeString(data?.zip)}
                                    </p>
                                    <p>
                                        <img src={listStyleDot} alt="List Style Disc" />
                                        {data?.phone}
                                    </p>
                                    <h6>
                                        <img src={listStyleDot} alt="List Style Disc" />
                                        {capitalizeString(data?.email)}
                                    </h6>
                                    {props.mapState.triangulation && (
                                        <p>
                                            <img src={listStyleDot} alt="List Style Disc" />
                                            {data.distanceFromUser} miles
                                        </p>
                                    )}
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default MapsGridTabularData;
