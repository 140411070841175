import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    systemNotificationsData: {
        filteredNotifications: [],
        totalNotificationsCount: null,
        unReadCount: null,
    },
    showSystemNotificationsModel: false,
    systemNotificationsLoading: false,
    systemNotificationsError: null,
};

export const systemNotificationsSlice = createSlice({
    name: "systemNotifications",
    initialState,
    reducers: {
        setAllNotification: (state, action) => {
            state.systemNotificationsData = action.payload;
        },
        setShowSystemNotificationModel: (state, action) => {
            state.showSystemNotificationsModel = action.payload;
        },
        setNotificationsLoading: (state, action) => {
            state.systemNotificationsLoading = action.payload;
        },
        setNotificationsError: (state, action) => {
            state.systemNotificationsError = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setAllNotification,
    setShowSystemNotificationModel,
    setNotificationsError,
    setNotificationsLoading,
} = systemNotificationsSlice.actions;
export const getAllNotifications = (state) => state.systemNotifications.systemNotificationsData;
export const getNotificationsError = (state) => state.systemNotifications.systemNotificationsError;
export const getNotificationsLoading = (state) =>
    state.systemNotifications.systemNotificationsLoading;
export const getUnreadCount = (state) =>
    state.systemNotifications?.systemNotificationsData?.unReadCount;
export const showSystemNotificationsModel = (state) =>
    state.systemNotifications.showSystemNotificationsModel;

export default systemNotificationsSlice.reducer;
