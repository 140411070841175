import React from "react";

export const InfoIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path
                id="info-active"
                d="M11,3a8,8,0,1,0,8,8A8,8,0,0,0,11,3Zm.8,12H10.2V10.2h1.6Zm0-6.4H10.2V7h1.6Z"
                transform="translate(-3 -3)"
                fill="#999b9e"
            />
        </svg>
    );
};
