export default function FileIcon({ color = "inherit", fill = "inherit", width = "20px" }) {
    return (
        <svg
            id="Export"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <g id="Boundary" fill="#eee" stroke="rgba(255,255,255,0.3)" strokeWidth="1" opacity="0">
                <rect width="24" height="24" rx="5" stroke="none" />
                <rect x="0.5" y="0.5" width="23" height="23" rx="4.5" fill="none" />
            </g>
            <path
                id="Path_file"
                data-name="Path / file"
                d="M11.2,7.6V3.2l4.4,4.4M5.6,2A1.594,1.594,0,0,0,4,3.6V16.4A1.6,1.6,0,0,0,5.6,18h9.6a1.6,1.6,0,0,0,1.6-1.6V6.8L12,2Z"
                transform="translate(2 2)"
                fill="#999b9e"
            />
        </svg>
    );
}
