import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    planDetails: [],
};

export const planDetailsSlice = createSlice({
    name: "planDetails",
    initialState,
    reducers: {
        setPlanDetails: (state, action) => {
            state.planDetails = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setPlanDetails } = planDetailsSlice.actions;

export default planDetailsSlice.reducer;
