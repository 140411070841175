/* global google */

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { LoginSocialFacebook } from "reactjs-social-login";

import { axiosInstance } from "../../apiCalls/axios";
import { autoLogout, setUser } from "../../store/slicers/authSlice";
import DotLoader from "../../components/DotLoaderBtn/DotLoader";
import Footer from "../../components/Sidebar/Footer";
import Forgetpassword from "./Forgetpassword";
import ResetPasswordMessage from "./ResetPasswordMessage";
import { errorMessage, successMessage } from "../../utils/messages";

import {
    useGetUserByEmailAndPasswordMutation,
    useRequestResetPasswordMutation,
    useLazyGetUserByGoogleQuery,
    useLazyGetUserByFacebookQuery,
    useResendVerificationMutation,
    useVerifyTwoStepVerificationMutation,
} from "../../services/baseApi";

import eyeIcon from "../../assets/images/svg/eyeIcon.svg";
import eyeIconOff from "../../assets/images/svg/eyeIconOff.svg";

import { APP_IDS } from "../../config";

import jwtDecode from "jwt-decode";
import "react-toastify/dist/ReactToastify.css";
import VerifyEmailLogin from "./VerifyEmailLogin";
import TwoStepVerification from "./TwoStepVerification";
import { FacebookIcon } from "../../components/icons/FacebookIcon";
import { GoogleIcon } from "../../components/icons/GoogleIcon";

const Login = () => {
    const [getUserByEmailAndPassword, { isLoading: loginLoading }] =
        useGetUserByEmailAndPasswordMutation();

    const [requestResetPassword, { isLoading: resetPasswordLoading }] =
        useRequestResetPasswordMutation();

    const [resendVerification] = useResendVerificationMutation();
    const [verifyTwoStepVerification] = useVerifyTwoStepVerificationMutation();

    const [loginState, setLoginState] = useState({
        email: "",
        password: "",
        showPassword: false,
        showForgetModal: false,
        forgetEmail: "",
        showForgetSuccessModal: false,
        showVerifyLoginModal: false,
        loading: false,
        showTwoStepVerification: false,
        twoStepVerifyCode: "",
    });

    const [reqLoading, setReqLoading] = useState({
        fbLoading: false,
        ggLoading: false,
    });

    let navigate = useNavigate();
    const dispatch = useDispatch();

    function setUserData(data, token) {
        axiosInstance.defaults.headers = {
            "x-auth-token": token || "",
        };

        localStorage.setItem("authToken", token);

        dispatch(setUser(data));

        navigate("/dashboard");
    }

    const handleSubmitLogin = async (e) => {
        e.preventDefault();

        const { data, error } = await getUserByEmailAndPassword({
            email: loginState.email,
            password: loginState.password,
            isPlatform: "web",
        });

        if (error) {
            dispatch(setUser(null));

            errorMessage(error.data?.message);
        }
        if (data) {
            if (!data.verified) {
                setLoginState((prevState) => {
                    return {
                        ...prevState,
                        showVerifyLoginModal: true,
                        showForgetModal: false,
                    };
                });
                return;
            }
            if (data.twoStepVerification) {
                setLoginState((prevState) => {
                    return {
                        ...prevState,
                        showTwoStepVerification: true,
                    };
                });
                return;
            }

            setUserData(data, data.token);
            dispatch(autoLogout(false));
        }
    };

    const handleSubmitForget = async (e, showToast) => {
        e.preventDefault();

        const { data, error } = await requestResetPassword({
            email: loginState.forgetEmail,
        });

        if (data?.status === "SUCCESS") {
            setLoginState((prevState) => {
                return {
                    ...prevState,
                    showForgetModal: false,
                    showForgetSuccessModal: true,
                };
            });

            showToast && successMessage("Successfully sent");
        }

        if (error) {
            setLoginState((prevState) => {
                return {
                    ...prevState,
                    showForgetModal: true,
                    showForgetSuccessModal: false,
                };
            });
            errorMessage(error?.data?.message);
        }

        setTimeout(() => {
            setLoginState((prevState) => {
                return {
                    ...prevState,
                    showForgetModal: false,
                    showForgetSuccessModal: false,
                };
            });
        }, 90000);
    };

    const handleSendVerificationAgain = async (e) => {
        setLoginState((prevState) => {
            return {
                ...prevState,
                loading: true,
            };
        });
        e.preventDefault();
        const { data, error } = await resendVerification({ email: loginState.email });

        if (data) {
            successMessage("Successfully sent");
            setLoginState((prevState) => {
                return {
                    ...prevState,
                    showVerifyLoginModal: false,
                };
            });
        }

        if (error) {
            errorMessage(error.data?.message);
        }
        setLoginState((prevState) => {
            return {
                ...prevState,
                loading: false,
            };
        });
    };

    const handleSendTwoStepVerification = async (e) => {
        e.preventDefault();
        setLoginState((prevState) => {
            return {
                ...prevState,
                loading: true,
            };
        });

        const { data, error } = await verifyTwoStepVerification({
            code: loginState.twoStepVerifyCode,
            email: loginState.email,
        });

        if (data) {
            console.log("verify data: ", data);
            setLoginState((prevState) => {
                return {
                    ...prevState,
                    showTwoStepVerification: false,
                };
            });

            setUserData(data.data, data.token);
        }

        if (error) {
            errorMessage(error.data?.message);
        }
        setLoginState((prevState) => {
            return {
                ...prevState,
                loading: false,
            };
        });
    };

    // FACEBOOK AUTHENTICATION
    const [getUserByFacebook] = useLazyGetUserByFacebookQuery();

    const handleFacebookResolve = async ({ provider, data: fbData }) => {
        setReqLoading((prevState) => ({ ...prevState, fbLoading: true }));

        const userData = {
            firstName: fbData.first_name,
            lastName: fbData.last_name,
            email: fbData.email,
            userID: fbData.userID,
            accessToken: fbData.accessToken,
            type: "facebook",
        };

        const { data, err } = await getUserByFacebook(userData);

        if (!data.data && !data.message) {
            setReqLoading((prevState) => ({ ...prevState, fbLoading: false }));
            navigate("/sign-up", { state: userData });
            return;
        }

        if (!data.data && data.message) {
            errorMessage(data.message);
            setReqLoading((prevState) => ({ ...prevState, fbLoading: false }));

            return;
        }

        if (data) {
            setUserData({ ...data.data, token: data.token }, data.token);
            setReqLoading((prevState) => ({ ...prevState, fbLoading: false }));

            return;
        }
        if (err) {
            console.log(err);
            errorMessage(err?.data?.message);
            setReqLoading((prevState) => ({ ...prevState, fbLoading: false }));

            return;
        }
    };

    // GOOGLE AUTHENTICATION
    const [getUserByGoogle] = useLazyGetUserByGoogleQuery();

    const handleGoogleCallback = async (res) => {
        setReqLoading((prevState) => ({ ...prevState, ggLoading: true }));

        //1: GETTING JWT
        const { credential: jwt } = res;

        const googleUserData = jwtDecode(jwt);

        const userData = {
            firstName: googleUserData.given_name,
            lastName: googleUserData.family_name,
            email: googleUserData.email,
            type: "google",
            jwtToken: jwt,
        };

        //2: CHECKING IF USER EXIST
        const { data, err } = await getUserByGoogle({ jwtToken: jwt });

        if (!data.data && !data.message) {
            setReqLoading((prevState) => ({ ...prevState, ggLoading: false }));

            navigate("/sign-up", { state: userData });
            return;
        }

        if (!data.data && data.message) {
            errorMessage(data.message);
            setReqLoading((prevState) => ({ ...prevState, ggLoading: false }));

            return;
        }

        if (data) {
            setUserData({ ...data.data, token: data.token }, data.token);
            setReqLoading((prevState) => ({ ...prevState, ggLoading: false }));

            return;
        }
        if (err) {
            console.log(err);
            errorMessage(err?.data?.message);
            setReqLoading((prevState) => ({ ...prevState, ggLoading: false }));

            return;
        }
    };
    useEffect(() => {
        if (loginState.showForgetSuccessModal) {
            setTimeout(() => {
                setLoginState((prev) => {
                    return {
                        ...prev,
                        showForgetSuccessModal: false,
                    };
                });
            }, 90000);
        }
    }, [loginState.showForgetSuccessModal]);

    useEffect(() => {
        if (window.google) {
            window.google.accounts.id.initialize({
                client_id: APP_IDS.GOOGLE,
                callback: handleGoogleCallback,
            });

            window.google.accounts.id.renderButton(document.getElementById("googleSignIn"), {
                theme: "outline",
                size: "large",
            });

            window.google.accounts.id.prompt();
        }
    }, []);

    return (
        <>
            <section className="loginView--container">
                <div className="loginView-wrapper">
                    <div className="col-lg-8 credentials-wrapper">
                        <div className="credentials-wrapper-inner">
                            <div className="credentials-content">
                                <form onSubmit={handleSubmitLogin}>
                                    <h2 className="title" style={{ marginBottom: 20 }}>
                                        Welcome back!
                                    </h2>
                                    <div className="field-group">
                                        <label>Email</label>
                                        <input
                                            type="email"
                                            aria-label="email adress"
                                            autoComplete="off"
                                            placeholder="john.doe@xoommaps.com"
                                            onChange={(e) =>
                                                setLoginState((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        email: e.target.value.trim().toLowerCase(),
                                                    };
                                                })
                                            }
                                            required
                                        />
                                    </div>

                                    <div className="field-group">
                                        <label>Password</label>
                                        <div className="pos-rel">
                                            <input
                                                type={loginState.showPassword ? "text" : "password"}
                                                aria-label="Password"
                                                autoComplete="off"
                                                placeholder="Password"
                                                onChange={(e) =>
                                                    setLoginState((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            password: e.target.value.trim(),
                                                        };
                                                    })
                                                }
                                                required
                                            />
                                            <img
                                                src={loginState.showPassword ? eyeIcon : eyeIconOff}
                                                className="eye-password-toggle"
                                                alt="eye-password-toggle"
                                                style={{ top: 8, right: 8 }}
                                                onClick={(e) => {
                                                    setLoginState((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            showPassword: !prevState.showPassword,
                                                        };
                                                    });
                                                    e.preventDefault();
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <button
                                        type="button"
                                        className="login-forget-cta"
                                        onClick={() =>
                                            setLoginState((prevState) => {
                                                return {
                                                    ...prevState,
                                                    showForgetModal: true,
                                                };
                                            })
                                        }
                                    >
                                        Forgot password?
                                    </button>

                                    {loginLoading ? (
                                        <button
                                            className="btn animate-dots-blue login-cta-outline"
                                            type="button"
                                            disabled
                                        >
                                            <DotLoader />
                                        </button>
                                    ) : (
                                        <button
                                            type="submit"
                                            className="btn login-btn"
                                            disabled={
                                                loginState.email !== "" &&
                                                loginState.password !== ""
                                                    ? false
                                                    : true
                                            }
                                        >
                                            Log in
                                        </button>
                                    )}
                                    <div className="pos-rel or-seperator">
                                        <p>or</p>
                                    </div>
                                </form>

                                <div className="platform-wrapper">
                                    <div className="googleBtnWrapper">
                                        {reqLoading.ggLoading ? (
                                            <button
                                                className="btn animate-dots-blue login-cta-outline"
                                                type="button"
                                                disabled
                                            >
                                                <DotLoader />
                                            </button>
                                        ) : (
                                            <>
                                                <button className="btn ">
                                                    <GoogleIcon />
                                                    Log in with Google
                                                </button>

                                                <div id="googleSignIn"></div>
                                            </>
                                        )}
                                    </div>

                                    {reqLoading.fbLoading ? (
                                        <button
                                            className="btn animate-dots-blue login-cta-outline"
                                            type="button"
                                            disabled
                                        >
                                            <DotLoader />
                                        </button>
                                    ) : (
                                        <LoginSocialFacebook
                                            // isOnlyGetToken
                                            appId={APP_IDS.FACEBOOK || ""}
                                            onResolve={handleFacebookResolve}
                                            onReject={(err) => {
                                                console.log(err);
                                            }}
                                        >
                                            <button className="btn">
                                                <FacebookIcon />
                                                Log in with Facebook
                                            </button>
                                        </LoginSocialFacebook>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 signUp-content ">
                        <div className="signUp-content-inner">
                            <div className="signUp-box-wrapper">
                                <h2 className="title">
                                    Create a <span className="pos-rel">free </span> XoomMaps <br />{" "}
                                    account
                                </h2>
                                <div className="sign-up-cta">
                                    <Link to="/sign-up">Sign up</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loginState.showForgetModal && (
                    <Forgetpassword
                        loading={resetPasswordLoading}
                        setLoginState={setLoginState}
                        loginState={loginState}
                        handleSubmitForget={handleSubmitForget}
                    />
                )}
                {loginState.showVerifyLoginModal && (
                    <VerifyEmailLogin
                        loading={resetPasswordLoading}
                        setLoginState={setLoginState}
                        loginState={loginState}
                        handleSendVerificationAgain={handleSendVerificationAgain}
                    />
                )}
                {loginState.showForgetSuccessModal && (
                    <ResetPasswordMessage
                        setLoginState={setLoginState}
                        loginState={loginState}
                        handleSubmitForget={handleSubmitForget}
                    />
                )}
                {loginState.showTwoStepVerification && (
                    <TwoStepVerification
                        setLoginState={setLoginState}
                        loginState={loginState}
                        handleSendTwoStepVerification={handleSendTwoStepVerification}
                    />
                )}
            </section>
            <Footer />
        </>
    );
};

export default Login;
