import React, { useEffect, useState } from "react";
import { convertArrayTSV, convertDataByGrouping, getFilteredData } from "../../../utils/utils";
import MapsGridTabularData from "../../../components/myMaps/MapsGridTabularData";
import MapsTabularData from "../../../components/myMaps/MapsTabularData";
import SpinLoader from "../../../components/SpinLoader/SpinLoader";
import searchWhiteIcon from "../../../assets/images/svg/searchWhiteIcon.svg";
import searchGreyIcon from "../../../assets/images/svg/searchGreyIcon.svg";
import { successMessage } from "../../../utils/messages";
import CopyIcon from "../../../components/icons/CopyIcon";
import PaginationPinDetails from "./PaginationPinDetails";
import copyGroupIcon from "../../../assets/images/svg/Copy groups - off.svg";
import copyRowIcon from "../../../assets/images/svg/Copy - Row (1).svg";

import {
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import GoogleMapResizer from "../../../components/GoogleMapResizer/GoogleMapResizer";
import MapGroups from "../../../components/MapGroups/MapGroups";
import { memo } from "react";
import ChevronRightIcon from "../../../components/icons/ChevronRightIcon";

// import copyRows from "../../../assets/images/svg/Copy - on.svg";
// import copyGroups from "../../../assets/images/svg/Copy groups - off.svg";
const columns = [
    {
        accessorKey: "name",
    },
    {
        accessorKey: "email",
    },
    {
        accessorKey: "phone",
    },
    {
        accessorKey: "url",
    },
    {
        accessorKey: "group",
    },
    {
        accessorKey: "address",
    },
    {
        accessorKey: "city",
    },
    {
        accessorKey: "state",
    },
    {
        accessorKey: "zip",
    },
];

function PinDetails(props) {
    const [instanceTable, setInstanceTable] = useState(null);
    const [changePinTable, setChangePinTable] = useState({
        showTable: true,
        active: false,
    });
    // const [changeCopyFormat, setChangeCopyFormat] = useState(false);

    const { mapState, setMapState, hiddenMarkers, pinsFilterState, showHistory } = props;
    const { data, searchText, triangualtion } = mapState;

    const handleSearchText = (e) => {
        setMapState((prevState) => ({
            ...prevState,
            searchText: e.target.value.toLowerCase(),
        }));
    };

    const toggleChangeTable = () => {
        setChangePinTable((prevState) => ({
            active: !prevState.active,
            showTable: !prevState.showTable,
        }));
    };

    let filteredTableData = getFilteredData(mapState?.data?.rawData, hiddenMarkers, searchText);

    const table = useReactTable({
        data: filteredTableData,
        columns,
        autoResetPageIndex: false,

        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),

        debugTable: true,
    });

    return !mapState.loading ? (
        <>
            <div className="mapData-tabular-parent-container">
                <div className="mapData-tabular--container">
                    <div className="mapData-tabular-functionality">
                        <div className="table-searchCopy">
                            <div
                                className={`gridTtable-data-toggle ${
                                    changePinTable.active ? "active" : ""
                                } `}
                                onClick={toggleChangeTable}
                            >
                                <svg
                                    id="Grid_view-on"
                                    data-name="Grid view-on"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 40 40"
                                >
                                    <rect
                                        id="Boundary"
                                        width="40"
                                        height="40"
                                        rx="18"
                                        fill="rgba(238,238,238,0.6)"
                                        opacity="0"
                                    />
                                    <g id="Icon" transform="translate(10 10)">
                                        <rect
                                            id="Rectangle_663"
                                            data-name="Rectangle 663"
                                            width="8"
                                            height="8"
                                            fill="#999b9e"
                                        />
                                        <rect
                                            id="Rectangle_664"
                                            data-name="Rectangle 664"
                                            width="8"
                                            height="8"
                                            transform="translate(12)"
                                            fill="#999b9e"
                                        />
                                        <rect
                                            id="Rectangle_665"
                                            data-name="Rectangle 665"
                                            width="8"
                                            height="8"
                                            transform="translate(12 12)"
                                            fill="#999b9e"
                                        />
                                        <rect
                                            id="Rectangle_666"
                                            data-name="Rectangle 666"
                                            width="8"
                                            height="8"
                                            transform="translate(0 12)"
                                            fill="#999b9e"
                                        />
                                    </g>
                                </svg>
                                <svg
                                    id="List_view-on"
                                    data-name="List view-on"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 40 40"
                                >
                                    <g
                                        id="Boundary"
                                        stroke="rgba(0,0,0,0)"
                                        strokeWidth="1"
                                        opacity="0"
                                    >
                                        <rect width="40" height="40" stroke="none" />
                                        <rect x="0.5" y="0.5" width="39" height="39" fill="none" />
                                    </g>
                                    <path
                                        id="Icon_metro-list2"
                                        data-name="Icon metro-list2"
                                        d="M10.071,1.928h12.5v5h-12.5Zm0,7.5h12.5v5h-12.5Zm0,7.5h12.5v5h-12.5v-5Zm-7.5-12.5a2.5,2.5,0,1,1,2.5,2.5,2.5,2.5,0,0,1-2.5-2.5Zm0,7.5a2.5,2.5,0,1,1,2.5,2.5,2.5,2.5,0,0,1-2.5-2.5Zm0,7.5a2.5,2.5,0,1,1,2.5,2.5,2.5,2.5,0,0,1-2.5-2.5Z"
                                        transform="translate(7.43 8.072)"
                                        fill="#999b9e"
                                    />
                                </svg>
                            </div>

                            <div className="tabular-seacrhBar">
                                <input
                                    className="form-control"
                                    type="text"
                                    value={mapState?.searchText}
                                    placeholder="Address, zip, group…"
                                    onChange={handleSearchText}
                                />
                                <img src={searchGreyIcon} alt="search icon" />
                            </div>
                            <div
                                className={`tabular-copydata
                            `}
                            >
                                <p>Copy File</p> <ChevronRightIcon />
                                <div className="copy-data-type-box">
                                    <button
                                        onClick={() => {
                                            convertArrayTSV(mapState?.data?.rawData);
                                            successMessage("Successfully copied");
                                        }}
                                        title="Copy by row"
                                    >
                                        Copy rows
                                    </button>
                                    <button
                                        onClick={() => {
                                            convertDataByGrouping(
                                                mapState?.data?.rawData,
                                                pinsFilterState?.activePinFilter
                                            );
                                            successMessage("Successfully copied");
                                        }}
                                        title="Copy by group"
                                    >
                                        Copy by groups
                                    </button>
                                </div>
                            </div>
                        </div>
                        {!changePinTable.showTable && (
                            <PaginationPinDetails
                                table={table}
                                dataLength={data?.rawData?.length}
                            />
                        )}
                    </div>

                    {!changePinTable.showTable && (
                        <MapsTabularData
                            data={data?.rawData}
                            triangualtion={triangualtion}
                            table={table}
                            flexRender={flexRender}
                            showHistory={showHistory}
                        />
                    )}
                    {changePinTable.showTable && (
                        <MapsGridTabularData
                            mapState={mapState}
                            hiddenMarkers={hiddenMarkers}
                            setChangePinTable={setChangePinTable}
                            changePinTable={changePinTable}
                        />
                    )}
                </div>
            </div>
        </>
    ) : (
        <SpinLoader />
    );
}

export default PinDetails;
