import React from "react";
import noImage from "../../../assets/images/noImage.png";
import moment from "moment/moment";
import { setMapDashboardId } from "../../../store/slicers/mapDashboardSlice";
import starGrey from "../../../assets/images/star-grey.png";
import starGolden from "../../../assets/images/star-golden.png";
import userIcon from "../../../assets/images/svg/userIcon.svg";
import avatarIcon from "../../../assets/images/avatar-grey.png";
import saveIcon from "../../../assets/images/svg/saveIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import ImageListView from "../../../components/ImageListView/ImageListView";
import TrashIcon from "../../../components/icons/TrashIcon";
import ChevronRightIcon from "../../../components/icons/ChevronRightIcon";
import ShareIcon from "../../../components/icons/ShareIcon";

const RouteMapListItem = (props) => {
    const {
        data,
        handleToggleMapDelete,
        setModalMapTitle,
        handleShare,
        routeState,
        setRouteState,
        myMapFavHandle,
        pinsLoading,
    } = props;

    const mapDashboardId = useSelector((state) => state.mapDashboardState.id);
    const { user } = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    const handleCheckboxChange = (id, title) => {
        if (routeState.checkListArr.some((item) => item.id === id)) {
            const updatedIds = routeState.checkListArr.filter((item) => item.id !== id);
            setRouteState((prevState) => {
                return {
                    ...prevState,
                    checkListArr: updatedIds,
                };
            });
        } else {
            setRouteState((prevState) => {
                return {
                    ...prevState,
                    checkListArr: [...routeState.checkListArr, { id: id, title: title }],
                };
            });
        }
    };

    return (
        <li
            className={`myMapList-data-item ${pinsLoading ? "clickable-none" : ""}`}
            onClick={() => {
                dispatch(setMapDashboardId(data._id));
            }}
        >
            <div
                className={`myMapList-data-item-inner ${
                    data._id === mapDashboardId ? "active" : ""
                }`}
            >
                <div className="flex-gap-y5">
                    <div className="myMap-img">
                        <div className="list-input-wrapper">
                            {routeState.showCheckList && (
                                <>
                                    <label
                                        className="custom-label"
                                        htmlFor={`${data._id}`}
                                        style={{ paddingLeft: 15 }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        <input
                                            type="checkbox"
                                            name="mapListcheck"
                                            id={data._id}
                                            value={data._id}
                                            onChange={() => {
                                                handleCheckboxChange(data._id, data.title);
                                            }}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </>
                            )}
                            <img
                                src={data.favorite ? starGolden : starGrey}
                                className="fav-star"
                                alt="Favourite Map"
                                title="Mark as favorite"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    myMapFavHandle(data._id, !data.favorite, "route");
                                }}
                            />
                        </div>

                        <img
                            src={data?.mapImage || noImage}
                            className="myMap-list-img"
                            alt="map-list"
                            onClick={(e) => {
                                e.stopPropagation();
                                setRouteState((prevState) => ({
                                    ...prevState,
                                    openMapCropperModal: true,
                                    clickedItemID: data._id,
                                    clickedItemAvatar: data?.mapImage,
                                }));
                            }}
                        />
                    </div>
                    <div className="mapList-data">
                        <h5 className="mapList-title">{data.title}</h5>

                        {user.email !== data.email && (
                            <>
                                <p className="sharedMap-sender">
                                    <img src={userIcon} alt="user icon" /> Owned by:{" "}
                                    <b>
                                        <u>{data?.owner_name}</u>
                                    </b>
                                </p>
                                <p className="sharedMap-sender">
                                    <img src={saveIcon} alt="save icon" /> Created on:{" "}
                                    {moment(data?.createdAt).format("LL")}
                                </p>
                            </>
                        )}

                        {data.shared?.length && user.email === data.email ? (
                            <>
                                <div
                                    className="myMapList-people-shared"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.handleShowSharedList();
                                    }}
                                >
                                    <ImageListView data={data?.shared} />
                                </div>
                            </>
                        ) : (
                            <>
                                {user.email === data.email && (
                                    <div className="no-shared-user-wrapper">
                                        <img src={avatarIcon} alt="user" />
                                    </div>
                                )}
                            </>
                        )}

                        <div className="myMapList-views" style={{ marginTop: 2 }}>
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    let url = `/map-view/route/${data._id}`;
                                    window.open(url, "_blank");
                                }}
                            >
                                Public view <ChevronRightIcon />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="myMap-config options">
                    {user.email === data.email && (
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                handleShare(data._id, data?.shared, "share-route");
                                props.setModalMapTitle(data?.title);
                            }}
                        >
                            <ShareIcon />
                        </button>
                    )}

                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            handleToggleMapDelete(
                                data._id,
                                data?.map_id,
                                data?.parent_group,
                                "delete-route"
                            );
                            setModalMapTitle(data?.title);
                        }}
                    >
                        <TrashIcon />
                    </button>
                </div>
            </div>
        </li>
    );
};

export default RouteMapListItem;
