import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setTempPasswordAuth } from "../../store/slicers/authSlice";
import { errorMessage } from "../../utils/messages";
import DotLoader from "../DotLoaderBtn/DotLoader";
import { ToastContainer } from "react-toastify";
import { TEMP_PASSWORD } from "../../config";

const TemPasswordAuth = () => {
    const [password, setPassword] = useState("");

    const dispatch = useDispatch();

    const handleSubmitPassword = (e) => {
        e.preventDefault();
        if (TEMP_PASSWORD === password) {
            dispatch(setTempPasswordAuth(true));
            sessionStorage.setItem("TempPasswordAuth", password);
        } else {
            errorMessage("Incorrect Password");
            sessionStorage.setItem("TempPasswordAuth", null);
        }
    };
    return (
        <>
            <ToastContainer
                autoClose={3000}
                closeButton={false}
                position="bottom-center"
                hideProgressBar={true}
            />
            <div className="password-publicview-wrapper">
                <form onSubmit={(e) => handleSubmitPassword(e)}>
                    <h2>Enter Password</h2>
                    <div className="inner-wrapper" style={{ width: "20rem !important" }}>
                        <input
                            type="password"
                            aria-label="password"
                            autoComplete="off"
                            onChange={(e) => {
                                let value = e.target.value.trim();
                                setPassword(value);
                            }}
                            required
                        />
                        {false ? (
                            <button
                                className="btn animate-dots-blue view-cta"
                                type="button"
                                disabled
                            >
                                <DotLoader />
                            </button>
                        ) : (
                            <button type="submit" className="view-cta">
                                Join Beta
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </>
    );
};

export default TemPasswordAuth;
