import React from "react";

export const MoveMrakerIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14.476" height="16" viewBox="0 0 14.476 16">
            <path
                id="file_move-inactive"
                data-name="file move-inactive"
                d="M11.619,13.429h3.048V11.143l3.81,3.429L14.667,18V15.714H11.619V13.429m-.762-6.1h4.19l-4.19-4.19v4.19M5.524,2h6.1L16.19,6.571V9.878a4.577,4.577,0,0,0-6.1,4.312,4.549,4.549,0,0,0,1.166,3.048H5.524A1.523,1.523,0,0,1,4,15.714V3.524A1.524,1.524,0,0,1,5.524,2Z"
                transform="translate(-4 -2)"
                fill="#90b4cd"
            />
        </svg>
    );
};
