export default function EmailIcon({ color = "inherit", fill = "inherit", width = "20px" }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12.8" viewBox="0 0 16 12.8">
            <path
                id="Mail-off"
                d="M17.4,6H4.6A1.6,1.6,0,0,0,3.008,7.6L3,17.2a1.6,1.6,0,0,0,1.6,1.6H17.4A1.6,1.6,0,0,0,19,17.2V7.6A1.6,1.6,0,0,0,17.4,6Zm0,3.2-6.4,4-6.4-4V7.6l6.4,4,6.4-4Z"
                transform="translate(-3 -6)"
                fill="#999b9e"
            />
        </svg>
    );
}
