/* global google */
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import decodePolyline from "decode-google-map-polyline";
import { InfoWindow, Marker, Polyline, Polygon, Rectangle } from "@react-google-maps/api";

import {
    useCreateGroupMutation,
    useCreateGroupsListMutation,
    useLazyGetGroupByIdQuery,
    useLazyGetGroupsHistoryQuery,
    useLazyGetMapByIdQuery,
    useLazyGetRouteByIdQuery,
} from "../../services/baseApi";

import {
    createToolBtn,
    customButtonsData,
    restoreCallback,
    repeatedActions,
    clearShapes,
    addShapeToDrawingHistory,
} from "../../components/Map/DrawingManagerBtns";
import DrawingComponent from "../../components/Map/DrawingComponent";
import Map from "../../components/Map/Map";
import MapMarkers from "../../components/Map/MapMarkers";
import MarkerInfo from "../../components/Map/MarkerInfo";
import MapGroups from "../../components/MapGroups/MapGroups";
import PinDetails from "./myMaps/PinDetails";
import Footer from "../../components/Sidebar/Footer";
import MapViewDashboardOptions from "../../components/Map/MapViewDashboardOptions";
import DotLoader from "../../components/DotLoaderBtn/DotLoader";
import GoogleMapResizer from "../../components/GoogleMapResizer/GoogleMapResizer";
import WarningIcon from "../../components/icons/WarningIcon";

import {
    changeToGroup,
    colors,
    convertNumberToCommaNotation,
    getColor,
    makeDeepCopy,
} from "../../utils/utils";
import {
    calculateHistoryCenterPoint,
    distanceBetweenPointsV2,
    drawingToolsOptions,
    filterHeaders,
    getKmlPolygon,
    polylineOptions,
    setMapBounds,
    timezoneColors,
    timezonePolygonOptions,
} from "../../utils/mapUtils";
import { errorMessage, successMessage, warningLinkMessage } from "../../utils/messages";

import chevronUpLight from "../../assets/images/chevronUpLight.png";
import arrowDown from "../../assets/images/svg/arrow-down.svg";
import { KML_STATES_URLS, KML_TIMEZONES, statesPath, timezonesPath } from "../../utils/constants";
import SpinLoader from "../../components/SpinLoader/SpinLoader";
import { useMemo } from "react";
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation";
import DashboardSettingOptionsModel from "../../components/Map/DashboardSettingOptionsModel";
import { getDistanceMeasurement } from "../../store/slicers/authSlice";

import GroupHistoryDetailsModal from "../../components/Modals/GroupHistoryDetailsModal";

const containerStyle = {
    width: "100%",
    maxHeight: "100%",
    height: "100%",
};

let mapDataFields = [
    "region",
    "city",
    "state",
    "address",
    "zip",
    "group",
    "name",
    "description",
    "url",
    "email",
    "pinShape",
    "mapType",
    "targetLink",
    "markerLabel",
    "mapStyles",
    "imageURL",
    "longitude",
    "latitude",
    "mapStyle",
    "markerStyle",
];

function DashboardMap(props) {
    const authUser = useSelector((state) => state.auth.user);
    const { mapType, groupType } = useSelector((state) => state.mapTypeState);
    const toggleRefresh = useSelector((state) => state.toggleRefresh);
    const { id: mapDashboardId } = useSelector((state) => state.mapDashboardState);

    const {
        handleContextClick,
        setContextOptions,
        contextOptions,
        mapDataheaders,
        setDataHeaders,
        totalUserGroups,
        UserPlanData,
        showState,
        showGroupState,
        showSharedState,
    } = props;

    const measureDistanceType = useSelector(getDistanceMeasurement);
    const [hiddenMarkers, setHiddenMarkers] = useState([]);
    const [groupInputCount, setGroupInputCount] = useState(0);
    const [pinsFilterState, setPinsFilterState] = useState({
        activePinFilter: "group",
        showList: false,
    });
    const rulerTypeRef = useRef(true);
    const [drawingHistory, setDrawingHistory] = useState([]);

    const mapTypeRef = useRef("maps");
    const zoomRef = useRef({
        maxZoomOut: 4,
        defaultZoom: 3,
        maxZoomIn: 24,
    });

    const [mapState, setMapState] = useState(() => ({
        loading: true,
        center: { lat: 40.756795, lng: -73.954298 },
        data: {},
        mapData: {},
        errorMessage: "",
        markerInfo: null,
        duplicateMarkers: null,
        map: null,
        maps: null,
        openMarkerInfo: false,
        showUserBox: false,
        selectedPins: [],
        alreadySelectedPins: [],
        userLocation: null,

        triangulation: false,

        rowsToShow: null,
        searchText: "",

        shapeArea: null,
        shapeType: "",
        showError: false,

        groupToggleactive: true,
        saveMyGroup: false,
    }));

    const [mapDashboardOptionsState, setMapDashboardOptionsState] = useState({
        showLayerOption: false,
        showSettingsOption: false,
        showMapPDFmodal: false,
        showHistory: false,
        showFilter: false,
    });

    const [filterGroupState, setFilterGroupState] = useState({
        loading: false,
        stateProvince: false,
        timeZone: false,
        disableFilters: false,
        showCreateGroupModal: false,
    });

    const [showKML, setShowKML] = useState(false);
    const kmlGroupsRef = useRef({});

    const toggleStateFilter = async () => {
        setFilterGroupState((prevState) => {
            return {
                ...prevState,
                stateProvince: !prevState.stateProvince,
                timeZone: false,
                showCreateGroupModal: false,
            };
        });
    };

    const toggleTimeZoneFilter = async () => {
        setFilterGroupState((prevState) => {
            return {
                ...prevState,
                stateProvince: false,
                timeZone: !prevState.timeZone,
                showCreateGroupModal: false,
            };
        });
    };

    const handleMapSetting = () => {
        setMapDashboardOptionsState((prevState) => ({
            ...prevState,
            showLayerOption: false,
            showSettingsOption: !prevState.showSettingsOption,
            showMapPDFmodal: false,
            showHistory: false,
            showFilter: false,
        }));
    };

    const handleMapLayer = () => {
        setMapDashboardOptionsState((prevState) => ({
            ...prevState,
            showLayerOption: !prevState.showLayerOption,
            showSettingsOption: false,
            showMapPDFmodal: false,
            showHistory: false,
            showFilter: false,
        }));
    };

    const toggleMapHistory = (history) => {
        setMapDashboardOptionsState((prevState) => {
            return {
                ...prevState,
                showLayerOption: false,
                showSettingsOption: false,
                showMapPDFmodal: false,
                showHistory: history,
                showFilter: false,
            };
        });
    };

    const handleMapFilter = () => {
        setMapDashboardOptionsState((prevState) => ({
            ...prevState,
            showLayerOption: false,
            showSettingsOption: false,
            showMapPDFmodal: false,
            showHistory: false,
            showFilter: !prevState.showFilter,
        }));
    };

    const handleShowPDF = () => {
        setMapDashboardOptionsState((prevState) => ({
            ...prevState,
            showMapPDFmodal: !prevState.showMapPDFmodal,
        }));
    };

    useEffect(() => {
        handleGroupHistory(mapDashboardOptionsState.showHistory);
    }, [JSON.stringify(mapDashboardOptionsState)]);

    const overlayRef = useRef([]);
    const addShape = (shape) => {
        overlayRef.current.push(shape);
    };
    const deleteAllShapes = () => {
        overlayRef?.current?.forEach((shape) => {
            shape?.overlay?.setMap?.(null);
            shape?.setMap?.(null);
        });
    };

    // location of Marker Info
    const [loc, setLoc] = useState({});

    const handleMarkerClick = (e, details, showuserbox) => {
        setLoc({
            lat: Number(details?.[0]?.location?.latitude || details?.[0]?.location?.lat),
            lng: Number(details?.[0]?.location?.longitude || details?.[0]?.location?.lng),
        });
        if (details?.length > 1) {
            setMapState((prevState) => ({
                ...prevState,
                markerInfo: details?.[0],
                duplicateMarkers: details,
                openMarkerInfo: !prevState.openMarkerInfo,
                showUserBox: showuserbox ? true : false,
            }));
        } else {
            setMapState((prevState) => ({
                ...prevState,
                markerInfo: details?.[0],
                duplicateMarkers: null,
                openMarkerInfo: !prevState.openMarkerInfo,
                showUserBox: showuserbox ? true : false,
            }));
        }
    };

    const handleCloseMarkerModel = () => {
        setMapState((prevState) => {
            return {
                ...prevState,
                openMarkerInfo: false,
            };
        });
    };

    function setMapZoom(map, ref) {
        return () => {
            ref.current.defaultZoom = map.zoom;
        };
    }

    const onMapLoad = ({ map, maps }) => {
        map.addListener("zoom_changed", setMapZoom(map, zoomRef));

        setMapState((prevState) => {
            return {
                ...prevState,
                map,
                maps,
            };
        });

        // SHOWING CUSTOM TOOLS
        const ignoreControl = document.createElement("section");
        ignoreControl.className = ".container-ignore";
        const controlDiv = document.createElement("div");

        controlDiv.classList.add("drawing-manager-wrapper");

        customButtonsData?.forEach((btn) => {
            var options = {
                map,
                maps,
                mapState,
                addShape,
                deleteAllShapes,
                setMapState,
                toggleMapHistory,
                measureDistanceType,
            };

            let btnControl;
            if (btn?.id === "rulerButton") {
                const divWithTwoRulers = document.createElement("div");
                divWithTwoRulers.classList.add("RulerDivBtns");

                let RulerButtonsArray = btn.innerButton;
                if (measureDistanceType === 1) {
                    RulerButtonsArray = [...btn.innerButton].reverse();
                }
                RulerButtonsArray.forEach((innerBtn) => {
                    btnControl = createToolBtn(innerBtn.id, innerBtn.title, innerBtn?.icon);
                    divWithTwoRulers.appendChild(btnControl);
                    btnControl.addEventListener("click", () => {
                        innerBtn.callback(options);
                    });
                });

                divWithTwoRulers.addEventListener("mouseenter", (e) => {
                    e.stopPropagation();

                    const getBtn1 = document.querySelector(".RulerDivBtns button:first-child");
                    const getBtn2 = document.querySelector(".RulerDivBtns button:last-child");
                    getBtn2.style.display = "block";
                    getBtn2.style.position = "absolute";
                    getBtn2.style.top = "37px";
                    getBtn2.addEventListener("click", (e) => {
                        e.stopPropagation();
                        getBtn2.classList.add("active");
                        if (measureDistanceType === 1) {
                            rulerTypeRef.current = true;
                        } else {
                            rulerTypeRef.current = false;
                        }
                    });
                    getBtn1.addEventListener("click", (e) => {
                        e.stopPropagation();
                        getBtn1.classList.add("active");
                        if (measureDistanceType === 2) {
                            rulerTypeRef.current = true;
                        } else {
                            rulerTypeRef.current = false;
                        }
                    });
                });

                divWithTwoRulers.addEventListener("mouseleave", (e) => {
                    e.stopPropagation();
                    const getBtn1 = document.querySelector(".RulerDivBtns button:first-child");
                    const getBtn2 = document.querySelector(".RulerDivBtns button:last-child");
                    getBtn2.style.display = "block";
                    getBtn2.style.top = "0px";
                    if (getBtn2.classList.contains("active")) {
                        getBtn1.style.zIndex = 0;
                        getBtn2.style.zIndex = 1;
                    } else {
                        getBtn1.style.zIndex = 1;
                        getBtn2.style.zIndex = 0;
                    }
                });

                controlDiv.appendChild(divWithTwoRulers);
                ignoreControl.appendChild(controlDiv);
                if (mapType === 3 || props?.isNocUrl) {
                    if (btn.id === "rulerButton" || btn.id === "handButton") {
                        btnControl.disabled = false;
                    } else {
                        btnControl.disabled = true;
                    }
                }
            } else {
                const btnControl = createToolBtn(btn.id, btn.title, btn.icon);
                btnControl.addEventListener("click", () => btn.callback(options));
                controlDiv.appendChild(btnControl);
                ignoreControl.appendChild(controlDiv);
                if (mapType === 3 || props?.isNocUrl) {
                    if (btn.id === "rulerButton" || btn.id === "handButton") {
                        btnControl.disabled = false;
                    } else {
                        btnControl.disabled = true;
                    }
                }
            }
        });

        // Collapse button for drawing manager
        const drawManagerCollapse = document.createElement("div");
        drawManagerCollapse.classList.add("draw-manager-collapse");

        const drawManagerCollapseBtn = document.createElement("button");
        const drawManagerCollapseBtnImg = document.createElement("img");

        drawManagerCollapseBtnImg.src = chevronUpLight;

        drawManagerCollapseBtn.appendChild(drawManagerCollapseBtnImg);
        drawManagerCollapse.appendChild(drawManagerCollapseBtn);

        drawManagerCollapse.addEventListener("click", (e) => {
            if (e.currentTarget.parentElement.classList.toggle("active")) {
                drawManagerCollapseBtnImg.src = arrowDown;
            } else drawManagerCollapseBtnImg.src = chevronUpLight;
        });

        controlDiv.appendChild(drawManagerCollapse);
        map.controls[google.maps.ControlPosition.TOP_CENTER].push(controlDiv);
        // }

        if (mapState.data.rawData) {
            setMapBounds(map, mapState.data.rawData, false, window.google?.maps.LatLngBounds);
        }

        return () => {
            map.removeListener("zoom_changed", setMapZoom(zoomRef, map));
        };
    };

    const overlayWrapper = useCallback((data) => {
        return (shape) => {
            let val;
            let selectedPins = [];
            let alreadySelectedPins = [];

            deleteAllShapes();

            addShape(shape);

            if (shape.type === "polygon") {
                val = convertNumberToCommaNotation(
                    window.google.maps.geometry.spherical
                        .computeArea(shape.overlay.getPath())
                        .toFixed(2) /
                        2 /
                        2589988.11
                );
                selectedPins = data.filter((item) => {
                    const { location } = item;
                    const latLng = new window.google.maps.LatLng(location.lat, location.lng);
                    if (
                        window.google.maps.geometry.poly.containsLocation(latLng, shape.overlay) &&
                        item?.history?.[item.history?.length - 1]?.status
                    ) {
                        alreadySelectedPins.push(item);
                    }
                    if (
                        window.google.maps.geometry.poly.containsLocation(latLng, shape.overlay) &&
                        !item?.history?.[item.history?.length - 1]?.status
                    ) {
                        return item;
                    } else return false;
                });
            }

            if (shape.type === "polyline") {
                let meters = window.google.maps.geometry.spherical.computeLength(
                    shape.overlay.getPath().getArray()
                );
                if (rulerTypeRef.current) {
                    val = convertNumberToCommaNotation(`${(meters / 1000).toFixed(2)}`) + " Km";
                } else {
                    val =
                        convertNumberToCommaNotation(`${(meters / 1609.344).toFixed(2)}`) +
                        " miles";
                }

                setMapState((prevState) => {
                    return {
                        ...prevState,
                        shapeArea: val,
                        selectedPins: [],
                        shapeType: shape.type,
                        showError: false,
                        path: "",
                    };
                });
                return;
            }

            if (selectedPins.length) {
                // USING THIS TIMEOUT TO CAPTURE THE DRAWING AFTER SELECTING ANOTHER SHAPE AFTER LASSO TOOL
                console.log("all selectedPins : ", selectedPins);

                setTimeout(() => {
                    setMapState((prevState) => {
                        return {
                            ...prevState,
                            shapeArea: val,
                            selectedPins,
                            // selectedPins: { ...selectedPins, ...authUser },
                            path: shape.overlay.getPath().getArray(),
                            shapeType: shape.type,
                            showError: false,
                        };
                    });
                }, 0);
            } else {
                setMapState((prevState) => {
                    return {
                        ...prevState,
                        shapeArea: null,
                        selectedPins: [],
                        alreadySelectedPins: [],
                        shapeType: "",
                        path: "",
                        showError: true,
                        // errorMessage: "Select 1 Pin atleast",
                        errorMessage: "Selection must contain a minimum of one marker.",
                    };
                });
            }
        };
    }, []);

    let [createGroup, { isLoading: createGroupLoading }] = useCreateGroupMutation();

    const handleCreateGroup = async (e) => {
        e.preventDefault();
        if (totalUserGroups >= Number(UserPlanData?.groups)) {
            warningLinkMessage("group limit exceeded");
            handleCancelSaveGroup();
            return;
        }
        let userId = authUser._id;

        let groupName = e.target["group-name"].value.trim();

        if (groupName) {
            let payload = {
                title: groupName,
                email: authUser.email,
                userAvatar: authUser.avatar,
                mapData: {},
                pathInfo: {
                    shapeType: mapState.shapeType,
                },
            };

            mapDataFields?.forEach((field) => {
                payload.mapData[field] = mapState.data[field];
            });

            if (mapState.shapeType === "rectangle") {
                payload.pathInfo.bounds = mapState.bounds;
            } else {
                payload.pathInfo.path = mapState.path;
            }

            // payload.mapData.rawData = mapState.selectedPins;
            payload.mapData.rawData = mapState.selectedPins?.map((item) => {
                return {
                    ...item,
                    userId,
                };
            });
            payload.mapData.groupedData = changeToGroup(
                makeDeepCopy(mapState.selectedPins),
                mapState.data.group
            );

            if (mapTypeRef.current === "maps") {
                payload.type = "map";
                payload.map_id = mapState.data.map_id;
                payload.parent_group = null;
                payload.owner_name = `${authUser.first_name} ${authUser.last_name}`;
            }

            // IF USER IS ON SHARED MAPS
            if (mapType === 2) {
                if (showSharedState?.isGroupSelected) {
                    let sharedMap = props.sharedMapState.sharedMaps.find((data) => {
                        return data?.map?._id === showSharedState?.parentId;
                    });
                    payload.type = "sharedGroup";
                    payload.map_id = sharedMap.map_id;
                    payload.parent_group = mapDashboardId;
                    payload.owner_name = mapState.mapData.owner_name;
                } else {
                    let sharedMap = props.sharedMapState.sharedMaps.find((data) => {
                        return data.map._id === mapDashboardId;
                    });
                    payload.type = "sharedMap";
                    payload.map_id = sharedMap._id;
                    payload.parent_group = null;
                    payload.owner_name = mapState.mapData.owner_name;
                }
            }

            // IF USER IS ON MY GROUPS
            if (mapType === 4 || mapTypeRef.current === "group") {
                payload.type = "group";
                payload.parent_group = mapState.data.group_id;
                payload.owner_name = `${authUser.first_name} ${authUser.last_name}`;
                payload.map_id = mapState.mapData.map_id;
            }

            const { data, error } = await createGroup(payload);

            if (data) {
                setMapState((prevState) => {
                    return {
                        ...prevState,
                        shapeArea: null,
                        selectedPins: [],
                        shapeType: "",
                        showError: false,
                        getRouteById,
                    };
                });

                getSingleMapData(mapDashboardId);

                successMessage("Successfully created");
            }
            if (error) {
                errorMessage(error?.data?.message);
            }
        } else {
            alert("ENTER GROUP NAME");
        }
    };

    const [getGroupById] = useLazyGetGroupByIdQuery();
    const [getMapById] = useLazyGetMapByIdQuery();
    const [getRouteById] = useLazyGetRouteByIdQuery();

    async function getSingleMapData(id, showPinLoading = true) {
        if (id) {
            if (showPinLoading) {
                props.setParentState((prevState) => {
                    return {
                        ...prevState,
                        pinsLoading: true,
                    };
                });
            }

            let type = "maps";

            if (mapType === 1) {
                type = "maps";
                let mapItem = props.parentState.data.find((item) => {
                    return item._id === id;
                });

                if (!mapItem?.render_method) {
                    if (
                        props.parentState?.groupsData.length ||
                        props.parentState?.secondGroupData.length ||
                        props.parentState?.myGroupsSecondGroupData.length
                    ) {
                        type = "group";
                    }
                }
            }

            if (mapType === 2) {
                let mapItem = props.sharedMapState?.sharedMaps?.find((data) => {
                    return data?.map?._id === id;
                });
                if (mapItem?.type === "group") {
                    type = "group";
                } else {
                    type = showSharedState?.isGroupSelected ? "group" : "maps";
                }
            }

            if (mapType === 3) {
                type = "route";
            }

            if (mapType === 4) {
                type = "group";
            }
            mapTypeRef.current = type;
            let resp;

            switch (type) {
                case "maps": {
                    resp = await getMapById(id);

                    break;
                }

                case "group": {
                    resp = await getGroupById(id);

                    break;
                }
                case "route": {
                    resp = await getRouteById(id);

                    break;
                }
                default:
                    break;
            }

            const { data, error } = resp;

            if (!data?.data) {
                errorMessage(error?.data?.message);

                setMapState((prevState) => {
                    return {
                        ...prevState,
                        data: {},
                        markerInfo: "",
                        openMarkerInfo: false,
                        loading: false,
                    };
                });
                props.setParentState((prevState) => {
                    return {
                        ...prevState,
                        pinsLoading: false,
                    };
                });
                return;
            } else {
                let copiedData = makeDeepCopy(data?.data);
                // REMOVING DELETED PIN
                copiedData = {
                    ...copiedData,
                    rawData: copiedData?.rawData?.filter((loc) => {
                        if (loc.isActive === false) {
                            return false;
                        }
                        return true;
                    }),
                };

                if (data?.data?.rawData?.length > 0 && setDataHeaders) {
                    setDataHeaders((prevState) => {
                        return {
                            ...prevState,
                            newHeaders: filterHeaders(Object.keys(data?.data?.rawData[0]) || []),
                            orgHeaders: filterHeaders(Object.keys(data?.data?.rawData[0]) || []),
                        };
                    });
                }

                setMapState((prevState) => {
                    let newState = {
                        ...prevState,
                        data: copiedData,
                        markerInfo: "",
                        openMarkerInfo: false,
                        mapData: data?.mapData,
                        loading: false,
                    };

                    if (window.google) {
                        setMapBounds(
                            newState?.map,
                            newState?.data?.rawData,
                            zoomRef,
                            window.google?.maps?.LatLngBounds
                        );
                    }

                    return newState;
                });
                props.setParentState((prevState) => {
                    return {
                        ...prevState,
                        pinsLoading: false,
                    };
                });
            }

            if (error) {
                setMapState((prevState) => ({
                    ...prevState,
                    markerInfo: "",
                    openMarkerInfo: false,
                    loading: false,
                }));
                props.setParentState((prevState) => {
                    return {
                        ...prevState,
                        pinsLoading: false,
                    };
                });

                errorMessage(error?.data?.message);
            }
        } else {
            setMapState((prevState) => ({
                ...prevState,
                loading: true,
            }));
            props.setParentState((prevState) => {
                return {
                    ...prevState,
                    pinsLoading: false,
                };
            });
        }
    }

    function removeKML() {
        kmlGroupsRef.current = {};
    }

    let [createGroupsList] = useCreateGroupsListMutation();

    async function handleSaveFilterGroup(groupsData) {
        try {
            setFilterGroupState((prevState) => {
                return {
                    ...prevState,
                    loading: true,
                };
            });

            let globalPayload = {
                email: authUser.email,
                mapData: {},
                pathInfo: {
                    shapeType: "kmlPolygon",
                },
            };

            // ADDING NECESSARY DATA INTO PAYLOAD
            mapDataFields?.forEach((field) => {
                globalPayload.mapData[field] = mapState.data[field];
            });

            if (mapType === 1) {
                globalPayload.type = "map";
                globalPayload.map_id = mapState.data.map_id;
                globalPayload.parent_group = null;
                globalPayload.owner_name = `${authUser.first_name} ${authUser.last_name}`;
            }

            if (mapType === 2) {
                let sharedMap = props.sharedMapState.sharedMaps.find((data) => {
                    return data.map._id === mapDashboardId;
                });

                if (sharedMap?.group_id) {
                    globalPayload.type = "sharedGroup";
                    globalPayload.map_id = sharedMap.map_id;
                    globalPayload.parent_group = sharedMap._id;
                } else {
                    globalPayload.type = "sharedMap";
                    globalPayload.map_id = sharedMap._id;
                    globalPayload.parent_group = null;
                }

                globalPayload.owner_name = mapState.mapData.owner_name;
            }

            if (mapType === 4) {
                globalPayload.type = "group";
                globalPayload.parent_group = mapState.data.group_id;
                globalPayload.owner_name = `${authUser.first_name} ${authUser.last_name}`;
                globalPayload.map_id = mapState.mapData.map_id;
            }

            let combinedPayload = [];

            groupsData?.forEach((group) => {
                if (group.checked) {
                    let payload = makeDeepCopy(globalPayload);

                    payload.title = group.groupName;
                    payload.pathInfo.pathsArr = group.polygons;
                    payload.pathInfo.drawingOptions = group.polygonOptions;
                    payload.mapData.rawData = group.pins;
                    payload.mapData.groupedData = changeToGroup(group.pins, mapState.data.group);

                    combinedPayload.push(payload);
                }
            });

            const { data, error } = await createGroupsList(combinedPayload);

            // HIDE SAVE MODAL
            setFilterGroupState((prevState) => {
                return {
                    ...prevState,
                    loading: false,
                    showCreateGroupModal: false,
                    stateProvince: false,
                    timeZone: false,
                };
            });

            // HIDE DRAWN KML
            setShowKML(false);

            // HIDE FILTERS MENU
            resetMapDashboardOptionsState();

            if (error) {
                errorMessage(error?.data?.message);
            } else {
                getSingleMapData(mapDashboardId);
                successMessage("Successfully created.");
            }
        } catch (err) {
            errorMessage(err?.data?.message);
        }
    }

    useEffect(() => {
        removeKML();
        setShowKML(false);

        if (filterGroupState.timeZone || filterGroupState.stateProvince) {
            setFilterGroupState((prevState) => {
                return {
                    ...prevState,
                    disableFilters: true,
                };
            });

            (async () => {
                kmlGroupsRef.current = {};
                const statesWithGroupId = mapState.data?.rawData?.reduce((states, pin) => {
                    if (pin.groupId && !states.includes(pin.state)) {
                        states.push(pin.state);
                    }
                    return states;
                }, []);
                // RESET VALUE

                for (const pin of mapState.data?.rawData) {
                    // CHECKING IF PIN IS NOT IN ANY GROUP

                    if (pin?.history?.at(-1)?.status === 0) {
                        if (filterGroupState.timeZone) {
                            let timezone = pin.g_timezone?.trim();
                            let timezoneUrl = timezone && `${timezonesPath}/${timezone}.kml`;

                            if (timezoneUrl && KML_TIMEZONES.includes(timezoneUrl)) {
                                // ADDING KML

                                // console.log("TIMEZONE URL RESPONSE: ", response);

                                if (kmlGroupsRef.current[timezone]) {
                                    kmlGroupsRef.current[timezone].pins.push(pin);
                                } else {
                                    kmlGroupsRef.current[timezone] = {
                                        url: "",
                                        polygons: [],
                                        pins: [],
                                    };

                                    let timezonePolygons = await getKmlPolygon(timezoneUrl);

                                    kmlGroupsRef.current[timezone].url = timezoneUrl;

                                    kmlGroupsRef.current[timezone].polygons = timezonePolygons;
                                    kmlGroupsRef.current[timezone].polygonOptions = {
                                        ...timezonePolygonOptions,
                                        fillColor: timezoneColors[timezone],
                                    };

                                    kmlGroupsRef.current[
                                        timezone
                                    ].groupName = `${mapState.mapData.title} - (${timezone}:00)`;

                                    kmlGroupsRef.current[timezone].checked = true;

                                    kmlGroupsRef.current[timezone].pins = [pin];
                                }
                                // }
                            }
                        }

                        if (filterGroupState.stateProvince) {
                            let state = pin.g_state?.trim();
                            let stateUrl = pin.g_state?.trim() && `${statesPath}/${state}.kml`;

                            // isStateHaveGroup()

                            if (stateUrl && KML_STATES_URLS.includes(stateUrl)) {
                                // ADDING KML

                                // console.log("STATE URL RESPONSE: ", response);
                                if (!statesWithGroupId?.includes(state)) {
                                    if (kmlGroupsRef.current[state]) {
                                        kmlGroupsRef.current[state].pins.push(pin);
                                    } else {
                                        kmlGroupsRef.current[state] = {
                                            url: "",
                                            polygons: [],
                                            pins: [],
                                        };

                                        let timezonePolygons = await getKmlPolygon(stateUrl);

                                        kmlGroupsRef.current[state].url = stateUrl;

                                        kmlGroupsRef.current[state].polygons = timezonePolygons;

                                        kmlGroupsRef.current[
                                            state
                                        ].groupName = `${mapState.mapData.title} - (${state})`;

                                        kmlGroupsRef.current[state].checked = true;

                                        kmlGroupsRef.current[state].pins = [pin];
                                    }
                                }
                            }
                        }
                    }
                }

                if (Object.keys(kmlGroupsRef.current).length) {
                    setShowKML(true);

                    setTimeout(() => {
                        setMapBounds(
                            mapState.map,
                            Object.keys(kmlGroupsRef.current).reduce((arr, group) => {
                                arr.push(...kmlGroupsRef.current[group].pins);
                                return arr;
                            }, []),
                            zoomRef,
                            window.google?.maps?.LatLngBounds
                        );
                    }, 1000);
                }

                setFilterGroupState((prevState) => {
                    return {
                        ...prevState,
                        disableFilters: false,
                        showCreateGroupModal: true,
                    };
                });
            })();
        }
    }, [filterGroupState.timeZone, filterGroupState.stateProvince]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function resetFilters() {
        setShowKML(false);
        setFilterGroupState((prevState) => {
            return {
                ...prevState,
                stateProvince: false,
                timeZone: false,
                showCreateGroupModal: false,
                disableFilters: false,
                loading: false,
            };
        });
    }

    function resetMapDashboardOptionsState() {
        setMapDashboardOptionsState((prevState) => ({
            showLayerOption: false,
            showSettingsOption: false,
            showMapPDFmodal: false,
            showHistory: false,
            showFilter: false,
        }));
    }

    const countCharHandle = (value) => {
        setGroupInputCount(value.length);
    };

    const handleCancelSaveGroup = () => {
        let btn = document.querySelector(".custom-drawing-tool--btn.active");
        repeatedActions(btn.id, {
            ...mapState,
            setMapState,
            deleteAllShapes,
        });
        btn.click();
    };

    const handleGroupToggle = (e) => {
        setMapState((prevState) => {
            return {
                ...prevState,
                groupToggleactive: !prevState.groupToggleactive,
                saveMyGroup: !prevState.saveMyGroup,
            };
        });
    };

    const [getGroupsHistory] = useLazyGetGroupsHistoryQuery();

    const updateRawData = (rawData, historyData) => {
        let historyDataCopy = makeDeepCopy(historyData);
        let rawDataCopy = makeDeepCopy(rawData);
        historyDataCopy?.forEach((history, index) => {
            let color = colors[index];

            history.groupColor = color;
            rawDataCopy?.forEach((rawPin) => {
                if (rawPin.groupId == history.group_id) {
                    rawPin.groupColor = color;
                }
            });
        });
        return {
            updatedHistoryData: historyDataCopy,
            updatedRawData: rawDataCopy,
        };
    };

    async function handleGroupHistory(showHistory) {
        if (showHistory) {
            let params = {
                id: mapDashboardId,
                type: groupType === null ? "map" : groupType,
            };
            const { data, error } = await getGroupsHistory(params);

            if (data) {
                const { updatedRawData, updatedHistoryData } = updateRawData(
                    mapState?.data?.rawData,
                    data
                );

                setDrawingHistory(updatedHistoryData);
                setMapState((prevState) => {
                    return {
                        ...prevState,
                        data: {
                            ...prevState.data,
                            rawData: updatedRawData,
                        },
                    };
                });
            }

            if (error) {
                setDrawingHistory([]);
            }
        } else {
            setDrawingHistory([]);
        }
    }

    const handleDrawingHistoryClick = (drawing) => () => {
        props.setActiveDrawing(drawing);
        if (props?.collapseBar.guageBar) {
            props?.setCollapseBar((prev) => {
                return {
                    ...prev,
                    guageBar: false,
                };
            });
        }

        console.log("DRAWING CLICKED: ", drawing);
    };

    // Hiding infoWindow on clicking somewhere
    useEffect(() => {
        resetFilters();

        deleteAllShapes();

        props.setActiveDrawing(null);

        setMapState((prevState) => {
            return {
                ...prevState,
                markerInfo: "",
                openMarkerInfo: false,
                shapeArea: null,
                selectedPins: [],
                shapeType: "",
                showError: false,
                errorMessage: "",
            };
        });

        resetMapDashboardOptionsState();
    }, [mapType]);

    useEffect(() => {
        if (mapState?.data?.rawData) {
            setMapState((prevState) => {
                let arr = [];

                prevState.data?.rawData?.forEach((item) => {
                    let pinExist = arr.find(
                        (obj) =>
                            obj?.[pinsFilterState?.activePinFilter] ==
                            item?.[pinsFilterState?.activePinFilter]
                    );
                    if (!pinExist) {
                        item.dummyColor =
                            pinsFilterState?.activePinFilter === "group" ? item.color : getColor();
                        arr.push(item);
                    } else {
                        item.dummyColor = pinExist.dummyColor;
                    }
                });

                return { ...prevState };
            });
        }
    }, [pinsFilterState?.activePinFilter]);

    const getPinsBounds = () => {
        if (!props?.isNocUrl) {
            if (hiddenMarkers?.length) {
                mapState?.data?.rawData?.filter((pin) => {
                    if (
                        hiddenMarkers.some(
                            (hiddenMarker) =>
                                hiddenMarker?.[pinsFilterState.activePinFilter] ===
                                pin?.[pinsFilterState.activePinFilter]
                        )
                    ) {
                        return true;
                    }
                });
            } else {
                return mapState.data?.rawData;
            }
        } else {
            if (hiddenMarkers?.length) {
                mapState?.data?.rawData?.filter((pin) => {
                    if (
                        hiddenMarkers.some(
                            (prevMarker) =>
                                prevMarker?.pinColor === pin?.pinColor &&
                                prevMarker?.userEmail === pin.userEmail
                        )
                    ) {
                        return true;
                    }
                });
            } else {
                return mapState.data?.rawData;
            }
        }
    };

    const BoundingData = getPinsBounds();

    useEffect(() => {
        if (hiddenMarkers?.length === 0) {
            setMapBounds(mapState.map, BoundingData, zoomRef, window.google?.maps?.LatLngBounds);
        }
    }, [JSON.stringify(BoundingData), mapState.map, hiddenMarkers?.length]);

    useEffect(() => {
        resetFilters();

        if (mapDashboardId) {
            if (mapState.map) {
                restoreCallback({
                    ...mapState,
                    setMapState,
                    toggleMapHistory,
                    deleteAllShapes,
                });
            }
        }

        getSingleMapData(mapDashboardId);

        handleGroupHistory(false);

        resetMapDashboardOptionsState();

        setPinsFilterState({
            activePinFilter: "group",
            showList: false,
        });

        props.setActiveDrawing(null);

        setHiddenMarkers([]);

        clearShapes();
        setMapState((prevState) => ({
            ...prevState,
            searchText: "",
        }));
        return () => {
            deleteAllShapes();
        };
    }, [mapDashboardId, toggleRefresh]);

    useEffect(() => {
        const drawingBtnsWrapper = document.querySelector(".drawing-manager-wrapper");

        if (drawingBtnsWrapper) {
            if (props.showEmptyMap || mapType === 3) {
                drawingBtnsWrapper.style.pointerEvents = "none";
            } else {
                drawingBtnsWrapper.style.pointerEvents = "all";
            }
        }
    }, [props.showEmptyMap, mapType]);

    useEffect(() => {
        if (mapState.userLocation) {
            let data = mapState.data.rawData.map((element) => {
                let loc = {
                    lat: Number(element?.location?.lat),
                    lng: Number(element?.location?.lng),
                };

                return { location: loc };
            });
            data.push({ location: mapState.center });

            setMapBounds(mapState.map, data, false, window.google?.maps.LatLngBounds);
        }
    }, [mapState.userLocation]);

    const markers = useMemo(() => {
        return (
            mapState.data?.rawData?.filter(
                (data) =>
                    data?.name?.toLowerCase().includes(mapState.searchText) ||
                    data?.phone?.toLowerCase().includes(mapState.searchText) ||
                    data?.group?.toLowerCase().includes(mapState.searchText) ||
                    data?.address?.toLowerCase().includes(mapState.searchText) ||
                    data?.zip?.toLowerCase().includes(mapState.searchText) ||
                    data?.state?.toLowerCase().includes(mapState.searchText) ||
                    data?.city?.toLowerCase().includes(mapState.searchText)
            ) || []
        );
    }, [mapState.searchText, mapState.data?.rawData]);

    const hideBars = () => {
        let gmnoprint = document.getElementsByClassName("gmnoprint");
        document.getElementsByClassName("drawing-manager-wrapper")[0].style.display = "none";
        gmnoprint[0].style.display = "none";
        gmnoprint[4].style.display = "none";
        document.getElementsByClassName("gm-svpc")[0].style.display = "none";
        document.getElementsByClassName("gm-fullscreen-control")[0].style.display = "none";
        document.getElementsByClassName("mapView-options--container")[0].style.display = "none";
        document.getElementsByClassName("resize-container")[0].style.display = "none";
    };
    const showBars = () => {
        let gmnoprint = document.getElementsByClassName("gmnoprint");
        document.getElementsByClassName("drawing-manager-wrapper")[0].style.display = "flex";
        gmnoprint[0].style.display = "flex";
        gmnoprint[4].style.display = "flex";
        document.getElementsByClassName("gm-svpc")[0].style.display = "flex";
        document.getElementsByClassName("gm-fullscreen-control")[0].style.display = "flex";
        document.getElementsByClassName("mapView-options--container")[0].style.display = "flex";
        document.getElementsByClassName("resize-container")[0].style.display = "flex";
    };

    const [historyGroupDetails, setHistoryGroupDetails] = useState({
        showModal: false,
        title: "Unknown Group",
        groupOwner: null,
        mapOwner: null,

        markers: 0,
        modalLocation: {
            lat: 33.35892906592354,
            lng: -117.92311176357425,
        },
    });

    const handleDrawingHover = (drawing) => {
        let loc = calculateHistoryCenterPoint(drawing?.pathInfo);
        if (drawing?.group_id) {
            setHistoryGroupDetails((prev) => {
                return {
                    ...prev,
                    showModal: true,
                    title: drawing?.title,
                    markers: drawing?.groupCount || 0,
                    mapOwner: drawing?.map_owner || null,
                    groupOwner: drawing?.email || null,
                    modalLocation: loc,
                };
            });
        }
    };
    const handleDrawingHoverLeave = () => {
        setHistoryGroupDetails((prev) => {
            return {
                ...prev,
                showModal: false,
            };
        });
    };

    return mapState.loading || props.showEmptyMap ? (
        <Map
            componentProps={{
                mapStyle: mapState.data.mapStyle,
                center: mapState.center,
                containerStyle,
                maxZoomOut: zoomRef.current.maxZoomOut,
                defaultZoom: zoomRef.current.defaultZoom,
                maxZoomIn: zoomRef.current.maxZoomIn,
                onMapLoad: () => {},
                setContextOptions: setContextOptions,
                handleContextClick: handleContextClick,
            }}
        />
    ) : (
        <>
            {filterGroupState.disableFilters && (
                <div
                    style={{
                        position: "fixed",
                        inset: 0,
                        zIndex: 2,
                        backgroundColor: "rgba(0,0,0,0.5)",
                        display: "grid",
                        placeItems: "center",
                    }}
                >
                    <SpinLoader />
                </div>
            )}
            {props.parentState.pinsLoading ? (
                <LoadingAnimation />
            ) : (
                <Map
                    componentProps={{
                        mapStyle: mapState.data.mapStyle,
                        center: mapState.center,
                        containerStyle,
                        maxZoomOut: zoomRef.current.maxZoomOut,
                        defaultZoom: zoomRef.current.defaultZoom,
                        maxZoomIn: zoomRef.current.maxZoomIn,
                        onMapLoad,
                        setContextOptions: setContextOptions,
                        handleContextClick: handleContextClick,
                    }}
                >
                    {/* DRAWING MANAGER */}
                    <DrawingComponent onOverlayComplete={overlayWrapper(mapState.data.rawData)} />

                    {/* MARKERS */}
                    {!!mapState.data?.rawData?.length && (
                        <MapMarkers
                            showNumbers={mapType === 3}
                            data={markers}
                            groupContain={mapState.data?.groupContain}
                            hiddenMarkers={hiddenMarkers}
                            handleMarkerClick={handleMarkerClick}
                            markerStyle={mapState.data?.markerStyle}
                            map={mapState.map}
                            setMapState={setMapState}
                            pinsFilterState={pinsFilterState}
                            isNocUrl={props.isNocUrl}
                            nocGroupFilterMarkers={props.nocGroupFilterMarkers}
                            nocGroupFilterBarMarkers={props.nocGroupFilterBarMarkers}
                        />
                    )}

                    {/* --------------------- */}
                    {/* TRIANGULATION START */}
                    {/* --------------------- */}
                    {mapState.userLocation && (
                        <>
                            {/* USER PIN */}
                            <Marker
                                position={mapState.userLocation}
                                icon={{
                                    // url: `${GEO_MAPPING_BASE_URL}marker?type=default&size=15&fill=red`,
                                    url: "/userImage.png",
                                }}
                            ></Marker>

                            {/* POLYLINES FROM EVERYPOINT TO USERLOCATION */}
                            {mapState.data?.rawData?.map((item, index) => {
                                return (
                                    <Polyline
                                        key={index}
                                        path={[mapState.userLocation, item.location]}
                                        options={polylineOptions}
                                        onMouseOver={(e) => {
                                            let distance = (
                                                (distanceBetweenPointsV2(
                                                    mapState.userLocation,
                                                    item.location
                                                ) /
                                                    1609.344) *
                                                1000
                                            ).toFixed(2);

                                            const info = document.querySelector("#info");
                                            info.style.display = "block";
                                            info.style.left = `${e.domEvent.clientX + 10}px`;
                                            info.style.top = `${e.domEvent.clientY}px`;
                                            info.textContent = `${distance} miles`;
                                        }}
                                        onMouseOut={(e) => {
                                            const info = document.querySelector("#info");
                                            info.textContent = "";
                                            info.style.display = "none";
                                        }}
                                    />
                                );
                            })}
                        </>
                    )}

                    {mapState.data.overview_polyline && mapType === 3 && (
                        <Polyline
                            path={decodePolyline(mapState.data.overview_polyline)}
                            options={polylineOptions}
                        />
                    )}

                    {showKML &&
                        Object.keys(kmlGroupsRef.current).map((groupKey, index) => {
                            let color = colors[index];
                            return (
                                <Polygon
                                    key={groupKey}
                                    options={
                                        kmlGroupsRef.current[groupKey].polygonOptions || {
                                            ...drawingToolsOptions.rectangleOptions,
                                            fillColor: color,
                                            fillOpacity: 0.15,
                                            strokeColor: color,
                                        }
                                    }
                                    paths={kmlGroupsRef.current[groupKey].polygons}
                                />
                            );
                        })}

                    {/* --------------------- */}
                    {/* TRIANGULATION END */}
                    {/* --------------------- */}

                    {mapState.markerInfo && mapState.openMarkerInfo && (
                        <InfoWindow
                            onCloseClick={() =>
                                setMapState((prevState) => ({
                                    ...prevState,
                                    openMarkerInfo: false,
                                }))
                            }
                            position={loc}
                            zIndex={2}
                        >
                            <MarkerInfo
                                info={mapState.markerInfo}
                                setMapState={setMapState}
                                mapState={mapState}
                                getSingleMapData={getSingleMapData}
                                sharedMapState={props.sharedMapState}
                                handleMarkerClick={handleMarkerClick}
                                showUserBox={mapState?.showUserBox}
                                UserPlanData={UserPlanData}
                                totalUserGroups={totalUserGroups}
                                handleCloseMarkerModel={handleCloseMarkerModel}
                            />
                        </InfoWindow>
                    )}

                    {mapState.shapeType === "polyline" && !!mapState.shapeArea && (
                        <div className="drawing-detail">
                            <p>
                                <strong>Distance</strong> {mapState.shapeArea}
                            </p>
                        </div>
                    )}

                    {mapState.shapeType &&
                        !!mapState.shapeArea &&
                        mapState.shapeType !== "polyline" && (
                            <section className="create-group-container">
                                <form onSubmit={handleCreateGroup}>
                                    <div className="create-group-wrapper">
                                        <div className="title">
                                            <h5>Save</h5>
                                        </div>
                                        <p className="markerCount" style={{ textAlign: "left" }}>
                                            Successfully selected
                                            <strong> {mapState.selectedPins?.length} </strong>
                                            <strong>
                                                {mapState.selectedPins?.length === 1
                                                    ? " marker. "
                                                    : " markers. "}{" "}
                                            </strong>
                                        </p>
                                        <div
                                            className={`create-group-toggle-wrapper ${
                                                mapState.groupToggleactive ? "active" : ""
                                            } `}
                                            onClick={handleGroupToggle}
                                        >
                                            <button type="button">Container</button>
                                            <button type="button">My Routes</button>
                                        </div>

                                        <div className="input-box">
                                            <div>
                                                <label>Name</label>
                                                <span>{groupInputCount}/36</span>
                                            </div>
                                            <input
                                                type="text"
                                                name="group-name"
                                                id="group-name"
                                                autoComplete="off"
                                                placeholder="Type title"
                                                maxLength="36"
                                                onChange={(e) => countCharHandle(e.target.value)}
                                            />
                                        </div>

                                        <div className="form-actions">
                                            <button
                                                className="border-cancel-btn btn"
                                                type="button"
                                                onClick={handleCancelSaveGroup}
                                                disabled={createGroupLoading}
                                            >
                                                Cancel
                                            </button>

                                            {createGroupLoading ? (
                                                <button
                                                    className="btn animate-dots-blue cta-border-blue-auto"
                                                    type="button"
                                                    disabled
                                                >
                                                    <DotLoader />
                                                </button>
                                            ) : (
                                                <button
                                                    className="btn save"
                                                    type="submit"
                                                    disabled={groupInputCount ? false : true}
                                                >
                                                    Save
                                                </button>
                                            )}
                                        </div>
                                        <div className="create-group-note flex">
                                            <p>
                                                NOTE: Markers selected in previous containers will not
                                                appear in new containers within the same map.
                                            </p>
                                            <a href="#">Read More</a>
                                        </div>
                                    </div>
                                </form>
                            </section>
                        )}

                    {mapState.showError && (
                        <section className="selectedMarkerError">
                            <WarningIcon color="var(--clr-danger)" fill="#fff" />
                            <h3>{mapState.errorMessage}</h3>
                        </section>
                    )}
                    {historyGroupDetails?.showModal &&
                        (authUser?.email === historyGroupDetails?.mapOwner ||
                            authUser?.email === historyGroupDetails?.groupOwner) && (
                            <InfoWindow position={historyGroupDetails?.modalLocation}>
                                <GroupHistoryDetailsModal singleGroupData={historyGroupDetails} />
                            </InfoWindow>
                        )}

                    {!!drawingHistory.length &&
                        drawingHistory.reduce((init, drawing, index) => {
                            if (drawing.pathInfo?.bounds) {
                                init.push(
                                    <Rectangle
                                        key={`${drawing.pathInfo.shapeType}-${index}`}
                                        options={{
                                            ...drawingToolsOptions.rectangleOptions,
                                            fillColor: drawing?.groupColor,
                                            fillOpacity: 0.15,
                                            strokeColor: drawing?.groupColor,
                                        }}
                                        bounds={drawing.pathInfo.bounds}
                                        onLoad={(rectangle) => {
                                            addShapeToDrawingHistory(
                                                rectangle.getBounds?.(),
                                                drawing
                                            );
                                        }}
                                        onClick={handleDrawingHistoryClick(drawing)}
                                        onMouseOver={() => handleDrawingHover(drawing)}
                                        onMouseOut={handleDrawingHoverLeave}
                                    />
                                );
                            } else if (drawing.pathInfo?.path) {
                                init.push(
                                    <Polygon
                                        key={`${drawing.pathInfo.shapeType}-${index}`}
                                        options={{
                                            ...drawingToolsOptions.rectangleOptions,
                                            fillColor: drawing?.groupColor,
                                            fillOpacity: 0.15,
                                            strokeColor: drawing?.groupColor,
                                        }}
                                        path={drawing.pathInfo.path}
                                        onLoad={(polygon) => {
                                            let bounds = new google.maps.LatLngBounds();

                                            polygon
                                                .getPath()
                                                .getArray()
                                                .forEach((latlng) => {
                                                    bounds.extend(latlng);
                                                });

                                            addShapeToDrawingHistory(bounds, drawing);
                                        }}
                                        onClick={handleDrawingHistoryClick(drawing)}
                                        onMouseOver={() => handleDrawingHover(drawing)}
                                        onMouseOut={handleDrawingHoverLeave}
                                    />
                                );
                            }
                            if (drawing.pathInfo?.pathsArr) {
                                drawing.pathInfo?.pathsArr?.forEach((paths) => {
                                    init.push(
                                        <Polygon
                                            key={`${drawing.pathInfo.shapeType}-${index}`}
                                            options={
                                                drawing.pathInfo?.drawingOptions || {
                                                    ...drawingToolsOptions.rectangleOptions,
                                                    fillColor: drawing?.groupColor,
                                                    fillOpacity: 0.15,
                                                    strokeColor: drawing?.groupColor,
                                                }
                                            }
                                            paths={paths}
                                            onLoad={(polygon) => {
                                                let bounds = new google.maps.LatLngBounds();

                                                polygon
                                                    .getPath()
                                                    .getArray()
                                                    .forEach((latlng) => {
                                                        bounds.extend(latlng);
                                                    });

                                                addShapeToDrawingHistory(bounds, drawing);
                                            }}
                                            onMouseOver={() => handleDrawingHover(drawing)}
                                            onMouseOut={handleDrawingHoverLeave}
                                            onClick={handleDrawingHistoryClick(drawing)}
                                        />
                                    );
                                });
                            }
                            return init;
                        }, [])}
                    <section>
                        <MapViewDashboardOptions
                            collapseBarHandle={props.collapseBarHandle}
                            collapseBar={props.collapseBar}
                            setCollapseBar={props.setCollapseBar}
                            mapDashboardOptionsState={mapDashboardOptionsState}
                            handleMapSetting={handleMapSetting}
                            handleMapLayer={handleMapLayer}
                            toggleMapHistory={toggleMapHistory}
                            handleMapFilter={handleMapFilter}
                            handleShowPDF={handleShowPDF}
                            kmlGroupsRef={kmlGroupsRef}
                            toggleTimeZoneFilter={toggleTimeZoneFilter}
                            toggleStateFilter={toggleStateFilter}
                            handleSaveFilterGroup={handleSaveFilterGroup}
                            filterGroupState={filterGroupState}
                            resetFilters={resetFilters}
                            setContextOptions={setContextOptions}
                            showGroupState={showGroupState}
                            showState={showState}
                            showBars={showBars}
                            hideBars={hideBars}
                            setMapDashboardOptionsState={setMapDashboardOptionsState}
                        />

                        <GoogleMapResizer />
                    </section>

                    {!!mapState.data.rawData && (
                        <MapGroups
                            markerStyle={mapState?.data?.markerStyle}
                            mapData={mapState.data}
                            groupContain={mapState.data?.groupContain}
                            isNocUrl={props.isNocUrl}
                            data={mapState.data.rawData}
                            hiddenMarkers={hiddenMarkers}
                            setHiddenMarkers={setHiddenMarkers}
                            pinsFilterState={pinsFilterState}
                            mapDataheaders={mapDataheaders}
                            setPinsFilterState={setPinsFilterState}
                        />
                    )}
                    {(!mapState.loading || !props.showEmptyMap) && (
                        <div
                            className="context-map-options"
                            style={{
                                display: contextOptions?.showOptionModel ? "block" : "none",
                                top: contextOptions?.TopY,
                                left: contextOptions?.LeftX,
                            }}
                        >
                            <DashboardSettingOptionsModel
                                setContextOptions={setContextOptions}
                                showState={showState}
                                showGroupState={showGroupState}
                                showBars={showBars}
                                hideBars={hideBars}
                                handleShowPDF={handleShowPDF}
                                handleContextClick={handleContextClick}
                            />
                        </div>
                    )}
                </Map>
            )}
            <PinDetails
                mapState={mapState}
                setMapState={setMapState}
                hiddenMarkers={hiddenMarkers}
                pinsFilterState={pinsFilterState}
                showHistory={mapDashboardOptionsState.showHistory}
            />

            <Footer />
        </>
    );
}

export default DashboardMap;
