import React from "react";
import { useDispatch } from "react-redux";
import { setGroupType, setMapType } from "../../store/slicers/userMapSlice";

function CustomSpeedoMeter({
    title = "My Maps",
    active,
    valueMapType,

    showEmptyMap,
    data,
}) {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(setMapType(valueMapType));
        if (valueMapType === 4) {
            dispatch(setGroupType("group"));
        } else {
            dispatch(setGroupType(null));
        }
    };

    return (
        <button
            style={{ pointerEvents: `${showEmptyMap && !data ? "none" : ""}` }}
            className={`dashboard-map-menu--item ${active ? "active" : ""}`}
            onClick={handleClick}
        >
            <p> {title}</p>
        </button>
    );
}

export default CustomSpeedoMeter;
