import React, { useRef, useState } from "react";
import sendIcon from "../../assets/images/svg/send-icon.svg";
import closeIconGrey from "../../assets/images/svg/closeIconGrey.svg";

import searchGreyIcon from "../../assets/images/svg/searchGreyIcon.svg";
import "./notification.css";
import NotificationUser from "./NotificationUser";
import { useGetSystemNotificationsQuery } from "../../services/baseApi";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { errorMessage } from "../../utils/messages";
import {
    getAllNotifications,
    getNotificationsError,
    getNotificationsLoading,
    setShowSystemNotificationModel,
} from "../../store/slicers/systemNotificationsSlice";
import SpinLoader from "../../components/SpinLoader/SpinLoader";
import { debounce, filterdData } from "../../utils/utils";
import ActionTabsList from "../../components/ActionTabsList/ActionTabsList";
const tabsList = [
    {
        label: "All",
        id: "all",
    },
    {
        label: "Read",
        id: "read",
    },
    {
        label: "Unread",
        id: "unread",
    },
];

const Notifications = (props) => {
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const Notifications = useSelector(getAllNotifications);
    const NotificationsError = useSelector(getNotificationsError);
    const NotificationsLoading = useSelector(getNotificationsLoading);
    const [systemNotifications, setSystemNotifications] = useState({
        allNotifications: [],
        allError: null,
        isLoading: false,
    });
    const { systemNotifcationsFilter, setSystemNotifcationsFilter } = props;
    const [searchState, setSearchState] = useState("");
    const [activeTab, setActiveTab] = useState("all");
    const [checkSearchQueryRun, setCheckSearchQueryRun] = useState(false);
    const handleTabClick = (id) => () => {
        if (id === "read") {
            setSystemNotifcationsFilter((prevState) => {
                return {
                    ...prevState,
                    filter: 2,
                };
            });
        } else if (id === "unread") {
            setSystemNotifcationsFilter((prevState) => {
                return {
                    ...prevState,
                    filter: 3,
                };
            });
        } else {
            setSystemNotifcationsFilter((prevState) => {
                return {
                    ...prevState,
                    filter: 1,
                };
            });
        }
        setSystemNotifcationsFilter((prevState) => {
            return {
                ...prevState,
                pageNum: 1,
            };
        });
        // setTimeout(() => {
        setActiveTab(id);
        // }, 300);
    };

    const handleScrollLeft = () => {
        setSystemNotifcationsFilter((prevState) => {
            return {
                ...prevState,
                pageNum: prevState?.pageNum - 1,
            };
        });
    };
    const handleScrollRight = () => {
        setSystemNotifcationsFilter((prevState) => {
            return {
                ...prevState,
                pageNum: prevState?.pageNum + 1,
            };
        });
    };

    useEffect(() => {
        if (searchState) {
            // if (checkSearchQueryRun) {
            setSystemNotifcationsFilter((prevState) => {
                return {
                    ...prevState,
                    search: searchState,
                };
            });
            // setCheckSearchQueryRun((prevState) => !prevState);
            // }
        } else {
            // setSearchState("");
            setSystemNotifcationsFilter((prevState) => {
                return {
                    ...prevState,
                    search: "",
                };
            });
        }
    }, [setSystemNotifcationsFilter, searchState, setSearchState]);

    //////pagination Notifications and Filterations of tabs and search Bar ///////

    //  filterdData(
    //     Notifications?.filteredNotifications,
    //     searchState,
    //     checkSearchQueryRun,
    //     setCheckSearchQueryRun
    // );
    // const filterNotificationsToShow = filterdData(
    //     Notifications?.filteredNotifications,
    //     searchState,
    //     checkSearchQueryRun,
    //     setCheckSearchQueryRun
    // );
    ///pagination
    const itemsPerPage = 20;
    const totalItems = Notifications?.totalNotificationsCount;
    const startIndex = (systemNotifcationsFilter?.pageNum - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const itemsOnRight = endIndex > totalItems ? totalItems : endIndex;
    const filterNotificationsToShow = Notifications?.paginatedUsers;

    //////pagination Notifications and Filterations of tabs and search Bar ///////

    useEffect(() => {
        if (NotificationsLoading) {
            setSystemNotifications((prevState) => {
                return {
                    ...prevState,
                    isLoading: true,
                };
            });
        }
        if (Notifications?.filteredNotifications?.length) {
            setSystemNotifications((prevState) => {
                return {
                    ...prevState,
                    allNotifications: Notifications?.filteredNotifications,
                };
            });
        }

        if (NotificationsError) {
            errorMessage(NotificationsError?.data?.message);
        }
        if (!NotificationsLoading) {
            setSystemNotifications((prevState) => {
                return {
                    ...prevState,
                    isLoading: false,
                };
            });
        }
    }, [Notifications, NotificationsLoading, NotificationsError]);

    return (
        <div className="notification-container">
            {/* NotificationBox */}
            <div className="notification-wrapper">
                {/* <form onSubmit={shareMapEmail}> */}
                <div className="notification-header">
                    {/* closeIcon */}
                    <div className="share-close-icon">
                        <button
                            type="button"
                            className="share-close-icon"
                            aria-label="Close"
                            onClick={() => dispatch(setShowSystemNotificationModel(false))}
                        >
                            <img src={closeIconGrey} alt="search move pin" />
                        </button>
                    </div>
                    {/* closeIcon */}
                    <h6>Notifications</h6>
                    <div className="movePin-search-box">
                        <input
                            type="email"
                            name="myMapListSearch"
                            value={searchState ? searchState : ""}
                            placeholder="Search..."
                            onChange={
                                (e) => {
                                    let value = e.target.value.trim().toLowerCase();
                                    setSearchState(value);
                                }

                                // debounce((e) => {
                                // }, 1000)
                            }
                            autoComplete="off"
                        />
                        {searchState ? (
                            <img
                                onClick={(e) => {
                                    e.preventDefault();
                                    setSearchState("");
                                }}
                                src={closeIconGrey}
                                alt="search move pin"
                                style={{ width: 20, top: 8, cursor: "pointer" }}
                            />
                        ) : (
                            <img src={searchGreyIcon} alt="search move pin" />
                        )}
                    </div>
                </div>
                {/* header */}
                <div>
                    <ActionTabsList
                        tabsList={tabsList}
                        activeTab={activeTab}
                        handleTabClick={handleTabClick}
                    />
                </div>
            </div>
            {systemNotifications?.isLoading ? (
                <div className="notification-Loader">
                    <SpinLoader />
                </div>
            ) : (
                <div className="notifications-user-parent-container">
                    {filterNotificationsToShow?.length === 0 ? (
                        <h5 className="no-data flex-center">No results found</h5>
                    ) : (
                        filterNotificationsToShow?.map((item, index) => {
                            return (
                                <NotificationUser
                                    loadMore={systemNotifications?.loadMore}
                                    index={index}
                                    key={index}
                                    item={item}
                                    user={user}
                                />
                            );
                        })
                    )}
                </div>
            )}
            <section className="notification-footer-container">
                <div className="notification-footer-wrapper">
                    <button
                        className="btn"
                        onClick={handleScrollLeft}
                        disabled={systemNotifcationsFilter?.pageNum === 1}
                    >
                        <i className="fa fa-chevron-left"></i>
                    </button>
                    <div className="pagination-indexes">
                        <p>
                            {startIndex} - {itemsOnRight ? itemsOnRight : 0}
                            &nbsp;of {totalItems ? totalItems : 0}
                        </p>
                    </div>

                    <button
                        className="btn"
                        onClick={handleScrollRight}
                        disabled={
                            systemNotifcationsFilter?.pageNum >
                            Math.ceil(totalItems / itemsPerPage) - 1
                        }
                    >
                        <i className="fa fa-chevron-right"></i>
                    </button>
                </div>
            </section>

            {/* NotificationBox */}
        </div>
    );
};

export default Notifications;
