import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: 0,
};

export const mapLayerSlice = createSlice({
    name: "mapLayerType",
    initialState,
    reducers: {
        setMapLayerType: (state, { payload }) => {
            state.value = payload;
        },
    },
});

export const { setMapLayerType } = mapLayerSlice.actions;

export default mapLayerSlice.reducer;
