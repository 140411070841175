import React, { useState } from "react";
import DotLoader from "../DotLoaderBtn/DotLoader";
import { capitalizeString, debounce } from "../../utils/utils";
import { useSelector } from "react-redux";
import { useShareETALocationMutation } from "../../services/baseApi";
import { successMessage, warningMessage } from "../../utils/messages";
import closeIconGrey from "../../assets/images/svg/closeIconGrey.svg";

const ShareETAModal = (props) => {
    const { setMarkerInfoState, pinInfo, routeId, markerInfoState } = props;
    // const authUser = useSelector((state) => state.auth.user);

    const handleClose = () => {
        setMarkerInfoState((prev) => {
            return {
                ...prev,
                showShareETAModal: !prev.showShareETAModal,
            };
        });
    };

    const [shareETALocation] = useShareETALocationMutation();
    // console.log("authUser: ", authUser);

    const handleShareETA = async (e) => {
        e.preventDefault();
        setMarkerInfoState((prev) => {
            return {
                ...prev,
                isShareETALoading: true,
            };
        });
        const { data, error } = await shareETALocation({
            id: routeId,
            pinId: pinInfo?.id,
            email: markerInfoState?.shareETAEmail,
        });
        if (data) {
            warningMessage(data?.message);
            setMarkerInfoState((prev) => {
                return {
                    ...prev,
                    isShareETALoading: false,
                    showShareETAModal: false,
                };
            });
        }
        if (error) {
            successMessage(error?.data?.message);
            setMarkerInfoState((prev) => {
                return {
                    ...prev,
                    isShareETALoading: false,
                    showShareETAModal: false,
                };
            });
        }
    };

    return (
        <section className="delete-modal-wrapper">
            <div className="text-right">
                <button
                    className="deleteMarker-modal-close"
                    onClick={() => handleClose()}
                    disabled={markerInfoState?.isShareETALoading}
                >
                    <img src={closeIconGrey} alt="close icon" />
                </button>
            </div>
            <h5 className="delete-modal-wrapper-title share_eta_title ">Share Location</h5>
            <div style={{ marginBottom: 15 }}>
                <p className="content share_eta_content">
                    You have shared marker location: <br />
                    <b className="share_bold_title">
                        {capitalizeString(pinInfo?.address)}, {capitalizeString(pinInfo?.city)},{" "}
                        {pinInfo?.state}, {capitalizeString(pinInfo?.zip)}{" "}
                    </b>{" "}
                    <p>Enter user's email</p>
                </p>
            </div>
            <div className="delete-modal-actions">
                <form onSubmit={(e) => handleShareETA(e)}>
                    <div className="input-fields">
                        <div>
                            <input
                                className="share_eta_input"
                                type="text"
                                name="group titile"
                                autoComplete="off"
                                placeholder="Email"
                                onChange={debounce(
                                    (e) =>
                                        setMarkerInfoState((prevState) => {
                                            return {
                                                ...prevState,
                                                shareETAEmail: e.target.value,
                                            };
                                        }),
                                    500
                                )}
                            />
                        </div>
                    </div>
                    <div className="buttons">
                        <button
                            type="button"
                            onClick={() => handleClose()}
                            aria-label="Close"
                            className="btn border-cancel-btn"
                            disabled={markerInfoState?.isShareETALoading}
                        >
                            Cancel
                        </button>
                        {/* {updateGroupState.loading ? ( */}
                        {markerInfoState?.isShareETALoading ? (
                            <button
                                className="btn animate-dots-blue cta-border-blue-auto"
                                type="button"
                                disabled
                                onClick={(e) => handleShareETA(e)}
                            >
                                <DotLoader />
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className="btn cta-filled-blue-auto"
                                disabled={!markerInfoState?.shareETAEmail}
                            >
                                Share
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </section>
    );
};

export default ShareETAModal;
