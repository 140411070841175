import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mapType: 1,
    groupType: null,
};

export const userMapSlice = createSlice({
    name: "mapType",
    initialState,
    reducers: {
        setMapType: (state, { payload }) => {
            state.mapType = payload;
        },
        setGroupType: (state, { payload }) => {
            state.groupType = payload;
        },
    },
});

export const { setMapType, setGroupType } = userMapSlice.actions;

export default userMapSlice.reducer;
