import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    connectedUsers: {
        paginatedUsers: [],
        totalUsersCount: null,
    },
    allUsers: [],
};

export const usersListSlice = createSlice({
    name: "usersList",
    initialState,
    reducers: {
        // setConnectedUsers: (state, action) => {
        //     state.connectedUsers = action.payload;
        // },
        setConnectedUsers: (state, { payload }) => {
            state.connectedUsers = payload;
        },
        setAllUsers: (state, { payload }) => {
            state.allUsers.push(...payload);
        },
    },
});

export const { setConnectedUsers, setAllUsers } = usersListSlice.actions;
export const getAllConnectedUsers = (state) => state.usersList;

export default usersListSlice.reducer;
