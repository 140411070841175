import React from "react";
import imageBG from "../../assets/images/Auth/signin-success.png";
import closeIcon from "../../assets/images/svg/closeIconGrey.svg";

const ThankyouRegistered = (props) => {
    return (
        <>
            <section className="signup-success--container">
                <div className="signup-success--wrapper">
                    <div className="img-wrapper">
                        <img src={imageBG} alt="thankyou registered" />
                    </div>
                    <div className="content-wrapper">
                        <button
                            onClick={(e) => {
                                props.setRegisterState((prevState) => {
                                    return {
                                        ...prevState,
                                        success: false,
                                        error: false,
                                    };
                                });
                                e.preventDefault();
                            }}
                            className="signUp-modal-close"
                        >
                            <img src={closeIcon} alt="close icon" />
                        </button>

                        {props.registerState.error ? (
                            <>
                                {" "}
                                <h2>Email verification</h2>
                                <p>Please go to your email and verify account.</p>
                                <p>Didn't receive email?</p>
                            </>
                        ) : (
                            <>
                                <h2 style={{ marginBottom: 15 }}>Verify email</h2>
                                <p style={{ lineHeight: "10px" }}>
                                    We just sent a verification email to
                                </p>
                                <p>
                                    <b>{props.registerState?.email}.</b>
                                    {/* <b>hamza@gmail.com</b>. */}
                                </p>
                                <p>
                                    Follow the link in your email to complete sign up. Please check
                                    your spam folder if you haven’t received our email.
                                </p>
                                <p>Didn't receive email?</p>
                            </>
                        )}

                        <button
                            className="forget-recover-cta mt-10"
                            onClick={props.handleSendVerificationAgain}
                        >
                            Send again
                        </button>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ThankyouRegistered;
