import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMapDashboardId } from "../../../store/slicers/mapDashboardSlice";
import userIcon from "../../../assets/images/avatar-grey.png";
import closeIcon from "../../../assets/images/svg/closeIconGrey.svg";
import rightChevron from "../../../assets/images/svg/chevron-right-poiner.svg";
import starGrey from "../../../assets/images/star-grey.png";
import starGolden from "../../../assets/images/star-golden.png";
import noImage from "../../../assets/images/noImage.png";
import ImageListView from "../../../components/ImageListView/ImageListView";
import DotLoader from "../../../components/DotLoaderBtn/DotLoader";
import EditIcon from "../../../components/icons/EditIcon";
import TrashIcon from "../../../components/icons/TrashIcon";
import ShareIcon from "../../../components/icons/ShareIcon";

const SharedMapGroups = (props) => {
    const {
        sharedState,
        setSharedState,
        modelOpen,
        modelClose,
        subGroup,
        GetSharedMapGroupGroups,
        handleShare,
        setModalMapTitle,
        handleEdit,
        handleToggleMapDelete,
    } = props;
    const dispatch = useDispatch();

    const mapDashboardId = useSelector((state) => state.mapDashboardState.id);
    const handleGroupDataClick = (id, mapId, parentGroup) => {
        dispatch(setMapDashboardId(id));
        setSharedState((prevState) => {
            return {
                ...prevState,
                activeGroupGroupID: null,
                parentId: mapId,
                isGroupSelected: true,
                parentGroup: parentGroup,
            };
        });
    };

    const handleSharedMapGroupGroups = (e, id, ownerId) => {
        e.stopPropagation();
        e.preventDefault();
        setSharedState((prevState) => {
            return {
                ...prevState,
                activeGroupGroupID: id,
            };
        });
        GetSharedMapGroupGroups(id);
        modelOpen();
    };

    return (
        <>
            <section className={`myMapGroupList-container ${subGroup ? "secondPane" : ""}`}>
                <div className="myMapGroupList-title">
                    <h5 className="mapTitle">Containers Included</h5>
                    <img src={closeIcon} alt="close icon" onClick={modelClose} />
                </div>
                {sharedState.isSharedLoading ? (
                    <div className="null-data-response animate-dots-blue">
                        <DotLoader />
                    </div>
                ) : sharedState?.sharedGroups?.length ? (
                    <div className="myMapGroups-data-container">
                        {sharedState?.sharedGroups?.map((data, index) => {
                            return (
                                <div
                                    className={`myMapGroups-data-item ${
                                        mapDashboardId === data._id ||
                                        sharedState?.activeGroupGroupID === data._id ||
                                        sharedState?.parentGroup === data?._id
                                            ? "active"
                                            : ""
                                    }`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleGroupDataClick(
                                            data?._id,
                                            data?.map_id,
                                            data?.parent_group
                                        );
                                    }}
                                    style={{ zIndex: sharedState?.sharedGroups?.length - index }}
                                    key={index}
                                >
                                    <div
                                        className={`myMapGroups-data-item-inner ${
                                            mapDashboardId === data?._id ? "active" : ""
                                        } ${
                                            // activeDrawing?.group_id === data._id
                                            false ? "active-history" : ""
                                        }`}
                                        id={data._id}
                                    >
                                        <div className="myMapGroups-img">
                                            <img
                                                src={data.favorite ? starGolden : starGrey}
                                                className="fav-star"
                                                alt="Favourite Map"
                                                title="Mark as favorite"
                                                // onClick={(e) => {
                                                //     e.stopPropagation();
                                                //     myMapFavHandle(
                                                //         data._id,
                                                //         !data.favorite,
                                                //         "groups"
                                                //     );
                                                //     setTimeout(() => {
                                                //         getMyMapsGroups(
                                                //             props.showState.activeMapGroupID
                                                //         );
                                                //     }, 1000);
                                                // }}
                                            />

                                            <div className="myGroup-tooltip--container">
                                                {" "}
                                                <img
                                                    src={data?.mapImage || noImage}
                                                    className="myMapGroups-list-img"
                                                    alt="map-list"
                                                />
                                                {/* <div className="myGroup-tooltip-icon">
                                                    <img src={tooltipWhite} alt="Tool tip" />
                                                    <span className="tooltiptext">
                                                        Created on: February 8, 2023
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="myMapGroups-data">
                                            <div className="myMapGroups-title-wrapper">
                                                <h5>{data.title}</h5>
                                            </div>
                                            {data.shared?.length ? (
                                                <div
                                                    className="myMapGroups-people-shared"
                                                    // onClick={(e) => {
                                                    //     e.stopPropagation();
                                                    //     handleHideMyGroups();
                                                    //     handleShowMyGroupsShared();
                                                    //     handleGroupSharedData(data._id);
                                                    // }}
                                                >
                                                    <ImageListView data={data?.shared} />
                                                </div>
                                            ) : (
                                                <div className="no-shared-user-wrapper">
                                                    <img src={userIcon} alt="user" />
                                                </div>
                                            )}

                                            <div className="myMapList-views">
                                                <button
                                                    onClick={(e) =>
                                                        handleSharedMapGroupGroups(
                                                            e,
                                                            data?._id,
                                                            data?.map_id
                                                        )
                                                    }
                                                >
                                                    Container
                                                    <img src={rightChevron} alt="move arrow" />
                                                </button>
                                            </div>
                                        </div>

                                        <div className="myMap-config">
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();

                                                    handleShare(
                                                        data?._id,
                                                        data?.shared,
                                                        "share-group"
                                                    );
                                                    setModalMapTitle(data.title);
                                                    modelClose();
                                                }}
                                            >
                                                <ShareIcon />
                                            </button>
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleEdit(data?._id, "group", data?.email);
                                                }}
                                            >
                                                <EditIcon />
                                            </button>
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleToggleMapDelete(
                                                        data._id,
                                                        data?.map_id,
                                                        data?.parent_group,
                                                        "group"
                                                    );
                                                    setModalMapTitle(data.title);
                                                }}
                                            >
                                                <TrashIcon />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <p className="null-data-response">No results found</p>
                )}
            </section>
        </>
    );
};

export default SharedMapGroups;
