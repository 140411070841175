import React, { memo, useState } from "react";
import chevronDown from "../../assets/images/svg/chevronDown.svg";
import chevronUp from "../../assets/images/chevronUp.png";
import { capitalizeString } from "../../utils/utils";

const MapsTabularData = (props) => {
    const { data, triangulation, table, flexRender, showHistory } = props;
    if (!data.length) {
        return "No result found";
    }

    return (
        <section className="mapTabular-TableView--container">
            <div className="table-container">
                <table className="table mapData-tabular--table">
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <th key={header.id} colSpan={header.colSpan}>
                                            {header.isPlaceholder ? null : (
                                                <div
                                                    {...{
                                                        className: header.column.getCanSort()
                                                            ? "cursor-pointer select-none"
                                                            : "",
                                                        onClick:
                                                            header.column.getToggleSortingHandler(),
                                                    }}
                                                >
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                    {{
                                                        asc: (
                                                            <img
                                                                src={chevronUp}
                                                                alt="ascending icon"
                                                            />
                                                        ),
                                                        desc: (
                                                            <img
                                                                src={chevronDown}
                                                                alt="descending icon"
                                                            />
                                                        ),
                                                    }[header.column.getIsSorted()] ?? (
                                                        <img src={chevronDown} alt="default icon" />
                                                    )}
                                                </div>
                                            )}
                                        </th>
                                    );
                                })}

                                {triangulation && <th>Distance</th>}
                            </tr>
                        ))}
                    </thead>

                    <tbody>
                        {table.getRowModel().rows?.map((row, i) => {
                            return (
                                <tr
                                    style={{
                                        backgroundColor:
                                            row?.original?.groupColor !== undefined && showHistory
                                                ? `rgba${row?.original?.groupColor?.slice(
                                                      3,
                                                      -1
                                                  )}, ${0.4})`
                                                : " ",
                                    }}
                                    key={row.id}
                                >
                                    {row.getVisibleCells()?.map((cell) => {
                                        return (
                                            <td key={cell.id}>
                                                {capitalizeString(cell.getContext().getValue()) || (
                                                    <span
                                                        style={{
                                                            textAlign: "center",
                                                            width: "100%",
                                                            display: "inline-block",
                                                        }}
                                                    >
                                                        ---
                                                    </span>
                                                )}
                                            </td>
                                        );
                                    })}

                                    {triangulation && <td>{data[i].distanceFromUser} miles</td>}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>
    );
};

export default MapsTabularData;
