import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id: null,
    currentMap: null,
    resetMapId: null,
};

export const mapIdSlice = createSlice({
    name: "mapDashboardId",
    initialState,
    reducers: {
        setMapDashboardId: (state, action) => {
            state.id = action.payload;
        },
        setCurrentMapDashboardId: (state, action) => {
            state.currentMap = action.payload;
        },
        setResetMapId: (state, action) => {
            state.resetMapId = action.payload;
        },
    },
});

export const { setMapDashboardId, setCurrentMapDashboardId, setResetMapId } = mapIdSlice.actions;

export default mapIdSlice.reducer;
