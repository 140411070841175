/* global google */
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import { InfoWindow, Polyline } from "@react-google-maps/api";
import { polylineOptions, setMapBounds } from "../../utils/mapUtils";
import Map from "../../components/Map/Map";
import MapMarkers from "../../components/Map/MapMarkers";
import MarkerInfo from "../../components/Map/MarkerInfo";
import MapGroups from "../../components/MapGroups/MapGroups";
import PinDetails from "../dashboard/myMaps/PinDetails";
import { errorMessage } from "../../utils/messages";
import {
    useLazyGetPublicViewRouteQuery,
    usePublicViewMapOrGroupMutation,
} from "../../services/baseApi";
import "../../assets/css/publicview.module.css";
import decodePolyline from "decode-google-map-polyline";
import DotLoader from "../../components/DotLoaderBtn/DotLoader";
import { deepCopy, getColor } from "../../utils/utils";
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation";

const PublicView = () => {
    let { type, id } = useParams();

    const [publicViewLoading, setPublicViewLoading] = useState(false);
    const [mapAccess, setMapAccess] = useState(null);
    const [pinsFilterState, setPinsFilterState] = useState({
        activePinFilter: "group",
        showList: false,
    });

    const zoomRef = useRef({
        maxZoomOut: 2,
        defaultZoom: 3,
        maxZoomIn: 24,
    });

    const [hiddenMarkers, setHiddenMarkers] = useState([]);

    const [mapState, setMapState] = useState(() => ({
        center: { lat: 40.756795, lng: -73.954298 },
        data: {},
        mapData: {},
        errorMessage: "",
        markerInfo: "",
        map: null,
        maps: null,
        openMarkerInfo: false,
        selectedPins: [],
        alreadySelectedPins: [],
        userLocation: null,
        triangulation: false,
        rowsToShow: null,
        searchText: "",
        sortBy: "",
        shapeArea: null,
        shapeType: "",
        moveMarker: "show",
        showBox: "block",
        publicViewPassword: "",
        passwordVerified: false,
    }));

    const [publicViewMapOrGroup] = usePublicViewMapOrGroupMutation();
    const [getPublicViewRoute] = useLazyGetPublicViewRouteQuery();

    useEffect(() => {
        (async () => {
            if (type === "map" || type === "group") {
                setPublicViewLoading(true);
                const { data, error } = await publicViewMapOrGroup({
                    id: id,
                    type: type,
                });
                if (data) {
                    setMapState((prevState) => {
                        let newState = {
                            ...prevState,
                            data: deepCopy(data.data),
                            markerInfo: "",
                            openMarkerInfo: false,
                            mapData: data.mapData,
                        };
                        return newState;
                    });
                }

                if (error) {
                    setMapState((prevState) => ({
                        ...prevState,
                        markerInfo: "",
                        openMarkerInfo: false,
                    }));

                    error.data.access === "unlisted" && setMapAccess("unlisted");
                    error.data.access === "password" && setMapAccess("password");
                }
                setPublicViewLoading(false);
            } else if (type === "route") {
                setPublicViewLoading(true);
                const { data, error } = await getPublicViewRoute(id);
                if (data) {
                    // setMapAccess(data?.mapData?.access);
                    setMapState((prevState) => {
                        let newState = {
                            ...prevState,
                            data: data.data,
                            markerInfo: "",
                            openMarkerInfo: false,
                            mapData: data.mapData,
                        };

                        return newState;
                    });
                }

                if (error) {
                    setMapState((prevState) => ({
                        ...prevState,
                        markerInfo: "",
                        openMarkerInfo: false,
                    }));
                    error.data.message === "Map is unlisted. Public View is not available" &&
                        setMapAccess("unlisted");
                    error.data.message === "Map is password protected. Password is required" &&
                        setMapAccess("password");
                }
                setPublicViewLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        if (mapState?.data?.rawData) {
            setMapState((prevState) => {
                let arr = [];

                prevState?.data?.rawData?.forEach((item) => {
                    let pinExist = arr.find(
                        (obj) =>
                            obj?.[pinsFilterState?.activePinFilter] ===
                            item?.[pinsFilterState?.activePinFilter]
                    );
                    if (!pinExist) {
                        item.dummyColor =
                            pinsFilterState?.activePinFilter === "group" ? item.color : getColor();
                        arr.push(item);
                    } else {
                        item.dummyColor = pinExist.dummyColor;
                    }
                });

                return { ...prevState };
            });
        }
    }, [pinsFilterState?.activePinFilter]);

    const markers = useMemo(() => {
        return (
            mapState.data?.rawData?.filter(
                (data) =>
                    data?.name?.toLowerCase().includes(mapState.searchText) ||
                    data?.phone?.toLowerCase().includes(mapState.searchText) ||
                    data?.group?.toLowerCase().includes(mapState.searchText) ||
                    data?.address?.toLowerCase().includes(mapState.searchText) ||
                    data?.zip?.toLowerCase().includes(mapState.searchText) ||
                    data?.state?.toLowerCase().includes(mapState.searchText) ||
                    data?.city?.toLowerCase().includes(mapState.searchText)
            ) || []
        );
    }, [mapState.searchText, mapState.data?.rawData?.length]);

    const handleSubmiPublicPassword = async (e) => {
        e.preventDefault();
        setPublicViewLoading(true);
        const { data, error } = await publicViewMapOrGroup({
            id: id,
            type: type,
            password: mapState.publicViewPassword,
        });
        if (data) {
            setMapState((prevState) => {
                let newState = {
                    ...prevState,
                    data: data.data,
                    markerInfo: "",
                    openMarkerInfo: false,
                    mapData: data.mapData,
                    passwordVerified: true,
                };

                return newState;
            });
        }

        if (error) {
            setMapState((prevState) => ({
                ...prevState,
                markerInfo: "",
                openMarkerInfo: false,
            }));

            errorMessage("Incorrect password");
            // errorMessage(error.data.message);
        }
        setPublicViewLoading(false);
    };

    if (mapAccess === "unlisted") {
        return (
            <div className="unlisted-publicview-wrapper">
                <h6>Map preview does not exist</h6>
            </div>
        );
    }

    if (mapAccess === "password" && !mapState.passwordVerified) {
        return (
            <div className="password-publicview-wrapper">
                <form onSubmit={handleSubmiPublicPassword}>
                    <h2>Enter Password to view</h2>
                    <div className="inner-wrapper">
                        <input
                            type="password"
                            aria-label="password"
                            autoComplete="off"
                            onChange={(e) =>
                                setMapState((prevState) => {
                                    return {
                                        ...prevState,
                                        publicViewPassword: e.target.value.trim(),
                                    };
                                })
                            }
                            required
                        />
                        {publicViewLoading ? (
                            <button
                                className="btn animate-dots-blue view-cta"
                                type="button"
                                disabled
                            >
                                <DotLoader />
                            </button>
                        ) : (
                            <button type="submit" className="view-cta">
                                View
                            </button>
                        )}
                    </div>
                </form>
            </div>
        );
    }

    const handleMarkerClick = (e, place) => {
        setMapState((prevState) => ({
            ...prevState,
            markerInfo: place,
            openMarkerInfo: !prevState.openMarkerInfo,
        }));
    };

    function setMapZoom(map, ref) {
        ref.current.defaultZoom = map.zoom;
    }

    const onMapLoad = ({ map, maps }) => {
        map.addListener("zoom_changed", () => setMapZoom(map, zoomRef));
        setMapState((prevState) => {
            return {
                ...prevState,
                map,
                maps,
            };
        });

        setTimeout(() => {
            setMapBounds(map, mapState.data.rawData, false, window.google?.maps.LatLngBounds);
        }, 0);

        return () => {
            map.removeListener("zoom_changed", () => setMapZoom(map, zoomRef));
        };
    };

    return publicViewLoading ? (
        <LoadingAnimation />
    ) : (
        <>
            <section className="publicView-bg-map">
                <Map
                    componentProps={{
                        mapStyle: mapState.data.mapStyle,
                        center: mapState.center,
                        containerStyle: {
                            width: "100%",
                            maxHeight: "100%",
                            height: "100%",
                        },
                        maxZoomOut: zoomRef.current.maxZoomOut,
                        defaultZoom: zoomRef.current.defaultZoom,
                        maxZoomIn: zoomRef.current.maxZoomIn,
                        onMapLoad,
                    }}
                >
                    {/* MARKERS */}
                    {!!mapState.data?.rawData?.length && (
                        <MapMarkers
                            showNumbers={type === "route"}
                            data={markers}
                            hiddenMarkers={hiddenMarkers}
                            handleMarkerClick={handleMarkerClick}
                            map={mapState.map}
                            setMapState={setMapState}
                            markerStyle={mapState.data?.markerStyle}
                            pinsFilterState={pinsFilterState}
                        />
                    )}

                    {mapState.markerInfo && mapState.openMarkerInfo && (
                        <InfoWindow
                            onCloseClick={() =>
                                setMapState((prevState) => ({
                                    ...prevState,
                                    openMarkerInfo: false,
                                }))
                            }
                            position={{
                                lat: Number(mapState?.markerInfo?.location?.lat),
                                lng: Number(mapState?.markerInfo?.location?.lng),
                            }}
                            zIndex={2}
                        >
                            <MarkerInfo
                                info={mapState.markerInfo}
                                publicViewMoveMarker={mapState.moveMarker}
                                setMapState={setMapState}
                                hidePinBar={true}
                                handleMarkerClick={handleMarkerClick}
                            />
                        </InfoWindow>
                    )}

                    {mapState.data.overview_polyline && (
                        <Polyline
                            path={decodePolyline(mapState.data.overview_polyline)}
                            options={polylineOptions}
                        />
                    )}

                    {!!mapState.data.rawData && (
                        <MapGroups
                            markerStyle={mapState?.data?.markerStyle}
                            mapData={mapState.data}
                            data={mapState?.data?.rawData}
                            hiddenMarkers={hiddenMarkers}
                            setHiddenMarkers={setHiddenMarkers}
                            pinsFilterState={pinsFilterState}
                            setPinsFilterState={setPinsFilterState}
                        />
                    )}
                </Map>

                <PinDetails
                    mapState={mapState}
                    hiddenMarkers={hiddenMarkers}
                    setMapState={setMapState}
                />
            </section>
        </>
    );
};

export default PublicView;
