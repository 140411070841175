import { toast } from "react-toastify";
import toastSuccess from "../assets/images/svg/toastSuccess.svg";
import toastAlert from "../assets/images/svg/toastAlert.svg";
import { toastApiMessage } from "./utils";
import { useNavigate } from "react-router-dom";

export function errorMessage(msg, options = {}) {
    toast.error(toastApiMessage(msg), {
        icon: ({ theme, type }) => <img src={toastAlert} alt="error" />,
        ...options,
    });
}
function CustomIconLink() {
    const navigateBilling = useNavigate();
    const handleNavigate = () => {
        navigateBilling("/billing");
    };
    return (
        <div className="custom_warning_box">
            <img src={toastAlert} alt="error" />
            <button onClick={handleNavigate}>Subscription</button>
            {/* <p>group limit exceeds</p> */}
        </div>
    );
}

export function warningLinkMessage(msg, options = {}) {
    toast.warning(msg, {
        icon: ({ theme, type }) => <CustomIconLink />,
        ...options,
    });
}

export function warningMessage(msg, options = {}) {
    toast.warning(toastApiMessage(msg), {
        icon: ({ theme, type }) => <img src={toastAlert} alt="error" />,
        ...options,
    });
}

export function successMessage(msg, options = {}) {
    if (typeof msg === "string") {
        toast.success(toastApiMessage(msg), {
            icon: ({ theme, type }) => <img src={toastSuccess} alt="success" />,
            ...options,
        });
    } else {
        toast.success(msg, {
            icon: ({ theme, type }) => <img src={toastSuccess} alt="success" />,
            ...options,
        });
    }
}
