import { Component } from "react";
import FallbackUI from "../FallbackUI/FallbackUI";

export default class GlobalErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: "" };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        console.log("GET DERIVED STATE: ", error);
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        console.log("ERROR: ", error);
        console.log("ERROR INFO: ", errorInfo);
        // You can also log the error to an error reporting service
        //   logErrorToMyService(error, errorInfo);
    }

    handleHideFallError() {
        this.setState({
            hasError: false,
            error: "",
        });
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <FallbackUI
                    error={this.state.error}
                    handleHideFallError={this.handleHideFallError}
                />
            );
        }

        return this.props.children;
    }
}
