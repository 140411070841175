import React from "react";
import { capitalizeString } from "../../utils/utils";

function GroupButtons({ data, activeGroup, handleActiveGroup }) {
    /* GROUP BUTTONS */
    return (
        <div className="editmap-colorGrids">
            {data.map((item) => (
                <button
                    key={item.group}
                    type="button"
                    className={`group-color-show ${activeGroup === item.group ? "active" : ""}`}
                    onClick={() => handleActiveGroup(item.group)}
                >
                    <span
                        style={{
                            backgroundColor: `${item.color}`,
                        }}
                        className="color-box-show"
                    ></span>
                    {capitalizeString(item.group)}
                </button>
            ))}
        </div>
    );
}

export default GroupButtons;
