import React from "react";
import { getPercentageColor } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProgressBarMap = (props) => {
    const navigate = useNavigate();

    const { planData } = useSelector((state) => state.auth);

    const handleUpgrade = () => {
        navigate("/billing", {
            state: {
                openSubscribeModal: true,
                planModalOpen: planData?.planName,
            },
        });
    };

    return (
        <div className="maps-progress-bar-container">
            <div
                className="maps-progress-bar-wrapper"
                role="progressbar"
                aria-valuenow={4}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{
                    width: `${props.percentageValue}%`,
                    backgroundColor: `${getPercentageColor(props.percentageValue)}`,
                }}
            >
                {props.percentageValue < 99 ? (
                    `${props.percentageValue}%`
                ) : (
                    <button className="upgrade-cta" onClick={handleUpgrade}>
                        {" "}
                        UPGRADE
                    </button>
                )}
            </div>
        </div>
    );
};

export default ProgressBarMap;
