import React, { useEffect } from "react";
import chevDown from "../../../assets/images/svg/chevronDown.svg";

const PaginationPinDetails = (props) => {
    const { table, dataLength } = props;

    const pageSizeSet = (e) => {
        table.setPageSize(Number(e.target.value));
    };
    const nextPage = () => {
        table.nextPage();
    };
    const previousPage = () => {
        table.previousPage();
    };

    useEffect(() => {
        table.setPageSize(25);
    }, []);

    return (
        <div className="table-sortPagination">
            <div className="tabular-paginationBar">
                <label>Row Per Page:</label>
                <select value={table.getState().pagination.pageSize} onChange={pageSizeSet}>
                    <option value={dataLength}>All</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                </select>
            </div>

            <div className="tabular-paginationBar">
                <label>
                    Page: &nbsp;
                    {table.getState().pagination.pageIndex + 1} &nbsp; / &nbsp;
                    {table?.getPageCount?.() || 1}
                </label>

                <div className="btns-group">
                    <button
                        className="arrow-icon"
                        onClick={previousPage}
                        disabled={!table?.getCanPreviousPage?.()}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="#011c4d"
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 19.5L8.25 12l7.5-7.5"
                            />
                        </svg>
                    </button>

                    <button
                        className="arrow-icon"
                        onClick={nextPage}
                        disabled={!table?.getCanNextPage?.()}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="#011c4d"
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M8.25 4.5l7.5 7.5-7.5 7.5"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PaginationPinDetails;
