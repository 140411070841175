import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import DotLoader from "../../components/DotLoaderBtn/DotLoader";
import closeIconGrey from "../../assets/images/svg/closeIconGrey.svg";
import { debounce } from "../../utils/utils";

const DeleteSharedUserModal = (props) => {
    const { mapType } = useSelector((state) => state.mapTypeState);
    const [deleteText, setDeleteText] = useState("");

    const [modalGuageTitle, setModalGuageTitle] = useState("");

    const {
        deleteSharedUserLoading,
        handleDeleteSharedUser,
        setDeleteSharedUser,
        deleteSharedUser,
    } = props;

    useEffect(() => {
        if (mapType === 1) {
            setModalGuageTitle("My Maps");
        }
        if (mapType === 2) {
            setModalGuageTitle("Shared Maps");
        }
        if (mapType === 3) {
            setModalGuageTitle("My Routes");
        }
        if (mapType === 4) {
            setModalGuageTitle("My Groups");
        }
    }, [mapType]);

    return (
        <div className="delete-modal-wrapper">
            <div className="text-right">
                <img
                    src={closeIconGrey}
                    alt="close icon"
                    className="deleteMarker-modal-close"
                    onClick={() =>
                        setDeleteSharedUser((prevState) => {
                            return {
                                ...prevState,
                                modalToggle: false,
                            };
                        })
                    }
                />
            </div>

            <h5 className="delete-modal-wrapper-title">Stop sharing?</h5>
            <div>
                <p className="content">
                    Are you sure you want to stop sharing with{" "}
                    <strong>{deleteSharedUser?.name}</strong>?
                </p>
            </div>

            <div className="delete-modal-actions">
                {/* <div className="input-fields">
                    <input
                        type="text"
                        name="delete-map-modal"
                        id="deleteMapInput"
                        autoComplete="off"
                        placeholder="Enter Remove to confirm"
                        onChange={(e) => setDeleteText(e.target.value.toLowerCase())}
                    />
                </div> */}
                <div className="buttons">
                    <button
                        type="button"
                        onClick={debounce(() => {
                            setDeleteSharedUser((prevState) => {
                                return {
                                    ...prevState,
                                    modalToggle: false,
                                };
                            });
                        }, 500)}
                        aria-label="Close"
                        className="btn border-cancel-btn"
                        disabled={deleteSharedUserLoading}
                    >
                        Cancel
                    </button>

                    {deleteSharedUserLoading ? (
                        <button
                            className="btn animate-dots-red del-outline-btn"
                            type="button"
                            disabled
                        >
                            <DotLoader />
                        </button>
                    ) : (
                        <button
                            type="button"
                            className="btn del-outline-btn"
                            onClick={() => handleDeleteSharedUser()}
                        >
                            Confirm
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DeleteSharedUserModal;
