import React from "react";
import noImage from "../../assets/images/noImage.png";
import tick from "../../assets/images/svg/tick.svg";

const MovePInList = (props) => {
  const {
    listData,
    searchItem,
    currentMapId,
    selectedMapId,
    setSelectedMapId,
  } = props;

  return (
    <div className="moveMarker-maps-container">
      {listData?.length ? (
        listData
          ?.filter((item) => {
            return (
              item?.title
                ?.toLowerCase()
                .includes(searchItem.trim().toLowerCase()) &&
              item._id !== currentMapId
            );
          })
          ?.map((data, index) => {
            return (
              <div className="moveMarker-map-item" key={index}>
                <div>
                  <input
                    className="movePin-map-check"
                    type="radio"
                    name="pinsMaps"
                    id={data._id}
                    value={data._id}
                    onChange={() => setSelectedMapId(data._id)}
                    checked={data._id === selectedMapId}
                    hidden
                  />
                </div>
                <label
                  className="moveMarker-map-item--inner"
                  htmlFor={data._id}
                >
                  <div className="moveMarker-map-img">
                    <img
                      src={data?.mapImage || noImage}
                      alt="map-list"
                      className="map-image"
                    />
                  </div>

                  <div className="moveMarker-map-detail">
                    <h5 className="title">{data.title}</h5>
                    {data.count ? (
                      <p className="count">{data.count} markers</p>
                    ) : (
                      ""
                    )}
                  </div>

                  {data._id === selectedMapId && (
                    <div className="moveMarker-check">
                      <img src={tick} alt="check" className="check-icon" />
                    </div>
                  )}
                </label>
              </div>
            );
          })
      ) : (
        <p>No Maps to show</p>
      )}
    </div>
  );
};

export default MovePInList;
