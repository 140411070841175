import React from "react";
import imageBG from "../../assets/images/Auth/login-hover.png";
import closeIcon from "../../assets/images/closeIcon.png";
import "react-toastify/dist/ReactToastify.css";
import DotLoader from "../../components/DotLoaderBtn/DotLoader";

const VerifyEmailLogin = (props) => {
    const { setLoginState, loginState, handleSendVerificationAgain, loading } = props;

    return (
        <>
            <section className="login-forget--container">
                <div className="login-forget--wrapper">
                    <div className="content-wrapper">
                        <form onSubmit={(e) => handleSendVerificationAgain(e)}>
                            <h2>Verification email</h2>
                            <p>Please go to your email and verify account.</p>
                            <p>Didn’t receive email?</p>
                            <div className="forget-input-container">
                                <label>Email</label>
                                <input
                                    type="email"
                                    aria-label="email adress"
                                    placeholder="Type your email"
                                    value={loginState.email}
                                    onChange={(e) =>
                                        setLoginState((prevState) => {
                                            return {
                                                ...prevState,
                                                email: e.target.value.trim().toLowerCase(),
                                            };
                                        })
                                    }
                                    required
                                />
                            </div>

                            {loginState.loading ? (
                                <button
                                    className="btn animate-dots-blue login-load-outline-btn"
                                    type="button"
                                    disabled
                                >
                                    <DotLoader />
                                </button>
                            ) : (
                                <button
                                    type="submit"
                                    className="forget-recover-cta"
                                    disabled={!loginState.email.trim()}
                                >
                                    Send again
                                </button>
                            )}
                        </form>
                    </div>
                    <div className="img-wrapper">
                        <button
                            className="close-button"
                            onClick={(e) => {
                                setLoginState((prevState) => {
                                    return {
                                        ...prevState,
                                        showVerifyLoginModal: false,
                                    };
                                });
                                e.preventDefault();
                            }}
                        >
                            <img src={closeIcon} alt="close icon" />
                        </button>
                        <img src={imageBG} className="bg-image" alt="thankyou registered" />
                    </div>
                </div>
            </section>
        </>
    );
};

export default VerifyEmailLogin;
