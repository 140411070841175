import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import DotLoader from "../../components/DotLoaderBtn/DotLoader";
import closeIconGrey from "../../assets/images/svg/closeIconGrey.svg";
import { capitalizeFirstCharatcter } from "../../utils/utils";

const DeleteMapModal = (props) => {
    const { mapType } = useSelector((state) => state.mapTypeState);

    const [modalGuageTitle, setModalGuageTitle] = useState("");
    const [deleteText, setDeleteText] = useState("");
    const [isHoverDel, setIsHoverDel] = useState(false);

    const { loadingDeleteMap, setConfirmDeleteMapOpen, handleDeleteMap, title, setMapTypeState } =
        props;

    useEffect(() => {
        if (mapType === 1) {
            setModalGuageTitle("My Maps");
        }
        if (mapType === 2) {
            setModalGuageTitle("Shared Maps");
        }
        if (mapType === 3) {
            setModalGuageTitle("My Routes");
        }
        if (mapType === 4) {
            setModalGuageTitle("Container");
        }
    }, [mapType]);
    const handleHoverEnter = () => {
        if (deleteText !== "remove") {
            setIsHoverDel(true);
        } else {
            setIsHoverDel(false);
        }
    };
    const handleHoverLeave = () => {
        setIsHoverDel(false);
    };

    const handleCloseDeleteModel = () => {
        setConfirmDeleteMapOpen(false);
        setMapTypeState((prev) => {
            return {
                ...prev,
                mapGroup: false,
            };
        });
    };

    return (
        <div className="delete-modal-wrapper">
            <div className="text-right">
                <button
                    className="deleteMarker-modal-close"
                    onClick={handleCloseDeleteModel}
                    disabled={loadingDeleteMap}
                >
                    <img src={closeIconGrey} alt="close icon" />
                </button>
            </div>

            <h5 className="delete-modal-wrapper-title ">Remove map</h5>
            <div>
                <p className="content">
                    {" "}
                    <b>{capitalizeFirstCharatcter(title)} </b>
                    from <b> {modalGuageTitle}</b>
                </p>
            </div>

            <div className="delete-modal-actions">
                <form onSubmit={handleDeleteMap}>
                    <div className="input-fields">
                        <input
                            type="text"
                            name="delete-map-modal"
                            id="deleteMapInput"
                            autoComplete="off"
                            placeholder={`Type "Remove" to confirm`}
                            onChange={(e) => setDeleteText(e.target.value.toLowerCase())}
                        />
                    </div>
                    <p className="content">
                        Clicking <strong>confirm</strong> permanently deletes all connections.
                    </p>
                    <div className="buttons">
                        <button
                            type="button"
                            onClick={handleCloseDeleteModel}
                            aria-label="Close"
                            className="btn border-cancel-btn"
                            disabled={loadingDeleteMap}
                        >
                            Cancel
                        </button>
                        <div onMouseLeave={handleHoverLeave} onMouseEnter={handleHoverEnter}>
                            {loadingDeleteMap ? (
                                <button
                                    className="btn animate-dots-red del-outline-btn"
                                    type="button"
                                    disabled
                                >
                                    <DotLoader />
                                </button>
                            ) : (
                                <button
                                    type="submit"
                                    className="btn del-outline-btn"
                                    disabled={deleteText !== "remove"}
                                >
                                    Confirm
                                </button>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default DeleteMapModal;
