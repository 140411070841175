import DotLoader from "../DotLoaderBtn/DotLoader";
import "../Map/SharedList/shareTypeList.css";
import closeIconGrey from "../../assets/images/svg/closeIconGrey.svg";

const BulkSharingModal = (props) => {
    const { setBulkData, bulkData, shareEmail, shareMapEmail } = props;

    const handlBulkModalClose = () => {
        setBulkData((prev) => {
            return {
                ...prev,
                showBulkModal: false,
            };
        });
    };
    return (
        <div className="bulk-modal-wrapper">
            <div className="share-close-icon">
                <button
                    type="button"
                    className="share-close-icon"
                    aria-label="Close"
                    onClick={handlBulkModalClose}
                >
                    <img src={closeIconGrey} alt="search move pin" />
                </button>
            </div>
            <h5 className="bulk-modal-wrapper-title ">Sharing Emails</h5>
            <div>
                {bulkData?.invalidEmailCount > 0 && (
                    <p>
                        <b>{bulkData?.invalidEmailCount} Invalid Emails Found</b>
                    </p>
                )}
                {bulkData?.duplicateCount > 0 && (
                    <p>
                        <b>{bulkData?.duplicateCount} Duplicate Emails Found</b>
                    </p>
                )}
                <div className="content">
                    Clicking <strong>continue</strong> complete the process.
                </div>
                <div className="bulk-modal-buttons">
                    <button
                        type="button"
                        onClick={handlBulkModalClose}
                        aria-label="Close"
                        className="btn border-cancel-btn"
                    >
                        Cancel
                    </button>
                    {shareEmail?.loading ? (
                        <button
                            className="btn animate-dots-red del-outline-btn"
                            type="button"
                            disabled
                        >
                            <DotLoader />
                        </button>
                    ) : (
                        <button
                            type="submit"
                            className="btn del-bulk-outline-btn"
                            onClick={(e) => shareMapEmail(e, bulkData?.uniqueEmails)}
                            disabled={shareEmail?.loading ? true : false}
                        >
                            Confirm
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BulkSharingModal;
