import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import imageBG from "../../assets/images/Auth/signin-success.png";
import closeIcon from "../../assets/images/svg/closeIconGrey.svg";
import congratsAnimation from "../../assets/images/Gif/congrats-animation.gif";
import Register from "./Register";
import { useVerifyTokenMutation } from "../../services/baseApi";

const RegisterSuccess = () => {
    const [verifyState, setVerifyState] = useState({
        verify: false,
        response: "",
        showModal: true,
        error: false,
    });

    const location = useLocation();

    const livePath = location?.search;
    const email = livePath?.split("=")[1]?.slice(0, -6);
    const token = livePath?.split("=")[2];

    const [verifyToken] = useVerifyTokenMutation();

    const handleSubmit = async () => {
        const { data, error } = await verifyToken({
            email: email,
            token: token,
        });
        if (data) {
            setVerifyState((prevState) => {
                return {
                    ...prevState,
                    response: data?.message,
                    verify: true,
                };
            });
        }
        if (error) {
            setVerifyState((prevState) => {
                return {
                    ...prevState,
                    response: error?.data?.message,
                    verify: false,
                    error: true,
                };
            });
        }
    };

    useEffect(() => {
        handleSubmit();
    }, []);

    return (
        <>
            <Register />
            {verifyState.showModal && (
                <section className="signup-success--container">
                    <div className="signup-success--wrapper">
                        <div className="img-wrapper">
                            <img src={imageBG} alt="thankyou registered" />
                        </div>
                        <div className="content-wrapper" style={{ width: 500 }}>
                            <button
                                onClick={(e) => {
                                    setVerifyState((prevState) => {
                                        return {
                                            ...prevState,
                                            showModal: false,
                                        };
                                    });
                                    e.preventDefault();
                                }}
                                className="signUp-modal-close"
                            >
                                <img src={closeIcon} alt="close icon" />
                            </button>
                            {!verifyState.error ? (
                                <div className="content-wrapper-congrats">
                                    <img src={congratsAnimation} alt="congrats animation" />
                                    <h2 style={{ position: "relative" }}>Congratulations</h2>
                                    <p style={{ position: "relative", textAlign: "center" }}>
                                        You’re all set.
                                    </p>
                                    <Link
                                        className="forget-recover-cta"
                                        to="/login"
                                        style={{ marginTop: 20 }}
                                    >
                                        Continue to log in
                                    </Link>
                                </div>
                            ) : (
                                <>
                                    <h3>{verifyState.response}</h3>
                                </>
                            )}
                        </div>
                    </div>
                </section>
            )}
        </>
    );
};

export default RegisterSuccess;
