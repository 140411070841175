import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import DotLoader from "../../components/DotLoaderBtn/DotLoader";
import closeIconGrey from "../../assets/images/svg/closeIconGrey.svg";
import { debounce } from "../../utils/utils";

const EditGroupModal = (props) => {
    const { mapType } = useSelector((state) => state.mapTypeState);

    const [modalGuageTitle, setModalGuageTitle] = useState("");

    const {
        handleClose,
        updateGroupState,
        setUpdateGroupState,
        handleUpdateGroup,
        title,
        id,
        mapId,
        parentGroup,
    } = props;

    useEffect(() => {
        if (mapType === 1) {
            setModalGuageTitle("My Maps");
        }
        if (mapType === 2) {
            setModalGuageTitle("Shared Maps");
        }
        if (mapType === 3) {
            setModalGuageTitle("My Routes");
        }
        if (mapType === 4) {
            setModalGuageTitle("Container");
        }
    }, [mapType]);

    return (
        <div className="delete-modal-wrapper">
            <div className="text-right">
                <img
                    src={closeIconGrey}
                    alt="close icon"
                    className="deleteMarker-modal-close"
                    onClick={handleClose}
                />
            </div>
            <h5 className="delete-modal-wrapper-title ">Edit Group?</h5>
            <div>
                <p className="content">
                    <b>{title} </b>
                    from <b> {modalGuageTitle}.</b>
                </p>
            </div>

            <div className="delete-modal-actions">
                <form onSubmit={(e) => handleUpdateGroup(e, id, mapId, parentGroup)}>
                    <div className="input-fields">
                        <div>
                            <input
                                type="text"
                                name="group titile"
                                defaultValue={updateGroupState.updatedTitle}
                                autoComplete="off"
                                placeholder="Updated Title"
                                onChange={debounce(
                                    (e) =>
                                        setUpdateGroupState((prevState) => {
                                            return {
                                                ...prevState,
                                                updatedTitle: e.target.value,
                                            };
                                        }),
                                    500
                                )}
                            />
                        </div>
                    </div>
                    <div className="buttons">
                        <button
                            type="button"
                            onClick={() => handleClose()}
                            aria-label="Close"
                            className="btn border-cancel-btn"
                            disabled={updateGroupState.loading}
                        >
                            Cancel
                        </button>
                        {updateGroupState.loading ? (
                            <button
                                className="btn animate-dots-blue cta-border-blue-auto"
                                type="button"
                                disabled
                            >
                                <DotLoader />
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className="btn cta-filled-blue-auto"
                                disabled={!updateGroupState.updatedTitle}
                            >
                                Update
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditGroupModal;
