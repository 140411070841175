import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import closeIconGrey from "../../assets/images/svg/closeIconGrey.svg";
import DotLoader from "../DotLoaderBtn/DotLoader";
import { capitalizeString } from "../../utils/utils";
import { Link } from "react-router-dom";
import { useElements, useStripe, CardCvcElement } from "@stripe/react-stripe-js";
import { errorMessage, successMessage } from "../../utils/messages";
import { useCreatePaymentIntentMutation, useSubscribePlanMutation } from "../../services/baseApi";
import { STRIPE_CLIENT_SECRET } from "../../config";
const SubscribePlanModal = (props) => {
    const { user } = useSelector((state) => state.auth);
    const { title, price, billingState, setbillingState } = props;
    const [subscribePlan] = useSubscribePlanMutation();
    const [createPaymentIntent] = useCreatePaymentIntentMutation();

    const [userPlan, setUserPlan] = useState({});

    const stripe = useStripe();
    const elements = useElements();

    const CARD_OPTIONS = {
        style: {
            base: {
                fontSize: "16px",
                color: "#424770",
                "::placeholder": {
                    color: "#aab7c4",
                },
            },
            invalid: {
                color: "#9e2146",
            },
        },
    };

    const handleConfirmPayment = async (e, plan) => {
        e.preventDefault();

        if (!stripe) {
            return;
        }

        setbillingState((prevState) => {
            return {
                ...prevState,
                loading: true,
            };
        });

        const { data, error } = await subscribePlan({
            email: user.email,
            plan: plan,
            password: billingState.confirmPassword,
        });

        if (data) {
            setbillingState((prevState) => {
                return {
                    ...prevState,
                    loading: false,
                    openSubscribeModal: false,
                };
            });
            successMessage("Successfully subscribed");
        }
        if (error) {
            errorMessage(error.data.message);
            setbillingState((prevState) => {
                return {
                    ...prevState,
                    loading: false,
                };
            });
        }
    };

    return (
        <div className="subscribe-plan-modal-wrapper">
            <div className="text-right">
                <img
                    src={closeIconGrey}
                    alt="close icon"
                    className="deleteMarker-modal-close"
                    onClick={() =>
                        setbillingState((prevState) => {
                            return {
                                ...prevState,
                                openSubscribeModal: false,
                                planModalOpen: "",
                            };
                        })
                    }
                />
            </div>
            <h5 className="delete-modal-wrapper-title ">Upgrade plan</h5>
            <div>
                <p className="content">
                    We’ll upgrade you to XoomMaps {capitalizeString(title)} plan immediately. Are
                    you sure to confirm the payment.
                </p>
            </div>

            <div className="delete-modal-actions card-cvc-element" style={{ marginTop: 10 }}>
                <form onSubmit={(e) => handleConfirmPayment(e, title)}>
                    <div className="input-fields">
                        <label style={{ marginBottom: 0 }}>Password</label>
                        <input
                            type="password"
                            name="delete-map-modal"
                            id="deleteMapInput"
                            autoComplete="off"
                            placeholder="Password"
                            onChange={(e) =>
                                setbillingState((prevState) => {
                                    return {
                                        ...prevState,
                                        confirmPassword: e.target.value,
                                    };
                                })
                            }
                        />
                    </div>
                    <div className="buttons">
                        {/* <button
                            type="button"
                            onClick={() =>
                                setbillingState((prevState) => {
                                    return {
                                        ...prevState,
                                        openSubscribeModal: false,
                                    };
                                })
                            }
                            aria-label="Close"
                            className="btn border-cancel-btn"
                            disabled={billingState.loading}
                        >
                            Cancel
                        </button> */}
                        {billingState.loading ? (
                            <button
                                className="btn width-full animate-dots-blue cta-border-blue-auto "
                                type="button"
                                disabled
                            >
                                <DotLoader />
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className="btn  cta-filled-blue-auto width-full"
                                // onClick={(e) => handleConfirmPayment(e, title)}
                                disabled={!billingState.confirmPassword}
                            >
                                Pay ${price} / month
                            </button>
                        )}
                    </div>
                </form>
            </div>
            <div className="footer-note mt-15">
                <p className="text-center">
                    By clicking “Pay” you are agreeing to XoomMaps{" "}
                    <Link to="/billing">Terms and Conditions.</Link>{" "}
                </p>
            </div>
        </div>
    );
};

export default SubscribePlanModal;
