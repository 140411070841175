import React, { useState } from "react";
import warning from "../../../assets/images/warning.png";
import closeIcon from "../../../assets/images/svg/closeIconGrey.svg";
import GeoMappingSample from "../../../assets/Templates/Sampledata.xlsx";
import ChevronDown from "../../../components/icons/ChevronDown";

const FailedRecordRows = (props) => {
    const { failedMapData, setShowFailedRecords } = props;

    const [showFilesList, setShowFilesList] = useState(false);

    const handleToggleFilesList = () => {
        setShowFilesList(!showFilesList);
    };

    return (
        <div className="failedRecords-row--wrapper">
            <div className="header">
                <div>
                    {/* <img src={warning} alt="warning icon" style={{ height: 25 }} /> */}
                    <h5>We were unable to map the following locations:</h5>
                </div>
                <button onClick={() => setShowFailedRecords(false)}>
                    <img src={closeIcon} alt="close icon" />
                </button>
            </div>
            <div className="content">
                {failedMapData?.map((item, index) => {
                    return (
                        <p key={item.index}>
                            <strong>Row {item?.index}:</strong> {item.address} {item.city}{" "}
                            {item.state} {item.zip}{" "}
                        </p>
                    );
                })}
            </div>
            <div className="footer">
                <p>
                    Please ensure entries include an accurate physical address. You may also use one
                    of our helpful{" "}
                    <span className="pos-rel">
                        <button
                            onClick={handleToggleFilesList}
                            className={` ${showFilesList ? "active" : ""}`}
                        >
                            templates
                            <ChevronDown />
                        </button>
                        <ul
                            className={`mapPinBar-group-list-content ${
                                showFilesList ? "active" : ""
                            }`}
                        >
                            <li>
                                <a download href={GeoMappingSample}>
                                    Sample data
                                </a>
                            </li>
                            <li>
                                <a download href={GeoMappingSample}>
                                    Real estate
                                </a>
                            </li>
                            <li>
                                <a download href={GeoMappingSample}>
                                    Help desk
                                </a>
                            </li>
                            <li>
                                <a download href={GeoMappingSample}>
                                    Field service
                                </a>
                            </li>
                            <li>
                                <a download href={GeoMappingSample}>
                                    Retailer
                                </a>
                            </li>
                        </ul>
                    </span>{" "}
                    for additional assistance.
                </p>
            </div>
        </div>
    );
};

export default FailedRecordRows;
