import React from "react";
import { Link } from "react-router-dom";
import arrowLeft from "../../../assets/images/arrowLeft.png";
import DotLoader from "../../../components/DotLoaderBtn/DotLoader";

import { debounce } from "../../../utils/utils";
import FailedRecordRows from "../createMap/FailedRecordRows";

const EditMapNav = (props) => {
  const { setHistoryState, historyState } = props;

  const handleUpdate = () => {
    props?.handleUpdateMap();
  };
  const updateLocation = () => {
    setHistoryState((prev) => {
      return {
        ...prev,
        isHistoryLoading: true,
      };
    });
    props?.handleSaveUpdates();
  };
  return (
    <div className="createMap-nav editMap-nav flex-between">
      <h5 onClick={props.handleCancel}>
        {/* <Link to="/dashboard"> */}
        <img
          src={arrowLeft}
          alt="left arrow icon"
          style={{ width: "22px", marginRight: "10px" }}
        />
        <strong>Edit map</strong>
        {/* </Link> */}
      </h5>
      {props.showFailedRecords && (
        <FailedRecordRows
          failedMapData={props.state.failedRows}
          setShowFailedRecords={props.setShowFailedRecords}
        />
      )}

      <div>
        <button
          className="btn cancel"
          onClick={props.handleCancel}
          disabled={props.state.updateLoading}
        >
          Cancel
        </button>

        {props.state.updateLoading ? (
          <button
            className="btn animate-dots-blue cta-border-blue-auto mx-10"
            type="button"
            disabled
          >
            <DotLoader />
          </button>
        ) : (
          <>
            {!props.state.updateLocations && props?.changedRow?.length > 0 ? (
              <>
                {historyState?.isHistoryLoading ? (
                  <button
                    className="btn animate-dots-red del-outline-btn"
                    type="button"
                    disabled
                  >
                    <DotLoader />
                  </button>
                ) : (
                  <button
                    className="btn save update-loc"
                    onClick={updateLocation}
                  >
                    Update Locations
                  </button>
                )}
              </>
            ) : (
              <button className="btn save" onClick={handleUpdate}>
                Update
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default EditMapNav;
