import { OverlayView } from "@react-google-maps/api";
import dummyUser from "../../assets/images/avatar-grey.png";
import React, { useState } from "react";
import { GEO_MAPPING_BASE_URL } from "../../config";
const NocMarker = (props) => {
    const {
        handleClick,
        group,
        groupsData,
        markerStyle,
        hiddenMarkers,
        nocGroupFilterMarkers,
        uniqueGroupUsersImage,
    } = props;
    const [isHover, setIsHover] = useState(false);

    let groupUser = [];

    const count = groupsData?.reduce((acc, item) => {
        const value = acc + (item?.user?.email === group?.user?.email ? 1 : 0);
        if (item?.user?.email === group?.user?.email) {
            groupUser.push(item);
        }
        return value;
    }, 0);

    let userExists = hiddenMarkers?.find((item) => item?.userEmail === group?.user?.email);
    let ItemImageData = groupsData?.filter((item) => {
        if (nocGroupFilterMarkers?.length > 0) {
            return nocGroupFilterMarkers.some((id) => id === item?.groupId) ? true : false;
        }
    });
    let loc = {
        lat: Number(group?.location?.latitude),
        lng: Number(group?.location?.longitude),
    };

    function getImageContent() {
        let contentImage;
        return (contentImage = (
            <>
                <div
                    className="overlay"
                    onMouseOver={(e) => {
                        e.stopPropagation();
                        setIsHover(true);
                    }}
                    onMouseLeave={(e) => {
                        e.stopPropagation();
                        setIsHover(false);
                    }}
                    style={{
                        color: "white",
                        backgroundColor:
                            // true
                            isHover && groupUser?.length > 1
                                ? groupUser?.find(
                                      (item) => item?.user?.email === group?.user?.email
                                  )?.pinColor
                                : "transparent",
                        // width: isHover ? "100%" : "auto",
                    }}
                >
                    <div
                        className="pin"
                        style={{
                            border: `18px solid ${
                                groupUser?.find((item) => item?.user?.email === group?.user?.email)
                                    ?.pinColor || "red"
                            }`,
                        }}
                    >
                        <img
                            onClick={(e) => {
                                e.stopPropagation();
                                handleClick(e);
                            }}
                            src={groupUser[0]?.user?.avatar || dummyUser}
                            alt="Marker"
                            className="marker-image"
                        />
                        {groupUser?.length > 1 && <div className="countCircle">{count}</div>}
                    </div>

                    {groupUser?.length > 1 && isHover && (
                        <>
                            <div className="groupHoverContainer">
                                {groupUser?.length > 1 &&
                                    groupUser?.map((item, index) => {
                                        return (
                                            <ul key={index} className="subgroup ">
                                                {/* <span className="subgroupIcon"> */}

                                                <li className="flex-center">
                                                    <span>
                                                        <img
                                                            src={`${GEO_MAPPING_BASE_URL}marker?type=${
                                                                markerStyle || "red" || "default"
                                                            }&size=8&fill=${item?.pinColor}`}
                                                            alt="pinImage"
                                                        />
                                                    </span>
                                                    {item.groupName}
                                                </li>

                                                {/* </span> */}
                                            </ul>
                                        );
                                    })}
                            </div>
                        </>
                    )}
                </div>
            </>
        ));
    }

    let content;
    if (hiddenMarkers?.length === 0 && nocGroupFilterMarkers.length === 0) {
        content = getImageContent();
    } else {
        if (hiddenMarkers?.length > 0) {
            content =
                (userExists?.userEmail || userExists?.user?.email) === group?.user?.email &&
                getImageContent();
        } else if (hiddenMarkers?.length === 0 && nocGroupFilterMarkers.length > 0) {
            content =
                uniqueGroupUsersImage?.length > 0 &&
                uniqueGroupUsersImage?.map((item, index) => {
                    if (item?.user?.email === group?.user?.email) {
                        return getImageContent();
                    }
                });
        }
    }

    return (
        <OverlayView position={loc} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
            <div className="custom-marker">{content}</div>
        </OverlayView>
    );
};

export default NocMarker;
