import React from "react";
import { capitalizeString } from "../../utils/utils";

function StandardOptions({ options, data, handleStandardOptions }) {
    return (
        <div className="vanishIn" id="standard-options">
            <div className="createMap-formgroup">
                <label htmlFor="disabledSelect">Region</label>
                <select
                    id="disabledSelectss"
                    className="form-control"
                    name="region"
                    value={data.region}
                    onChange={handleStandardOptions}
                >
                    <option value="international">International</option>
                    <option value="usa">USA</option>
                    <option value="canada">Canada</option>
                    <option value="mexico">Mexico</option>
                </select>
            </div>

            <div className="createMap-formgroup">
                <label htmlFor="disabledSelect">Location / Address</label>
                <select
                    id="disabledSelectss"
                    className="form-control"
                    name="address_location"
                    value={data.address_location}
                    onChange={handleStandardOptions}
                    required
                >
                    <option value="">None</option>
                    {options.map((option) => (
                        <option key={option} value={option}>
                            {capitalizeString(option)}
                        </option>
                    ))}
                </select>
            </div>

            <div className="createMap-formgroup">
                <label htmlFor="disabledSelect">City / Country</label>
                <select
                    id="disabledSelectss"
                    className="form-control"
                    value={data.city_country}
                    name="city_country"
                    onChange={handleStandardOptions}
                    required
                >
                    <option value="">None</option>
                    {options.map((option) => (
                        <option key={option} value={option}>
                            {capitalizeString(option)}
                        </option>
                    ))}
                </select>
            </div>

            <div className="createMap-formgroup">
                <label htmlFor="disabledSelect">State / Province</label>
                <select
                    id="disabledSelectss"
                    className="form-control"
                    value={data.state_province}
                    name="state_province"
                    onChange={handleStandardOptions}
                    required
                >
                    <option value="">None</option>
                    {options.map((option) => (
                        <option key={option} value={option}>
                            {capitalizeString(option)}
                        </option>
                    ))}
                </select>
            </div>

            <div className="createMap-formgroup">
                <label htmlFor="disabledSelect">Zip / Postcodes</label>
                <select
                    id="disabledSelectss"
                    className="form-control"
                    value={data.zip_postcode}
                    name="zip_postcode"
                    onChange={handleStandardOptions}
                    required
                >
                    <option value="">None</option>
                    {options.map((option) => (
                        <option key={option} value={option}>
                            {capitalizeString(option)}
                        </option>
                    ))}
                </select>
            </div>

            <div className="createMap-formgroup">
                <label htmlFor="disabledSelect">Phone</label>
                <select
                    id="disabledSelectss"
                    className="form-control"
                    value={data.phone}
                    name="phone"
                    onChange={handleStandardOptions}
                >
                    <option value="">None</option>
                    {options.map((option) => (
                        <option key={option} value={option}>
                            {capitalizeString(option)}
                        </option>
                    ))}
                </select>
            </div>

            <div className="createMap-formgroup">
                <label htmlFor="disabledSelect">Group by / Thematic value</label>
                <select
                    id="disabledSelectss"
                    className="form-control"
                    value={data.groupby_thematicValue}
                    name="groupby_thematicValue"
                    onChange={handleStandardOptions}
                >
                    <option value="NO_GROUP">None</option>
                    {options.map((option) => (
                        <option key={option} value={option}>
                            {capitalizeString(option)}
                        </option>
                    ))}
                </select>
            </div>

            <div className="createMap-formgroup flex-between">
                <div>
                    <label htmlFor="disabledSelect">Check in date</label>
                    <select
                        id="disabledSelectss"
                        className="form-control"
                        // value={data.zip_postcode}
                        name="zip_postcode"
                        // onChange={handleStandardOptions}
                        required
                    >
                        <option value="">None</option>
                        {/* {options.map((option) => (
                            <option key={option} value={option}>
                                {capitalizeString(option)}
                            </option>
                        ))} */}
                    </select>
                </div>
                <div>
                    <label htmlFor="disabledSelect">Check out date</label>
                    <select
                        id="disabledSelectss"
                        className="form-control"
                        // value={data.zip_postcode}
                        name="zip_postcode"
                        // onChange={handleStandardOptions}
                        required
                    >
                        <option value="">None</option>
                        {/* {options.map((option) => (
                            <option key={option} value={option}>
                                {capitalizeString(option)}
                            </option>
                        ))} */}
                    </select>
                </div>
            </div>
            <div className="createMap-formgroup flex-between">
                <div>
                    <label htmlFor="disabledSelect">Check in time</label>
                    <select
                        id="disabledSelectss"
                        className="form-control"
                        // value={data.zip_postcode}
                        name="zip_postcode"
                        // onChange={handleStandardOptions}
                        required
                    >
                        <option value="">None</option>
                        {/* {options.map((option) => (
                            <option key={option} value={option}>
                                {capitalizeString(option)}
                            </option>
                        ))} */}
                    </select>
                </div>
                <div>
                    <label htmlFor="disabledSelect">Check out time</label>
                    <select
                        id="disabledSelectss"
                        className="form-control"
                        // value={data.zip_postcode}
                        name="zip_postcode"
                        // onChange={handleStandardOptions}
                        required
                    >
                        <option value="">None</option>
                        {/* {options.map((option) => (
                            <option key={option} value={option}>
                                {capitalizeString(option)}
                            </option>
                        ))} */}
                    </select>
                </div>
            </div>

            <div className="createMap-formgroup">
                <label htmlFor="disabledSelect">Lead by Source</label>
                <select
                    id="disabledSelectss"
                    className="form-control"
                    value={data.leadBySource}
                    name="leadBySource"
                    onChange={handleStandardOptions}
                >
                    <option value="">None</option>
                    {options.map((option) => (
                        <option key={option} value={option}>
                            {capitalizeString(option)}
                        </option>
                    ))}
                </select>
            </div>
            <div className="createMap-formgroup">
                <label htmlFor="disabledSelect">Stage</label>
                <select
                    id="disabledSelectss"
                    className="form-control"
                    value={data.stage}
                    name="leadBySource"
                    onChange={handleStandardOptions}
                >
                    <option value="">None</option>
                    {options.map((option) => (
                        <option key={option} value={option}>
                            {capitalizeString(option)}
                        </option>
                    ))}
                </select>
            </div>
            <div className="createMap-formgroup">
                <label htmlFor="disabledSelect">Opportunity</label>
                <select
                    id="disabledSelectss"
                    className="form-control"
                    value={data.opportunity}
                    name="leadBySource"
                    onChange={handleStandardOptions}
                >
                    <option value="">None</option>
                    {options.map((option) => (
                        <option key={option} value={option}>
                            {capitalizeString(option)}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
}

export default StandardOptions;
