import React from "react";
import { countryList } from "../../utils/country_list_with_code";
import { countryListWithStates } from "../../utils/country_list_with_states";
import { capitalizeFirstCharatcter, debounce } from "../../utils/utils";
import { useEffect } from "react";
import DotLoader from "../../components/DotLoaderBtn/DotLoader";
import { is } from "date-fns/locale";

function ProfileContact(props) {
    const {
        profileContactState,
        setProfileContactState,
        baseState,

        handleUpdateClick,
    } = props;

    const handleContactChange = (e) => {
        let { name, value } = e.target;

        setProfileContactState((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    };

    const countryHandler = (e) => {
        let selectedCountry = e.target.value;
        // Find the selected country in the data object and extract its states
        const selectedCountryRegions = countryListWithStates?.find(
            (item) => item.countryName === profileContactState?.country
        );
        setProfileContactState((prevState) => {
            return {
                ...prevState,
                country: selectedCountry,
                state: selectedCountryRegions?.regions,
            };
        });
    };

    useEffect(() => {
        setProfileContactState((prevState) => {
            return {
                ...prevState,
                countryCode:
                    countryList.find((item) => item.country === profileContactState.country)
                        ?.countryCode || profileContactState.countryCode,
            };
        });
    }, [profileContactState?.country]);

    return (
        <div id="contact">
            <form onSubmit={(e) => handleUpdateClick(e)}>
                <section className="left">
                    <div className="form-group">
                        <label htmlFor="first">First</label>
                        <input
                            type="text"
                            name="first_name"
                            id="first_name"
                            defaultValue={capitalizeFirstCharatcter(
                                profileContactState?.first_name
                            )}
                            onChange={handleContactChange}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="last">Last</label>
                        <input
                            type="text"
                            name="last_name"
                            id="last_name"
                            defaultValue={capitalizeFirstCharatcter(profileContactState?.last_name)}
                            onChange={handleContactChange}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="text"
                            name="email"
                            id="email"
                            defaultValue={profileContactState?.email}
                            disabled
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="countryRegion">Country / Region</label>
                        <select
                            name="country"
                            id="country"
                            value={profileContactState?.country}
                            onChange={countryHandler}
                        >
                            {countryListWithStates?.map((option) => (
                                <option key={option.countryName} value={option.countryName}>
                                    {option.countryName}
                                </option>
                            ))}
                        </select>
                    </div>
                </section>

                <section className="right">
                    <div className="form-group">
                        <label>Phone</label>
                        <div className="signup-phone-wrapper">
                            <input
                                type="tel"
                                disabled
                                value={
                                    countryList.find(
                                        (item) => item.country === profileContactState?.country
                                    )?.countryCode || profileContactState?.countryCode
                                }
                                className="form-control"
                                name="country_code"
                                aria-label="Country Code"
                            />
                            <input
                                type="number"
                                aria-label="State Code"
                                autoComplete="off"
                                placeholder="510"
                                name="stateCode"
                                value={profileContactState?.stateCode}
                                className="form-control"
                                onChange={(e) => {
                                    if (e.target.value < 10000) {
                                        setProfileContactState((prev) => {
                                            return {
                                                ...prev,
                                                stateCode: e.target.value,
                                            };
                                        });
                                    }
                                }}
                            />
                            <input
                                type="number"
                                aria-label="Phone Number"
                                autoComplete="off"
                                name="mobile"
                                placeholder="555-0123"
                                defaultValue={profileContactState?.mobile}
                                className="form-control"
                                onChange={debounce(handleContactChange, 500)}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="address">Address</label>
                        <input
                            type="text"
                            name="address"
                            id="address"
                            defaultValue={profileContactState?.address}
                            onChange={handleContactChange}
                        />
                    </div>

                    <div className="form-group-container">
                        <div className="form-group">
                            <label>City</label>
                            <input
                                type="text"
                                name="city"
                                id="city"
                                defaultValue={profileContactState?.city}
                                onChange={handleContactChange}
                            />
                        </div>

                        <div className="form-group">
                            <label>Zip code</label>
                            <input
                                type="text"
                                name="zip"
                                id="zip"
                                // defaultValue={profileContactState?.zip}
                                onChange={handleContactChange}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="stateProvince">State / Province</label>
                        <select
                            name="state"
                            id="state"
                            value={profileContactState?.state}
                            onChange={(e) =>
                                setProfileContactState((prevState) => {
                                    return {
                                        ...prevState,
                                        state: e.target.value,
                                    };
                                })
                            }
                        >
                            {countryListWithStates
                                ?.find((item) => item.countryName === profileContactState?.country)
                                ?.regions?.map((option, index) => (
                                    <option key={index} value={option.name}>
                                        {option.name}
                                    </option>
                                ))}
                        </select>
                    </div>

                    <div className="form-actions">
                        {baseState.loading ? (
                            <button
                                className="btn animate-dots-blue cta-border-blue-auto"
                                type="button"
                                disabled
                            >
                                <DotLoader />
                            </button>
                        ) : (
                            <button type="submit" className="btn cta-border-blue-auto">
                                Update
                            </button>
                        )}
                    </div>
                </section>
            </form>
        </div>
    );
}

export default ProfileContact;
