import React, { useState } from "react";
import "../howTo/howTo.css";
import closeIcon from "../../assets/images/svg/Close-white-icon.svg";
import HowToVideoBox from "./HowToVideoBox";
const contentMenu = [
    {
        name: "1. My Maps",
        path: "https://www.youtube.com/embed/rXtgCCGWrcE",
    },
    {
        name: "2. My Routes",
        path: "https://www.youtube.com/embed/rXtgCCGWrcE",
    },
    {
        name: "3. My Groups",
        path: "https://www.youtube.com/embed/rXtgCCGWrcE",
    },
    {
        name: "4. Shared Maps",
        path: "https://www.youtube.com/embed/rXtgCCGWrcE",
    },
    {
        name: "5. Users",
        path: "https://www.youtube.com/embed/rXtgCCGWrcE",
    },
    {
        name: "6. Map Overview",
        path: "https://www.youtube.com/embed/rXtgCCGWrcE",
    },
    {
        name: "7. NOC",
        path: "https://www.youtube.com/embed/rXtgCCGWrcE",
    },
    {
        name: "8. Edit Tools",
        path: "https://www.youtube.com/embed/rXtgCCGWrcE",
    },
];

const HowTo = (props) => {
    const [showBorderVideo, setShowBorderVideo] = useState("");
    const handlCloseHowTo = () => {
        props?.setHowToModelOpen(false);
    };
    const handleBorder = (name) => {
        setShowBorderVideo(name);
    };

    return (
        <div className="box-model-how-to-conatiner ">
            <div className="box-model-how-to-wrapper">
                <div className="how-to-header-container">
                    <div className="how-to-header-wrapper">
                        <div>
                            <h3>HOW TO</h3>
                            <p>Effective date: October 25,2023</p>
                        </div>
                        <img onClick={handlCloseHowTo} src={closeIcon} alt="close-how-to" />
                    </div>
                </div>
                <div className="how-to-content-container">
                    <div className="how-to-content-wrapper ">
                        <div className="how-to-content-left">
                            <h4>Table of Contents</h4>
                            <div>
                                <ul>
                                    {contentMenu.map((item, index) => {
                                        return (
                                            <li>
                                                <p
                                                    onClick={() => handleBorder(item?.name)}
                                                    key={index}
                                                >
                                                    {item.name}
                                                </p>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="how-to-content-right flex-wrap">
                            {contentMenu.map((item, index) => {
                                return (
                                    <HowToVideoBox
                                        url={item?.path}
                                        name={item?.name}
                                        showBorderVideo={showBorderVideo}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowTo;
