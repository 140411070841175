import { tsvToJSON, changeToGroup } from "./utils";

export const NAME = "IMMIX";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";

export const tsvData = `address	city	state	zip	name	phone	group	url	email\tnote\topportunities\tLeadBySource
1206 VAN NESS AVE	FRESNO	CA	93721	Wells Fargo	(800) 869-3577	ATM	https://www.wellsfargo.com	info@wellsfargo.com\tLorem ipsum dolor sit amet, consetetur sadips-cing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat\t100\tcolleague
50 W SAN FERNANDO ST 	SAN JOSE	CA	95113	Wells Fargo	(408) 689-6800	Branch	https://www.wellsfargo.com	info@wellsfargo.com\tLorem ipsum dolor sit amet, consetetur sadips-cing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat\t150\tNewspaper
5763 CHRISTIE AVE	EMERYVILLE	CA	94608	Wells Fargo	(800) 869-3557	Loans	https://www.wellsfargo.com	info@wellsfargo.com\tLorem ipsum dolor sit amet, consetetur sadips-cing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat\t200
1121 LAS VEGAS	LAS VEGAS	NV	89104	Wells Fargo	(702) 464-3138	Branch	https://www.wellsfargo.com	info@wellsfargo.com\tLorem ipsum dolor sit amet, consetetur sadips-cing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat\t250
1300 22ND ST	BALERSFIELD	CA	93301	Wells Fargo	(661) 861-9971	Mortgage	https://www.wellsfargo.com	info@wellsfargo.com\tLorem ipsum dolor sit amet, consetetur sadips-cing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat\t300 
232 E 2ND ST	LOS ANGELES	CA	90012	Wells Fargo	(213) 335-6549	Branch	https://www.wellsfargo.com	info@wellsfargo.com\tLorem ipsum dolor sit amet, consetetur sadips-cing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat\t350
101 W BROADWAY STE	SAN DIEGO	CA	92101	Wells Fargo	(800) 869-3557	Branch	https://www.wellsfargo.com	info@wellsfargo.com\tLorem ipsum dolor sit amet, consetetur sadips-cing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat\t400`.toUpperCase();
// Lahore	SAN DIEGO	CA	92101	Wells Fargo	(800) 869-3557	Branch	https://www.wellsfargo.com	info@wellsfargo.com
// Faisalabad	LOS ANGELES	CA	90012	Wells Fargo	(213) 335-6549	Branch	https://www.wellsfargo.com	info@wellsfargo.com
// Multan	SAN DIEGO	CA	92101	Wells Fargo	(800) 869-3557	Branch	https://www.wellsfargo.com	info@wellsfargo.com
// Sheikhupura	SAN DIEGO	CA	92101	Wells Fargo	(800) 869-3557	Branch	https://www.wellsfargo.com	info@wellsfargo.com
// Sargodha	SAN DIEGO	CA	92101	Wells Fargo	(800) 869-3557	Branch	https://www.wellsfargo.com	info@wellsfargo.com`;

export const rawData = tsvToJSON(tsvData);
export const groupedData = changeToGroup(rawData, "GROUP");

let uploadPath = "https://xoommaps.skylinxtech.com/uploads";
export let timezonesPath = `${uploadPath}/timezones`;
export let statesPath = `${uploadPath}/states`;

export let KML_TIMEZONES = [
    `${timezonesPath}/-10.kml`,
    `${timezonesPath}/-08.kml`,
    `${timezonesPath}/-07.kml`,
    `${timezonesPath}/-06.kml`,
    `${timezonesPath}/-05.kml`,
    `${timezonesPath}/-04.kml`,
];

export const KML_STATES_URLS = [
    // USA
    `${statesPath}/AL.kml`,
    `${statesPath}/AK.kml`,
    `${statesPath}/AZ.kml`,
    `${statesPath}/AR.kml`,
    `${statesPath}/CA.kml`,
    `${statesPath}/CO.kml`,
    `${statesPath}/CT.kml`,
    `${statesPath}/DE.kml`,
    `${statesPath}/FL.kml`,
    `${statesPath}/GA.kml`,
    `${statesPath}/HI.kml`,
    `${statesPath}/ID.kml`,
    `${statesPath}/IL.kml`,
    `${statesPath}/IN.kml`,
    `${statesPath}/IA.kml`,
    `${statesPath}/KS.kml`,
    `${statesPath}/KY.kml`,
    `${statesPath}/LA.kml`,
    `${statesPath}/ME.kml`,
    `${statesPath}/MD.kml`,
    `${statesPath}/MA.kml`,
    `${statesPath}/MI.kml`,
    `${statesPath}/MN.kml`,
    `${statesPath}/MS.kml`,
    `${statesPath}/MO.kml`,
    `${statesPath}/MT.kml`,
    `${statesPath}/NE.kml`,
    `${statesPath}/NV.kml`,
    `${statesPath}/NH.kml`,
    `${statesPath}/NJ.kml`,
    `${statesPath}/NM.kml`,
    `${statesPath}/NY.kml`,
    `${statesPath}/NC.kml`,
    `${statesPath}/ND.kml`,
    `${statesPath}/OH.kml`,
    `${statesPath}/OK.kml`,
    `${statesPath}/OR.kml`,
    `${statesPath}/PA.kml`,
    `${statesPath}/RI.kml`,
    `${statesPath}/SC.kml`,
    `${statesPath}/SD.kml`,
    `${statesPath}/TN.kml`,
    `${statesPath}/TX.kml`,
    `${statesPath}/UT.kml`,
    `${statesPath}/VT.kml`,
    `${statesPath}/VA.kml`,
    `${statesPath}/WA.kml`,
    `${statesPath}/WV.kml`,
    `${statesPath}/WI.kml`,
    `${statesPath}/WY.kml`,
    // CANADA
    `${statesPath}/AB.kml`,
    `${statesPath}/BC.kml`,
    `${statesPath}/MB.kml`,
    `${statesPath}/NB.kml`,
    `${statesPath}/NL.kml`,
    `${statesPath}/NT.kml`,
    `${statesPath}/NS.kml`,
    `${statesPath}/NU.kml`,
    `${statesPath}/ON.kml`,
    `${statesPath}/PE.kml`,
    `${statesPath}/QC.kml`,
    `${statesPath}/SK.kml`,
    `${statesPath}/YT.kml`,
];
