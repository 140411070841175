import React, { useState } from "react";
import closeIconGrey from "../../assets/images/svg/closeIconGrey.svg";
import DotLoader from "../DotLoaderBtn/DotLoader";
import { handleMapToImage, saveImageAsPdf } from "../../utils/utils";

const ExportMapModal = (props) => {
    const {
        mapDashboardOptionsState,
        handleShowPDF,
        hideBars,
        showBars,
        setMapDashboardOptionsState,
    } = props;

    const [exportState, setExportState] = useState({
        checkPDF: true,
        checkImage: false,
        windowSize: true,
        highResolution: false,
        loading: false,
    });

    const handleExportMap = () => {
        setMapDashboardOptionsState((prevState) => {
            return {
                ...prevState,
                showSettingsOption: false,
            };
        });
        setExportState((prevState) => {
            return {
                ...prevState,
                loading: true,
            };
        });

        hideBars();

        exportState.checkPDF &&
            setTimeout(() => {
                saveImageAsPdf();
            }, 1000);

        exportState.checkImage &&
            setTimeout(() => {
                handleMapToImage();
            }, 1000);

        setTimeout(() => {
            showBars();
            setExportState((prevState) => {
                return {
                    ...prevState,
                    loading: false,
                };
            });
            handleShowPDF();
            setMapDashboardOptionsState((prevState) => {
                return {
                    ...prevState,
                    showSettingsOption: true,
                };
            });
        }, 2000);
    };

    return (
        <section className="exportMap-modal-wrapper">
            <div className="text-right">
                <button
                    className="deleteMarker-modal-close"
                    onClick={() => handleShowPDF()}
                    disabled={exportState.loading}
                >
                    <img src={closeIconGrey} alt="close icon" />
                </button>
            </div>

            <div className="exportMap-modal-content">
                <div className="option-wrapper">
                    <h4>Image export format</h4>
                    <div className=" form-check">
                        <input
                            type="radio"
                            id="pdf"
                            name="format"
                            onChange={() =>
                                setExportState((prevState) => {
                                    return {
                                        ...prevState,
                                        checkPDF: true,
                                        checkImage: false,
                                    };
                                })
                            }
                            checked={exportState.checkPDF}
                            value="pdf"
                            className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="pdf">
                            PDF
                        </label>
                    </div>
                    <div className=" form-check">
                        <input
                            type="radio"
                            id="png_image"
                            name="format"
                            onChange={() =>
                                setExportState((prevState) => {
                                    return {
                                        ...prevState,
                                        checkImage: true,
                                        checkPDF: false,
                                    };
                                })
                            }
                            checked={exportState.checkImage}
                            value="pdfImage"
                            className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="png_image">
                            PNG image
                        </label>
                    </div>
                </div>
                <div className="option-wrapper">
                    <h4>Image size</h4>
                    <div className="form-check">
                        <input
                            type="radio"
                            id="imgae_size"
                            name="size"
                            onChange={() =>
                                setExportState((prevState) => {
                                    return {
                                        ...prevState,
                                        windowSize: true,
                                        highResolution: false,
                                    };
                                })
                            }
                            checked={exportState.windowSize}
                            value="ImageSize"
                            className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="imgae_size">
                            Standard 8.5” x 11” printout
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            type="radio"
                            id="high_resolution"
                            name="size"
                            onChange={() =>
                                setExportState((prevState) => {
                                    return {
                                        ...prevState,
                                        highResolution: true,
                                        windowSize: false,
                                    };
                                })
                            }
                            checked={exportState.highResolution}
                            value="high resolution"
                            className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="high_resolution">
                            High resolution 11” x 17” printout
                        </label>
                    </div>
                </div>
            </div>

            <div className="exportMap-modal-actions">
                <button
                    type="button"
                    aria-label="Close"
                    className="btn cancel-border-btn"
                    onClick={() => handleShowPDF()}
                    disabled={exportState.loading}
                >
                    Cancel
                </button>
                {exportState.loading ? (
                    <button
                        className="btn animate-dots-blue cta-border-blue-auto"
                        type="button"
                        disabled
                    >
                        <DotLoader />
                    </button>
                ) : (
                    <button
                        type="button"
                        className="btn cta-filled-blue-auto"
                        onClick={() => handleExportMap()}
                    >
                        Export
                    </button>
                )}
            </div>
        </section>
    );
};

export default ExportMapModal;
