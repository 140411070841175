import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import ThankyouRegistered from "../../components/ThankyouRegistered/ThankyouRegistered";
import Footer from "../../components/Sidebar/Footer";
import eyeIcon from "../../assets/images/svg/eyeIcon.svg";
import eyeIconOff from "../../assets/images/svg/eyeIconOff.svg";
import checkMark from "../../assets/images/svg/checkmark.svg";
import checkMarkGreen from "../../assets/images/svg/checkMark-green.svg";
import DotLoader from "../../components/DotLoaderBtn/DotLoader";

import { errorMessage, successMessage } from "../../utils/messages";
import { useAddUserMutation, useResendVerificationMutation } from "../../services/baseApi";
import { countryList } from "../../utils/country_list_with_code";
import { useEffect } from "react";
import { checkEmailValid } from "../../utils/utils";

const Register = (props) => {
    const location = useLocation();

    const [registerState, setRegisterState] = useState({
        firstName: location.state?.firstName || "",
        lastName: location.state?.lastName || "",
        email: location.state?.email || "",
        countryCode: "+1",
        stateCode: "",
        phone: "",
        country: "United States of America",
        password: "",
        confirmPassword: "",
        error: false,
        success: false,
        showPassword: false,
        passwordMatch: true,
    });

    const [regexState, setRegexState] = useState({
        stringLength: false,
        upperCase: false,
        lowerCase: false,
        specialChar: false,
        numberVal: false,
    });

    const [addUser, { isLoading: addUserLoading }] = useAddUserMutation();
    const [resendVerification, { isLoading: verifyLoading }] = useResendVerificationMutation();

    const countryHandler = (e) => {
        let selectedCountry = e.target.value;

        setRegisterState((prevState) => {
            return {
                ...prevState,
                country: selectedCountry,
            };
        });
    };

    const handleSubmit = async (e, showToast) => {
        e.preventDefault();

        if (checkEmailValid(location.state?.email || registerState.email)) {
            errorMessage("Invalid Email Format");
            return;
        }

        const form = new FormData();
        form.append("first_name", registerState.firstName);
        form.append("last_name", registerState.lastName);
        form.append("email", location.state?.email || registerState.email);
        form.append("password", registerState.password);
        form.append("mobile", registerState.phone);
        form.append(
            "countryCode",
            countryList.find((item) => item.country === registerState.country)?.countryCode ||
                registerState.countryCode
        );
        form.append("stateCode", registerState.stateCode);
        form.append("country", registerState.country);

        form.append("type", location.state?.type || null);
        form.append("access_token", location.state?.accessToken || null);
        form.append("userID", location.state?.fbUserID || null);
        form.append("jwtToken", location.state?.jwtToken || null);

        const { data, error } = await addUser(form);

        if (data) {
            setRegisterState((prevState) => {
                return {
                    ...prevState,
                    success: true,
                };
            });
            showToast && successMessage("Successfully sent");
        }

        if (error) {
            errorMessage(error.data?.message);
            setRegisterState((prevState) => {
                return {
                    ...prevState,
                    error: true,
                };
            });
        }
    };

    const handleSendVerificationAgain = async () => {
        const { data, error } = await resendVerification({ email: registerState.email });

        if (data) {
            successMessage("Successfully sent");
        }

        if (error) {
            errorMessage(error.data?.message);
        }
    };

    const handleCPassword = (e) => {
        let checkPassword = e.target.value.trim();
        setRegisterState((prevState) => {
            return {
                ...prevState,
                confirmPassword: checkPassword,
                passwordMatch: registerState.password.startsWith(checkPassword),
            };
        });
    };

    const validatePassword = (password) => {
        const checkPassword = {
            stringLength: password.length >= 8,
            upperCase: /[A-Z]/g.test(password),
            lowerCase: /[a-z]/g.test(password),
            specialChar: /(?=.*?[@!#$%+/=-])/g.test(password),
            numberVal: /\d/g.test(password),
        };

        setRegexState(checkPassword);
    };

    return (
        <>
            <section className="signinView--container">
                <>
                    <div className="signinView-wrapper">
                        <div className="col-lg-4 signUp-content">
                            <div className="signUp-content-inner">
                                <div className="signUp-box-wrapper">
                                    <h2 className="title">Already have an account?</h2>
                                    <div className="sign-up-cta">
                                        <Link to="/login">Log in</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 credentials-wrapper">
                            <div className="credentials-wrapper-inner">
                                <div className="credentials-content">
                                    <form onSubmit={handleSubmit} autoComplete="off">
                                        <div className="header">
                                            <h2 className="title">Sign up</h2>
                                        </div>
                                        <div className="field-group">
                                            <label>First</label>
                                            <input
                                                type="text"
                                                aria-label="First Name"
                                                placeholder="John"
                                                defaultValue={registerState.firstName}
                                                onChange={(e) =>
                                                    setRegisterState((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            firstName: e.target.value,
                                                        };
                                                    })
                                                }
                                                required
                                            />
                                        </div>
                                        <div className="field-group">
                                            <label>Last</label>
                                            <input
                                                type="text"
                                                aria-label="Last Name"
                                                placeholder="Doe"
                                                defaultValue={registerState.lastName}
                                                onChange={(e) =>
                                                    setRegisterState((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            lastName: e.target.value,
                                                        };
                                                    })
                                                }
                                                required
                                            />
                                        </div>
                                        <div className="field-group">
                                            <label>Email</label>
                                            <input
                                                type="email"
                                                aria-label="email address"
                                                placeholder="john.doe@xoommaps.com"
                                                defaultValue={registerState.email}
                                                disabled={location.state?.email}
                                                onChange={(e) => {
                                                    if (location.state?.email) {
                                                        return;
                                                    }

                                                    setRegisterState((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            email: e.target.value,
                                                        };
                                                    });
                                                }}
                                                required
                                            />
                                        </div>
                                        <div className="field-group">
                                            <label>Country / Region</label>
                                            <div className="input-groups signup-country">
                                                <select
                                                    value={registerState.country}
                                                    onChange={countryHandler}
                                                    id="country_picker_select"
                                                >
                                                    {countryList.map((option) => (
                                                        <option
                                                            key={option.country}
                                                            value={option.country}
                                                        >
                                                            {option.country}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="field-group">
                                            <label>Phone</label>
                                            <div className="signup-phone-wrapper">
                                                <input
                                                    type="tel"
                                                    required
                                                    disabled
                                                    value={
                                                        countryList.find(
                                                            (item) =>
                                                                item.country ===
                                                                registerState.country
                                                        )?.countryCode || registerState.phoneCode
                                                    }
                                                    onChange={(e) =>
                                                        setRegisterState((prevState) => {
                                                            return {
                                                                ...prevState,
                                                                countryCode: e.target.value,
                                                            };
                                                        })
                                                    }
                                                />
                                                <input
                                                    type="number"
                                                    aria-label="Phone Number"
                                                    autoComplete="off"
                                                    placeholder="510"
                                                    required
                                                    value={registerState.stateCode}
                                                    onChange={(e) => {
                                                        if (e.target.value < 10000)
                                                            setRegisterState((prevState) => {
                                                                return {
                                                                    ...prevState,
                                                                    stateCode: e.target.value,
                                                                };
                                                            });
                                                    }}
                                                />
                                                <input
                                                    type="number"
                                                    aria-label="Phone Number"
                                                    autoComplete="off"
                                                    placeholder="555-0123"
                                                    maxLength="7"
                                                    value={registerState.phone}
                                                    onChange={(e) =>
                                                        setRegisterState((prevState) => {
                                                            return {
                                                                ...prevState,
                                                                phone: e.target.value,
                                                            };
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="field-group">
                                            <label>Password</label>
                                            <div className="pos-rel">
                                                <input
                                                    type={
                                                        registerState.showPassword
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    aria-label="Password"
                                                    autoComplete="new-password"
                                                    onChange={(e) => {
                                                        let value = e.target.value.trim();

                                                        setRegisterState((prevState) => {
                                                            return {
                                                                ...prevState,
                                                                password: value,
                                                            };
                                                        });
                                                    }}
                                                    value={registerState.password}
                                                    onKeyUp={(e) => {
                                                        let value = e.target.value.trim();
                                                        validatePassword(value);
                                                    }}
                                                    required
                                                />
                                                <img
                                                    src={
                                                        registerState.showPassword
                                                            ? eyeIconOff
                                                            : eyeIcon
                                                    }
                                                    className="eye-password-toggle"
                                                    alt="eye-password-toggle"
                                                    style={{ top: 8, right: 8 }}
                                                    onClick={() => {
                                                        setRegisterState((prevState) => {
                                                            return {
                                                                ...prevState,
                                                                showPassword:
                                                                    !prevState.showPassword,
                                                            };
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="signup-password-specs">
                                            <p>Your password must contain:</p>

                                            <div className="checklist">
                                                <img
                                                    src={
                                                        regexState.stringLength
                                                            ? checkMarkGreen
                                                            : checkMark
                                                    }
                                                    alt="checkbox"
                                                />
                                                <span>Eight character minimum.</span>
                                            </div>
                                            <div className="checklist">
                                                <img
                                                    src={
                                                        regexState.upperCase
                                                            ? checkMarkGreen
                                                            : checkMark
                                                    }
                                                    alt="checkbox"
                                                />
                                                <span>One uppercase character.</span>
                                            </div>
                                            <div className="checklist">
                                                <img
                                                    src={
                                                        regexState.lowerCase
                                                            ? checkMarkGreen
                                                            : checkMark
                                                    }
                                                    alt="checkbox"
                                                />
                                                <span>One lowercase character.</span>
                                            </div>
                                            <div className="checklist">
                                                <img
                                                    src={
                                                        regexState.numberVal
                                                            ? checkMarkGreen
                                                            : checkMark
                                                    }
                                                    alt="checkbox"
                                                />
                                                <span>One number.</span>
                                            </div>
                                            <div className="checklist">
                                                <img
                                                    src={
                                                        regexState.specialChar
                                                            ? checkMarkGreen
                                                            : checkMark
                                                    }
                                                    alt="checkbox"
                                                />
                                                <span>
                                                    One special character: @ ! # $ % + / = -
                                                </span>
                                            </div>
                                        </div>
                                        <div className="field-group">
                                            <label>Confirm password</label>
                                            <input
                                                type="password"
                                                aria-label="Confirm Password"
                                                onChange={handleCPassword}
                                                autoComplete="off"
                                                required
                                                className={`${
                                                    !registerState.passwordMatch ? "error" : ""
                                                }`}
                                            />

                                            {!registerState.passwordMatch && (
                                                <div className="passwordError">
                                                    {" "}
                                                    Error: Passwords do not match{" "}
                                                </div>
                                            )}
                                        </div>

                                        {addUserLoading ? (
                                            <button
                                                className="btn animate-dots-blue login-cta-outline"
                                                type="button"
                                                disabled
                                            >
                                                <DotLoader />
                                            </button>
                                        ) : (
                                            <button
                                                type="submit"
                                                className="btn login-btn"
                                                disabled={
                                                    registerState.password ===
                                                        registerState.confirmPassword &&
                                                    regexState.stringLength &&
                                                    regexState.upperCase &&
                                                    regexState.lowerCase &&
                                                    regexState.specialChar &&
                                                    regexState.numberVal
                                                        ? false
                                                        : true
                                                }
                                            >
                                                Sign up
                                            </button>
                                        )}
                                        <p className="term-condition">
                                            By clicking “Sign up”, you are agreeing to our
                                            <span>
                                                <Link to="/sign-up">Terms and Conditions</Link>
                                            </span>
                                            .
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {registerState.success && (
                        <ThankyouRegistered
                            registerState={registerState}
                            setRegisterState={setRegisterState}
                            handleSendVerificationAgain={handleSendVerificationAgain}
                        />
                    )}
                </>
            </section>
            <Footer />
        </>
    );
};

export default Register;
