import io from "socket.io-client";
import { GEO_MAPPING_BASE_URL } from "../config";

const socket = io(GEO_MAPPING_BASE_URL, {
    autoConnect: false,
    closeOnBeforeunload: false,
});
// const socket = io("http://localhost:5000");

export function addSetSocketMap(map) {
    if (socket.maps) {
        let index = socket.maps.findIndex((innerMap) => innerMap.id === map.id);

        if (index !== -1) {
            socket.maps[index] = map;
        } else {
            socket.maps.push(map);
        }
    } else {
        socket.maps = [map];
    }
}

export function updateSocketMap(updatedMap) {
    if (socket.maps) {
        let index = socket.maps?.findIndex((map) => map.id === updatedMap.id);

        if (index !== -1) {
            socket.maps[index] = updatedMap;
        }
    }
}

export function deleteSocketMap(id) {
    if (socket.maps) {
        socket.maps = socket.maps?.filter((map) => map.id !== id);
    }
}

export default socket;
