import React from "react";

export const GoogleIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="icon" transform="translate(-2.546 -0.006)">
                <g
                    id="c_olor"
                    data-name="c olor"
                    transform="translate(2.546 0.006)"
                    fill="#fff"
                    stroke="#707070"
                    stroke-width="1"
                    opacity="0"
                >
                    <rect width="24" height="24" stroke="none" />
                    <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
                </g>
                <path
                    id="Icon_awesome-google"
                    data-name="Icon awesome-google"
                    d="M16,8.75a7.489,7.489,0,0,1-7.869,7.813A8.057,8.057,0,0,1,0,8.563a8.057,8.057,0,0,1,8.131-8,7.89,7.89,0,0,1,5.452,2.094L11.37,4.75a5.028,5.028,0,1,0-3.239,8.865,4.378,4.378,0,0,0,4.616-3.448H8.131V7.414h7.741A6.9,6.9,0,0,1,16,8.75Z"
                    transform="translate(6.746 3.426)"
                    fill="#32373c"
                />
            </g>
        </svg>
    );
};
