import React from "react";

const DotLoader = () => {
    return (
        <div className="load">
            <div className="one"></div>
            <div className="two"></div>
            <div className="three"></div>
        </div>
    );
};

export default DotLoader;
