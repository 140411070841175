import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMapDashboardId } from "../../../store/slicers/mapDashboardSlice";
import dummyUser from "../../../assets/images/avatar-grey.png";
import noImage from "../../../assets/images/noImage.png";
import chevronDown from "../../../assets/images/svg/chevronDown.svg";
import chevronUp from "../../../assets/images/chevronUp.png";
import DotLoader from "../../../components/DotLoaderBtn/DotLoader";

const MyMapNocList = (props) => {
    const dispatch = useDispatch();
    const mapDashboardId = useSelector((state) => state.mapDashboardState.id);

    const [checkedItems, setCheckedItems] = useState({});

    const handleItemClick = (index) => {
        setCheckedItems((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    return (
        <section
            className={`nocList-container ${
                props.collapseBar.guageBar ? "collapsebar-active" : ""
            }`}
        >
            <ul className="nocList-wrapper">
                {props.data.map((item, index) => {
                    return (
                        <>
                            <li
                                className={`nocitem-wrapper ${
                                    mapDashboardId === item._id ? "active" : ""
                                }`}
                                key={index}
                            >
                                <div
                                    className="noc-singlemap-item"
                                    onClick={() => {
                                        props.handleAccordionClick(index);
                                        dispatch(setMapDashboardId(item._id));
                                        props.getMyMapsGroups(item._id);
                                    }}
                                    onMouseEnter={() => props.handleMouseEnter(index)}
                                    onMouseLeave={() => props.handleMouseLeave()}
                                >
                                    <div className="content">
                                        <img
                                            src={item?.mapImage || noImage}
                                            alt="profile"
                                            className="mapimage"
                                        />
                                        <div>
                                            <h5 className="mapList-title">{item.title}</h5>
                                            {props.isHoveredMap.isHoverd &&
                                            index === props.isHoveredMap.id ? (
                                                <p className="count-style-one">
                                                    {item?.count || 0} markers
                                                </p>
                                            ) : (
                                                <p className="count-style-one">
                                                    {item?.directGroups?.length || 0} groups
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="accordion-icon">
                                        {" "}
                                        {props.activeAccordion === index ? (
                                            <img src={chevronUp} alt="chevron up"></img>
                                        ) : (
                                            <img src={chevronDown} alt="chevron up"></img>
                                        )}
                                    </div>
                                </div>
                                {props.activeAccordion === index && (
                                    <>
                                        {props.groupsListLoading.groupsByMapIdLoading ? (
                                            <div className="null-data-response animate-dots-blue bg-trans">
                                                <DotLoader />
                                            </div>
                                        ) : (
                                            <>
                                                {props.groupData?.length ? (
                                                    <>
                                                        {props?.groupData.map((data, index) => {
                                                            return (
                                                                <>
                                                                    <div
                                                                        key={index}
                                                                        className="noc-group-lists-item"
                                                                        onClick={() => {
                                                                            handleItemClick(index);
                                                                            props.toggleNocMapMarkers(
                                                                                data._id
                                                                            );
                                                                        }}
                                                                    >
                                                                        <div className="content">
                                                                            <label className="custom-label">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={
                                                                                        checkedItems[
                                                                                            index
                                                                                        ]
                                                                                    }
                                                                                    onClick={(e) =>
                                                                                        e.stopPropagation()
                                                                                    }
                                                                                    name="mapListcheck"
                                                                                />
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                            <img
                                                                                src={
                                                                                    data?.mapImage ||
                                                                                    noImage
                                                                                }
                                                                                className="mapimage"
                                                                                alt="map avatar"
                                                                            />
                                                                            <div>
                                                                                <p className="title">
                                                                                    {data?.title}
                                                                                </p>
                                                                                <p className="count">
                                                                                    {data?.count ||
                                                                                        0}{" "}
                                                                                    markers
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <img
                                                                                src={
                                                                                    data?.userAvatar ||
                                                                                    dummyUser
                                                                                }
                                                                                alt="groupmap"
                                                                                className="mapimage"
                                                                                style={{
                                                                                    borderRadius:
                                                                                        "50%",
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            );
                                                        })}
                                                    </>
                                                ) : (
                                                    <>
                                                        <p className="null-data-response bg-trans">
                                                            No results found
                                                        </p>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </li>
                        </>
                    );
                })}
            </ul>
        </section>
    );
};

export default MyMapNocList;
