import React from "react";
import avatar from "../../../assets/images/avatar-male.jpg";
import DotLoader from "../../DotLoaderBtn/DotLoader";

const ConnectedUserList = (props) => {
    const { data, shareMapEmail, shareEmail } = props;

    return (
        <div className="shareMap-user-item-wrapper">
            <label className="custom-label" htmlFor={data?.email}>
                <div className="shareMap-user-img">
                    {data?.avatar && data?.avatar !== "null" ? (
                        <img src={data?.avatar} alt="user" />
                    ) : (
                        <img src={avatar} alt="user" />
                    )}
                </div>
                <div className="moveMarker-map-detail">
                    <h5 className="title">
                        {data?.first_name || "New user"} {data?.last_name}
                    </h5>
                    <p className="count">{data?.email}</p>
                </div>
                {!data?.isShared ? (
                    shareEmail?.loading && shareEmail.value[0]?.email === data?.email ? (
                        <button
                            className=" share-map-dots-btn btn animate-dots-blue "
                            type="button"
                            disabled
                        >
                            <DotLoader />
                        </button>
                    ) : (
                        <button
                            onClick={(e) => shareMapEmail(e, [{ email: data?.email }])}
                            className="share-map-btn  btn "
                        >
                            Share
                        </button>
                    )
                ) : (
                    <button className="share-disable-btn disabled btn ">Shared</button>
                )}
            </label>
        </div>
    );
};

export default ConnectedUserList;
