import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../components/Sidebar/Sidebar";
import { useIdleTimer } from "react-idle-timer";
import LoadingAnimation from "../components/LoadingAnimation/LoadingAnimation";
import socket from "../socket/socket";
import { axiosInstance } from "../apiCalls/axios";
import { successMessage } from "../utils/messages";
import { autoLogout } from "../store/slicers/authSlice";
import {
    setAllNotification,
    setNotificationsError,
    setNotificationsLoading,
    showSystemNotificationsModel,
} from "../store/slicers/systemNotificationsSlice";
import { useGetSystemNotificationsQuery } from "../services/baseApi";
import Notifications from "./SystemNotificationsModel/Notifiactions";
import ReactPortal from "../components/Portal/ReactPortal";
import HowTo from "./howTo/HowTo";

const timeInMilliSeconds = 1000;
const timeInSeconds = timeInMilliSeconds * 60; // 60s = 1min
const logoutTime = timeInSeconds * 60; // 1min * 60 = 60mins = 1hr

const Home = () => {
    const { user } = useSelector((state) => state.auth);
    const SystemNotificationsModel = useSelector(showSystemNotificationsModel);
    const [systemNotifcationsFilter, setSystemNotifcationsFilter] = useState({
        email: user?.email,
        pageNum: 1,
        filter: 1,
        search: "",
    });

    const [howToModelOpen, setHowToModelOpen] = useState(false);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const onIdle = () => {
        localStorage.clear();

        // DELETE USER TOKEN
        axiosInstance.defaults.headers = {
            "x-auth-token": "",
        };
        navigate("/login");
        setTimeout(() => {
            dispatch(autoLogout(true));
            successMessage("Automatically log out due to inactivity");
        }, 1000);
    };

    const { reset } = useIdleTimer({
        onIdle,
        timeout: logoutTime,
    });

    useEffect(() => {
        socket.resetLogoutTimer = reset;
    }, []);

    ////////setNotifications Data to Redux ////////
    //  notificatiosn implmentation
    let paramNotifications = {
        email: user?.email,
        pageNum: systemNotifcationsFilter?.pageNum,
        filter: systemNotifcationsFilter?.filter,
        search: systemNotifcationsFilter?.search,
    };
    const {
        isFetching: systemNotificationsFetching,
        data: systemNotificationsData,
        error: systemNotificationsError,
    } = useGetSystemNotificationsQuery(paramNotifications);

    useEffect(() => {
        if (systemNotificationsFetching) {
            dispatch(setNotificationsLoading(systemNotificationsFetching));
        }
        if (systemNotificationsData?.data) {
            dispatch(setAllNotification(systemNotificationsData?.data));
        }
        if (systemNotificationsError?.data?.error) {
            dispatch(setNotificationsError(systemNotificationsError?.data?.error));
        }
        if (!systemNotificationsFetching) {
            dispatch(setNotificationsLoading(systemNotificationsFetching));
        }
    }, [
        dispatch,
        systemNotificationsData?.data,
        systemNotificationsFetching,
        systemNotificationsError,
    ]);

    return user ? (
        <section className="home-sidebar-flex">
            <Sidebar setHowToModelOpen={setHowToModelOpen} />

            <main className="map-main-parent--container">
                <Outlet />
                {!!SystemNotificationsModel && (
                    <Notifications
                        systemNotifcationsFilter={systemNotifcationsFilter}
                        setSystemNotifcationsFilter={setSystemNotifcationsFilter}
                    />
                )}
                <section className="box-model-how-to-container">
                    {howToModelOpen && (
                        <ReactPortal wrapperId="external_modal_container">
                            <HowTo setHowToModelOpen={setHowToModelOpen} />
                        </ReactPortal>
                    )}
                </section>
            </main>
        </section>
    ) : (
        <LoadingAnimation />
    );
};

export default Home;
