import React from "react";

function ActionTabsList({ tabsList, activeTab, handleTabClick }) {
    return (
        <ul className="tabsList">
            {tabsList.map((tab) => {
                return (
                    <li key={tab.id}>
                        <button
                            className={`tabsListItem ${activeTab === tab.id ? "active" : ""}`}
                            onClick={handleTabClick(tab.id)}
                        >
                            {tab.label}
                        </button>
                    </li>
                );
            })}
        </ul>
    );
}

export default ActionTabsList;
