import React from "react";
import avatar from "../../assets/images/avatar-male.jpg";
import threeDot from "../../assets/images/svg/threeDot.svg";
import SpinLoader from "../../components/SpinLoader/SpinLoader";
import trashIcon from "../../assets/images/svg/trashIconRed.svg";
import { useState } from "react";
import { formatDistanceToNow, parse } from "date-fns";
import TimeEcerpt from "./TimeEcerpt";
import {
    useDeleteSystemNotificationMutation,
    useSetSystemNotificationsStatusMutation,
} from "../../services/baseApi";

// import DotLoader from "../../DotLoaderBtn/DotLoader";

const NotificationUser = (props) => {
    // const { data, isShared, shareMapEmail, shareEmail, searchState } = props;
    const { item, index, loadMore } = props;

    const [isActiveN, setIsActiveN] = useState(null);
    const [deleteBTn, setDeleteBtn] = useState(false);
    // console.log(item);
    const [setSystemNotificationsStatus] = useSetSystemNotificationsStatusMutation();
    const [deleteSystemNotification] = useDeleteSystemNotificationMutation();
    const handleStatus = (id, status) => {
        setSystemNotificationsStatus({ id, status });
    };
    const handleDelete = (id) => {
        deleteSystemNotification(id);
    };

    let Content;
    const renderNotificationsData = () => {
        return (Content = (
            <div className={`notification-user-container`}>
                <div className={` notification-user-wrapper flex-between`}>
                    <div className="notification-user-img flex-center">
                        {item?.sender?.avatar && item?.sender?.avatar !== "null" ? (
                            <img src={item?.sender?.avatar} alt="user" />
                        ) : (
                            <img src={avatar} alt="user" />
                        )}
                        <div className="notifcation-user-text-wrapper ">
                            <div className=" notifcation-user-title flex-center">
                                <p title={item?.content || "Send you an iniviation."}>
                                    {item?.sender?.first_name || "New user"}{" "}
                                    {item?.sender?.last_name}
                                    <span>{item?.content || "Send you an iniviation."}</span>
                                </p>
                            </div>
                            <div className=" notifcation-user-time flex-gap-y5">
                                <TimeEcerpt date={item?.createdAt} />
                            </div>
                        </div>
                    </div>
                    <div className="notification-threeDot">
                        <img style={{ width: "20px" }} src={threeDot} alt="threeDotImage" />
                        <div>
                            <button
                                onClick={() => handleStatus(item?._id, false)}
                                className={`btn flex-center ${!item?.unread ? "disableBtn" : ""}`}
                                style={{ backgroundColor: "" }}
                                disabled={!item?.unread}
                            >
                                Read
                            </button>
                            <button
                                onClick={() => handleStatus(item?._id, true)}
                                className={`btn flex-center ${!item?.unread ? "" : "disableBtn"}`}
                                disabled={item?.unread}
                            >
                                Unread
                            </button>
                            <button
                                onClick={() => handleDelete(item?._id)}
                                className="btn flex-center"
                            >
                                Remove
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        ));
    };

    return <>{renderNotificationsData()}</>;
};

export default NotificationUser;
