/* global google */
import { Circle, GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import React, { memo, useEffect, useMemo } from "react";
import { GEO_MAPPING_GOOGLE_API_KEY } from "../../config";
import { mapStyles } from "../../utils/mapUtils";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";

function Map({ children, componentProps }) {
    const googleMapLibraries = useMemo(
        () => ["drawing", "geometry", "localContext", "places", "visualization", "marker"],
        []
    );
    const { setContextOptions, handleContextClick } = componentProps;

    const isEditMode =
        window.location.href.includes("create-map") || window.location.href.includes("edit-map");

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: `${GEO_MAPPING_GOOGLE_API_KEY}`,
        libraries: googleMapLibraries,
        language: "en",
        region: "EN",
        version: "weekly",
        mapIds: ["e1e9471c83ae516"],
    });

    const handleContext = (e) => {
        e.stopPropagation();
        if (
            e.target.classList.contains("container-ignore") ||
            e.target.closest(".container-ignore") ||
            e.target.closest(".drawing-manager-wrapper")
        )
            return;
        let value1 = e.clientX < 312 ? `${e.clientX - 98}px` : `${e.clientX - 300}px`;
        let value2 = e.clientY < 100 ? `${e.clientY + 210}px` : `${e.clientY + 150}px`;
        if (setContextOptions) {
            setContextOptions((prevState) => {
                return {
                    ...prevState,
                    showOptionModel: !prevState?.showOptionModel,
                    LeftX: value1,
                    TopY: value2,
                };
            });
        }
    };

    useEffect(() => {
        if (setContextOptions) {
            if (!isLoaded) {
                setContextOptions((prevState) => {
                    return {
                        ...prevState,
                        showOptionModel: false,
                    };
                });
            }
        }
    }, [isLoaded, setContextOptions]);

    return isLoaded ? (
        <>
            <div
                className="google-map-container"
                onContextMenu={handleContext}
                onClick={handleContextClick}
                id="google_map_container"
                style={{
                    height: `${
                        isEditMode ? "clamp(100vh, 100vh, 100vh)" : "clamp(92vh, 92vh, 92vh)"
                    }`,
                }}
            >
                <GoogleMap
                    id="my_google_map"
                    mapId="e1e9471c83ae516"
                    mapTypeId="e1e9471c83ae516"
                    mapContainerStyle={componentProps.containerStyle}
                    center={componentProps.center}
                    onLoad={(map) =>
                        componentProps.onMapLoad({
                            map,
                            maps: window.google.maps,
                        })
                    }
                    options={{
                        styles: mapStyles[componentProps.mapStyle || "simple"],
                        zoom:
                            componentProps.defaultZoom === true
                                ? undefined
                                : componentProps.defaultZoom,
                        minZoom: componentProps.maxZoomOut,
                        maxZoom: componentProps.maxZoomIn,
                        gestureHandling: "cooperative",
                    }}
                >
                    <section className="container-ignore">{children}</section>
                </GoogleMap>
            </div>
        </>
    ) : (
        <LoadingAnimation />
    );
}

export default memo(Map);
