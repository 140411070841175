import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ActionTabsList from "../../components/ActionTabsList/ActionTabsList";
import userCoverImage from "../../assets/images/userProfileCover.png";
import userPrefernceCoverImage from "../../assets/images/profilePrefernceCover.png";
import userIcon from "../../assets/images/avatar-grey.png";
import "./profile.css";
import ProfileContact from "./ProfileContact";
import ProfilePreference from "./ProfilePreference";

import ReactPortal from "../../components/Portal/ReactPortal";
import { ManageUserCallSalesModal } from "../../components/Modals/ManageUserCallSalesModal";
import CustomToastMsg from "../../components/Modals/CustomToastMsg";
import ProfilePlanDetails from "./ProfilePlanDetails";
import ProfileNotification from "./ProfileNotification";
import ProfileSupport from "./ProfileSupport";
import CancelSubscriptionModal from "../../components/Modals/CancelSubscriptionModal";
import {
    useConfirmUserVerificationCodeMutation,
    useDeleteUserCoverImageMutation,
    useDeleteUserProfileImageMutation,
    useGetAllGroupsQuery,
    useGetAllMapsQuery,
    useGetAllRoutesQuery,
    useGetAllSharedMapsQuery,
    useGetUserDataQuery,
    useGetUserNotificationsQuery,
    useResetPasswordOnLoginMutation,
    useSendUserCodeMutation,
    useUpdateUserDataMutation,
    useVerifyTwoStepVerificationMutation,
} from "../../services/baseApi";
import { useEffect } from "react";
import { errorMessage, successMessage } from "../../utils/messages";
import ImageCropperModal from "../../components/Modals/ImageCropper/ImageCropperModal";
import { setUser, setUserImage } from "../../store/slicers/authSlice";
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation";
import "../../components/ActionTabsList/actionTabsList.css";
import TwoStepVerification from "../auth/TwoStepVerification";
import CodeLogin from "../auth/CodeLogin";
import LoginWithCode from "../auth/LoginWithCode";
import ResetPasswordMessage from "../auth/ResetPasswordMessage";
import ResetpasswordModal from "../auth/ResetpasswordModal";
import ProfileVerificationModel from "./ProfileVerificationModel";
import { countryList } from "../../utils/country_list_with_code";

const tabsList = [
    {
        label: "Contact",
        id: "contact",
    },
    {
        label: "Preferences",
        id: "preference",
    },
    {
        label: "Notifications",
        id: "notifications",
    },
    {
        label: "Support",
        id: "support",
    },
];

const Profile = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);

    const [activeTab, setActiveTab] = useState("contact");

    const [userDataState, setUserDataState] = useState({
        userData: null,
        coworkersData: null,
        showCancelSubscriptionModal: false,
        openImageCropperModal: false,
        openImageCropperCircleModal: false,
        notificationsData: null,
        verificationLoading: false,
        isTwoStepVerificationModelOpen: false,
        twoStepVerifyCode: "",
    });
    const [profileContactState, setProfileContactState] = useState({
        first_name: "",
        last_name: "",
        email: "",
        countryCode: "",
        stateCode: "",
        mobile: "",
        country: "",
        city: "",
        state: "",
        address: "",
        zip: "",
    });

    const [baseState, setBaseState] = useState({
        error: false,
        success: false,
        loading: false,
    });

    const [passwordState, setPasswordState] = useState({
        oldPassword: "",
        showOldPassword: false,
        password: "",
        confirmPassword: "",
        showPassword: false,
        showPasswordModal: false,
        passwordMatch: true,
        showOldFeild: true,
    });

    const [preferenceState, setPreferenceState] = useState({
        showWhiteLabelPopup: false,
        showWhiteLabelModal: false,
        whiteLabelLoading: false,
        confirmWhiteLabelPassword: "",
        loading: false,
    });

    const [manageUsersState, setManageUsersState] = useState({
        openCallSalesModal: false,
        showCustomToast: false,
    });

    //Storing percentage value of all three sharedMaps, groups, routes
    const [mapPercentagesState, setMapPercentagesState] = useState({
        myMaps: null,
        mySharedMaps: null,
        myRoutes: null,
        myGroups: null,
    });

    const { data: userProfileData, error: userProfileDataError } = useGetUserDataQuery(user.email);

    const { data: allSharedMapsData, error: allSharedMapsError } = useGetAllSharedMapsQuery(
        user.email
    );
    const { data: allMapsData, error: allMapsError } = useGetAllMapsQuery(user.email);
    const { data: allRoutesData, error: allRoutesError } = useGetAllRoutesQuery(user.email);
    const { data: allGroupsData, error: allGroupsError } = useGetAllGroupsQuery(user.email);

    const [deleteUserProfileImage] = useDeleteUserProfileImageMutation();
    const [deleteUserCoverImage] = useDeleteUserCoverImageMutation();

    // Notifications API
    const { data: allNotificationsData, error: allNotificationsError } =
        useGetUserNotificationsQuery(user._id);

    //getting user data
    useEffect(() => {
        if (userProfileData) {
            setUserDataState((prevState) => {
                return {
                    ...prevState,
                    userData: userProfileData,
                };
            });

            dispatch(setUser(userProfileData));
            dispatch(setUserImage(userProfileData?.avatar));
        }

        if (userProfileDataError) {
            errorMessage(userProfileDataError?.data?.message);
        }
    }, [userProfileData, userProfileDataError]);

    //getting maps percentage
    useEffect(() => {
        if (allMapsData) {
            setMapPercentagesState((prevState) => {
                return {
                    ...prevState,
                    myMaps: allMapsData,
                };
            });
        }

        if (allMapsError) {
            errorMessage(allMapsError?.data?.message);
        }
    }, [allMapsData, allMapsError]);

    //getting routes percentage
    useEffect(() => {
        if (allRoutesData) {
            setMapPercentagesState((prevState) => {
                return {
                    ...prevState,
                    myRoutes: allRoutesData,
                };
            });
        }

        if (allRoutesError) {
            errorMessage(allRoutesError?.data?.message);
        }
    }, [allRoutesData, allRoutesError]);

    //getting groups percentage
    useEffect(() => {
        if (allGroupsData) {
            setMapPercentagesState((prevState) => {
                return {
                    ...prevState,
                    myGroups: allGroupsData,
                };
            });
        }

        if (allGroupsError) {
            errorMessage(allGroupsError?.data?.message);
        }
    }, [allGroupsData, allGroupsError]);

    //getting sharedMaps percentage
    useEffect(() => {
        if (allSharedMapsData) {
            setMapPercentagesState((prevState) => {
                return {
                    ...prevState,
                    mySharedMaps: allSharedMapsData,
                };
            });
        }

        if (allSharedMapsError) {
            errorMessage(allSharedMapsError?.data?.message);
        }
    }, [allSharedMapsData, allSharedMapsError]);

    //getting user notifications
    useEffect(() => {
        if (allNotificationsData) {
            setUserDataState((prevState) => {
                return {
                    ...prevState,
                    notificationsData: allNotificationsData?.data,
                };
            });
        }

        if (allNotificationsError) {
            errorMessage(allNotificationsError?.data?.message);
        }
    }, [allNotificationsData, allNotificationsError]);

    const handleTabClick = (id) => () => {
        setActiveTab(id);
    };

    const handleDeleteCoverImage = async () => {
        const { data, error } = await deleteUserCoverImage(user?.email);
        if (data) {
            setUserDataState((prevState) => {
                return {
                    ...prevState,
                    openImageCropperModal: false,
                };
            });
            successMessage(data?.message);
        }
        if (error) {
            errorMessage(error?.data?.message);
        }
    };

    const handleDeleteUserImage = async () => {
        const { data, error } = await deleteUserProfileImage(user.email);
        if (data) {
            setUserDataState((prevState) => {
                return {
                    ...prevState,
                    openImageCropperCircleModal: false,
                };
            });
            successMessage(data?.message);
        }
        if (error) {
            errorMessage(error?.data?.message);
        }
    };
    const [updateUserData] = useUpdateUserDataMutation();
    const [sendUserCode] = useSendUserCodeMutation();
    const [confirmUserVerificationCode] = useConfirmUserVerificationCodeMutation();

    const handleContactSubmit = async () => {
        // e.preventDefault();
        setBaseState((prevState) => {
            return {
                ...prevState,
                loading: true,
            };
        });

        const { data, error } = await updateUserData(profileContactState);
        if (data) {
            successMessage("Profile updated successfully");
        }
        if (error) {
            errorMessage(error?.data?.message);
        }
        setBaseState((prevState) => {
            return {
                ...prevState,
                loading: false,
            };
        });
    };
    const handleSendTwoStepVerification = async (e) => {
        e.preventDefault();
        setUserDataState((prevState) => {
            return {
                ...prevState,
                verificationLoading: true,
            };
        });

        const { data, error } = await confirmUserVerificationCode({
            code: userDataState.twoStepVerifyCode,
            email: userDataState?.userData?.email,
        });

        if (data) {
            if (activeTab === "contact") {
                handleContactSubmit();
            } else if (activeTab === "preference") {
                handlePasswordSubmit();
                setPasswordState((prevState) => {
                    return {
                        ...prevState,
                        showPasswordModal: false,
                    };
                });
            }
            setUserDataState((prevState) => {
                return {
                    ...prevState,
                    isTwoStepVerificationModelOpen: false,
                };
            });
        }

        if (error) {
            errorMessage(error.data?.message);
        }
        setUserDataState((prevState) => {
            return {
                ...prevState,
                verificationLoading: false,
            };
        });
    };

    const handleUpdateClick = async (e) => {
        e.preventDefault();
        if (
            userDataState?.userData &&
            userDataState?.userData?.mobile !== profileContactState.mobile
        ) {
            const { data, error } = await sendUserCode({ email: userDataState?.userData?.email });
            if (data) {
                successMessage("OTP sent successfully");
                setUserDataState((prevState) => {
                    return {
                        ...prevState,
                        isTwoStepVerificationModelOpen: true,
                    };
                });

                if (error) {
                    errorMessage(error?.data?.message);
                }
            }
        } else {
            handleContactSubmit();
        }
    };

    const [resetPasswordOnLogin] = useResetPasswordOnLoginMutation();

    const handleUpdatePassword = async (e) => {
        e.preventDefault();
        if (passwordState.oldPassword === passwordState.password) {
            errorMessage("New password must be different.");
            return;
        }

        const { data, error } = await sendUserCode({ email: userDataState?.userData?.email });
        if (data) {
            successMessage("OTP sent successfully");
            setUserDataState((prevState) => {
                return {
                    ...prevState,
                    isTwoStepVerificationModelOpen: true,
                };
            });

            if (error) {
                errorMessage(error?.data?.message);
            }
        }
    };

    const handlePasswordSubmit = async () => {
        setPreferenceState((prevState) => {
            return {
                ...prevState,
                verificationLoading: true,
            };
        });

        const { data, error } = await resetPasswordOnLogin({
            email: user?.email,
            oldPassword: passwordState.oldPassword,
            newPassword: passwordState.password,
        });
        if (data) {
            successMessage(data?.message);
        }
        if (error) {
            errorMessage(error?.data?.message);
        }
        setPreferenceState((prevState) => {
            return {
                ...prevState,
                verificationLoading: false,
            };
        });
    };

    useEffect(() => {
        if (userDataState?.userData) {
            setProfileContactState((prevState) => {
                return {
                    ...prevState,
                    first_name: userDataState?.userData?.first_name,
                    last_name: userDataState?.userData?.last_name,
                    email: userDataState?.userData?.email,
                    countryCode: countryList.find(
                        (item) => item.country === userDataState?.userData?.country_code
                    )?.countryCode,
                    stateCode: userDataState?.userData?.stateCode || "",
                    mobile: userDataState?.userData?.mobile || "",
                    country: userDataState?.userData?.country,
                    city: userDataState?.userData?.city || "",
                    state: userDataState?.userData?.state || "",
                    address: userDataState?.userData?.address || "",
                    zip: userDataState?.userData?.zip || "United States",
                };
            });
        }
    }, [userDataState?.userData]);

    return (
        <>
            {!userProfileData ? (
                <LoadingAnimation />
            ) : (
                <section className="userprofile-container">
                    <section className="userprofile-content">
                        <header>
                            <div className="user-coverimage-container">
                                <figure>
                                    <img
                                        src={
                                            userDataState?.userData?.coverImage ||
                                            (activeTab !== "contact"
                                                ? userPrefernceCoverImage
                                                : userCoverImage)
                                        }
                                        alt="User cover"
                                    />
                                </figure>

                                <div className="cover-image-action">
                                    <button
                                        type="button"
                                        onClick={(e) =>
                                            setUserDataState((prevState) => ({
                                                ...prevState,
                                                openImageCropperModal: true,
                                            }))
                                        }
                                    >
                                        Edit cover photo
                                    </button>
                                </div>
                                <div className="cover-shadow"></div>
                            </div>

                            <article className="user-profileimage-container">
                                <figure>
                                    <img
                                        src={userDataState?.userData?.avatar || userIcon}
                                        alt="user profile"
                                        onClick={(e) =>
                                            setUserDataState((prevState) => ({
                                                ...prevState,
                                                openImageCropperCircleModal: true,
                                            }))
                                        }
                                    />
                                </figure>

                                <div className="user-content">
                                    <h3>
                                        {user.first_name} {user.last_name}
                                    </h3>
                                    <h6>Owner</h6>
                                </div>
                            </article>
                        </header>

                        <section className="userSetting-container">
                            <ActionTabsList
                                tabsList={tabsList}
                                activeTab={activeTab}
                                handleTabClick={handleTabClick}
                            />

                            <section className="userSetting-content">
                                {activeTab === "contact" && (
                                    <ProfileContact
                                        setBaseState={setBaseState}
                                        baseState={baseState}
                                        handleContactSubmit={handleContactSubmit}
                                        handleUpdateClick={handleUpdateClick}
                                        userData={userDataState?.userData}
                                        setUserData={setUserDataState}
                                        setProfileContactState={setProfileContactState}
                                        profileContactState={profileContactState}
                                    />
                                )}

                                {activeTab === "preference" && (
                                    <ProfilePreference
                                        userData={userDataState?.userData}
                                        setUserData={setUserDataState}
                                        setPreferenceState={setPreferenceState}
                                        preferenceState={preferenceState}
                                        passwordState={passwordState}
                                        setPasswordState={setPasswordState}
                                        handleUpdatePassword={handleUpdatePassword}
                                    />
                                )}

                                {activeTab === "notifications" && (
                                    <ProfileNotification
                                        notificationsData={userDataState.notificationsData}
                                        userData={user}
                                    />
                                )}

                                {activeTab === "support" && <ProfileSupport />}

                                <ProfilePlanDetails
                                    setManageUsersState={setManageUsersState}
                                    userDataState={userDataState}
                                    setUserDataState={setUserDataState}
                                    activeTab={activeTab}
                                    mapPercentagesState={mapPercentagesState}
                                />
                            </section>
                        </section>
                    </section>

                    {manageUsersState.openCallSalesModal && (
                        <ReactPortal wrapperId="external_modal_container">
                            <ManageUserCallSalesModal setManageUsersState={setManageUsersState} />
                        </ReactPortal>
                    )}

                    {manageUsersState.showCustomToast && (
                        <CustomToastMsg
                            title={"Thank you!"}
                            msg={
                                "A XoomMaps team member will be contacting you soon to assist your request."
                            }
                        />
                    )}
                    {userDataState.showCancelSubscriptionModal && (
                        <ReactPortal wrapperId="external_modal_container">
                            <CancelSubscriptionModal
                                setParentState={setUserDataState}
                                mapPercentagesState={mapPercentagesState}
                            />
                        </ReactPortal>
                    )}
                    {userDataState.openImageCropperModal && (
                        <ReactPortal wrapperId="external_modal_container">
                            <ImageCropperModal
                                setParentState={setUserDataState}
                                parentState={userDataState}
                                displayImage={userDataState?.userData?.coverImage}
                                handleDelete={handleDeleteCoverImage}
                            />
                        </ReactPortal>
                    )}
                    {userDataState.openImageCropperCircleModal && (
                        <ReactPortal wrapperId="external_modal_container">
                            <ImageCropperModal
                                setParentState={setUserDataState}
                                parentState={userDataState}
                                displayImage={userDataState?.userData?.avatar}
                                circleVaraint={true}
                                handleDelete={handleDeleteUserImage}
                            />
                        </ReactPortal>
                    )}
                    {userDataState?.isTwoStepVerificationModelOpen && (
                        <ReactPortal wrapperId="external_modal_container">
                            <ProfileVerificationModel
                                userData={userDataState?.userData}
                                setUserData={setUserDataState}
                                handleSendTwoStepVerification={handleSendTwoStepVerification}
                            />
                        </ReactPortal>
                    )}
                </section>
            )}
        </>
    );
};

export default Profile;
