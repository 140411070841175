import imageBG from "../../assets/images/Auth/login-hover.png";
import closeIcon from "../../assets/images/closeIcon.png";
import DotLoader from "../../components/DotLoaderBtn/DotLoader";

const TwoStepVerification = (props) => {
    const { setLoginState, loginState, handleSendTwoStepVerification } = props;

    return (
        <>
            <section className="login-forget--container">
                <div className="login-forget--wrapper">
                    <div className="content-wrapper">
                        <form onSubmit={(e) => handleSendTwoStepVerification(e, true)}>
                            <h2 className="mb-15">Verification code sent!</h2>

                            <div className="forget-input-container">
                                <input
                                    type="number"
                                    aria-label="Verification code"
                                    placeholder="Enter verification code"
                                    value={loginState?.twoStepVerifyCode}
                                    onChange={(e) =>
                                        setLoginState((prevState) => {
                                            return {
                                                ...prevState,
                                                twoStepVerifyCode: e.target.value.trim(),
                                            };
                                        })
                                    }
                                    required
                                />
                            </div>

                            {loginState?.loading ? (
                                <button
                                    className="btn animate-dots-blue cta-border-blue-auto w-100"
                                    v
                                    type="button"
                                    disabled
                                >
                                    <DotLoader />
                                </button>
                            ) : (
                                <button type="submit" className="forget-recover-cta">
                                    Submit
                                </button>
                            )}
                        </form>
                        <p>Didn't receive a code? Go back and try again.</p>
                    </div>
                    <div className="img-wrapper">
                        <button
                            className="close-button"
                            onClick={(e) => {
                                setLoginState((prevState) => {
                                    return {
                                        ...prevState,
                                        showTwoStepVerification: false,
                                    };
                                });
                                e.preventDefault();
                            }}
                        >
                            <img src={closeIcon} alt="close icon" />
                        </button>
                        <img src={imageBG} className="bg-image" alt="thankyou registered" />
                    </div>
                </div>
            </section>
        </>
    );
};

export default TwoStepVerification;
