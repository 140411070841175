import React, { useRef } from "react";
import noImage from "../../../assets/images/noImage.png";
import { useSelector, useDispatch } from "react-redux";
import { setMapDashboardId } from "../../../store/slicers/mapDashboardSlice";
import userIcon from "../../../assets/images/avatar-grey.png";
import starGrey from "../../../assets/images/star-grey.png";
import starGolden from "../../../assets/images/star-golden.png";
import ImageListView from "../../../components/ImageListView/ImageListView";
import TrashIcon from "../../../components/icons/TrashIcon";
import ShareIcon from "../../../components/icons/ShareIcon";
import EditIcon from "../../../components/icons/EditIcon";
import ChevronRightIcon from "../../../components/icons/ChevronRightIcon";
import { setGroupType } from "../../../store/slicers/userMapSlice";

const MyMapListItem = (props) => {
    const mapDashboardId = useSelector((state) => state.mapDashboardState.id);
    const groupsBtnRef = useRef(null);

    const {
        data,
        handleToggleMapDelete,
        handleShare,
        handleEdit,
        myMapFavHandle,
        setModalMapTitle,
        setShowState,
        showState,
        handleHideAllGroups,
        pinsLoading,
    } = props;

    const dispatch = useDispatch();

    const handleMapGroups = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setShowState((prevState) => {
            return {
                ...prevState,
                activeMapGroupID: data._id,
                activeGroupGroupID: null,
            };
        });
        props.handleShowMyGroups();
        props.handleClosedSharedList();
        props.getMyMapsGroups(data._id);
        props?.hideShare();
    };

    const handleMapItemClick = (id) => {
        setShowState((prevState) => {
            return {
                ...prevState,
                activeMapGroupID: null,
                isGroupSelected: false,
                firstGroups: false,
                secondGroups: false,
            };
        });
        dispatch(setMapDashboardId(id));
        dispatch(setGroupType(null));
    };

    return (
        <li
            className={`myMapList-data-item ${pinsLoading ? "clickable-none" : ""}`}
            onClick={() => handleMapItemClick(data?._id)}
        >
            <div
                className={`myMapList-data-item-inner ${
                    showState.activeMapGroupID === data._id || mapDashboardId === data._id
                        ? "active"
                        : ""
                } ${props.activeDrawing?.map_id === data._id ? "active-history" : ""}`}
            >
                <div className="flex-gap-y5">
                    <div className="myMap-img">
                        <div className="list-input-wrapper">
                            <img
                                src={data.favorite ? starGolden : starGrey}
                                className="fav-star"
                                alt="Favourite Map"
                                title="Mark as favorite"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    myMapFavHandle(data._id, !data.favorite, "maps");
                                }}
                            />
                        </div>
                        <img
                            src={data?.mapImage || noImage}
                            className="myMap-list-img"
                            alt="map-list"
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowState((prevState) => ({
                                    ...prevState,
                                    openMapCropperModal: true,
                                    clickedItemID: data._id,
                                    clickedItemAvatar: data?.mapImage,
                                }));
                            }}
                        />
                    </div>
                    <div className="mapList-data">
                        <h5 className="mapList-title">{data.title}</h5>
                        {data?.shared?.length ? (
                            <div
                                className="myMapList-people-shared"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    props.handleShowSharedList();
                                    props.handleHideMyGroups();
                                }}
                            >
                                <ImageListView data={data?.shared} />
                            </div>
                        ) : (
                            <div className="no-shared-user-wrapper">
                                <img src={userIcon} alt="user" />
                            </div>
                        )}

                        <div className="myMapList-views">
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    let url = `/map-view/map/${data._id}/`;
                                    window.open(url, "_blank");
                                }}
                            >
                                Public view <ChevronRightIcon />
                            </button>
                            <button onClick={(e) => handleMapGroups(e)} ref={groupsBtnRef}>
                                Container
                                <ChevronRightIcon />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="myMap-config options">
                    <button
                        onClick={(e) => {
                            e.stopPropagation();

                            handleShare(data?._id, data?.shared, "share-map");
                            setModalMapTitle(data.title);
                            props?.handleHideAllGroups();
                        }}
                    >
                        <ShareIcon />
                    </button>
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(data._id, "map", data?.email);
                        }}
                    >
                        <EditIcon />
                    </button>
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            handleToggleMapDelete(
                                data._id,
                                data?.map_id,
                                data?.parent_group,
                                "maps"
                            );
                            handleHideAllGroups();
                            props.setModalMapTitle(data.title);
                        }}
                    >
                        <TrashIcon />
                    </button>
                </div>
            </div>
        </li>
    );
};

export default MyMapListItem;
