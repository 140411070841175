import React from "react";
import whiteLabelLogo from "../../assets/images/white-label-img.png";
import closeIconGrey from "../../assets/images/svg/closeIconGrey.svg";

const WhiteLabelPopup = (props) => {
    return (
        <div className="whiteLable-popup--wrapper">
            <div className="header ">
                <div className="text-right">
                    <img
                        src={closeIconGrey}
                        className="cursor-pointer"
                        alt="close"
                        width={20}
                        style={{ marginRight: 5, paddingTop: 5 }}
                        onClick={() => {
                            props.setState((prevState) => {
                                return {
                                    ...prevState,
                                    showWhiteLabelPopup: false,
                                };
                            });
                        }}
                    />
                </div>
                <h6 className="text-center">White label</h6>
            </div>
            <figure>
                <img src={whiteLabelLogo} alt="custom logo" className="logo-img" />
            </figure>
            <div className="footer text-center">
                <p>Rebrand XoomMaps with your company logo.</p>
            </div>
        </div>
    );
};

export default WhiteLabelPopup;
