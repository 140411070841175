import React from "react";
import closeIconGrey from "../../assets/images/svg/closeIconGrey.svg";
import DotLoader from "../DotLoaderBtn/DotLoader";
import { Link } from "react-router-dom";

const WhiteLabelModal = (props) => {
    const { setParentState, handleSubmit, price, parentState } = props;

    return (
        <div className="subscribe-plan-modal-wrapper">
            <div className="text-right">
                <img
                    src={closeIconGrey}
                    alt="close icon"
                    className="deleteMarker-modal-close"
                    onClick={() =>
                        setParentState((prevState) => {
                            return {
                                ...prevState,
                                showWhiteLabelModal: false,
                            };
                        })
                    }
                />
            </div>
            <h5 className="delete-modal-wrapper-title ">White label</h5>
            <div>
                <p className="content">
                    White labeling operates on an annual billing cycle. Please type your password to
                    confirm payment.
                </p>
            </div>

            <div className="delete-modal-actions" style={{ marginTop: 10 }}>
                <form onSubmit={handleSubmit}>
                    <div className="input-fields">
                        <label style={{ marginBottom: 0 }}>Password</label>
                        <input
                            type="password"
                            name="delete-map-modal"
                            id="deleteMapInput"
                            autoComplete="off"
                            placeholder="Password"
                            onChange={(e) =>
                                setParentState((prevState) => {
                                    return {
                                        ...prevState,
                                        confirmWhiteLabelPassword: e.target.value,
                                    };
                                })
                            }
                        />
                    </div>
                    <div className="buttons">
                        {parentState.whiteLabelLoading ? (
                            <button
                                className="btn width-full animate-dots-blue cta-border-blue-auto "
                                type="button"
                                disabled
                            >
                                <DotLoader />
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className="btn  cta-filled-blue-auto width-full"
                                disabled={!parentState.confirmWhiteLabelPassword}
                            >
                                Pay ${price} / month
                            </button>
                        )}
                    </div>
                </form>
            </div>
            <div className="footer-note mt-15">
                <p className="text-center">
                    By clicking “Pay” you are agreeing to XoomMaps{" "}
                    <Link to="/billing">Terms and Conditions.</Link>{" "}
                </p>
            </div>
        </div>
    );
};

export default WhiteLabelModal;
