import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const Footer = () => {
    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
        apiKey: "AIzaSyCPHNdiRUDaekpW4UH7ODhjgKrTjob6Z94",
        authDomain: "betax-78319.firebaseapp.com",
        projectId: "betax-78319",
        storageBucket: "betax-78319.appspot.com",
        messagingSenderId: "798403920771",
        appId: "1:798403920771:web:7a69d3ea08a41902392cb6",
        measurementId: "G-ZKQBBV0HXS",
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    return (
        <section className="footer-container">
            <div className="copyright">
                <p>
                    <span>©</span>2023 XoomMaps LLC all right reserved
                </p>
            </div>
            <div className="links">
                <Link to="/billing">Privacy policy</Link>
                <Link to="/billing">Terms and Conditions</Link>
            </div>
        </section>
    );
};

export default Footer;
