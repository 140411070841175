/* global google */
import React, { memo } from "react";
import { DrawingManager } from "@react-google-maps/api";
import { drawingToolsOptions } from "../../utils/mapUtils";

const DrawingComponent = (props) => {
    return (
        <DrawingManager
            onLoad={props.onDrawingManagerLoad}
            onOverlayComplete={(shape) => props.onOverlayComplete(shape)}
            options={{
                drawingControl: true,
                drawingControlOptions: {
                    position: google.maps.ControlPosition[props.controlPosition || "TOP_CENTER"],
                },
                ...drawingToolsOptions,
            }}
        />
    );
};

export default memo(DrawingComponent);
