// LOCAL BASE URL
// export const GEO_MAPPING_BASE_URL =
//     process.env.NODE_ENV === "development"
//         ? "http://localhost:5000/"
//         : "https://xoommaps.skylinxtech.com/";
// export const GEO_MAPPING_BASE_URL_API =
//     process.env.NODE_ENV === "development"
//         ? "http://localhost:5000/api"
//         : "https://xoommaps.skylinxtech.com/api";

//LIVE BASE URL
export const GEO_MAPPING_BASE_URL = "https://apis.xoommaps.com/";
export const GEO_MAPPING_BASE_URL_API = "https://apis.xoommaps.com/api";

export const STRIPE_CLIENT_SECRET =
    "sk_test_51HB18GGU5g8164JnQWTOQGVdPBwUx6RL6KojeKFj6QG1co50fkTkcNMlOL22ZuuMyZHCnBmhzRliLtL499Ql9W7t003QmZ21Jf";

//LOCAL BASE URL
// export const GEO_MAPPING_BASE_URL = "http://localhost:5000/";
// export const GEO_MAPPING_BASE_URL_API = "http://localhost:5000/api";

export const GEO_MAPPING_GOOGLE_API_KEY = "AIzaSyAyVbXoEG8ec0x-ZVumFQYXyTgZ6kyJUyw";
export const PUBLIC_KEY_STRIPE =
    "pk_test_51HB18GGU5g8164JnwwB98hGhyXVv4P2dHeWmjgOIQYpxzoTLwDl2mLqQOryLUMCyMCpjlVkHBuhPfhWnIr5txuyL00bw8kS3p5";

export const APP_IDS = {
    GOOGLE: "513310211701-0u3g5v6dnkncnvk0etmttf02amhbek6o.apps.googleusercontent.com",
    FACEBOOK: "157723214101578",
    // FACEBOOK: "780795190261164",
};

export const TEMP_PASSWORD = "Xoommaps@123";

const RANDOM_MAP_IMAGES_PATH = `${GEO_MAPPING_BASE_URL}uploads/MapImages/`;

export const MAP_IMAGES = Array.from({ length: 10 }).map(
    (item, i) => `${RANDOM_MAP_IMAGES_PATH}${i + 1}.png`
);
