/* global google */

import React, { useEffect, useRef } from "react";
import { Marker, HeatmapLayer, MarkerClusterer } from "@react-google-maps/api";
import { GEO_MAPPING_BASE_URL } from "../../config";
import { useSelector } from "react-redux";
import { useState } from "react";
import NocMarker from "./NocMarker";

function MapMarkers({ markerStyle, ...props }) {
    const infoHoverRef = useRef();
    const [newAdvancedMarkerElement, setAdvancedMarkerElement] = useState({
        Fs: "",
        xs: null,
        vo: false,
    });

    const renderedPositions = new Set();

    const [showUserMarkerHoverEffect, setShowUserMarkerHoverEffect] = useState(false);

    const mapLayerType = useSelector((state) => state.mapLayerType.value);

    let markersToShow = props.data;

    if (!props.isNocUrl && props.hiddenMarkers?.length) {
        markersToShow = props.data.filter((pin) =>
            props.hiddenMarkers.some(
                (hiddenMarker) =>
                    hiddenMarker?.[props.pinsFilterState.activePinFilter] ===
                    pin?.[props.pinsFilterState.activePinFilter]
            )
        );
    } else if (props.isNocUrl) {
        if (props.hiddenMarkers?.length) {
            markersToShow = props.data.filter((pin) =>
                props.hiddenMarkers.some(
                    (hiddenMarker) =>
                        hiddenMarker?.pinColor === pin?.color &&
                        hiddenMarker?.userEmail === pin?.user?.email
                )
            );
        } else if (Boolean(props.nocGroupFilterMarkers?.length)) {
            markersToShow = props.data.filter((pin) =>
                props.nocGroupFilterMarkers.includes(pin?.groupId)
            );
        }
    } else {
        markersToShow = props.data;
    }

    const heatmapOptions = {
        maxIntensity: 1,
        opacity: 0.6,
        radius: 15,
    };

    useEffect(() => {
        const getmap = async () => {
            const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");
            setAdvancedMarkerElement({
                ...newAdvancedMarkerElement,
                Fs: AdvancedMarkerElement,
                vo: true,
            });
        };
        getmap();
    }, []);

    const handleUserNarkerHoverIn = (e, pin) => {
        setShowUserMarkerHoverEffect(true);
        let pinItem = document.getElementById(pin.id);
        let pinsContainer = document.querySelector(".mapTabular-gridView-details");

        if (pinItem && pinsContainer) {
            let pinRect = pinItem.getBoundingClientRect();
            let containerRect = pinsContainer.getBoundingClientRect();

            let scrollOffset =
                pinRect.left - containerRect.left - (containerRect.width - pinRect.width) / 2;

            pinsContainer.scrollBy({
                behavior: "smooth",
                left: scrollOffset,
                top: 0,
            });
        }
        document.getElementById(pin.id)?.classList.add("active");
    };
    const handleUserNarkerHoverOut = (e, pin) => {
        setShowUserMarkerHoverEffect(false);
        document.getElementById(pin.id)?.classList.remove("active");
    };

    const filterGroupImage = props?.groupContain?.filter((item) => {
        return props?.nocGroupFilterMarkers?.includes(item?.groupId) ? item : null;
    });
    const getFilterUniqueData = (data) => {
        const groupUser = {};
        return data?.reduce((acc, item) => {
            const email = item?.user?.email;
            // Check if email is not already in the object
            if (!groupUser[email]) {
                groupUser[email] = true; // Mark the email as seen
                acc.push(item); // Add the object to the result array
            }
            return acc;
        }, []);
    };
    const uniqueGroupUsers = getFilterUniqueData(props?.groupContain);
    const uniqueGroupUsersImage = getFilterUniqueData(filterGroupImage);

    function findDuplicatesForPin(data, pin) {
        // console.log("Map MArkers Data", data, pin);
        const duplicates = data?.filter(
            (item) =>
                item?.location?.lat === pin?.location?.lat &&
                item?.location?.lng === pin?.location?.lng
        );

        return duplicates.length > 1 ? duplicates : []; // Only return duplicates if more than one
    }

    return (
        <>
            {mapLayerType === 0 &&
                markersToShow?.map((pin, index) => {
                    let loc = {
                        lat: Number(pin?.location?.lat),
                        lng: Number(pin?.location?.lng),
                    };
                    const duplicateData = findDuplicatesForPin(markersToShow, pin);
                    let positionKey = JSON.stringify(loc);
                    // if (renderedPositions.has(positionKey)) {
                    //     return null;
                    // }
                    // renderedPositions.add(positionKey);
                    return (
                        <Marker
                            key={JSON.stringify(loc) + index}
                            position={loc}
                            label={
                                duplicateData?.length > 1
                                    ? {
                                          text: `${duplicateData?.length}`,
                                          color: "#fff",
                                          className: "duplicated_marker_Label",
                                      }
                                    : ""
                            }
                            icon={
                                !props?.showNumbers
                                    ? {
                                          url: `${GEO_MAPPING_BASE_URL}marker?type=${
                                              markerStyle || pin?.markerStyle || "default"
                                          }&size=16&fill=${pin?.dummyColor || pin?.color || "red"}`,
                                      }
                                    : ""
                            }
                            onClick={(e) => {
                                props?.handleMarkerClick(
                                    e,
                                    duplicateData?.length > 1 ? duplicateData : [pin]
                                );
                            }}
                            onMouseOver={function (e) {
                                !props.showNumbers &&
                                    this.setIcon({
                                        url: `${GEO_MAPPING_BASE_URL}marker?type=${
                                            markerStyle || pin?.markerStyle || "default"
                                        }&size=20&fill=${pin?.dummyColor || pin?.color || "red"}`,
                                    });

                                // SHOWING MARKER INFO
                                if (props.map) {
                                    let wrapper = document.createElement("div");
                                    wrapper.classList.add("marker-infobox--container");
                                    wrapper.innerHTML = `
                                            <p>
                                                <b>${pin.name || " "}</b>
                                            </p>
                                            <p>
                                                ${pin.address || " "}, ${pin.state || " "}, ${
                                        pin.zip || " "
                                    } 
                                            </p>
                                        `;

                                    infoHoverRef.current = new window.google.maps.InfoWindow({
                                        content: wrapper,
                                        pixelOffset: new window.google.maps.Size(0, 70),
                                        position: e.latLng,
                                        map: props.map,
                                    });
                                }

                                let pinItem = document.getElementById(pin.id);
                                let pinsContainer = document.querySelector(
                                    ".mapTabular-gridView-details"
                                );

                                if (pinItem && pinsContainer) {
                                    let pinRect = pinItem.getBoundingClientRect();
                                    let containerRect = pinsContainer.getBoundingClientRect();

                                    // Calculate the scroll offset needed to bring the pin to the center of the container
                                    let scrollOffset =
                                        pinRect.left -
                                        containerRect.left -
                                        (containerRect.width - pinRect.width) / 2;

                                    pinsContainer.scrollBy({
                                        behavior: "smooth",
                                        left: scrollOffset,
                                        top: 0,
                                    });
                                }

                                document.getElementById(pin.id)?.classList.add("active");
                            }}
                            onMouseOut={function (e) {
                                !props.showNumbers &&
                                    this.setIcon({
                                        url: `${GEO_MAPPING_BASE_URL}marker?type=${
                                            markerStyle || pin?.markerStyle || "default"
                                        }&size=16&fill=${pin?.dummyColor || pin?.color || "red"}`,
                                    });

                                infoHoverRef.current.close();
                                document.getElementById(pin.id)?.classList.remove("active");
                            }}
                            draggable={!!props.draggable}
                            onDragStart={(data) => props.handlePinDragStart(data, pin)}
                            onDragEnd={(data) => props.handlePinDragEnd(data, pin)}
                        />
                    );
                })}

            {uniqueGroupUsers?.map((group) => {
                if (props?.isNocUrl) {
                    return (
                        <NocMarker
                            map={props.map}
                            key={group?.groupId}
                            handleClick={(e) => {
                                props.handleMarkerClick(e, group, "userbox");
                            }}
                            uniqueGroupUsersImage={uniqueGroupUsersImage}
                            hiddenMarkers={props?.hiddenMarkers}
                            markerStyle={markerStyle}
                            groupsData={props?.groupContain}
                            showHoverInfo={showUserMarkerHoverEffect}
                            nocGroupFilterMarkers={props.nocGroupFilterMarkers}
                            group={group}
                        />
                    );
                }
            })}

            {mapLayerType === 1 && (
                <HeatmapLayer
                    data={
                        markersToShow.length > 0 &&
                        markersToShow?.map((pin) => {
                            if (
                                (!pin.hasOwnProperty("isActive") && pin.isActive !== false) ||
                                pin?.isActive === true
                            ) {
                                return new window.google.maps.LatLng(
                                    pin.location.lat,
                                    pin.location.lng
                                );
                            }
                        })
                    }
                    options={heatmapOptions}
                />
            )}

            {mapLayerType === 2 && (
                <MarkerClusterer gridSize={30} enableRetinaIcons averageCenter={true}>
                    {(clusterer) =>
                        markersToShow?.map((pin, index) => {
                            let loc = {
                                lat: Number(pin?.location?.lat),
                                lng: Number(pin?.location?.lng),
                            };
                            return (
                                <Marker
                                    key={index}
                                    position={loc}
                                    clusterer={clusterer}
                                    onMouseOut={(e) => props.handleInfoBoxClose?.(e, pin)}
                                    onMouseOver={(e) => props.handleInfoBoxOpen?.(e, pin)}
                                    onClick={(e) => props.handleMarkerClick?.(e, pin)}
                                    icon={{
                                        url: `${GEO_MAPPING_BASE_URL}marker?type=${
                                            markerStyle || pin?.markerStyle || "default"
                                        }&size=14&fill=${pin?.color || "red"}`,
                                    }}
                                    opacity={pin?.history?.at(-1)?.status === 1 ? 0.6 : 1}
                                    draggable={true}
                                />
                            );
                        })
                    }
                </MarkerClusterer>
            )}
        </>
    );
}

export default MapMarkers;
