export default function PhoneIcon({ color = "inherit", fill = "inherit", width = "20px" }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path
                id="phone-off"
                d="M7.718,11.424a13.465,13.465,0,0,0,5.858,5.858l1.956-1.956a.884.884,0,0,1,.907-.213,10.14,10.14,0,0,0,3.173.507.892.892,0,0,1,.889.889v3.1a.892.892,0,0,1-.889.889A15.11,15.11,0,0,1,4.5,5.389.892.892,0,0,1,5.389,4.5H8.5a.892.892,0,0,1,.889.889A10.1,10.1,0,0,0,9.9,8.562a.892.892,0,0,1-.222.907Z"
                transform="translate(-4.5 -4.5)"
                fill="#999b9e"
            />
        </svg>
    );
}
