import { Link } from "react-router-dom";
import imageBG from "../../assets/images/Auth/login-hover.png";
import closeIcon from "../../assets/images/closeIcon.png";

const ResetPasswordMessage = (props) => {
    const { setLoginState, loginState, handleSubmitForget } = props;

    return (
        <>
            <section className="login-forget--container">
                <div className="login-forget--wrapper">
                    <div className="content-wrapper">
                        <form onSubmit={(e) => handleSubmitForget(e, true)}>
                            <h2>Email sent!</h2>

                            <p>
                                Didn’t receive email? Please check your span folder or hit send
                                again.
                            </p>

                            <button type="submit" className="forget-recover-cta">
                                Send again
                            </button>
                            <div className="footer-note mt-15">
                                <p className="text-center">
                                    By clicking "Send again" you agree to XoomMaps{" "}
                                    <a href="#">Terms and Conditions</a>.
                                </p>
                            </div>
                        </form>
                    </div>
                    <div className="img-wrapper">
                        <button
                            className="close-button"
                            onClick={(e) => {
                                setLoginState((prevState) => {
                                    return {
                                        ...prevState,
                                        showForgetSuccessModal: false,
                                    };
                                });
                                e.preventDefault();
                            }}
                        >
                            <img src={closeIcon} alt="close icon" />
                        </button>
                        <img src={imageBG} className="bg-image" alt="thankyou registered" />
                    </div>
                </div>
            </section>
        </>
    );
};

export default ResetPasswordMessage;
