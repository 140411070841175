import React, { useState } from "react";
import { useSelector } from "react-redux";
import DotLoader from "../../components/DotLoaderBtn/DotLoader";
import closeIconGrey from "../../assets/images/svg/closeIconGrey.svg";
import { countryList } from "../../utils/country_list_with_code";
import { useUpgradeEnterpriseContactMutation } from "../../services/baseApi";
import { errorMessage, successMessage } from "../../utils/messages";
import { debounce } from "../../utils/utils";
import { useEffect } from "react";

export const ManageUserCallSalesModal = (props) => {
    const { setManageUsersState } = props;

    const [upgradeEnterpriseContact] = useUpgradeEnterpriseContactMutation();

    const { user } = useSelector((state) => state.auth);

    const [loading, setLoading] = useState(false);
    const [callSalesModalState, setCallSalesModalState] = useState({
        country: user?.country || "",
        sender: user?.email || "",
        receiver: "xoommaps@mailinator.com",
        stateCode: "",
        countryCode: "+1",
        phone: "",
        shift: "",
        prefered_contact: "phone",
    });

    const countryHandler = (e) => {
        let selectedCountry = e.target.value;
        setCallSalesModalState((prevState) => {
            return {
                ...prevState,
                country: selectedCountry,
            };
        });
    };

    const successFunc = (state) => {
        setManageUsersState((prevState) => {
            return {
                ...prevState,
                showCustomToast: state,
                openCallSalesModal: false,
            };
        });
    };

    useEffect(() => {
        setCallSalesModalState((prevState) => {
            return {
                ...prevState,
                countryCode:
                    countryList.find((item) => item.country === callSalesModalState.country)
                        ?.countryCode || callSalesModalState.countryCode,
            };
        });
    }, [callSalesModalState.country]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const { data, error } = await upgradeEnterpriseContact({ body: callSalesModalState });
        if (data) {
            successFunc(true);

            setTimeout(() => {
                successFunc(false);
            }, 5000);
        }
        if (error) {
            errorMessage(error?.data?.message);
        }
        setLoading(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="manageusers-call-modal--container">
                <div className="text-right">
                    <img
                        src={closeIconGrey}
                        alt="close icon"
                        className="deleteMarker-modal-close"
                        onClick={() =>
                            setManageUsersState((prevState) => {
                                return {
                                    ...prevState,
                                    openCallSalesModal: false,
                                };
                            })
                        }
                    />
                </div>

                <h5 className="delete-modal-wrapper-title ">Enterprise upgrade</h5>

                <div className="content-wrapper mb-15">
                    <p>Confirm number</p>
                    <div className="markerInfo-formgroup">
                        <div>
                            <label>Country / Region</label>
                        </div>
                        <select
                            defaultValue={callSalesModalState.country}
                            onChange={countryHandler}
                            className="form-control"
                        >
                            <option>United States</option>
                            {countryList.map((option) => (
                                <option key={option.country} value={option.country}>
                                    {option.country}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="markerInfo-formgroup">
                        <label>Phone</label>
                        <div className="signup-phone-wrapper">
                            <input
                                type="tel"
                                disabled
                                value={
                                    countryList.find(
                                        (item) => item.country === callSalesModalState.country
                                    )?.countryCode || callSalesModalState.countryCode
                                }
                                className="form-control"
                            />
                            <input
                                type="tel"
                                aria-label="Phone Number"
                                autoComplete="off"
                                placeholder="510"
                                defaultValue={callSalesModalState.stateCode}
                                className="form-control"
                                onChange={debounce((e) => {
                                    setCallSalesModalState((prevState) => {
                                        return {
                                            ...prevState,
                                            stateCode: e.target.value,
                                        };
                                    });
                                }, 500)}
                            />
                            <input
                                type="number"
                                aria-label="Phone Number"
                                autoComplete="off"
                                placeholder="555-0123"
                                defaultValue={callSalesModalState.phone}
                                className="form-control"
                                onChange={debounce((e) => {
                                    setCallSalesModalState((prevState) => {
                                        return {
                                            ...prevState,
                                            phone: e.target.value,
                                        };
                                    });
                                }, 500)}
                            />
                        </div>
                    </div>

                    <div className="mb-10">
                        <p className="mb-0">Confirm email</p>
                    </div>
                    <div className="markerInfo-formgroup">
                        <label>Email</label>
                        <input
                            type="email"
                            aria-label="email adress"
                            placeholder="john.doe@xoommaps.com"
                            value={callSalesModalState.sender}
                            className="form-control"
                            onChange={(e) => {
                                setCallSalesModalState((prevState) => {
                                    return {
                                        ...prevState,
                                        email: e.target.value,
                                    };
                                });
                            }}
                            required
                            disabled
                        />
                    </div>

                    <div className="preferred-contact">
                        <p>Preferred contact method</p>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                id="prefered_contact_phone"
                                name="prefered_contact"
                                defaultValue="phone"
                                checked={
                                    callSalesModalState?.prefered_contact === "phone" ? true : false
                                }
                                onChange={(e) =>
                                    setCallSalesModalState((prevState) => {
                                        return {
                                            ...prevState,
                                            prefered_contact: e.target.value,
                                        };
                                    })
                                }
                            />
                            <label className="form-check-label" htmlFor="prefered_contact_phone">
                                Phone
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                id="preferredContact_email"
                                name="prefered_contact"
                                value="email"
                                checked={
                                    callSalesModalState?.prefered_contact === "email" ? true : false
                                }
                                onChange={(e) =>
                                    setCallSalesModalState((prevState) => {
                                        return {
                                            ...prevState,
                                            prefered_contact: e.target.value,
                                        };
                                    })
                                }
                            />
                            <label className="form-check-label" htmlFor="preferredContact_email">
                                Email
                            </label>
                        </div>
                    </div>
                    <div className="consent-wrapper">
                        <p>Best time of day</p>
                        <div className="markerInfo-formgroup">
                            <select
                                value={callSalesModalState?.shift}
                                id="shift"
                                name="shift"
                                className="form-control"
                                onChange={(e) => {
                                    setCallSalesModalState((prevState) => {
                                        return {
                                            ...prevState,
                                            shift: e.target.value,
                                        };
                                    });
                                }}
                            >
                                <option value="">- - Select one - -</option>
                                <option value="Anytime">Anytime</option>
                                <option value="Morning">Morning</option>
                                <option value="Afternoon">Afternoon</option>
                                <option value="Evening">Evening</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="buttons">
                    {loading ? (
                        <button
                            className="btn animate-dots-blue cta-border-blue-auto w-100"
                            v
                            type="button"
                            disabled
                        >
                            <DotLoader />
                        </button>
                    ) : (
                        <button type="submit" className="btn cta-filled-blue-auto width-full">
                            Confirm
                        </button>
                    )}
                </div>
            </div>
        </form>
    );
};
