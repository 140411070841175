import React, { useState } from "react";
import { HuePicker } from "react-color";

function ColorPicker({ handleChangeColor }) {
    const [color, setColor] = useState("");
    const onChangeColor = (color) => {
        setColor(color);
        handleChangeColor(color);
    };
    return (
        <div className="map-colorPicker">
            <div style={{ cursor: "pointer" }}>
                <HuePicker onChangeComplete={onChangeColor} color={color} />
            </div>
        </div>
    );
}

export default ColorPicker;
