import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    maps: [
        // {
        //     map_id: "id",
        //     groups: [
        //         {
        //             group_id: "id",
        //             drawings: [
        //                 {
        //                     title: "",
        //                     pathInfo: {
        //                         shapeType: "",
        //                         path: [],
        //                     },
        //                 },
        //             ],
        //         },
        //     ],
        //     drawings: [
        //         {
        //             title: "",
        //             pathInfo: {
        //                 shapeType: "",
        //                 path: [],
        //             },
        //         },
        //     ],
        // },
    ],
};

export const drawingHistorySlice = createSlice({
    name: "drawingHistory",
    initialState,
    reducers: {
        addHistory: (state, { payload }) => {
            // state.user = action.payload;
            let { mapId, drawingDetails, groupId } = payload;
        },

        deleteHistory: (state, { payload }) => {
            // state.user = null;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setUser, deleteUser } = drawingHistorySlice.actions;

export default drawingHistorySlice.reducer;
