import React from "react";
import avatar from "../../assets/images/avatar-male.jpg";
import { errorMessage, successMessage } from "../../utils/messages";
import searchGreyIcon from "../../assets/images/svg/searchGreyIcon.svg";
import emailIcon from "../../assets/images/svg/emailIcon.svg";
import {
    useDeleteCoworkerMutation,
    useToggleRoleCoworkerMutation,
    useToggleStatusCoworkerMutation,
} from "../../services/baseApi";
import { debounce } from "../../utils/utils";
import TrashIcon from "../../components/icons/TrashIcon";

const ManageUsersTable = (props) => {
    const { manageUsersState, setManageUsersState, user, handleInviteUserAgain } = props;

    const [deleteCoworker] = useDeleteCoworkerMutation();
    const [toggleStatusCoworker] = useToggleStatusCoworkerMutation();
    const [toggleRoleCoworker] = useToggleRoleCoworkerMutation();

    const handleDeleteUser = async (email, coWorkerEmail) => {
        const { data, error } = await deleteCoworker({
            email: email,
            coworkerEmail: coWorkerEmail,
        });

        if (data) {
            successMessage("Successfully removed");
        }

        if (error) {
            errorMessage(error.data.message);
        }
    };

    const handleToggleStatus = async (e, coworkerEmail) => {
        const { data, error } = await toggleStatusCoworker({
            email: user.email,
            status: e.target.value,
            coworkerEmail: coworkerEmail,
        });
        if (data) {
            successMessage("Successfully updated");
        }
        if (error) {
            errorMessage(error?.data?.message);
        }
    };

    const handleToggleRole = async (e, coworkerEmail) => {
        const { data, error } = await toggleRoleCoworker({
            email: user.email,
            role: e.target.value,
            coworkerEmail: coworkerEmail,
        });
        if (data) {
            successMessage("Successfully updated");
        }
        if (error) {
            errorMessage(error?.data?.message);
        }
    };

    return (
        <>
            <section className="manage-users-table--container">
                <div className="manageUsers-search-wrapper">
                    <div className="manageUsers-search-sec">
                        <input
                            type="text"
                            name="searchUser"
                            placeholder="Search..."
                            autoComplete="off"
                            value={manageUsersState.searchItem}
                            onChange={(e) =>
                                debounce(
                                    setManageUsersState((prevState) => ({
                                        ...prevState,
                                        searchItem: e.target.value,
                                    })),
                                    300
                                )
                            }
                        />
                        <img src={searchGreyIcon} className="searchIcon" alt="search icon" />
                    </div>
                </div>
                <div className="table-wrapepr">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th>Status</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="user-info">
                                    <img src={user?.avatar} alt="user" className="user-dp" />
                                    <span>
                                        {user.first_name} {user.last_name} (You)
                                    </span>
                                </td>
                                <td>{user.email}</td>
                                <td className="form-check-wrapper">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id={`${user?.email}-owner`}
                                            name="ownerrole"
                                            defaultValue="owner"
                                            checked
                                            readOnly
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor={`${user?.email}-owner`}
                                        >
                                            <strong>Owner</strong>
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id={`${user?.email}-member`}
                                            name="ownerrole"
                                            defaultValue="member"
                                            disabled
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor={`${user?.email}-member`}
                                        >
                                            Member
                                        </label>
                                    </div>
                                </td>
                                <td className="form-check-wrapper">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id={`${user?.email}-inactive`}
                                            name="ownerstatus"
                                            defaultValue="inactive"
                                            disabled
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor={`${user?.email}-inactive`}
                                        >
                                            Inactive
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id={`${user?.email}-active`}
                                            name="ownerstatus"
                                            defaultValue="active"
                                            checked
                                            readOnly
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor={`${user?.email}-active`}
                                        >
                                            <strong>Active</strong>
                                        </label>
                                    </div>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            {manageUsersState?.coworkersData
                                ?.filter((item) => {
                                    return (
                                        item.name
                                            .toLowerCase()
                                            .includes(
                                                manageUsersState.searchItem?.trim()?.toLowerCase()
                                            ) ||
                                        item.email
                                            .toLowerCase()
                                            .includes(
                                                manageUsersState.searchItem?.trim()?.toLowerCase()
                                            )
                                    );
                                })
                                .map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="user-info">
                                                <img
                                                    src={item?.avatar || avatar}
                                                    alt="user"
                                                    className="user-dp"
                                                    loading="lazy"
                                                />
                                                <span>{item?.name}</span>
                                            </td>
                                            <td>{item?.email}</td>

                                            <td className="form-check-wrapper">
                                                {item?.status === "PENDING" ? (
                                                    <button
                                                        className="pending-invite-wrapper"
                                                        onClick={debounce((e) => {
                                                            handleInviteUserAgain(e, item?.email);
                                                        }, 800)}
                                                    >
                                                        <span>Resend invitation</span>
                                                        <img src={emailIcon} alt="message icon" />
                                                    </button>
                                                ) : (
                                                    <>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                id={`${item?.email}-owner`}
                                                                name={`${item?.email}-role`}
                                                                value="owner"
                                                                checked={item?.role === "owner"}
                                                                onChange={debounce((e) => {
                                                                    handleToggleRole(
                                                                        e,
                                                                        item?.email
                                                                    );
                                                                }, 500)}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor={`${item?.email}-owner`}
                                                            >
                                                                {item?.role === "owner" ? (
                                                                    <strong>Owner</strong>
                                                                ) : (
                                                                    "Owner"
                                                                )}
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                id={`${item?.email}-member`}
                                                                name={`${item?.email}-role`}
                                                                value="member"
                                                                checked={
                                                                    item?.role === "member"
                                                                        ? true
                                                                        : false
                                                                }
                                                                onChange={debounce((e) => {
                                                                    handleToggleRole(
                                                                        e,
                                                                        item?.email
                                                                    );
                                                                }, 500)}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor={`${item?.email}-member`}
                                                            >
                                                                {item?.role === "member" ? (
                                                                    <strong>Member</strong>
                                                                ) : (
                                                                    "Member"
                                                                )}
                                                            </label>
                                                        </div>
                                                    </>
                                                )}
                                            </td>
                                            <td className="form-check-wrapper">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        id={`${item?.email}-inactive`}
                                                        name={`${item?.email}-status`}
                                                        value="false"
                                                        defaultChecked={
                                                            item?.isActive ? false : true
                                                        }
                                                        onChange={debounce((e) => {
                                                            handleToggleStatus(e, item?.email);
                                                        }, 500)}
                                                        disabled={item?.status === "PENDING"}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor={`${item?.email}-inactive`}
                                                    >
                                                        {!item?.isActive ? (
                                                            <strong>Inactive</strong>
                                                        ) : (
                                                            "Inactive"
                                                        )}
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        id={`${item?.email}-active`}
                                                        name={`${item?.email}-status`}
                                                        value="true"
                                                        defaultChecked={item?.isActive}
                                                        onClick={debounce((e) => {
                                                            handleToggleStatus(e, item?.email);
                                                        }, 800)}
                                                        disabled={item?.status === "PENDING"}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor={`${item?.email}-active`}
                                                    >
                                                        {item?.isActive ? (
                                                            <strong>Active</strong>
                                                        ) : (
                                                            "Active"
                                                        )}
                                                    </label>
                                                </div>
                                            </td>

                                            <td>
                                                {item?.role === "owner" ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        <span
                                                            className="trash-icon"
                                                            onClick={debounce((e) => {
                                                                handleDeleteUser(
                                                                    user.email,
                                                                    item?.email
                                                                );
                                                            }, 1000)}
                                                            style={{
                                                                cursor: "pointer",
                                                                minWidth: 12,
                                                            }}
                                                        >
                                                            <TrashIcon />
                                                        </span>
                                                    </>
                                                )}
                                            </td>
                                            <td></td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    );
};

export default ManageUsersTable;
