import React, { useState } from "react";
import closeIconGrey from "../../assets/images/svg/closeIconGrey.svg";
import { useSelector } from "react-redux";
import { getUserPlanData } from "../../store/slicers/authSlice";
import { useCancelSubscriptionMutation } from "../../services/baseApi";
import { successMessage } from "../../utils/messages";
// const stripe

const CancelSubscriptionModal = (props) => {
    const { setParentState, mapPercentagesState } = props;
    const userPlanData = useSelector(getUserPlanData);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [form, setform] = useState({
        confirmCancel: "",
        missingFeatures: "",
        suggestions: "",
    });

    const handleInputChange = (e) => {
        setform((prevForm) => ({ ...prevForm, [e.target.name]: e.target.value }));
    };

    const handleCheckboxChange = (e) => {
        const checkboxLabel = e.target.value;
        const isChecked = e.target.checked;

        if (isChecked) {
            setSelectedCheckboxes([...selectedCheckboxes, checkboxLabel]);
        } else {
            setSelectedCheckboxes(selectedCheckboxes.filter((label) => label !== checkboxLabel));
            if (checkboxLabel === "Missing features") {
                setform({ ...form, missingFeatures: "" });
            } else if (checkboxLabel === "Other") {
                setform({ ...form, suggestions: "" });
            }
        }
    };

    const [cancelSubscription] = useCancelSubscriptionMutation();
    const handleSubmit = async (id) => {
        let sendingData = {
            id: id,
            rejectionReasons: selectedCheckboxes,
            missingFeatures: form.missingFeatures,
            suggestions: form.suggestions,
        };
        const res = await cancelSubscription(sendingData);
        if (res) {
            successMessage("Subscription cancel successfully");
            setParentState((prevState) => ({
                ...prevState,
                showCancelSubscriptionModal: false,
            }));
        }
    };

    return (
        <section className="cancel-subscription-modal-wrapper">
            <div className="title-wrapper">
                <div className="close-wrapper">
                    <img
                        src={closeIconGrey}
                        alt="close"
                        width={22}
                        onClick={(e) =>
                            setParentState((prevState) => ({
                                ...prevState,
                                showCancelSubscriptionModal: false,
                            }))
                        }
                    />
                </div>
                <h5>Cancel subscription</h5>
            </div>
            <div className="content-wrapper">
                <p className="para-one">
                    Canceling your subscription will remove the following maps from your account:
                </p>
                <ul className="user-plan-count--list">
                    <li>
                        <span>My Maps</span> {mapPercentagesState?.myMaps?.length} /{" "}
                        {userPlanData.maps} maps
                    </li>
                    <li>
                        <span>Shared Maps</span> {mapPercentagesState?.mySharedMaps?.length} /{" "}
                        {userPlanData.sharedMaps} maps
                    </li>
                    <li>
                        <span>My Routes</span> {mapPercentagesState?.myRoutes?.length} /{" "}
                        {userPlanData.optimizedRoutes} maps
                    </li>
                    <li>
                        <span>My Groups</span> {mapPercentagesState?.myGroups?.length} /{" "}
                        {userPlanData.groups} maps
                    </li>
                </ul>
                <p className="para-one">
                    Your account will remain accessible until <strong> June 5 2023.</strong>
                </p>

                <div className="cancel-input-wrapper">
                    <label>To continue, type “cancel” below:</label>
                    <input
                        type="text"
                        name="confirmCancel"
                        placeholder="Type “Cancel” to confirm"
                        autoComplete="off"
                        onChange={handleInputChange}
                    />
                </div>
                <div className="feedbacl-wrapper">
                    <p className="para-one">
                        {" "}
                        We value your feedback. Please tell us more about why you want to cancel.
                    </p>
                    <div className="formgroup-flex">
                        <label className="custom-label" htmlFor="setup_difficult">
                            <input
                                type="checkbox"
                                onChange={handleCheckboxChange}
                                value="Set up was difficult"
                                name="active-shared-map"
                                id="setup_difficult"
                            />
                            <span>Set up was difficult</span>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="formgroup-flex">
                        <label className="custom-label" htmlFor="time_consume">
                            <input
                                type="checkbox"
                                onChange={handleCheckboxChange}
                                value="Too time consuming"
                                name="time-consume"
                                id="time_consume"
                            />
                            <span>Too time consuming</span>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="formgroup-flex">
                        <label className="custom-label" htmlFor="difficult_navigate">
                            <input
                                onChange={handleCheckboxChange}
                                value="Difficult to navigate through application"
                                type="checkbox"
                                name="difficult-navigate"
                                id="difficult_navigate"
                            />
                            <span>Difficult to navigate through application</span>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="formgroup-flex">
                        <label className="custom-label" htmlFor="expensive_value">
                            <input
                                type="checkbox"
                                onChange={handleCheckboxChange}
                                value="Expensive or not a good value"
                                name="expensive-value"
                                id="expensive_value"
                            />
                            <span>Expensive or not a good value</span>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="formgroup-flex">
                        <label
                            className="custom-label"
                            htmlFor="missing_features"
                            style={{ marginBottom: 5 }}
                        >
                            <input
                                type="checkbox"
                                onChange={handleCheckboxChange}
                                value="Missing features"
                                name="missing-features"
                                id="missing_features"
                            />
                            <span>Missing features</span>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div
                        className="feedback-input-wrapper"
                        style={{
                            display: `${
                                selectedCheckboxes?.includes("Missing features") ? "block" : "none"
                            }`,
                        }}
                    >
                        <textarea
                            rows="2"
                            name="missingFeatures"
                            placeholder="What features would you like added?"
                            onChange={handleInputChange}
                        ></textarea>
                    </div>
                    <div className="formgroup-flex">
                        <label className="custom-label" htmlFor="no_longer_need">
                            <input
                                type="checkbox"
                                onChange={handleCheckboxChange}
                                value="No longer meets our organization’s needs"
                                name="no-longer-need"
                                id="no_longer_need"
                            />
                            <span>No longer meets our organization’s needs</span>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="formgroup-flex">
                        <label
                            className="custom-label"
                            htmlFor="other_reason"
                            style={{ marginBottom: 5 }}
                        >
                            <input
                                type="checkbox"
                                onChange={handleCheckboxChange}
                                value="Other"
                                name="other-reason"
                                id="other_reason"
                            />
                            <span>Other</span>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div
                        className="feedback-input-wrapper"
                        style={{
                            display: `${selectedCheckboxes?.includes("Other") ? "block" : "none"}`,
                        }}
                    >
                        <textarea
                            rows="2"
                            onChange={handleInputChange}
                            name="suggestions"
                            placeholder="Please provide any additional feedback and or suggestions."
                        ></textarea>
                    </div>
                </div>
            </div>
            <div className="action-cta-wrapper text-center mt-15">
                <button
                    style={{
                        pointerEvents: `${
                            form.confirmCancel.toLocaleLowerCase() !== "cancel" ? "none" : "auto"
                        }`,
                    }}
                    type="button"
                    className="del-outline-btn"
                    onClick={() => handleSubmit(userPlanData?.subscriptionId)}
                >
                    Confirm
                </button>
            </div>
        </section>
    );
};

export default CancelSubscriptionModal;
