import React, { useEffect } from "react";
import Speedometer, { Needle, Marks, Indicator, DangerPath } from "../components/speedoMeter";
import { calculatePercentage } from "../utils/utils";
import { Text } from "svgs";
import { useSearchParams } from "react-router-dom";

const needleSize = 30;

const speedometerWidth = 40;

const needleColor = "#24B6F7";

function SpeedoMeterWebView() {
    const [params] = useSearchParams();
    const value = params.get("value");
    const maxValue = params.get("maxValue");

    useEffect(() => {
        document.body.style.backgroundColor = "transparent";
    }, []);

    return (
        <div className="speedometer-view">
            <Speedometer
                value={value}
                max={maxValue}
                fontFamily="SourceSansPro-Regular"
                accentColor="#000"
                width={80}
                height={80}
            >
                <Needle color="#000">
                    {() => {
                        return (
                            <svg
                                viewBox="194.049 231.875 6.998 29.853"
                                width={needleSize}
                                height={needleSize}
                                x={speedometerWidth / 1.6}
                                y={20}
                            >
                                <g>
                                    <title>Needle</title>
                                    <ellipse
                                        style={{
                                            stroke: needleColor,
                                            fill: "transparent",
                                            strokeWidth: 1,
                                        }}
                                        cx="197.548"
                                        cy="257.229"
                                        rx="3.499"
                                        ry="3.499"
                                    ></ellipse>
                                    <path
                                        style={{
                                            stroke: needleColor,
                                            strokeWidth: 1,
                                        }}
                                        d="M 197.65 239.445 L 197.589 253.575"
                                        transform="matrix(-1, 0, 0, -1, 395.238942, 493.035)"
                                    ></path>
                                    <ellipse
                                        style={{
                                            stroke: needleColor,
                                            fill: "transparent",
                                            strokeWidth: 1,
                                        }}
                                        cx="197.525"
                                        cy="237.327"
                                        rx="1.857"
                                        ry="1.857"
                                    ></ellipse>
                                    <path
                                        d="M 197.56 233.875 L 198.333 235.478 L 196.787 235.478 L 197.56 233.875 Z"
                                        data-bx-shape="triangle 196.787 231.875 1.546 1.603 0.5 0 1@262750ce"
                                        style={{
                                            stroke: needleColor,
                                            strokeWidth: 1,
                                        }}
                                    ></path>
                                </g>
                            </svg>
                        );
                    }}
                </Needle>

                <Marks
                    lineColor="#90b4cd"
                    lineOpacity={1}
                    fill="#000"
                    step={15}
                    lineCap="square"
                    lineSize={6}
                    fontSize={-2}
                />

                <DangerPath arcWidth={3} angle={60} color="#FFA700" />
                <DangerPath arcWidth={3} angle={30} color="#f23434" />

                <Indicator color="#000">
                    {(value, textProps) => (
                        <Text
                            {...textProps}
                            fontSize={16}
                            fill="#fff"
                            x={speedometerWidth}
                            y={70}
                            textAnchor="middle"
                            fontFamily="SourceSansPro-Regular"
                        >
                            {calculatePercentage(value, maxValue)}%
                        </Text>
                    )}
                </Indicator>
            </Speedometer>
        </div>
    );
}

export default SpeedoMeterWebView;
