import React, { useState } from "react";
import { useSelector } from "react-redux";
import attachmentIcon from "../../assets/images/svg/attachment-icon.svg";
import { debounce } from "../../utils/utils";
import { useSubmitIssueMutation } from "../../services/baseApi";
import { errorMessage, successMessage } from "../../utils/messages";
import DotLoader from "../../components/DotLoaderBtn/DotLoader";

const ProfileSupport = () => {
    const { user } = useSelector((state) => state.auth);
    const [submitIssue] = useSubmitIssueMutation();

    const [supportState, setSupportState] = useState({
        name: user?.first_name + " " + user.last_name,
        email: user?.email,
        description: null,
        // avatar: [],
        avatar: null,
        // images: [],
    });

    const [functionState, setFunctionState] = useState({
        maxCount: 3,
        loading: false,
    });
    const imageHandler = (e) => {
        setSupportState((prevState) => {
            return {
                ...prevState,
                avatar: [...supportState.avatar, e.target.files[0]],
            };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFunctionState((prevState) => {
            return {
                ...prevState,
                loading: true,
            };
        });
        setSupportState((prevState) => {
            return {
                ...prevState,
                description: null,
            };
        });
        const form = new FormData();
        form.append("name", supportState.name);
        form.append("email", supportState.email);
        form.append("description", supportState.description);
        form.append("avatar", supportState.avatar);
        // for (let i = 0; i < supportState?.avatar?.length; i++) {
        //     form.append("avatar", supportState?.avatar[i]);
        // }
        // form.append("description", supportState.description);

        const { data, error } = await submitIssue(form);
        if (data) {
            successMessage(data.message);
        }
        if (error) {
            errorMessage(error?.data?.message);
        }

        setFunctionState((prevState) => {
            return {
                ...prevState,
                loading: false,
            };
        });
    };

    return (
        <div className="profile-support--wrapper">
            <h4>Having issues with XoomMaps?</h4>
            <p>Please provide a detailed explanation of your issue.</p>

            <form onSubmit={handleSubmit} style={{ marginTop: 20 }}>
                <label>Details</label>
                <div className="support-input-wrapper">
                    <textarea
                        rows="12"
                        name="comment"
                        onChange={debounce((e) => {
                            setSupportState((prevState) => {
                                return {
                                    ...prevState,
                                    description: e.target.value,
                                };
                            });
                        }, 500)}
                        required
                    ></textarea>
                    <div className="file-upload-wrapper">
                        <input
                            type="file"
                            id="issue_img"
                            name="img"
                            accept="image/*"
                            className="custom-file-input"
                            multiple="multiple"
                            // onChange={imageHandler}
                            onChange={(e) => {
                                setSupportState((prevState) => {
                                    return {
                                        ...prevState,
                                        avatar: e.target.files[0],
                                    };
                                });
                            }}
                        />
                        <div className="custom-text">
                            <img src={attachmentIcon} alt="attach file" />
                            <p>Attach image (Maximum of 1)</p>
                        </div>
                    </div>
                </div>
                <div className="form-actions mt-20">
                    {/* <button className="border-cancel-btn" type="button">
                        Cancel
                    </button> */}
                    {functionState.loading ? (
                        <button
                            className="btn animate-dots-blue cta-border-blue-auto"
                            v
                            type="button"
                            disabled
                        >
                            <DotLoader />
                        </button>
                    ) : (
                        <button type="submit" className="btn cta-border-blue-auto">
                            Submit
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
};

export default ProfileSupport;
