import React from "react";
import "./fallbackUI.css";
import { Link } from "react-router-dom";

function FallbackUI(props) {
    return (
        <section className="fallback-error-container">
            <h2>
                OOPS <span></span> <span></span> <span></span>
            </h2>
            <p className="error-message">Looks like you've arrived at the wrong location.</p>
            <Link to="/dashboard" onClick={props.handleHideFallError}>
                Go home
            </Link>
        </section>
    );
}

export default FallbackUI;
