import React, { useEffect, useState } from "react";
import "./shareTypeList.css";
import ActionTabsList from "../../ActionTabsList/ActionTabsList";
import ConnectedUserList from "./ConnectedUserList";
import SpinLoader from "../../SpinLoader/SpinLoader";
import DotLoader from "../../DotLoaderBtn/DotLoader";
import Dropzone from "react-dropzone";
import copyIcon from "../../../assets/images/copyIcon.png";
import { extractExtention, filterDataEmail, filterdData } from "../../../utils/utils";
import * as XLSX from "xlsx";
import { errorMessage } from "../../../utils/messages";

let acceptedExtentions = ["xls", "xlsx", "csv"];
const tabsList = [
    {
        label: "All",
        id: "all",
    },
    {
        label: "Shared",
        id: "shared",
    },
    {
        label: "Unshared",
        id: "unshared",
    },
    {
        label: "Bulk Sharing",
        id: "bulksharing",
    },
];

const SharedTypeList = (props) => {
    const {
        connectedUsers,
        connectedUsersPageLoading,
        setConnectedUsersFilter,
        connectedUsersFilter,
        shareEmail,
        searchState,
        shareMapEmail,
        setConfirmShareOpen,
        setSearchState,
        setBulkData,
        bulkData,
        setBulkEmail,
        bulkEmail,
    } = props;
    const [activeTab, setActiveTab] = useState("all");
    const [checkSearchQueryRun, setCheckSearchQueryRun] = useState(false);
    const handleTabClick = (id) => () => {
        setConnectedUsersFilter((prevState) => {
            return {
                ...prevState,
                pageNum: 1,
            };
        });
        setActiveTab(id);
        if (id === "shared") {
            setConnectedUsersFilter((prevState) => {
                return {
                    ...prevState,
                    filter: 2,
                };
            });
        } else if (id === "unshared") {
            setConnectedUsersFilter((prevState) => {
                return {
                    ...prevState,
                    filter: 3,
                };
            });
        } else {
            setConnectedUsersFilter((prevState) => {
                return {
                    ...prevState,
                    filter: 1,
                };
            });
        }
    };

    ///DropState Mangement
    const [dropState, setDropState] = useState({
        fileData: [],
        showFilesList: false,
        hideHover: true,
    });

    const { uniqueEmails, invalidEmailCount, duplicateCount } = filterDataEmail(
        dropState?.fileData
    );

    useEffect(() => {
        if (invalidEmailCount > 0 || duplicateCount > 0) {
            setBulkEmail(true);
        }
    }, [setBulkEmail, invalidEmailCount, duplicateCount]);

    useEffect(() => {
        if (bulkEmail) {
            setBulkData((prevState) => {
                return {
                    ...prevState,
                    uniqueEmails: uniqueEmails,
                    duplicateCount: duplicateCount,
                    invalidEmailCount: invalidEmailCount,
                };
            });
        }
    }, [duplicateCount, invalidEmailCount, setBulkData, uniqueEmails, bulkEmail]);

    useEffect(() => {
        if (searchState) {
            if (checkSearchQueryRun) {
                setConnectedUsersFilter((prevState) => {
                    return {
                        ...prevState,
                        search: searchState,
                    };
                });
                setCheckSearchQueryRun((prevState) => !prevState);
            }
        } else {
            setConnectedUsersFilter((prevState) => {
                return {
                    ...prevState,
                    search: "",
                };
            });
        }
    }, [setConnectedUsersFilter, searchState, checkSearchQueryRun, setSearchState]);

    let filteredConnectedUsers = filterdData(
        connectedUsers?.paginatedUsers,
        searchState,
        checkSearchQueryRun,
        setCheckSearchQueryRun
    );

    const handleScrollLeft = () => {
        setConnectedUsersFilter((prevState) => {
            return {
                ...prevState,
                pageNum: prevState.pageNum - 1,
            };
        });
    };

    const handleScrollRight = () => {
        setConnectedUsersFilter((prevState) => {
            return {
                ...prevState,
                pageNum: prevState.pageNum + 1,
            };
        });
    };

    const itemsPerPage = 20;
    const totalItems = connectedUsers?.totalUsersCount;
    const startIndex = (connectedUsersFilter?.pageNum - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const itemsOnRight = endIndex > totalItems ? totalItems : endIndex;

    function wrongFile(msg) {
        setDropState((prevState) => {
            return {
                ...prevState,
                showFilesList: false,
            };
        });

        console.log(msg);

        errorMessage(msg);
    }
    const handleDrop = (acceptedFiles) => {
        let file = acceptedFiles[0];
        let ext = extractExtention(file?.path);

        if (acceptedExtentions.includes(ext)) {
            const fileReader = new FileReader();

            fileReader.onload = function (event) {
                const data = event.target.result;

                const workbook = XLSX.read(data, {
                    type: "binary",
                });
                const sheet = workbook.SheetNames[0];

                let rawData = [];

                if (ext === "csv") {
                    let csvData = XLSX.utils.sheet_to_csv(workbook.Sheets[sheet]);
                    rawData = csvData.split("\n");
                } else {
                    let arrayData = XLSX.utils.sheet_to_json(
                        workbook.Sheets[sheet],
                        { header: 1 },
                        { raw: true }
                    );
                    rawData = [].concat(...arrayData);
                }

                if (!rawData.length) {
                    wrongFile("No data found in the file!");
                    return;
                }

                setDropState((prevState) => {
                    return {
                        ...prevState,
                        fileData: rawData,
                        showFilesList: true,
                        hideHover: false,
                    };
                });
            };

            fileReader.readAsBinaryString(file);
        } else wrongFile("Only excel & csv files are acceptable.");
    };

    ///////////////////////////////Drop Files BulkSharing Handle/////////

    return (
        <>
            <section className="share-type-container ">
                <div className="share-type-wrapper ">
                    <ActionTabsList
                        tabsList={tabsList}
                        activeTab={activeTab}
                        handleTabClick={handleTabClick}
                    />
                </div>
                {connectedUsersPageLoading && activeTab !== "bulksharing" ? (
                    <div className="moveMarker-maps-container">
                        <SpinLoader />
                    </div>
                ) : searchState && activeTab !== "bulksharing" ? (
                    <>
                        <section className="moveMarker-maps-container">
                            {connectedUsers?.paginatedUsers?.length ? (
                                (connectedUsers?.paginatedUsers?.length !== 0 ||
                                    connectedUsersFilter?.search) &&
                                filteredConnectedUsers?.map((item, index) => {
                                    return (
                                        <ConnectedUserList
                                            data={item}
                                            shareEmail={shareEmail}
                                            shareMapEmail={shareMapEmail}
                                            key={item._id || index}
                                            // isShared={isShared}
                                        />
                                    );
                                })
                            ) : (
                                <>
                                    <p className="no-user-indication">
                                        Sharing this map with user <span>{searchState}</span>
                                    </p>
                                </>
                            )}
                        </section>
                    </>
                ) : (
                    <>
                        <section
                            className="connected-users-data"
                            style={{
                                padding: `${
                                    activeTab !== "bulksharing" ? "17px 10px 6px" : "17px 0px 6px"
                                }`,
                            }}
                        >
                            {activeTab !== "bulksharing" &&
                                connectedUsers?.paginatedUsers?.length !== 0 &&
                                filteredConnectedUsers?.map((item, index) => {
                                    return (
                                        <ConnectedUserList
                                            data={item}
                                            shareEmail={shareEmail}
                                            shareMapEmail={shareMapEmail}
                                            key={item._id || index}
                                        />
                                    );
                                })}
                            {activeTab === "bulksharing" &&
                                (dropState?.hideHover ? (
                                    <Dropzone noClick multiple={false} onDrop={handleDrop}>
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="bulk-sharing-dropzone-wrapper flex-center">
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <div className="flex-center">
                                                        <img
                                                            src={copyIcon}
                                                            style={{
                                                                width: "26px",
                                                                marginRight: "10px",
                                                            }}
                                                            alt="copy"
                                                        />{" "}
                                                        <h2>Drag & Drop here or </h2>
                                                    </div>
                                                    <p>(Google Sheets, Excel and Numbers)</p>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                ) : (
                                    <>
                                        <section className="bulk-sharing-data-wrapper">
                                            <p className="title">
                                                Total {dropState?.fileData?.length} email address
                                            </p>
                                            <ul>
                                                {dropState?.fileData?.map((item, index) => {
                                                    return (
                                                        <li className="emailList" key={index}>
                                                            {item}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </section>
                                    </>
                                ))}
                        </section>
                    </>
                )}
            </section>
            {activeTab !== "bulksharing" ? (
                <section className="share-type-footer-container">
                    <div className="share-type-footer-wrapper">
                        <button
                            className="btn"
                            onClick={handleScrollLeft}
                            disabled={connectedUsersFilter?.pageNum === 1}
                        >
                            <i className="fa fa-chevron-left"></i>
                        </button>
                        <div className="pagination-indexes">
                            <p>
                                {startIndex} - {itemsOnRight ? itemsOnRight : 0}
                                &nbsp;of {totalItems ? totalItems : 0}
                            </p>
                        </div>

                        <button
                            className="btn"
                            onClick={handleScrollRight}
                            disabled={
                                connectedUsersFilter?.pageNum >
                                Math.ceil(totalItems / itemsPerPage) - 1
                            }
                        >
                            <i className="fa fa-chevron-right"></i>
                        </button>
                    </div>
                </section>
            ) : (
                <>
                    <section className="connected-users-data "></section>
                    <section className="bulk-sharing-footer-container">
                        <div className="bulk-sharing-wrapper flex-center">
                            <button
                                type="button"
                                onClick={(e) => {
                                    setConfirmShareOpen(false);
                                    setSearchState("");
                                }}
                                aria-label="Close"
                                className="cancel-border-btn"
                                disabled={shareEmail.loading}
                            >
                                Cancel
                            </button>
                            {bulkData?.showBulkModal ? (
                                <button
                                    onClick={(e) => shareMapEmail(e, uniqueEmails)}
                                    type="submit"
                                    className="btn cta-filled-blue-auto"
                                    disabled={bulkData?.showBulkModal ? true : false}
                                >
                                    Share
                                </button>
                            ) : shareEmail.loading ? (
                                <button
                                    className="btn animate-dots-blue 
                                    cta-border-red-auto
                                    "
                                    type="button"
                                    disabled
                                >
                                    <DotLoader />
                                </button>
                            ) : (
                                <button
                                    onClick={(e) => shareMapEmail(e, uniqueEmails)}
                                    type="submit"
                                    className="btn cta-filled-blue-auto"
                                    disabled={
                                        dropState?.hideHover || uniqueEmails > 0 ? true : false
                                    }
                                >
                                    Share
                                </button>
                            )}
                        </div>
                    </section>
                </>
            )}
        </>
    );
};

export default SharedTypeList;
