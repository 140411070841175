import React from "react";
import { useSelector } from "react-redux";
import closeIconGrey from "../../assets/images/svg/closeIconGrey.svg";
import DotLoader from "../DotLoaderBtn/DotLoader";
import { errorMessage, successMessage } from "../../utils/messages";
import { capitalizeString } from "../../utils/utils";
import { useDeletePinMutation } from "../../services/baseApi";
import { useParams } from "react-router-dom";

const DeletePin = (props) => {
    const { setMarkerInfoState, pinInfo, mapData, getSingleMapData, getCurrentMap } = props;

    const { id: mapDashboardId } = useSelector((state) => state.mapDashboardState);

    let { type: currentMapType } = useParams();

    const { groupType } = useSelector((state) => state.mapTypeState);

    const [deletePin, { isLoading: deletePinLoading }] = useDeletePinMutation();

    const handleDeletePin = async () => {
        const { data, error } = await deletePin({
            type: `${groupType === null ? "map" : "group"}`,
            delete_from: mapData?._id,
            mapData: [pinInfo],
        });

        if (data) {
            if (currentMapType) {
                getSingleMapData(mapDashboardId, currentMapType);
            } else {
                getSingleMapData(mapDashboardId);
            }

            setTimeout(() => {
                successMessage("Successfully removed");
            }, 1000);

            setMarkerInfoState((prevState) => {
                return {
                    ...prevState,
                    deletePinModal: !prevState.deletePinModal,
                };
            });
        }
        if (error) {
            errorMessage(error?.data?.message);
        }
    };

    return (
        <section className="delete-modal-wrapper">
            <h5 className="delete-modal-wrapper-title ">Remove marker?</h5>
            <div style={{ marginBottom: 15 }}>
                <p className="content">
                    <b>
                        {capitalizeString(pinInfo?.address)}, {capitalizeString(pinInfo?.city)},{" "}
                        {pinInfo?.state}, {capitalizeString(pinInfo?.zip)}{" "}
                    </b>{" "}
                    from <b> {mapData?.title}?</b>
                </p>
            </div>
            <div className="delete-modal-actions">
                <div className="buttons">
                    <button
                        type="button"
                        aria-label="Close"
                        className="btn border-cancel-btn"
                        onClick={() =>
                            setMarkerInfoState((prevState) => {
                                return {
                                    ...prevState,
                                    deletePinModal: !prevState.deletePinModal,
                                };
                            })
                        }
                        disabled={deletePinLoading}
                    >
                        Cancel
                    </button>
                    {deletePinLoading ? (
                        <button
                            className="btn animate-dots-red del-outline-btn"
                            type="button"
                            disabled
                        >
                            <DotLoader />
                        </button>
                    ) : (
                        <button
                            type="button"
                            className="btn del-outline-btn"
                            onClick={() => handleDeletePin()}
                        >
                            Confirm
                        </button>
                    )}
                </div>
            </div>
        </section>
    );
};

export default DeletePin;
