import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setMapLayerType } from "../../store/slicers/mapLayerSlice";
import heatLayer from "../../assets/images/MapLayers/heatLayer.png";
import clusterLayer from "../../assets/images/MapLayers/clusterLayer.png";
import defaultLayer from "../../assets/images/MapLayers/defaultLayer.png";
import closeIconGrey from "../../assets/images/svg/closeIconGrey.svg";

import ReactPortal from "../Portal/ReactPortal";
import ExportMapModal from "../Modals/ExportMapModal";
import FilterGroups from "../Modals/FilterGroups";
import ThreeDotIcon from "../icons/ThreeDotIcon";
import DashboardSettingOptionsModel from "./DashboardSettingOptionsModel";
import ChevronLeftRed from "../../assets/images/left-chevron-red.png";

const MapViewDashboardOptions = (props) => {
    const {
        collapseBarHandle,
        collapseBar,
        mapDashboardOptionsState,
        handleMapSetting,
        toggleMapHistory,
        handleMapLayer,
        handleShowPDF,
        handleMapFilter,
        hideBars,
        showBars,
        setContextOptions,
        setMapDashboardOptionsState,

        kmlGroupsRef,
        toggleTimeZoneFilter,
        toggleStateFilter,
        handleSaveFilterGroup,
        filterGroupState,
        resetFilters,
        showGroupState,
        showState,
    } = props;

    const kmlGroupsLength = Object.keys(kmlGroupsRef.current).length;

    const dispatch = useDispatch();

    const mapLayerType = useSelector((state) => state.mapLayerType.value);

    return (
        <>
            {mapDashboardOptionsState.showSettingsOption && (
                <DashboardSettingOptionsModel
                    showBars={showBars}
                    hideBars={hideBars}
                    handleMapSetting={handleMapSetting}
                    handleShowPDF={handleShowPDF}
                    showState={showState}
                    showGroupState={showGroupState}
                />
            )}
            <section
                className={`mapView-options--container ${
                    collapseBar.settingsBar ? "settingsbar-active" : ""
                }`}
            >
                <div className="mapView-options--wrapper">
                    <div className="settings-options" title="Options">
                        <button
                            onClick={() => {
                                handleMapSetting();
                                setContextOptions((prev) => {
                                    return {
                                        ...prev,
                                        showOptionModel: false,
                                    };
                                });
                            }}
                            className={
                                mapDashboardOptionsState.showSettingsOption
                                    ? "clickTabs active"
                                    : "clickTabs"
                            }
                        >
                            <svg
                                id="Option-off"
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                            >
                                <rect
                                    id="Boundary"
                                    width="32"
                                    height="32"
                                    fill="#fff"
                                    opacity="0"
                                />
                                <path
                                    id="Path_menu"
                                    data-name="Path / menu"
                                    d="M3,6H31V9.111H3V6m0,7.778H31v3.111H3V13.778m0,7.778H31v3.111H3Z"
                                    transform="translate(-1 0.667)"
                                    fill="#999b9e"
                                />
                            </svg>
                        </button>
                    </div>

                    <div className="history-options" title="History">
                        <button
                            onClick={() => toggleMapHistory(!mapDashboardOptionsState.showHistory)}
                            className={
                                mapDashboardOptionsState.showHistory
                                    ? "history-cta clickTabs active"
                                    : "history-cta clickTabs"
                            }
                        >
                            <svg
                                id="History-off"
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                viewBox="0 0 28 28"
                            >
                                <rect
                                    id="Boundary"
                                    width="40"
                                    height="40"
                                    fill="#fff"
                                    opacity="0"
                                />
                                <path
                                    id="Path_history-off"
                                    data-name="Path / history-off"
                                    d="M15.881,8.952H14.1V14.9l5.1,3.024.857-1.44-4.167-2.476V8.952M15.286,3A10.714,10.714,0,0,0,4.571,13.714H1l4.714,4.8,4.81-4.8H6.952A8.373,8.373,0,1,1,9.4,19.6l-1.69,1.69a10.59,10.59,0,0,0,7.571,3.143A10.714,10.714,0,1,0,15.286,3"
                                    transform="translate(-1 0.8)"
                                    fill="#999b9e"
                                />
                            </svg>
                        </button>
                    </div>

                    <div className="layers-options" title="Map detail">
                        <button
                            onClick={handleMapLayer}
                            className={
                                mapDashboardOptionsState.showLayerOption
                                    ? "active clickTabs"
                                    : "clickTabs"
                            }
                        >
                            <svg
                                id="Layer-off"
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="32"
                                viewBox="0 0 32 32"
                            >
                                <rect
                                    id="Boundary"
                                    width="32"
                                    height="32"
                                    fill="#fff"
                                    opacity="0"
                                />
                                <path
                                    id="Path_95"
                                    data-name="Path 95"
                                    d="M16.2,26.285,5.379,17.872,3,19.722,16.214,30,29.429,19.722l-2.393-1.865Zm.015-3.729,10.807-8.413,2.408-1.865L16.214,2,3,12.278l2.393,1.865Z"
                                    transform="translate(-0.215)"
                                    fill="#999b9e"
                                />
                            </svg>
                        </button>

                        {mapDashboardOptionsState.showLayerOption && (
                            <div className="mapView-layer-list-box" id="layer-setting-box">
                                <div className="mapSettings-title-box">
                                    <div className="close-wrap">
                                        <img
                                            src={closeIconGrey}
                                            alt="close icon"
                                            onClick={handleMapLayer}
                                        />
                                    </div>
                                    <h6>Map type</h6>
                                </div>
                                <ul>
                                    <li
                                        onClick={() => dispatch(setMapLayerType(0))}
                                        className={mapLayerType === 0 ? "active-border-blue" : ""}
                                    >
                                        <figure>
                                            <img src={defaultLayer} alt="Default Map" />
                                            <figCaption>Default</figCaption>
                                        </figure>
                                    </li>

                                    <li
                                        onClick={() => dispatch(setMapLayerType(1))}
                                        className={mapLayerType === 1 ? "active-border-blue" : ""}
                                    >
                                        <figure>
                                            <img src={heatLayer} alt="Heat Map" />
                                            <figCaption>Heat</figCaption>
                                        </figure>
                                    </li>
                                    <li
                                        onClick={() => dispatch(setMapLayerType(2))}
                                        className={mapLayerType === 2 ? "active-border-blue" : ""}
                                    >
                                        <figure>
                                            <img src={clusterLayer} alt="Cluster" />
                                            <figCaption>Cluster</figCaption>
                                        </figure>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>

                    <div className="filter-options" title="Filter">
                        <button
                            onClick={handleMapFilter}
                            className={
                                mapDashboardOptionsState.showFilter
                                    ? "active clickTabs"
                                    : " clickTabs"
                            }
                        >
                            <svg
                                id="Filter-off"
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="32"
                                viewBox="0 0 32 32"
                            >
                                <rect
                                    id="Boundary"
                                    width="32"
                                    height="32"
                                    fill="#7d8083"
                                    opacity="0"
                                />
                                <path
                                    id="Path_tune-vertical-variant"
                                    data-name="Path / tune-vertical-variant"
                                    d="M9.806,16.2V2H7.083V16.2A5.618,5.618,0,0,0,7.083,27v3H9.806V27a5.618,5.618,0,0,0,0-10.808M23.417,2H20.694V5a5.618,5.618,0,0,0,0,10.808V30h2.722V15.8A5.618,5.618,0,0,0,23.417,5V2"
                                    transform="translate(0.75)"
                                    fill="#999b9e"
                                />
                            </svg>
                        </button>

                        {mapDashboardOptionsState.showFilter && (
                            <div
                                className="mapView-settings-list-box mapfilter-settings"
                                id="layer-setting-box"
                            >
                                <div className="mapSettings-title-box">
                                    <div className="close-wrap">
                                        <img
                                            src={closeIconGrey}
                                            alt="close icon"
                                            onClick={handleMapFilter}
                                        />
                                    </div>
                                    <h6>Filter</h6>
                                </div>

                                <ul
                                    className={`${
                                        filterGroupState.disableFilters ? "disable" : ""
                                    }`}
                                >
                                    <li
                                        className={`${
                                            filterGroupState.stateProvince ? "active" : ""
                                        }`}
                                        onClick={toggleStateFilter}
                                    >
                                        <span>State / Province</span>
                                        <button className="clickTabs bg-trans">
                                            <ThreeDotIcon fill="#999b9e" color="#999b9e" />
                                        </button>
                                    </li>

                                    <li
                                        className={`${filterGroupState.timeZone ? "active" : ""}`}
                                        onClick={toggleTimeZoneFilter}
                                    >
                                        <span>Time Zone</span>
                                        <button className="clickTabs bg-trans">
                                            <ThreeDotIcon fill="#999b9e" color="#999b9e" />
                                        </button>
                                    </li>
                                </ul>

                                {filterGroupState.showCreateGroupModal && (
                                    <div className="group-popup">
                                        {(filterGroupState.timeZone ||
                                            filterGroupState.stateProvince) &&
                                        kmlGroupsLength ? (
                                            <FilterGroups
                                                handleSaveFilterGroup={handleSaveFilterGroup}
                                                filterGroupState={filterGroupState}
                                                kmlGroupsRef={kmlGroupsRef}
                                                resetFilters={resetFilters}
                                            />
                                        ) : (
                                            <div className="title-wrapper">
                                                <div className="text-right">
                                                    <img
                                                        src={closeIconGrey}
                                                        alt="close icon"
                                                        onClick={resetFilters}
                                                        style={{ width: 20, cursor: "pointer" }}
                                                    />
                                                </div>
                                                <h5>No Groups lies in this region</h5>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                <div>
                    <button
                        className="collapse-btn-settings"
                        onClick={collapseBarHandle("settings")}
                    >
                        <img
                            style={{ rotate: `${collapseBar.settingsBar ? "180deg" : ""}` }}
                            src={ChevronLeftRed}
                            alt="leftarrow blue"
                        />
                    </button>
                </div>
            </section>

            {mapDashboardOptionsState.showMapPDFmodal && (
                <ReactPortal wrapperId="external_modal_container">
                    <ExportMapModal
                        mapDashboardOptionsState={mapDashboardOptionsState}
                        handleShowPDF={handleShowPDF}
                        hideBars={hideBars}
                        showBars={showBars}
                        setMapDashboardOptionsState={setMapDashboardOptionsState}
                    />
                </ReactPortal>
            )}
        </>
    );
};

export default MapViewDashboardOptions;
