import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMapDashboardId } from "../../../store/slicers/mapDashboardSlice";
import closeIcon from "../../../assets/images/svg/closeIconGrey.svg";
import rightChevron from "../../../assets/images/svg/chevron-right-poiner.svg";
import userIcon from "../../../assets/images/avatar-grey.png";
import starGrey from "../../../assets/images/star-grey.png";
import starGolden from "../../../assets/images/star-golden.png";
import noImage from "../../../assets/images/noImage.png";
import ImageListView from "../../../components/ImageListView/ImageListView";
import DotLoader from "../../../components/DotLoaderBtn/DotLoader";
import TrashIcon from "../../../components/icons/TrashIcon";
import EditIcon from "../../../components/icons/EditIcon";
import ShareIcon from "../../../components/icons/ShareIcon";

const GroupGroupList = (props) => {
    const {
        groupData,
        myMapFavHandle,
        handleShare,
        handleToggleMapDelete,
        handleShowMyGroupsShared,
        handleGroupSharedData,
        groupsListLoading,
        handleShowMyGroupGroups,
        handleHideAllGroups,
        setShowGroupState,
        handleEdit,
    } = props;
    const dispatch = useDispatch();

    const mapDashboardId = useSelector((state) => state.mapDashboardState.id);
    const handleMapGroupGroupsDataClick = (id, mapId, parentGroup) => {
        dispatch(setMapDashboardId(id));
        setShowGroupState((prevState) => {
            return {
                ...prevState,
                activeSecondGroupID: null,
                parentId: mapId,
                parentGroup: parentGroup,
            };
        });
    };

    return (
        <>
            <section className="myMapGroupList-container">
                <div className="myMapGroupList-title">
                    <h5 className="mapTitle">Containers Included</h5>
                    <img src={closeIcon} alt="close icon" onClick={handleHideAllGroups} />
                </div>
                {groupsListLoading.groupsByGroupIdLoading ? (
                    <div className="null-data-response animate-dots-blue">
                        <DotLoader />
                    </div>
                ) : groupData.length ? (
                    <div className="myMapGroups-data-container">
                        {groupData.map((data, index) => {
                            return (
                                <div
                                    className={`myMapGroups-data-item ${
                                        mapDashboardId === data._id ? "active" : ""
                                    }`}
                                    onClick={() =>
                                        handleMapGroupGroupsDataClick(
                                            data._id,
                                            data?.map_id,
                                            data?.parent_group
                                        )
                                    }
                                    style={{ zIndex: groupData.length - index }}
                                    key={index}
                                >
                                    <div
                                        className={`myMapGroups-data-item-inner ${
                                            mapDashboardId === data._id ? "active" : ""
                                        }`}
                                    >
                                        <div className="myMapGroups-img">
                                            <img
                                                src={data.favorite ? starGolden : starGrey}
                                                className="fav-star"
                                                alt="Favourite Map"
                                                title="Mark as favorite"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    myMapFavHandle(
                                                        data._id,
                                                        !data.favorite,
                                                        "groups"
                                                    );

                                                    setTimeout(() => {
                                                        props.getMyGroupGroups(
                                                            props.showGroupState.activeGroupID
                                                        );
                                                    }, 1000);
                                                }}
                                            />

                                            <div className="myGroup-tooltip--container">
                                                {" "}
                                                <img
                                                    src={data?.mapImage || noImage}
                                                    className="myMapGroups-list-img"
                                                    alt="map-list"
                                                />
                                                {/* <div className="myGroup-tooltip-icon">
                                                    <img src={tooltipWhite} alt="Tool tip" />
                                                    <span className="tooltiptext">
                                                        Created on: February 8, 2023
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="myMapGroups-data">
                                            <div className="myMapGroups-title-wrapper">
                                                <h5>{data.title}</h5>
                                            </div>
                                            {data.shared?.length ? (
                                                <div
                                                    className="myMapGroups-people-shared"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        props.setShowGroupState((prevState) => {
                                                            return {
                                                                ...prevState,
                                                                firstGroups: false,
                                                                secondGroups: false,
                                                            };
                                                        });
                                                        handleShowMyGroupsShared();
                                                        handleGroupSharedData(data._id);
                                                    }}
                                                >
                                                    <ImageListView data={data?.shared} />
                                                </div>
                                            ) : (
                                                <div className="no-shared-user-wrapper">
                                                    <img src={userIcon} alt="user" />
                                                </div>
                                            )}

                                            <div className="myMapList-views">
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        let url = `/map-view/group/${data._id}`;
                                                        window.open(url, "_blank");
                                                    }}
                                                >
                                                    Public view{" "}
                                                    <img src={rightChevron} alt="move arrow" />
                                                </button>

                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        handleShowMyGroupGroups();
                                                        props.getMyGroupofOtherGroups(data._id);
                                                        props.setShowGroupState((prevState) => {
                                                            return {
                                                                ...prevState,
                                                                activeSecondGroupID: data._id,
                                                            };
                                                        });
                                                        props?.hideShare();
                                                    }}
                                                >
                                                    Container
                                                    <img src={rightChevron} alt="move arrow" />
                                                </button>
                                            </div>
                                        </div>

                                        <div className="myMap-config">
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleShare(
                                                        data._id,
                                                        data?.shared,
                                                        "share-group"
                                                    );
                                                    props.setModalMapTitle(data.title);
                                                    handleHideAllGroups();
                                                }}
                                            >
                                                <ShareIcon />
                                            </button>
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleEdit(data._id, "group", data?.email);
                                                }}
                                            >
                                                <EditIcon />
                                            </button>
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleToggleMapDelete(
                                                        data._id,
                                                        data?.map_id,
                                                        data?.parent_group,
                                                        "group"
                                                    );
                                                    props.setModalMapTitle(data.title);
                                                }}
                                            >
                                                <TrashIcon />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <p className="null-data-response">No results found</p>
                )}
            </section>
        </>
    );
};

export default GroupGroupList;
