import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import store from "./store/store";
import { Provider, useSelector } from "react-redux";
import GlobalErrorBoundary from "./components/ErrorBoundaries/GlobalErrorBoundary";
import { BrowserRouter } from "react-router-dom";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { GeneratePasswordToken, stringToArrayBuffer } from "./utils/utils";
import TemPasswordAuth from "./components/Modals/TemPasswordAuth";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <GlobalErrorBoundary>
                    <App />
                </GlobalErrorBoundary>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// serviceWorkerRegistration.unregister();
