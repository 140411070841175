import React from "react";
import DotLoader from "../../components/DotLoaderBtn/DotLoader";
import closeIconGrey from "../../assets/images/svg/closeIconGrey.svg";

const InviteConfirmation = (props) => {
    const { handleInviteUser, setManageUsersState, manageUsersState } = props;
    return (
        <div className="subscribe-plan-modal-wrapper">
            <div className="text-right">
                <img
                    src={closeIconGrey}
                    alt="close icon"
                    className="deleteMarker-modal-close"
                    onClick={() =>
                        setManageUsersState((prevState) => {
                            return {
                                ...prevState,
                                inviteModel: false,
                            };
                        })
                    }
                />
            </div>
            <h5 className="delete-modal-wrapper-title ">Upgrade plan</h5>
            <div>
                <p className="content">To secure your account, please type your password</p>
            </div>

            <div className="delete-modal-actions" style={{ marginTop: 10 }}>
                <form onSubmit={(e) => handleInviteUser(e)}>
                    <div className="input-fields">
                        <label style={{ marginBottom: 0 }}>Password</label>
                        <input
                            type="password"
                            name="delete-map-modal"
                            id="deleteMapInput"
                            autoComplete="off"
                            // defaultValue={}
                            placeholder="Password"
                            onChange={(e) =>
                                setManageUsersState((prevState) => {
                                    return {
                                        ...prevState,
                                        invitePassword: e.target.value,
                                    };
                                })
                            }
                        />
                    </div>
                    <div className="forget_btn">
                        <button
                            type="button"
                            className="login-forget-cta"
                            onClick={() =>
                                setManageUsersState((prevState) => {
                                    return {
                                        ...prevState,
                                        openForgetPassword: true,
                                        inviteModel: false,
                                    };
                                })
                            }
                        >
                            Forgot password?
                        </button>
                    </div>
                    <div className="buttons">
                        {manageUsersState.loading ? (
                            <button
                                className="btn width-full animate-dots-blue cta-border-blue-auto "
                                type="button"
                                disabled
                            >
                                <DotLoader />
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className="btn  cta-filled-blue-auto width-full"
                                disabled={!manageUsersState.invitePassword}
                            >
                                Submit
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default InviteConfirmation;
