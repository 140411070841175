import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MovePinList from "./MovePinList";
import searchGreyIcon from "../../assets/images/svg/searchGreyIcon.svg";
import closeIconGrey from "../../assets/images/svg/closeIconGrey.svg";
import DotLoader from "../DotLoaderBtn/DotLoader";
import { errorMessage, successMessage } from "../../utils/messages";
import { changeToGroup, debounce } from "../../utils/utils";
import { setMapType } from "../../store/slicers/userMapSlice";
import {
    useCreateGroupMutation,
    useLazyGetGroupByIdQuery,
    useLazyGetMapByIdQuery,
    useMovePinMutation,
} from "../../services/baseApi";
import { setMapDashboardId } from "../../store/slicers/mapDashboardSlice";
import { GenerateSinglePinCirclePolygon } from "../../utils/mapUtils";
import { SinglePinHistory, singlePinCallBack } from "./DrawingManagerBtns";

const CreateGroupModal = (props) => {
    const [groupName, setGroupName] = useState("");
    const [buttonClick, setButtonClick] = useState(false);

    const handleGroupName = useCallback((e) => {
        setGroupName(e.target.value);
    }, []);

    return (
        <section className="moveMarker-modal--footer">
            <div className="input-box">
                <div>
                    <label>Name</label>
                    <span>{groupName.length}/36</span>
                </div>
                <input
                    type="text"
                    name="group-name"
                    id="group-name"
                    autoComplete="off"
                    placeholder="Type title"
                    maxLength="36"
                    onChange={handleGroupName}
                />
            </div>

            {props.loading ? (
                <button
                    className="btn animate-dots-blue cta-border-blue-auto"
                    type="button"
                    disabled
                >
                    <DotLoader />
                </button>
            ) : (
                <button
                    type="button"
                    className="btn cta-filled-blue-auto"
                    onClick={() => {
                        if (!buttonClick) {
                            props.handleCreateGroupSubmit(groupName.trim());
                            setButtonClick(true);
                        }
                    }}
                    disabled={!groupName.trim()}
                >
                    Done
                </button>
            )}
        </section>
    );
};

const MovePin = (props) => {
    const {
        setMarkerInfoState,
        pinInfo,
        setMapState,
        myMapState,
        getSingleMapData,
        UserPlanData,
        totalUserGroups,
    } = props;

    const { mapType, groupType } = useSelector((state) => state.mapTypeState);
    const authUser = useSelector((state) => state.auth.user);
    console.log("PinInfo", pinInfo);

    //   Getting My maps list by redux

    const myMapsList = useSelector((state) => state.userCompleteMapStats.myMapsList);
    const myGroupslist = useSelector((state) => state.userCompleteMapStats.myGroupslist);

    //   console.log("groupList>>", myGroupslist);

    //   Getting selected My maps Id by redux
    const currentMapId = useSelector((state) => state.mapDashboardState.id);
    const dispatch = useDispatch();

    const [selectedMapId, setSelectedMapId] = useState("");

    const [movePinState, setMovePinState] = useState({
        loading: false,
        searchItem: "",
        showCreateGroupForm: false,
    });

    const [movePin, { isLoading: movePinLoading }] = useMovePinMutation();

    const movePinHandle = async (e) => {
        e.preventDefault();

        const { data, error } = await movePin({
            type: `${groupType === null ? "map" : "group"}`,
            moved_from: currentMapId,
            moved_to: selectedMapId,
            mapData: [pinInfo],
        });

        if (data?.status === "SUCCESS") {
            setMarkerInfoState((prevState) => {
                return {
                    ...prevState,
                    showMoveModal: false,
                };
            });

            setMapState((prevState) => {
                return {
                    ...prevState,
                    openMarkerInfo: false,
                };
            });

            successMessage(data?.message);
            getSingleMapData(currentMapId);
        }
        if (error) {
            errorMessage(error?.data?.message);
        }
    };

    const handleShowCreateGroupForm = useCallback(() => {
        setMovePinState((prevState) => {
            return {
                ...prevState,
                showCreateGroupForm: true,
            };
        });
    }, []);

    const handleHideCreateGroupForm = useCallback(() => {
        setMovePinState((prevState) => {
            return {
                ...prevState,
                showCreateGroupForm: false,
            };
        });
    }, []);

    let [createGroup, { isLoading: createGroupLoading }] = useCreateGroupMutation();

    let [getMapById] = useLazyGetMapByIdQuery();
    let [getGroupById] = useLazyGetGroupByIdQuery();

    const handleCreateGroupSubmit = async (groupName) => {
        if (totalUserGroups >= Number(UserPlanData?.groups)) {
            errorMessage("Groups Limit Exceeds");
            handleHideCreateGroupForm();
            return;
        }
        let centerPoint = { lat: pinInfo.location.lat, lng: pinInfo.location.lng };
        let path = SinglePinHistory(myMapState.map, centerPoint);
        let resp;

        if (mapType === 1) {
            resp = await getMapById(currentMapId);
        }

        if (mapType === 4) {
            resp = await getGroupById(currentMapId);
        }

        const { data: mapState, error: getByIdError } = resp;

        if (getByIdError) {
            console.log("getByIdError: ", getByIdError);
            return;
        }

        let mapDataFields = [
            "region",
            "city",
            "state",
            "address",
            "zip",
            "group",
            "name",
            "description",
            "url",
            "email",
            "pinShape",
            "mapType",
            "targetLink",
            "markerLabel",
            "mapStyles",
            "imageURL",
            "longitude",
            "latitude",
            "mapStyle",
            "markerStyle",
        ];

        let payload = {
            title: groupName,
            email: authUser.email,
            mapData: {},
        };

        mapDataFields?.forEach((field) => {
            payload.mapData[field] = mapState.data[field];
        });

        payload.mapData.rawData = [pinInfo];
        payload.mapData.groupedData = changeToGroup([pinInfo], mapState.data.group);

        if (mapType === 1) {
            payload.type = "map";
            payload.map_id = mapState.data.map_id;
            payload.parent_group = null;
            payload.owner_name = `${authUser.first_name} ${authUser.last_name}`;
            payload.pathInfo = { shapeType: "circle", path: path };
        }

        // IF USER IS ON MY GROUPS
        if (mapType === 4) {
            payload.type = "group";
            payload.parent_group = mapState.data.group_id;
            payload.owner_name = `${authUser.first_name} ${authUser.last_name}`;
            payload.map_id = mapState.mapData.map_id;
            payload.pathInfo = { shapeType: "circle", path: path };
        }
        // console.log("payloa", myMapState);
        // console.log("payloa", payload);
        const { data: createGroupData, error: createGroupError } = await createGroup(payload);

        setMarkerInfoState((prevState) => {
            return {
                ...prevState,
                showMoveModal: !prevState.showMoveModal,
            };
        });

        if (createGroupData) {
            if (mapType === 1) {
                successMessage(
                    () => (
                        <div
                            style={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "center",
                                whiteSpace: "nowrap",
                            }}
                        >
                            <p className="Toastify__toast-text-custom">New group created</p>
                            <button
                                className="white-border-btn mx-10"
                                style={{ fontWeight: 400 }}
                                onClick={() => {
                                    localStorage.setItem(
                                        "groupIdFromMovePin",
                                        createGroupData.data._id
                                    );
                                    setTimeout(() => {
                                        dispatch(setMapType(4));
                                    }, 100);
                                }}
                            >
                                View group
                            </button>
                        </div>
                    ),
                    {
                        autoClose: 5000,
                    }
                );
            } else if (mapType === 4) {
                successMessage(
                    () => (
                        <div
                            style={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "center",
                                whiteSpace: "nowrap",
                            }}
                        >
                            <p className="Toastify__toast-text-custom">New group created!</p>
                            <button
                                className="white-border-btn mx-10"
                                style={{ fontWeight: 400 }}
                                onClick={() => {
                                    dispatch(setMapDashboardId(createGroupData.data._id));
                                }}
                            >
                                View group
                            </button>
                        </div>
                    ),
                    {
                        autoClose: 5000,
                    }
                );
            } else {
                successMessage("Successfully Created.");
            }
        }

        if (createGroupError) {
            errorMessage(createGroupError?.data?.message);
        }
    };
    // console.log("MapPin Loading", movePinLoading);

    return (
        <section className="moveMarker-modal--container">
            <div className="moveMarker-modal-wrapper">
                <div className="moveMarker-header">
                    <div className="text-right">
                        <button
                            className="moveMarker-close"
                            onClick={() =>
                                setMarkerInfoState((prevState) => {
                                    return {
                                        ...prevState,
                                        showMoveModal: !prevState.showMoveModal,
                                    };
                                })
                            }
                            disabled={movePinLoading}
                        >
                            <img src={closeIconGrey} alt="close icon" />
                        </button>
                    </div>
                    <h6 style={{ marginTop: -10 }}>Move marker</h6>

                    <div className="movePin-search-box">
                        <input
                            type="text"
                            name="myMapListSearch"
                            id=""
                            placeholder="Search..."
                            value={movePinState.searchItem}
                            onChange={(e) =>
                                setMovePinState((prevState) => {
                                    return {
                                        ...prevState,
                                        searchItem: e.target.value,
                                    };
                                })
                            }
                            autoComplete="off"
                        />
                        <img src={searchGreyIcon} alt="search move pin" />
                    </div>
                </div>

                {groupType === null && (
                    <MovePinList
                        listData={myMapsList}
                        searchItem={movePinState.searchItem}
                        currentMapId={currentMapId}
                        selectedMapId={selectedMapId}
                        setSelectedMapId={setSelectedMapId}
                    />
                )}

                {groupType === "group" && (
                    <MovePinList
                        listData={myGroupslist}
                        searchItem={movePinState.searchItem}
                        currentMapId={currentMapId}
                        selectedMapId={selectedMapId}
                        setSelectedMapId={setSelectedMapId}
                    />
                )}

                {movePinState.showCreateGroupForm ? (
                    <CreateGroupModal
                        handleCreateGroupSubmit={handleCreateGroupSubmit}
                        handleHideCreateGroupForm={handleHideCreateGroupForm}
                        loading={createGroupLoading}
                    />
                ) : (
                    <div className="moveMarker-modal--footer">
                        <button
                            type="button"
                            aria-label="Close"
                            className="cancel-border-btn move_margin"
                            onClick={handleShowCreateGroupForm}
                        >
                            Create sub map
                        </button>

                        {movePinLoading ? (
                            <button
                                className="btn animate-dots-blue cta-border-blue-auto"
                                type="button"
                                disabled
                            >
                                <DotLoader />
                            </button>
                        ) : (
                            <button
                                type="button"
                                className="btn cta-filled-blue-auto"
                                onClick={movePinHandle}
                                disabled={!selectedMapId}
                            >
                                Done
                            </button>
                        )}
                    </div>
                )}
            </div>
        </section>
    );
};

export default MovePin;
