import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    useAcceptInviteTeamCoworkerMutation,
    useGetTeamCoworkerByTokenQuery,
} from "../../services/baseApi";
import { useNavigate } from "react-router";
import { errorMessage, successMessage } from "../../utils/messages";

const AcceptInvitaion = () => {
    const navigate = useNavigate();

    let { token } = useParams();

    const [receiverEmail, setReceiverEmail] = useState("");

    const [acceptInviteTeamCoworker] = useAcceptInviteTeamCoworkerMutation();

    const { data: coworkersDataByToken, error: cworkersErrorByToken } =
        useGetTeamCoworkerByTokenQuery(token);

    useEffect(() => {
        if (coworkersDataByToken) {
            setReceiverEmail(coworkersDataByToken.data.email);
        }
        if (cworkersErrorByToken) {
            console.log("invitation coworker by token error: ", cworkersErrorByToken.data.message);
        }
    }, [coworkersDataByToken, cworkersErrorByToken]);

    const handleAcceptInvitation = async () => {
        const { data, error } = await acceptInviteTeamCoworker({
            token: token,
        });
        if (data) {
            successMessage("Successfully accepted");
            navigate("/dashboard");
        }
        if (error) {
            console.log("error: ", error);
            if (error.data.status === "FAILED") {
                navigate("/sign-up", {
                    state: {
                        email: receiverEmail,
                    },
                });
                errorMessage(error.data.message);
            }
        }
    };

    const handleDeclineInvitation = () => {
        errorMessage("Invitation declined!");
        navigate("/dashboard");
    };

    return (
        <>
            <h2>Would you like to accept the invitation?</h2>
            <button type="button" onClick={handleAcceptInvitation}>
                Accept
            </button>
            <button type="button" onClick={handleDeclineInvitation}>
                Decline
            </button>
        </>
    );
};

export default AcceptInvitaion;
