import { formatDistanceToNow, parse, parseISO } from "date-fns";
import React from "react";

const TimeEcerpt = ({ date }) => {
    const convertMongoDBDate = (mongoDBDate) => {
        const dateObject = new Date(mongoDBDate);

        const creationTime = dateObject.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
        const timePassed = parse(mongoDBDate, "yyyy-MM-dd HH:mm:ss XXXX", new Date());
        let timeAgo = formatDistanceToNow(timePassed, {
            addSuffix: true,
            includeSeconds: true,
            unit: "minute",
        });
        timeAgo = timeAgo.replace(/^about\s/, "");

        return { timeAgo, creationTime };
    };
    const { timeAgo, creationTime } = convertMongoDBDate(date);
    return (
        <>
            <p>{timeAgo}</p>
            <span>{creationTime}</span>
        </>
    );
};

export default TimeEcerpt;
