import React from "react";
import Simple from "../../assets/images/MapStyle/Simple.png";
import Dark from "../../assets/images/MapStyle/Dark.png";
import Gray from "../../assets/images/MapStyle/Gray.png";
import Paledawn from "../../assets/images/MapStyle/Paledawn.png";
import Subtle from "../../assets/images/MapStyle/Subtle.png";
import Blue from "../../assets/images/MapStyle/Blue.png";

function MapStyles({ currentMapStyle, handleMapStyle }) {
    return (
        <div className="grid-fourPerRow">
            <button
                type="button"
                className={` mapStyle-Btn ${currentMapStyle === "simple" ? "active" : ""} `}
                onClick={(e) => {
                    handleMapStyle("simple");
                }}
            >
                <img src={Simple} alt="Simple Map Style" />
            </button>
            <button
                type="button"
                className={` mapStyle-Btn ${currentMapStyle === "dark" ? "active" : ""} `}
                onClick={(e) => {
                    handleMapStyle("dark");
                }}
            >
                <img src={Dark} alt="Dark Map Style" />
            </button>
            <button
                type="button"
                className={` mapStyle-Btn ${currentMapStyle === "subtle" ? "active" : ""} `}
                onClick={(e) => {
                    handleMapStyle("subtle");
                }}
            >
                <img src={Subtle} alt="Subtle Map Style" />
            </button>
            <button
                type="button"
                className={` mapStyle-Btn ${currentMapStyle === "gray" ? "active" : ""} `}
                onClick={(e) => {
                    handleMapStyle("gray");
                }}
            >
                <img src={Gray} alt="Gray Map Style" />
            </button>
            <button
                type="button"
                className={` mapStyle-Btn ${currentMapStyle === "paledawn" ? "active" : ""} `}
                onClick={(e) => {
                    handleMapStyle("paledawn");
                }}
            >
                <img src={Paledawn} alt="Paledawn Map Style" />
            </button>
            <button
                type="button"
                className={` mapStyle-Btn ${currentMapStyle === "blue" ? "active" : ""} `}
                onClick={(e) => {
                    handleMapStyle("blue");
                }}
            >
                <img src={Blue} alt="Blue Map Style" />
            </button>
        </div>
    );
}

export default MapStyles;
