import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

import MyMapList from "./myMaps/MyMapList";
import {
    setCurrentMapDashboardId,
    setMapDashboardId,
    setResetMapId,
} from "../../store/slicers/mapDashboardSlice";
import { setMyMaps, setMyGroupsList } from "../../store/slicers/myMapsDataSlice";
import SharedMapList from "./sharedMaps/SharedMapList";
import GroupMapList from "./myGroups/GroupMapList";
import RouteMapList from "./myRoutes/RouteMapList";
import DashboardMap from "./DashboardMap";

import DeleteMapModal from "../../components/Modals/DeleteMapModal";
import ShareModal from "../../components/Modals/ShareModal";
import DeleteSharedUserModal from "../../components/Modals/DeleteSharedUserModal";
import CustomSpeedoMeter from "../../components/CustomSpeedoMeter/CustomSpeedoMeter";
import ReactPortal from "../../components/Portal/ReactPortal";
import EditGroupModal from "../../components/Modals/EditGroupModal";

import {
    useDeleteGroupMutation,
    useDeleteMapMutation,
    useDeleteRouteMapMutation,
    useDeleteShareMapMutation,
    useDeleteUserFromMapOrGroupMutation,
    useDeleteUserFromMyRoutesMutation,
    useDeleteUserFromSharedMapMutation,
    useGetAllGroupsQuery,
    useGetAllMapsQuery,
    useGetAllRoutesQuery,
    useGetAllSharedMapsQuery,
    useLazyGetAllDirectGroupsByMapIdQuery,
    useLazyGetAllGroupsByGroupIdQuery,
    useLazyGetAllGroupsByMapIdQuery,
    useLazyGetAllSharedMapGroupGroupsQuery,
    useLazyGetAllSharedMapGroupsQuery,
    useShareGroupMutation,
    useShareMapMutation,
    useToggleGroupFavMutation,
    useToggleMapFavMutation,
    useToggleRouteFavMutation,
    useToggleShareMapFavMutation,
    useUpdateGroupMutation,
} from "../../services/baseApi";

import { errorMessage, successMessage } from "../../utils/messages";
import { calculatePercentage, makeDeepCopy } from "../../utils/utils";
import EditInfoMarker from "../../components/Map/EditInfoMarker";
import { setMapType } from "../../store/slicers/userMapSlice";
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation";
import BulkSharingModal from "../../components/Modals/BulkSharingModal";
import { getUserPlanData } from "../../store/slicers/authSlice";
import ChevronRightIcon from "../../components/icons/ChevronRightIcon";

const Dashboard = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const firstTimeRender = useRef(true);
    const isNocUrl = window.location.href.includes("noc");

    const { user, planData } = useSelector((state) => state.auth);
    const { mapType } = useSelector((state) => state.mapTypeState);
    const { id: mapDashboardId, currentMap: currentMapDashboardId } = useSelector(
        (state) => state.mapDashboardState
    );

    const UserPlanData = useSelector(getUserPlanData);
    const dashboardRefContainer = useRef(null);

    const { openPinDetailBox, pinMapStateInfo, mapMarkerInfo } = useSelector(
        (state) => state.pinDetailsPayload
    );

    const { mapDataheaders, setDataHeaders } = props;

    const [ownerModelState, setOwnerModelState] = useState(false);

    const [contextOptions, setContextOptions] = useState({
        showOptionModel: false,
        LeftX: "",
        TopY: "",
    });

    const [bulkData, setBulkData] = useState({
        showBulkModal: false,
        uniqueEmails: [],
        duplicateCount: 0,
        invalidEmailCount: 0,
    });
    const [bulkEmail, setBulkEmail] = useState(null);
    // Using this state for taking the id and types of maps for delete and shared maps
    const [mapTypeState, setMapTypeState] = useState({
        id: null,
        parentId: null,
        parentGroup: null,
        currentMap: null,
        mapGroup: false,
        type: "",
        shared: [],
        shareType: "",
        deleteType: "",
        multipleDelArr: [],
        endpoint: "",
    });

    const [showState, setShowState] = useState({
        activeMapGroupID: null,
        activeGroupGroupID: null,
        parentId: null,
        isGroupSelected: false,
        parentGroup: null,
        firstGroups: false,
        secondGroups: false,
        shared: false,
        groupShared: false,
        secondGroupShared: false,
        showOptions: false,
        optionID: null,
        sharedShowOptions: false,
        sharedOptionID: null,
        showCheckList: false,
        checkListArr: [],
        confirmMultipleDeleteMapOpen: false,
        mapDelLoading: false,
        openMapCropperModal: false,
        clickedItemID: null,
        clickedItemAvatar: null,
    });

    const [showGroupState, setShowGroupState] = useState({
        activeGroupID: null,
        activeSecondGroupID: null,
        parentId: null,
        parentGroup: null,
        isGroupSelected: false,
        searchItem: "",
        firstGroups: false,
        secondGroups: false,
        showShared: false,
        showGroupShared: false,
        showOptions: false,
        optionID: null,
        showSecondGroupData: false,
        sharedShowOptions: false,
        sharedOptionID: null,
        showCheckList: false,
        checkListArr: [],
        openMapCropperModal: false,
        clickedItemID: null,
        clickedItemAvatar: null,
    });

    const [showSharedState, setShowSharedState] = useState({
        isSharedLoading: false,
        isSharedGroupGroupsLoading: false,
        searchItem: "",
        activeMapGroupID: null,
        activeGroupGroupID: null,
        parentId: null,
        parentGroup: null,
        isGroupSelected: false,
        showShared: false,
        showSubGroupShared: false,
        sharedGroups: [],
        sharedSubGroups: [],
        showOptions: false,
        optionID: null,
        sharedShowOptions: false,
        sharedOptionID: null,
        showCheckList: false,
        checkListArr: [],
    });

    const [deleteSharedUser, setDeleteSharedUser] = useState({
        modalToggle: false,
        email: "",
        type: "",
        id: "",
        name: "",
    });

    const [confirmDeleteMapOpen, setConfirmDeleteMapOpen] = useState(false);

    // sharedMaps
    const [sharedMapState, setSharedMapState] = useState({
        id: null,
        sharedMaps: [],
    });

    const [getAllSharedMapGroups] = useLazyGetAllSharedMapGroupsQuery();
    const [getAllSharedMapGroupGroups] = useLazyGetAllSharedMapGroupGroupsQuery();

    const GetSharedMapGroups = async (id) => {
        setShowSharedState((prevState) => {
            return {
                ...prevState,
                isSharedLoading: true,
            };
        });

        const { data, error } = await getAllSharedMapGroups(id);
        if (data) {
            setShowSharedState((prevState) => {
                return {
                    ...prevState,
                    sharedGroups: data,
                };
            });
        }
        if (error) {
            errorMessage(error.data.message);
        }
        setShowSharedState((prevState) => {
            return {
                ...prevState,
                isSharedLoading: false,
            };
        });
    };
    const GetSharedMapGroupGroups = async (id) => {
        setShowSharedState((prevState) => {
            return {
                ...prevState,
                isSharedGroupGroupsLoading: true,
            };
        });

        const { data, error } = await getAllSharedMapGroupGroups(id);
        if (data) {
            setShowSharedState((prevState) => {
                return {
                    ...prevState,
                    sharedSubGroups: data,
                };
            });
        }
        if (error) {
            errorMessage(error.data.message);
        }
        setShowSharedState((prevState) => {
            return {
                ...prevState,
                isSharedGroupGroupsLoading: false,
            };
        });
    };

    // routes
    const [routeMapState, setRouteMapState] = useState({
        id: null,
        data: [],
        allEmail: [],
    });

    // groups
    const [confirmShareOpen, setConfirmShareOpen] = useState(false);
    const [confirmEditGroupOpen, setConfirmEditGroupOpen] = useState(false);

    // Share Map modal State
    const [shareEmail, setShareEmail] = useState({
        value: "",
        loading: false,
    });

    // state used for shared map modal title input
    const [modalMapTitle, setModalMapTitle] = useState("");

    const [activeDrawing, setActiveDrawing] = useState(null);

    const [showEmptyMap, setShowEmptyMap] = useState(false);
    const [planMapState, setPlanMapState] = useState({});
    const [nocGroupFilterMarkers, setNocGroupFilterMarkers] = useState([]);

    const [updateGroupState, setUpdateGroupState] = useState({
        updatedTitle: null,
        loading: false,
    });

    useEffect(() => {
        if (planData) {
            setPlanMapState(planData);
        }
    }, [planData]);

    // GETTING ALL MAPS
    const {
        isLoading: allMapsLoading,
        data: allMapsData,
        error: allMapsError,
    } = useGetAllMapsQuery(user?.email);

    // getMaps
    const [myMapState, setMyMapState] = useState({
        data: allMapsData || [],
        groupsData: [],
        secondGroupData: [],
        myGroupsSecondGroupData: [],
        pinsLoading: false,
    });

    const handleHideAllGroups = () => {
        setShowState((prevState) => {
            return {
                ...prevState,
                firstGroups: false,
                secondGroups: false,
            };
        });
    };

    useEffect(() => {
        if (allMapsData) {
            setMyMapState((prevState) => {
                return {
                    ...prevState,
                    data: allMapsData,
                };
            });
            dispatch(setMyMaps(allMapsData));
            if ((firstTimeRender.current || allMapsData.length > 0) && mapType !== 4) {
                dispatch(
                    setMapDashboardId(
                        currentMapDashboardId || mapDashboardId || allMapsData[0]?._id
                    )
                );

                dispatch(setResetMapId(allMapsData[0]?._id));
                setShowEmptyMap(false);
            }
            firstTimeRender.current = false;
        }

        if (allMapsError) {
            errorMessage(allMapsError?.data?.message);
        }
    }, [allMapsData, allMapsError]);

    const [getAllGroupsByMapId] = useLazyGetAllGroupsByMapIdQuery();
    const [getAllDirectGroupsByMapId] = useLazyGetAllDirectGroupsByMapIdQuery();
    const [getAllGroupsByGroupId] = useLazyGetAllGroupsByGroupIdQuery();

    const [groupsListLoading, setGroupsListLoading] = useState({
        groupsByMapIdLoading: false,
        groupsByGroupIdLoading: false,
        groupsByGroupGroupIdLoading: false,
    });

    const getMyMapsGroups = async (id) => {
        setGroupsListLoading((prevState) => {
            return {
                ...prevState,
                groupsByMapIdLoading: true,
            };
        });

        if (!isNocUrl) {
            const { data, error } = await getAllGroupsByMapId(id);

            if (data) {
                setMyMapState((prevState) => {
                    return {
                        ...prevState,
                        groupsData: data,
                    };
                });
            }

            if (error) {
                errorMessage(error.data.message);
            }
            setGroupsListLoading((prevState) => {
                return {
                    ...prevState,
                    groupsByMapIdLoading: false,
                };
            });

            if (data) {
                return data;
            }
        } else {
            const { data, error } = await getAllDirectGroupsByMapId(id);
            if (data) {
                setMyMapState((prevState) => {
                    return {
                        ...prevState,
                        groupsData: data,
                    };
                });
            }

            if (error) {
                errorMessage(error.data.message);
            }
            setGroupsListLoading((prevState) => {
                return {
                    ...prevState,
                    groupsByMapIdLoading: false,
                };
            });
        }
    };
    const getMyGroupGroups = async (id) => {
        setGroupsListLoading((prevState) => {
            return {
                ...prevState,
                groupsByGroupIdLoading: true,
            };
        });

        const { data, error } = await getAllGroupsByGroupId(id);

        if (data) {
            setMyMapState((prevState) => {
                return {
                    ...prevState,
                    secondGroupData: data,
                };
            });
        }

        if (error) {
            errorMessage(error.data.message);
        }
        setGroupsListLoading((prevState) => {
            return {
                ...prevState,
                groupsByGroupIdLoading: false,
            };
        });
        if (data) {
            return data;
        }
    };
    const getMyGroupofOtherGroups = async (id) => {
        setGroupsListLoading((prevState) => {
            return {
                ...prevState,
                groupsByGroupOfOtherGroupLoading: true,
            };
        });

        const { data, error } = await getAllGroupsByGroupId(id);

        if (data) {
            setMyMapState((prevState) => {
                return {
                    ...prevState,
                    myGroupsSecondGroupData: data,
                };
            });
        }

        if (error) {
            errorMessage(error.data.message);
        }
        setGroupsListLoading((prevState) => {
            return {
                ...prevState,
                groupsByGroupOfOtherGroupLoading: false,
            };
        });
    };

    const { data: allSharedMapsData, error: allSharedMapsError } = useGetAllSharedMapsQuery(
        user.email
    );

    useEffect(() => {
        if (allSharedMapsData) {
            setSharedMapState((prevState) => {
                return {
                    ...prevState,
                    sharedMaps: allSharedMapsData,
                    id: allSharedMapsData[0]?.map?._id,
                };
            });
        }

        if (mapType === 3 || allSharedMapsData?.length === 1) {
            firstTimeRender.current && dispatch(setMapDashboardId(allSharedMapsData[0]?.map?._id));
            firstTimeRender.current = false;

            setShowEmptyMap(false);
        }
        if (allSharedMapsData?.length === 0) {
            setShowEmptyMap(true);
        }

        if (allSharedMapsError) {
            errorMessage(allSharedMapsError?.data?.message);
        }
    }, [allSharedMapsData, allSharedMapsError]);

    // GET ALL ROUTES
    const { data: allRoutesData, error: allRoutesError } = useGetAllRoutesQuery(user.email);
    useEffect(() => {
        if (allRoutesData) {
            setRouteMapState((prevState) => {
                return {
                    ...prevState,
                    data: allRoutesData,
                    id: allRoutesData[0]?.map?._id,
                };
            });
        }
        if (mapType === 2 && allSharedMapsData?.length === 1) {
            firstTimeRender.current && dispatch(setMapDashboardId(allRoutesData[0]?.map?._id));
            firstTimeRender.current = false;
        }
        if (allSharedMapsData?.length === 0) {
            setShowEmptyMap(true);
        }

        if (allRoutesError) {
            errorMessage(allRoutesError?.data?.message);
        }
    }, [allRoutesData, allRoutesError]);

    const { data: allGroupsData, error: allGroupsError } = useGetAllGroupsQuery(user.email);
    const [groupsMapState, setGroupsMapState] = useState(allGroupsData || []);

    useEffect(() => {
        if (allGroupsData) {
            let groups = makeDeepCopy(allGroupsData);

            setGroupsMapState(groups);
            dispatch(setMyGroupsList(groups));

            if (mapType === 4 && groups?.length === 1) {
                firstTimeRender.current && dispatch(setMapDashboardId(groups?.[0]?._id));
                firstTimeRender.current = false;
            }

            if (groups.length === 0) {
                setShowEmptyMap(true);
            }
        }

        if (allGroupsError) {
            errorMessage(allGroupsError.data?.message);
        }
    }, [allGroupsData, allGroupsError]);

    const handleToggleSharedUserDelete = (email, type, name, id) => {
        setDeleteSharedUser((prevState) => {
            return {
                ...prevState,
                modalToggle: true,
                email: email,
                type: type,
                name: name,
                id: id,
            };
        });
    };

    const [deleteMapOrGroupLoading, setDeleteMapOrGroupLoading] = useState(false);

    const [deleteMap] = useDeleteMapMutation();
    const [deleteShareMap] = useDeleteShareMapMutation();
    const [deleteRouteMap] = useDeleteRouteMapMutation();
    const [deleteGroup] = useDeleteGroupMutation();

    const handleDeleteMap = async (e) => {
        console.log("-----------i am checing groups errror", mapTypeState);
        e.preventDefault();
        setDeleteMapOrGroupLoading(true);
        if (mapTypeState.deleteType === "maps") {
            const { data, error } = await deleteMap(mapTypeState.id);

            if (data) {
                firstTimeRender.current = true;

                handleClose();
                handleHideAllGroups();
                successMessage("Successfully removed");

                dispatch(
                    setCurrentMapDashboardId(
                        mapTypeState.id === allMapsData[0]?._id
                            ? allMapsData[1]?._id
                            : allMapsData[0]?._id
                    )
                );
            }

            if (error) {
                errorMessage(error?.data?.message);
            }
        }

        if (mapTypeState.deleteType === "delete-share") {
            const { data, error } = await deleteShareMap(mapTypeState?.id);

            if (data) {
                firstTimeRender.current = true;

                dispatch(
                    setMapDashboardId(
                        mapTypeState.parentId === allSharedMapsData[0]?.map?._id
                            ? allSharedMapsData[1]?.map?._id
                            : allSharedMapsData[0]?.map?._id
                    )
                );
                handleClose();
                successMessage("Successfully removed");
            }

            if (error) {
                errorMessage(error?.data?.message);
            }
        }

        if (mapTypeState.deleteType === "delete-route") {
            const { data, error } = await deleteRouteMap(mapTypeState.id);

            if (data) {
                firstTimeRender.current = true;
                successMessage("Successfully removed");
                handleClose();
            }

            if (error) {
                errorMessage(error?.data?.message);
            }
        }

        if (mapTypeState.deleteType === "group") {
            const { data, error } = await deleteGroup(mapTypeState.id);
            console.log("-----------i am checing groups errror", data, error);

            if (data) {
                firstTimeRender.current = true;
                if (mapType === 4) {
                    if (mapTypeState?.mapGroup) {
                        dispatch(setMapDashboardId(allGroupsData?.[0]?._id));
                    } else if (!mapTypeState?.mapGroup && mapTypeState?.parentGroup !== null) {
                        let groupData = await getMyGroupGroups(mapTypeState?.parentGroup);
                        if (groupData?.[0]?._id && groupData?.length > 0) {
                            dispatch(setMapDashboardId(groupData?.[0]?._id));
                        } else {
                            dispatch(setMapDashboardId(mapTypeState?.parentGroup));
                        }
                    }
                } else if (mapType === 1) {
                    if (mapTypeState?.parentGroup !== null) {
                        let groupData = await getMyGroupGroups(mapTypeState?.parentGroup);
                        if (groupData?.[0]?._id && groupData?.length > 0) {
                            dispatch(setMapDashboardId(groupData?.[0]?._id));
                        } else {
                            dispatch(setMapDashboardId(mapTypeState?.parentGroup));
                        }
                    } else if (mapTypeState?.parentId !== null) {
                        setShowState((prev) => {
                            return {
                                ...prev,
                                secondGroups: false,
                            };
                        });

                        let groupData = await getMyMapsGroups(mapTypeState?.parentId);

                        if (groupData?.[0]?._id && groupData?.length > 0) {
                            dispatch(setMapDashboardId(groupData?.[0]?._id));
                        } else {
                            dispatch(setMapDashboardId(mapTypeState?.parentId));
                        }
                    }
                } else if (mapType === 2) {
                    if (showSharedState?.parentGroup !== null) {
                        let groupData = await GetSharedMapGroupGroups(showSharedState?.parentGroup);
                        if (groupData?.[0]?._id && groupData?.length > 0) {
                            dispatch(setMapDashboardId(groupData?.[0]?._id));
                        } else {
                            dispatch(setMapDashboardId(showSharedState?.parentGroup));
                        }
                    } else if (showSharedState?.parentId !== null) {
                        setShowSharedState((prev) => {
                            return {
                                ...prev,
                                showSubGroupShared: false,
                                isGroupSelected: false,
                            };
                        });

                        let groupData = await GetSharedMapGroups(showSharedState?.parentId);

                        if (groupData?.[0]?._id && groupData?.length > 0) {
                            dispatch(setMapDashboardId(groupData?.[0]?._id));
                        } else {
                            dispatch(setMapDashboardId(mapTypeState?.parentId));
                        }
                    }
                }

                handleClose();
                successMessage("Successfully removed");
            }

            if (error) {
                errorMessage(error?.data?.message);
            }
        }
        setDeleteMapOrGroupLoading(false);
    };

    const [shareMap] = useShareMapMutation();
    const [shareGroup] = useShareGroupMutation();

    const shareMapEmail = async (e, emailId) => {
        e.preventDefault();
        if (emailId?.length < 1) {
            errorMessage("Invalid Emails");
            setBulkData((prev) => {
                return {
                    ...prev,
                    showBulkModal: false,
                };
            });
            handleClose();
            return;
        }
        if (sharedMapState?.sharedMaps?.length >= Number(UserPlanData?.sharedMaps)) {
            errorMessage("Share Limit Exceeds");
            handleClose();
            return;
        }
        setShareEmail((prevState) => {
            return {
                ...prevState,
                value: emailId,
                loading: true,
            };
        });

        if (mapTypeState.shareType === "share-group") {
            const { data, error } = await shareGroup({
                id: mapTypeState?.id,
                // id: mapTypeState?.id,
                email: emailId,
            });

            if (data) {
                successMessage(`Successfully shared`);
                handleClose();
            }
            if (error) {
                errorMessage(error.data?.message);
            }
        } else {
            // if (typeof emailId === "string") {
            const { data, error } = await shareMap({
                id: mapTypeState?.id,
                type: mapTypeState?.shareType, // share-map or shared-map
                email: emailId,
            });

            if (data) {
                successMessage("Successfully shared");
                handleClose();
            }

            if (error) {
                errorMessage(error.data?.message);
            }
        }
        setBulkData((prev) => {
            return {
                ...prev,
                showBulkModal: false,
            };
        });

        setShareEmail((prevState) => {
            return {
                ...prevState,
                value: "",
                loading: false,
            };
        });
    };

    useEffect(() => {
        if (bulkEmail) {
            setBulkData((prev) => {
                return {
                    ...prev,
                    showBulkModal: true,
                };
            });
        }
        setBulkEmail(null);
    }, [bulkEmail]);

    const [deleteSharedUserLoading, setDeleteSharedUserLoading] = useState(false);

    const [deleteUserFromMapOrGroup] = useDeleteUserFromMapOrGroupMutation();
    const [deleteUserFromSharedMap] = useDeleteUserFromSharedMapMutation();
    const [deleteUserFromMyRoutes] = useDeleteUserFromMyRoutesMutation();

    const handleDeleteSharedUser = async () => {
        setDeleteSharedUserLoading(true);

        if (mapType === 1 || mapType === 4) {
            const { data, error } = await deleteUserFromMapOrGroup({
                id: mapDashboardId,
                email: deleteSharedUser.email,
                type: deleteSharedUser.type,
                mapType,
            });

            if (data) {
                successMessage("Successfully removed");
                handleClose();
            }
            if (error) {
                errorMessage(error.data?.message);
            }
            setDeleteSharedUserLoading(false);
        }
        if (mapType === 2) {
            const { data, error } = await deleteUserFromSharedMap({
                id: deleteSharedUser.id,
                email: deleteSharedUser.email,
                type: deleteSharedUser.type,
                mapType,
            });

            if (data) {
                successMessage("Successfully removed");
                handleClose();
            }
            if (error) {
                errorMessage(error.data?.message);
            }
            setDeleteSharedUserLoading(false);
        }
        if (mapType === 3) {
            const { data, error } = await deleteUserFromMyRoutes({
                id: mapDashboardId,
                email: deleteSharedUser.email,
                type: deleteSharedUser.type,
            });

            if (data) {
                successMessage("Successfully removed");
                handleClose();
            }
            if (error) {
                errorMessage(error.data?.message);
            }
            setDeleteSharedUserLoading(false);
        }
    };

    const handleClose = () => {
        setConfirmDeleteMapOpen(false);
        setConfirmShareOpen(false);
        setConfirmEditGroupOpen(false);
        setDeleteSharedUser((prevState) => {
            return {
                ...prevState,
                modalToggle: false,
            };
        });
        setMapTypeState((prevState) => {
            return {
                ...prevState,
                id: null,
                shareType: null,
                deleteType: null,
                mapGroup: false,
            };
        });
    };

    const handleToggleMapDelete = (_id, mapId, parentGroup, deleteType) => {
        setMapTypeState((prevState) => {
            return {
                ...prevState,
                id: _id,
                parentId: mapId,
                parentGroup: parentGroup,
                currentMap: mapDashboardId,
                deleteType: deleteType,
            };
        });
        setConfirmDeleteMapOpen(true);
    };
    const handleToggleMapShareDelete = (_id, mapType, deleteType) => {
        setMapTypeState((prevState) => {
            return {
                ...prevState,
                id: _id,
                type: mapType,
                deleteType: deleteType,
            };
        });
        setConfirmDeleteMapOpen(true);
    };
    const handleToggleMultipleMapsDelete = (arr) => {
        setMapTypeState((prevState) => {
            return {
                ...prevState,
                multipleDelArr: arr,
            };
        });
    };

    const handleShare = (_id, mapSharedArray, shareType) => {
        setConfirmShareOpen(true);
        let type;
        if (mapType === 1) {
            type = "map";
        } else if (mapType === 2) {
            type = "shareMap";
        } else if (mapType === 3) {
            type = "route";
        } else if (mapType === 4) {
            type = "group";
        }

        setMapTypeState((prevState) => {
            return {
                ...prevState,
                id: _id,
                type: type,
                shareType: shareType,
                shared: mapSharedArray,
            };
        });
    };
    const hideShare = () => {
        setConfirmShareOpen(false);
    };

    const [toggleMapFav] = useToggleMapFavMutation();
    const [toggleShareMapFav] = useToggleShareMapFavMutation();
    const [toggleRouteFav] = useToggleRouteFavMutation();
    const [toggleGroupFav] = useToggleGroupFavMutation();

    const myMapFavHandle = async (favMapId, favState, type) => {
        switch (type) {
            case "maps": {
                await toggleMapFav({
                    id: favMapId,
                    favorite: favState,
                });
                return;
            }
            case "share-map": {
                await toggleShareMapFav({
                    id: favMapId,
                    favorite: favState,
                });
                return;
            }
            case "route": {
                await toggleRouteFav({
                    id: favMapId,
                    favorite: favState,
                });
                return;
            }

            case "groups": {
                await toggleGroupFav({
                    id: favMapId,
                    favorite: favState,
                });
                return;
            }
            default:
                break;
        }

        errorMessage(
            `Something went wrong while marking ${favState ? "favorite" : "not favorite"}.`
        );
    };

    const handleEdit = (_id, type, email) => {
        setMapTypeState((prevState) => {
            return {
                ...prevState,
                id: _id,
            };
        });
        dispatch(setMapDashboardId(_id));
        navigate(`/edit-map/${_id}/${type}`, {
            state: {
                ownerEmail: email,
                mapId: allMapsData?.[0]?._id,
                mapId_opt: allMapsData?.[1]?._id,
            },
        });
        // dispatch(emptyBar());
    };

    const handleEditGroup = (_id, mapId, parentGroupId) => {
        setMapTypeState((prevState) => {
            return {
                ...prevState,
                id: _id,
                parentId: mapId,
                parentGroup: parentGroupId,
            };
        });
        setConfirmEditGroupOpen(true);
    };

    const [updateGroup] = useUpdateGroupMutation();

    const handleUpdateGroup = async (e, id, mapId, parentGroup) => {
        e.preventDefault();
        setUpdateGroupState((prevState) => {
            return {
                ...prevState,
                loading: true,
            };
        });
        const { data, error } = await updateGroup({
            id: id,
            title: updateGroupState.updatedTitle,
        });
        if (data) {
            successMessage("Successfully updated");
            if (parentGroup !== null) {
                getMyGroupGroups(parentGroup);
            } else {
                getMyMapsGroups(mapId);
            }
        }
        if (error) {
            errorMessage(error?.data?.message);
        }
        setUpdateGroupState((prevState) => {
            return {
                ...prevState,
                loading: false,
            };
        });
        handleClose();
    };

    useEffect(() => {
        return () => {
            dispatch(setMapType(1));
            setShowEmptyMap(true);
        };
    }, [dispatch]);

    useEffect(() => {
        setShowEmptyMap(false);

        if (mapType === 1) {
            if (allMapsData?.length === 0) {
                setShowEmptyMap(true);
            }

            setShowState((prev) => {
                return {
                    ...prev,
                    activeMapGroupID: null,
                    activeGroupGroupID: null,
                    isGroupSelected: false,
                    firstGroups: false,
                    secondGroups: false,
                };
            });

            if (mapDashboardId || myMapState.data?.[0]?._id) {
                dispatch(setMapDashboardId(myMapState.data?.[0]?._id));
            }
            setShowGroupState((prevState) => {
                return {
                    ...prevState,
                    isGroupSelected: false,
                };
            });
        }

        if (mapType === 2) {
            setShowSharedState((prev) => {
                return {
                    ...prev,
                    activeMapGroupID: null,
                    activeGroupGroupID: null,
                    isGroupSelected: false,
                    showShared: false,
                    showSubGroupShared: false,
                };
            });
            if (sharedMapState?.sharedMaps?.length === 0) {
                setShowEmptyMap(true);
            }
            if (sharedMapState?.id) {
                dispatch(setMapDashboardId(sharedMapState?.id));
            }
        }

        if (mapType === 3) {
            if (routeMapState?.data?.length === 0) {
                setShowEmptyMap(true);
            }
            if (routeMapState?.data?.[0]?._id) {
                dispatch(setMapDashboardId(routeMapState?.data?.[0]?._id));
            }
        }

        if (mapType === 4) {
            if (groupsMapState?.length === 0) {
                setShowEmptyMap(true);
            }

            if (groupsMapState?.[0]?._id) {
                setShowGroupState((prevState) => {
                    return {
                        ...prevState,
                        activeMapGroupID: null,
                        activeGroupGroupID: null,
                        isGroupSelected: true,
                        firstGroups: false,
                        secondGroups: false,
                    };
                });
                let groupIdFromMovePin = localStorage.getItem("groupIdFromMovePin");
                if (groupIdFromMovePin) {
                    dispatch(setMapDashboardId(groupIdFromMovePin));
                    localStorage.removeItem("groupIdFromMovePin");
                } else {
                    dispatch(setMapDashboardId(groupsMapState?.[0]?._id));
                }
            }
        }
    }, [mapType]);

    useEffect(() => {
        if (mapDashboardId) {
            dispatch(setMapDashboardId(mapDashboardId));
        }
    }, [groupsMapState]);

    // routes
    const [collapseBar, setCollapseBar] = useState({
        guageBar: false,
        settingsBar: false,
    });

    const collapseBarHandle = (target) => () => {
        if (target === "guages") {
            setCollapseBar((prevState) => {
                return {
                    ...prevState,
                    guageBar: !prevState.guageBar,
                };
            });
        }
        if (target === "settings") {
            setCollapseBar((prevState) => {
                return {
                    ...prevState,
                    settingsBar: !prevState.settingsBar,
                };
            });
        }
    };

    // Calculations for the progress bar to calculate formula
    const sharedMapListPercentage =
        calculatePercentage(sharedMapState?.sharedMaps?.length, planMapState?.sharedMaps) || 0;
    const groupsListPercentage =
        calculatePercentage(groupsMapState?.length, planMapState?.groups) || 0;
    const routesListPercentage =
        calculatePercentage(routeMapState?.data?.length, planMapState?.optimizedRoutes) || 0;
    const myMapsListPercentage =
        calculatePercentage(myMapState?.data?.length, planMapState?.maps) || 0;
    const [activeTab, setActiveTab] = useState(1);

    const tabsList = [
        {
            label: "My Maps",
            id: 1,
            count: myMapState?.data?.length,
        },
        {
            label: "Shared Maps",
            id: 2,
            count: sharedMapState?.sharedMaps?.length,
        },
    ];

    const handleTabClick = (id) => () => {
        dispatch(setMapType(id));

        setActiveTab(id);
    };

    const toggleNocMapMarkers = (pin) => {
        if (nocGroupFilterMarkers.includes(pin)) {
            const updatedIds = nocGroupFilterMarkers.filter((id) => id !== pin);
            setNocGroupFilterMarkers(updatedIds);
        } else {
            setNocGroupFilterMarkers([...nocGroupFilterMarkers, pin]);
        }
    };

    const handleContextClick = () => {
        if (setContextOptions) {
            setContextOptions((prevState) => {
                return {
                    ...prevState,
                    showOptionModel: false,
                };
            });
        }
    };

    return (
        <>
            <section
                onClick={() => handleContextClick()}
                className="dashboard-bg-map"
                ref={dashboardRefContainer}
                id="dashboard_bg_map"
            >
                <DashboardMap
                    parentState={myMapState}
                    groupsMapState={groupsMapState}
                    routeMapState={routeMapState}
                    setParentState={setMyMapState}
                    sharedMapState={sharedMapState}
                    showEmptyMap={showEmptyMap}
                    collapseBarHandle={collapseBarHandle}
                    collapseBar={collapseBar}
                    setCollapseBar={setCollapseBar}
                    setActiveDrawing={setActiveDrawing}
                    isNocUrl={isNocUrl}
                    nocGroupFilterMarkers={nocGroupFilterMarkers}
                    toggleNocMapMarkers={toggleNocMapMarkers}
                    handleContextClick={handleContextClick}
                    setContextOptions={setContextOptions}
                    contextOptions={contextOptions}
                    mapDataheaders={mapDataheaders}
                    setDataHeaders={setDataHeaders}
                    totalUserGroups={allGroupsData?.length}
                    UserPlanData={UserPlanData}
                    showState={showState}
                    showGroupState={showGroupState}
                    myMapState={myMapState}
                    showSharedState={showSharedState}
                />
            </section>

            {openPinDetailBox && mapMarkerInfo.id && (
                <EditInfoMarker info={mapMarkerInfo} mapState={pinMapStateInfo} />
            )}

            {isNocUrl ? (
                <section
                    onClick={() => handleContextClick()}
                    className={`nocdashboard-main ${
                        collapseBar.guageBar ? "collapsebar-active" : ""
                    }`}
                >
                    <div className="nocdashboard-pane-wrapper">
                        <h4 className="window-pane-title"> Network Operations Center</h4>
                        <div className="nocdashboard-gauges-wrapper">
                            <ul className="noctabsList">
                                {tabsList.map((tab) => {
                                    return (
                                        <li key={tab.id}>
                                            <button
                                                className={`noctabsListItem ${
                                                    activeTab === tab.id ? "active" : ""
                                                }`}
                                                onClick={handleTabClick(tab.id)}
                                            >
                                                {tab.label} {`(${tab.count})`}
                                            </button>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                    <button className="collapse-btn-guage" onClick={collapseBarHandle("guages")}>
                        <div
                            style={{
                                transform: `${collapseBar.guageBar ? "" : "rotate(180deg)"}`,
                            }}
                        >
                            <ChevronRightIcon />
                        </div>
                    </button>
                </section>
            ) : (
                <section
                    onClick={() => {
                        handleClose();
                        handleContextClick();
                    }}
                    className={`dashboard-gauges-wrapper ${
                        collapseBar.guageBar ? "collapsebar-active" : ""
                    }`}
                >
                    <div className="dashboard-map-menu--conatiner flex-center">
                        <CustomSpeedoMeter
                            title="My Maps"
                            valueMapType={1}
                            active={mapType === 1}
                            handleHideAllGroups={handleHideAllGroups}
                            setShowState={setShowState}
                        />

                        <CustomSpeedoMeter
                            title="My Routes"
                            valueMapType={3}
                            active={mapType === 3}
                            data={routeMapState?.data?.length}
                            showEmptyMap={showEmptyMap}
                        />
                        <CustomSpeedoMeter
                            title="Container"
                            valueMapType={4}
                            active={mapType === 4}
                            data={groupsMapState?.length}
                            showEmptyMap={showEmptyMap}
                            handleHideAllGroups={handleHideAllGroups}
                            setShowState={setShowState}
                        />

                        <CustomSpeedoMeter
                            title="Shared Maps"
                            valueMapType={2}
                            active={mapType === 2}
                            data={sharedMapState?.sharedMaps?.length}
                            showEmptyMap={showEmptyMap}
                        />
                    </div>

                    <button className="collapse-btn-guage" onClick={collapseBarHandle("guages")}>
                        <div
                            style={{
                                transform: `${collapseBar.guageBar ? "" : "rotate(180deg)"}`,
                            }}
                        >
                            <ChevronRightIcon />
                        </div>
                    </button>
                </section>
            )}

            {mapType === 1 &&
                (allMapsLoading ? (
                    <LoadingAnimation />
                ) : (
                    <MyMapList
                        updateGroupState={updateGroupState}
                        percentageValue={myMapsListPercentage}
                        groupsListLoading={groupsListLoading}
                        groupsMapState={groupsMapState}
                        data={myMapState.data}
                        groupData={myMapState.groupsData}
                        secondGroupData={myMapState.secondGroupData}
                        handleToggleMapDelete={handleToggleMapDelete}
                        handleToggleMultipleMapsDelete={handleToggleMultipleMapsDelete}
                        handleShare={handleShare}
                        handleEdit={handleEdit}
                        handleEditGroup={handleEditGroup}
                        setModalMapTitle={setModalMapTitle}
                        myMapFavHandle={myMapFavHandle}
                        handleToggleSharedUserDelete={handleToggleSharedUserDelete}
                        getMyMapsGroups={getMyMapsGroups}
                        getMyGroupGroups={getMyGroupGroups}
                        collapseBar={collapseBar}
                        activeDrawing={activeDrawing}
                        setActiveDrawing={setActiveDrawing}
                        pinsLoading={myMapState.pinsLoading}
                        isNocUrl={isNocUrl}
                        toggleNocMapMarkers={toggleNocMapMarkers}
                        hideShare={hideShare}
                        handleContextClick={handleContextClick}
                        setShowState={setShowState}
                        showState={showState}
                        handleHideAllGroups={handleHideAllGroups}
                    />
                ))}

            {mapType === 2 && (
                <SharedMapList
                    percentageValue={sharedMapListPercentage}
                    sharedMapState={sharedMapState}
                    setSharedMapState={setSharedMapState}
                    sharedState={showSharedState}
                    setSharedState={setShowSharedState}
                    handleShare={handleShare}
                    handleEdit={handleEdit}
                    handleToggleMapShareDelete={handleToggleMapShareDelete}
                    handleToggleMapDelete={handleToggleMapDelete}
                    handleToggleMultipleMapsDelete={handleToggleMultipleMapsDelete}
                    myMapFavHandle={myMapFavHandle}
                    setModalMapTitle={setModalMapTitle}
                    handleToggleSharedUserDelete={handleToggleSharedUserDelete}
                    collapseBar={collapseBar}
                    pinsLoading={myMapState.pinsLoading}
                    isNocUrl={isNocUrl}
                    ownerModelState={ownerModelState}
                    setOwnerModelState={setOwnerModelState}
                    toggleNocMapMarkers={toggleNocMapMarkers}
                    getMyMapsGroups={getMyMapsGroups}
                    groupsListLoading={groupsListLoading}
                    groupData={myMapState.groupsData}
                    groupContain={myMapState.data?.groupContain}
                    GetSharedMapGroupGroups={GetSharedMapGroupGroups}
                    GetSharedMapGroups={GetSharedMapGroups}
                />
            )}

            {mapType === 3 && (
                <RouteMapList
                    percentageValue={routesListPercentage}
                    routeMapState={routeMapState}
                    setRouteMapState={setRouteMapState}
                    setModalMapTitle={setModalMapTitle}
                    myMapFavHandle={myMapFavHandle}
                    handleShare={handleShare}
                    handleToggleMapDelete={handleToggleMapDelete}
                    handleToggleMultipleMapsDelete={handleToggleMultipleMapsDelete}
                    handleToggleSharedUserDelete={handleToggleSharedUserDelete}
                    collapseBar={collapseBar}
                    pinsLoading={myMapState.pinsLoading}
                />
            )}

            {mapType === 4 && (
                <GroupMapList
                    percentageValue={groupsListPercentage}
                    groupsMapState={groupsMapState}
                    setGroupsMapState={setGroupsMapState}
                    groupData={myMapState.secondGroupData}
                    secondGroupData={myMapState.myGroupsSecondGroupData}
                    handleToggleMapDelete={handleToggleMapDelete}
                    setMapTypeState={setMapTypeState}
                    setConfirmDeleteMapOpen={setConfirmDeleteMapOpen}
                    handleToggleMultipleMapsDelete={handleToggleMultipleMapsDelete}
                    handleShare={handleShare}
                    myMapFavHandle={myMapFavHandle}
                    handleEditGroup={handleEditGroup}
                    setModalMapTitle={setModalMapTitle}
                    handleToggleSharedUserDelete={handleToggleSharedUserDelete}
                    getMyGroupGroups={getMyGroupGroups}
                    getMyGroupofOtherGroups={getMyGroupofOtherGroups}
                    groupsListLoading={groupsListLoading}
                    collapseBar={collapseBar}
                    pinsLoading={myMapState.pinsLoading}
                    hideShare={hideShare}
                    setShowGroupState={setShowGroupState}
                    showGroupState={showGroupState}
                    handleEdit={handleEdit}
                />
            )}

            <section className="myMap-delete-modal--container">
                {!!confirmDeleteMapOpen && (
                    <ReactPortal wrapperId="external_modal_container">
                        <DeleteMapModal
                            loadingDeleteMap={deleteMapOrGroupLoading}
                            setConfirmDeleteMapOpen={setConfirmDeleteMapOpen}
                            handleDeleteMap={handleDeleteMap}
                            setMapTypeState={setMapTypeState}
                            title={modalMapTitle}
                        />
                    </ReactPortal>
                )}
            </section>

            <section className="myMap-delete-modal--container">
                {!!confirmEditGroupOpen && (
                    <ReactPortal wrapperId="external_modal_container">
                        <EditGroupModal
                            handleClose={handleClose}
                            handleDeleteMap={handleDeleteMap}
                            handleUpdateGroup={handleUpdateGroup}
                            updateGroupState={updateGroupState}
                            setUpdateGroupState={setUpdateGroupState}
                            title={modalMapTitle}
                            id={mapTypeState.id}
                            mapId={mapTypeState.parentId}
                            parentGroup={mapTypeState.parentGroup}
                        />
                    </ReactPortal>
                )}
            </section>

            <section className="myMap-delete-modal--container">
                {deleteSharedUser.modalToggle && (
                    <ReactPortal wrapperId="external_modal_container">
                        <DeleteSharedUserModal
                            deleteSharedUserLoading={deleteSharedUserLoading}
                            handleDeleteSharedUser={handleDeleteSharedUser}
                            setDeleteSharedUser={setDeleteSharedUser}
                            deleteSharedUser={deleteSharedUser}
                        />
                    </ReactPortal>
                )}
            </section>

            {!!confirmShareOpen && (
                <ReactPortal wrapperId="external_modal_container">
                    <ShareModal
                        bulkEmail={bulkEmail}
                        setBulkEmail={setBulkEmail}
                        setBulkData={setBulkData}
                        bulkData={bulkData}
                        title={modalMapTitle}
                        shareMapEmail={shareMapEmail}
                        shareEmail={shareEmail}
                        setShareEmail={setShareEmail}
                        mapDataTypeState={mapTypeState}
                        setConfirmShareOpen={setConfirmShareOpen}
                    />
                </ReactPortal>
            )}

            <section className="myMap-delete-modal--container">
                {!!bulkData?.showBulkModal && (
                    <ReactPortal wrapperId="external_modal_container">
                        <BulkSharingModal
                            shareEmail={shareEmail}
                            shareMapEmail={shareMapEmail}
                            setBulkData={setBulkData}
                            bulkData={bulkData}
                        />
                    </ReactPortal>
                )}
            </section>
        </>
    );
};

export default Dashboard;
