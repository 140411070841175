import React from "react";
import dragTool from "../../assets/images/dragTool.png";

function GoogleMapResizer() {
    function handleGoogleMapHeight(e) {
        const googleMapContainer = document.querySelector("#google_map_container");

        googleMapContainer.style.cssText = `height:${e.clientY + 10}px !important`;
        googleMapContainer.style.minHeight = "calc(100vh - 100px)";
    }

    const handleDragMouseDown = (e) => {
        document.addEventListener("mousemove", handleGoogleMapHeight);
        document.addEventListener("mouseup", handleDragMouseUp, { once: true });
    };

    function handleDragMouseUp(e) {
        document.removeEventListener("mousemove", handleGoogleMapHeight);
    }

    return (
        <div className="resize-container">
            <div className="resize-wrapper" onMouseDown={handleDragMouseDown}>
                <img src={dragTool} alt="Drag Icon" />
            </div>
        </div>
    );
}

export default GoogleMapResizer;
