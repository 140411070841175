import axios from "axios";
import { GEO_MAPPING_BASE_URL_API } from "../config";

const authToken = localStorage.getItem("authToken");

export const axiosInstance = axios.create({
    baseURL: GEO_MAPPING_BASE_URL_API,
    headers: { "x-auth-token": authToken || "" },
});
export const axiosInstanceNoAuth = axios.create({
    baseURL: GEO_MAPPING_BASE_URL_API,
});

// const axiosInstance = axios.create({
//   baseURL: GEO_MAPPING_BASE_URL_API,
//   headers: { "x-auth-token": authToken ? authToken :""},
// });

// export default axiosInstance;
