import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import DotLoader from "../../components/DotLoaderBtn/DotLoader";
import closeIconGrey from "../../assets/images/svg/closeIconGrey.svg";

const DeleteMultipleMaps = (props) => {
    const { mapType } = useSelector((state) => state.mapTypeState);

    const [modalGuageTitle, setModalGuageTitle] = useState("");
    const [deleteText, setDeleteText] = useState("");

    const { loadingDeleteMap, setConfirmMultipleDeleteMapOpen, handleDeleteMaps, mapsList } = props;

    useEffect(() => {
        if (mapType === 1) {
            setModalGuageTitle("My Maps");
        }
        if (mapType === 2) {
            setModalGuageTitle("Shared Maps");
        }
        if (mapType === 3) {
            setModalGuageTitle("My Routes");
        }
        if (mapType === 4) {
            setModalGuageTitle("Container");
        }
    }, [mapType]);

    return (
        <div className="delete-modal-wrapper">
            <div className="text-right">
                <img
                    src={closeIconGrey}
                    alt="close icon"
                    className="deleteMarker-modal-close"
                    onClick={() => setConfirmMultipleDeleteMapOpen(false)}
                />
            </div>

            <h5 className="delete-modal-wrapper-title ">Remove map</h5>
            <div>
                <p className="content">
                    Are you sure you want to permanently remove <br />{" "}
                    <b>
                        {mapsList.map((item, index) => {
                            return (
                                <b key={index}>
                                    {item.title}, {""}
                                </b>
                            );
                        })}
                    </b>
                    from <b> {modalGuageTitle}?</b>
                </p>
                <p className="content">
                    Clicking remove permanently deletes all connections associated with this map.
                </p>
            </div>

            <div className="delete-modal-actions">
                <div className="input-fields">
                    <input
                        type="text"
                        name="delete-map-modal"
                        id="deleteMapInput"
                        autoComplete="off"
                        placeholder="Enter 'Remove' to confirm"
                        onChange={(e) => setDeleteText(e.target.value.toLowerCase())}
                    />
                </div>
                <div className="buttons">
                    <button
                        type="button"
                        onClick={() => setConfirmMultipleDeleteMapOpen(false)}
                        aria-label="Close"
                        className="btn border-cancel-btn"
                        disabled={loadingDeleteMap}
                    >
                        Cancel
                    </button>
                    {loadingDeleteMap ? (
                        <button
                            className="btn animate-dots-red del-outline-btn"
                            type="button"
                            disabled
                        >
                            <DotLoader />
                        </button>
                    ) : (
                        <button
                            type="button"
                            className="btn del-outline-btn"
                            onClick={() => handleDeleteMaps()}
                            disabled={!(deleteText === "remove")}
                        >
                            Confirm
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DeleteMultipleMaps;
