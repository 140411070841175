import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    toggle: false,
};

export const refreshSlice = createSlice({
    name: "refresh",
    initialState,
    reducers: {
        refresh: (state, payload) => {
            state.toggle = payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { refresh } = refreshSlice.actions;

export default refreshSlice.reducer;
